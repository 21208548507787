import React from 'react';
import { useRouter } from 'next/router';

const LanguagePicker = () => {
  const router = useRouter();
  const { locale, pathname, query } = router;

  const languages = [
    { value: 'fr', label: 'FR', flag: '🇫🇷' },
    { value: 'de', label: 'DE', flag: '🇩🇪' },
    { value: 'en', label: 'EN', flag: '🇬🇧' },
    { value: 'es', label: 'ES', flag: '🇪🇸' },
  ];

  const handleLanguageChange = (event) => {
    const newLocale = event.target.value;

    // Navigate to the same page with the selected locale
    console.debug('LanguagePicker', { newLocale, locale, pathname, query });
    router.push({ pathname, query }, undefined, { locale: newLocale });
  };

  return (
    <div className="language-picker">
      <select value={locale} onChange={handleLanguageChange} className="language-select">
        {languages.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.flag} {lang.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguagePicker;
