import strings from './strings.json'

if (!String.prototype.format) {
  String.prototype.format = function () {
    const args = arguments
    return this.replace(/{(\d+)}/g, (match, number) =>
      typeof args[number] !== 'undefined' ? args[number] : match
    )
  }
}

export function translate(key, locale, defaultLocale, ...args) {
  if (!key) return ''
  if (!locale || !strings[locale][key]) {
    console.warn(`Translation '${key}' for locale '${locale}' not found.`)
  }
  locale = locale ?? defaultLocale
  if (!defaultLocale) return key
  let s = strings[locale][key]
  if (!s) {
    s = strings[defaultLocale][key]
  }
  if(!s) {
    console.warn(`Translation '${key}' for locale '${locale}' not found.`)
  }
  if (args && s) {
    return s.format(...args)
  }
  return s || key
}

export const getPackTypeStr = (packType, t) =>
  t(
    {
      online: 'online',
      physical: 'physical',
      all: 'pack_type_all'
    }[packType]
  )
