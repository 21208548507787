"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gurubay
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrmApiAxiosParamCreator = exports.CorposApi = exports.CorposApiFactory = exports.CorposApiFp = exports.CorposApiAxiosParamCreator = exports.CorpoRolesApi = exports.CorpoRolesApiFactory = exports.CorpoRolesApiFp = exports.CorpoRolesApiAxiosParamCreator = exports.ChatsAdminApi = exports.ChatsAdminApiFactory = exports.ChatsAdminApiFp = exports.ChatsAdminApiAxiosParamCreator = exports.BookingsRetreatsApi = exports.BookingsRetreatsApiFactory = exports.BookingsRetreatsApiFp = exports.BookingsRetreatsApiAxiosParamCreator = exports.BookingsAdminApi = exports.BookingsAdminApiFactory = exports.BookingsAdminApiFp = exports.BookingsAdminApiAxiosParamCreator = exports.BookingsApi = exports.BookingsApiFactory = exports.BookingsApiFp = exports.BookingsApiAxiosParamCreator = exports.BillingsAdminApi = exports.BillingsAdminApiFactory = exports.BillingsAdminApiFp = exports.BillingsAdminApiAxiosParamCreator = exports.BillingsApi = exports.BillingsApiFactory = exports.BillingsApiFp = exports.BillingsApiAxiosParamCreator = exports.AudiencesApi = exports.AudiencesApiFactory = exports.AudiencesApiFp = exports.AudiencesApiAxiosParamCreator = exports.ActivitiesApi = exports.ActivitiesApiFactory = exports.ActivitiesApiFp = exports.ActivitiesApiAxiosParamCreator = exports.WaitingListStatus = exports.PromoType = exports.PremiumType = exports.PackType = exports.Frequency = exports.DiscountType = exports.CorpoRoleType = exports.ClaimStatus = exports.CivilStatus = void 0;
exports.ListingsApiFactory = exports.ListingsApiFp = exports.ListingsApiAxiosParamCreator = exports.ListingTemplatesApi = exports.ListingTemplatesApiFactory = exports.ListingTemplatesApiFp = exports.ListingTemplatesApiAxiosParamCreator = exports.ListingRequestsApi = exports.ListingRequestsApiFactory = exports.ListingRequestsApiFp = exports.ListingRequestsApiAxiosParamCreator = exports.LegalStatusesAdminApi = exports.LegalStatusesAdminApiFactory = exports.LegalStatusesAdminApiFp = exports.LegalStatusesAdminApiAxiosParamCreator = exports.LegalStatusesApi = exports.LegalStatusesApiFactory = exports.LegalStatusesApiFp = exports.LegalStatusesApiAxiosParamCreator = exports.InvoicesAdminApi = exports.InvoicesAdminApiFactory = exports.InvoicesAdminApiFp = exports.InvoicesAdminApiAxiosParamCreator = exports.InvoicesApi = exports.InvoicesApiFactory = exports.InvoicesApiFp = exports.InvoicesApiAxiosParamCreator = exports.InboxApi = exports.InboxApiFactory = exports.InboxApiFp = exports.InboxApiAxiosParamCreator = exports.ImagesApi = exports.ImagesApiFactory = exports.ImagesApiFp = exports.ImagesApiAxiosParamCreator = exports.HooksApi = exports.HooksApiFactory = exports.HooksApiFp = exports.HooksApiAxiosParamCreator = exports.EmailsApi = exports.EmailsApiFactory = exports.EmailsApiFp = exports.EmailsApiAxiosParamCreator = exports.CrmAdminApi = exports.CrmAdminApiFactory = exports.CrmAdminApiFp = exports.CrmAdminApiAxiosParamCreator = exports.CrmApi = exports.CrmApiFactory = exports.CrmApiFp = void 0;
exports.PropertiesApiAxiosParamCreator = exports.PromosAdminApi = exports.PromosAdminApiFactory = exports.PromosAdminApiFp = exports.PromosAdminApiAxiosParamCreator = exports.PromosApi = exports.PromosApiFactory = exports.PromosApiFp = exports.PromosApiAxiosParamCreator = exports.PremiumsAdminApi = exports.PremiumsAdminApiFactory = exports.PremiumsAdminApiFp = exports.PremiumsAdminApiAxiosParamCreator = exports.PremiumsApi = exports.PremiumsApiFactory = exports.PremiumsApiFp = exports.PremiumsApiAxiosParamCreator = exports.PrefsApi = exports.PrefsApiFactory = exports.PrefsApiFp = exports.PrefsApiAxiosParamCreator = exports.PaymentsAdminApi = exports.PaymentsAdminApiFactory = exports.PaymentsAdminApiFp = exports.PaymentsAdminApiAxiosParamCreator = exports.PaymentsApi = exports.PaymentsApiFactory = exports.PaymentsApiFp = exports.PaymentsApiAxiosParamCreator = exports.PacksAdminApi = exports.PacksAdminApiFactory = exports.PacksAdminApiFp = exports.PacksAdminApiAxiosParamCreator = exports.PacksApi = exports.PacksApiFactory = exports.PacksApiFp = exports.PacksApiAxiosParamCreator = exports.PackTemplatesApi = exports.PackTemplatesApiFactory = exports.PackTemplatesApiFp = exports.PackTemplatesApiAxiosParamCreator = exports.LoginApi = exports.LoginApiFactory = exports.LoginApiFp = exports.LoginApiAxiosParamCreator = exports.LocationsApi = exports.LocationsApiFactory = exports.LocationsApiFp = exports.LocationsApiAxiosParamCreator = exports.ListingsApi = void 0;
exports.VitalsAdminApi = exports.VitalsAdminApiFactory = exports.VitalsAdminApiFp = exports.VitalsAdminApiAxiosParamCreator = exports.UtilsApi = exports.UtilsApiFactory = exports.UtilsApiFp = exports.UtilsApiAxiosParamCreator = exports.UsersAdminApi = exports.UsersAdminApiFactory = exports.UsersAdminApiFp = exports.UsersAdminApiAxiosParamCreator = exports.UsersApi = exports.UsersApiFactory = exports.UsersApiFp = exports.UsersApiAxiosParamCreator = exports.ReviewsApi = exports.ReviewsApiFactory = exports.ReviewsApiFp = exports.ReviewsApiAxiosParamCreator = exports.RetreatsAdminApi = exports.RetreatsAdminApiFactory = exports.RetreatsAdminApiFp = exports.RetreatsAdminApiAxiosParamCreator = exports.RetreatsApi = exports.RetreatsApiFactory = exports.RetreatsApiFp = exports.RetreatsApiAxiosParamCreator = exports.PropertyTypesApi = exports.PropertyTypesApiFactory = exports.PropertyTypesApiFp = exports.PropertyTypesApiAxiosParamCreator = exports.PropertiesAdminApi = exports.PropertiesAdminApiFactory = exports.PropertiesAdminApiFp = exports.PropertiesAdminApiAxiosParamCreator = exports.PropertiesApi = exports.PropertiesApiFactory = exports.PropertiesApiFp = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("./base");
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var CivilStatus;
(function (CivilStatus) {
    CivilStatus["Mister"] = "mister";
    CivilStatus["Madam"] = "madam";
})(CivilStatus || (exports.CivilStatus = CivilStatus = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var ClaimStatus;
(function (ClaimStatus) {
    ClaimStatus[ClaimStatus["NUMBER_0"] = 0] = "NUMBER_0";
    ClaimStatus[ClaimStatus["NUMBER_1"] = 1] = "NUMBER_1";
    ClaimStatus[ClaimStatus["NUMBER_2"] = 2] = "NUMBER_2";
})(ClaimStatus || (exports.ClaimStatus = ClaimStatus = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var CorpoRoleType;
(function (CorpoRoleType) {
    CorpoRoleType[CorpoRoleType["NUMBER_0"] = 0] = "NUMBER_0";
    CorpoRoleType[CorpoRoleType["NUMBER_1"] = 1] = "NUMBER_1";
    CorpoRoleType[CorpoRoleType["NUMBER_99"] = 99] = "NUMBER_99";
})(CorpoRoleType || (exports.CorpoRoleType = CorpoRoleType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var DiscountType;
(function (DiscountType) {
    DiscountType[DiscountType["NUMBER_0"] = 0] = "NUMBER_0";
    DiscountType[DiscountType["NUMBER_1"] = 1] = "NUMBER_1";
})(DiscountType || (exports.DiscountType = DiscountType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var Frequency;
(function (Frequency) {
    Frequency["Daily"] = "daily";
    Frequency["Weekly"] = "weekly";
    Frequency["BiMonthly"] = "bi_monthly";
})(Frequency || (exports.Frequency = Frequency = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var PackType;
(function (PackType) {
    PackType["Online"] = "online";
    PackType["Physical"] = "physical";
    PackType["All"] = "all";
})(PackType || (exports.PackType = PackType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var PremiumType;
(function (PremiumType) {
    PremiumType[PremiumType["NUMBER_0"] = 0] = "NUMBER_0";
    PremiumType[PremiumType["NUMBER_1"] = 1] = "NUMBER_1";
    PremiumType[PremiumType["NUMBER_2"] = 2] = "NUMBER_2";
    PremiumType[PremiumType["NUMBER_3"] = 3] = "NUMBER_3";
})(PremiumType || (exports.PremiumType = PremiumType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var PromoType;
(function (PromoType) {
    PromoType[PromoType["NUMBER_1"] = 1] = "NUMBER_1";
    PromoType[PromoType["NUMBER_2"] = 2] = "NUMBER_2";
    PromoType[PromoType["NUMBER_3"] = 3] = "NUMBER_3";
})(PromoType || (exports.PromoType = PromoType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var WaitingListStatus;
(function (WaitingListStatus) {
    WaitingListStatus["Disabled"] = "disabled";
    WaitingListStatus["NotInList"] = "not_in_list";
    WaitingListStatus["InList"] = "in_list";
})(WaitingListStatus || (exports.WaitingListStatus = WaitingListStatus = {}));
/**
 * ActivitiesApi - axios parameter creator
 * @export
 */
var ActivitiesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new activity.
         * @summary Create Activity
         * @param {ActivityCreate} activityCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityApiV1ActivitiesPost: function (activityCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([activityCreate_1], args_1, true), void 0, function (activityCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'activityCreate' is not null or undefined
                            if (activityCreate === null || activityCreate === undefined) {
                                throw new base_1.RequiredError('activityCreate', 'Required parameter activityCreate was null or undefined when calling createActivityApiV1ActivitiesPost.');
                            }
                            localVarPath = "/api/v1/activities";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof activityCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(activityCreate !== undefined ? activityCreate : {}) : (activityCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an activity.
         * @summary Delete Activity
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityApiV1ActivitiesIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteActivityApiV1ActivitiesIdDelete.');
                            }
                            localVarPath = "/api/v1/activities/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve activities.
         * @summary Read Activities
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActivitiesApiV1ActivitiesGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/activities";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get activity by ID.
         * @summary Read Activity
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActivityApiV1ActivitiesIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readActivityApiV1ActivitiesIdGet.');
                    }
                    localVarPath = "/api/v1/activities/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Update an activity.
         * @summary Update Activity
         * @param {number} id
         * @param {ActivityUpdate} activityUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityApiV1ActivitiesIdPut: function (id_1, activityUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, activityUpdate_1], args_1, true), void 0, function (id, activityUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateActivityApiV1ActivitiesIdPut.');
                            }
                            // verify required parameter 'activityUpdate' is not null or undefined
                            if (activityUpdate === null || activityUpdate === undefined) {
                                throw new base_1.RequiredError('activityUpdate', 'Required parameter activityUpdate was null or undefined when calling updateActivityApiV1ActivitiesIdPut.');
                            }
                            localVarPath = "/api/v1/activities/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof activityUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(activityUpdate !== undefined ? activityUpdate : {}) : (activityUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ActivitiesApiAxiosParamCreator = ActivitiesApiAxiosParamCreator;
/**
 * ActivitiesApi - functional programming interface
 * @export
 */
var ActivitiesApiFp = function (configuration) {
    return {
        /**
         * Create new activity.
         * @summary Create Activity
         * @param {ActivityCreate} activityCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityApiV1ActivitiesPost: function (activityCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ActivitiesApiAxiosParamCreator)(configuration).createActivityApiV1ActivitiesPost(activityCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete an activity.
         * @summary Delete Activity
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityApiV1ActivitiesIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ActivitiesApiAxiosParamCreator)(configuration).deleteActivityApiV1ActivitiesIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve activities.
         * @summary Read Activities
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActivitiesApiV1ActivitiesGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ActivitiesApiAxiosParamCreator)(configuration).readActivitiesApiV1ActivitiesGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get activity by ID.
         * @summary Read Activity
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActivityApiV1ActivitiesIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ActivitiesApiAxiosParamCreator)(configuration).readActivityApiV1ActivitiesIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update an activity.
         * @summary Update Activity
         * @param {number} id
         * @param {ActivityUpdate} activityUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityApiV1ActivitiesIdPut: function (id, activityUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ActivitiesApiAxiosParamCreator)(configuration).updateActivityApiV1ActivitiesIdPut(id, activityUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ActivitiesApiFp = ActivitiesApiFp;
/**
 * ActivitiesApi - factory interface
 * @export
 */
var ActivitiesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new activity.
         * @summary Create Activity
         * @param {ActivityCreate} activityCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityApiV1ActivitiesPost: function (activityCreate, options) {
            return (0, exports.ActivitiesApiFp)(configuration).createActivityApiV1ActivitiesPost(activityCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an activity.
         * @summary Delete Activity
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityApiV1ActivitiesIdDelete: function (id, options) {
            return (0, exports.ActivitiesApiFp)(configuration).deleteActivityApiV1ActivitiesIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve activities.
         * @summary Read Activities
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActivitiesApiV1ActivitiesGet: function (skip, limit, options) {
            return (0, exports.ActivitiesApiFp)(configuration).readActivitiesApiV1ActivitiesGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get activity by ID.
         * @summary Read Activity
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActivityApiV1ActivitiesIdGet: function (id, options) {
            return (0, exports.ActivitiesApiFp)(configuration).readActivityApiV1ActivitiesIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update an activity.
         * @summary Update Activity
         * @param {number} id
         * @param {ActivityUpdate} activityUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityApiV1ActivitiesIdPut: function (id, activityUpdate, options) {
            return (0, exports.ActivitiesApiFp)(configuration).updateActivityApiV1ActivitiesIdPut(id, activityUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ActivitiesApiFactory = ActivitiesApiFactory;
/**
 * ActivitiesApi - object-oriented interface
 * @export
 * @class ActivitiesApi
 * @extends {BaseAPI}
 */
var ActivitiesApi = /** @class */ (function (_super) {
    __extends(ActivitiesApi, _super);
    function ActivitiesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new activity.
     * @summary Create Activity
     * @param {ActivityCreate} activityCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    ActivitiesApi.prototype.createActivityApiV1ActivitiesPost = function (activityCreate, options) {
        var _this = this;
        return (0, exports.ActivitiesApiFp)(this.configuration).createActivityApiV1ActivitiesPost(activityCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an activity.
     * @summary Delete Activity
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    ActivitiesApi.prototype.deleteActivityApiV1ActivitiesIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.ActivitiesApiFp)(this.configuration).deleteActivityApiV1ActivitiesIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve activities.
     * @summary Read Activities
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    ActivitiesApi.prototype.readActivitiesApiV1ActivitiesGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.ActivitiesApiFp)(this.configuration).readActivitiesApiV1ActivitiesGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get activity by ID.
     * @summary Read Activity
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    ActivitiesApi.prototype.readActivityApiV1ActivitiesIdGet = function (id, options) {
        var _this = this;
        return (0, exports.ActivitiesApiFp)(this.configuration).readActivityApiV1ActivitiesIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update an activity.
     * @summary Update Activity
     * @param {number} id
     * @param {ActivityUpdate} activityUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    ActivitiesApi.prototype.updateActivityApiV1ActivitiesIdPut = function (id, activityUpdate, options) {
        var _this = this;
        return (0, exports.ActivitiesApiFp)(this.configuration).updateActivityApiV1ActivitiesIdPut(id, activityUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ActivitiesApi;
}(base_1.BaseAPI));
exports.ActivitiesApi = ActivitiesApi;
/**
 * AudiencesApi - axios parameter creator
 * @export
 */
var AudiencesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new audience.
         * @summary Create Audience
         * @param {AudienceCreate} audienceCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudienceApiV1AudiencesPost: function (audienceCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([audienceCreate_1], args_1, true), void 0, function (audienceCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'audienceCreate' is not null or undefined
                            if (audienceCreate === null || audienceCreate === undefined) {
                                throw new base_1.RequiredError('audienceCreate', 'Required parameter audienceCreate was null or undefined when calling createAudienceApiV1AudiencesPost.');
                            }
                            localVarPath = "/api/v1/audiences";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof audienceCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(audienceCreate !== undefined ? audienceCreate : {}) : (audienceCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an audience.
         * @summary Delete Audience
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudienceApiV1AudiencesIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteAudienceApiV1AudiencesIdDelete.');
                            }
                            localVarPath = "/api/v1/audiences/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get audience by ID.
         * @summary Read Audience
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAudienceApiV1AudiencesIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readAudienceApiV1AudiencesIdGet.');
                    }
                    localVarPath = "/api/v1/audiences/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve audiences.
         * @summary Read Audiences
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAudiencesApiV1AudiencesGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/audiences";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Update an audience.
         * @summary Update Audience
         * @param {number} id
         * @param {AudienceUpdate} audienceUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudienceApiV1AudiencesIdPut: function (id_1, audienceUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, audienceUpdate_1], args_1, true), void 0, function (id, audienceUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateAudienceApiV1AudiencesIdPut.');
                            }
                            // verify required parameter 'audienceUpdate' is not null or undefined
                            if (audienceUpdate === null || audienceUpdate === undefined) {
                                throw new base_1.RequiredError('audienceUpdate', 'Required parameter audienceUpdate was null or undefined when calling updateAudienceApiV1AudiencesIdPut.');
                            }
                            localVarPath = "/api/v1/audiences/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof audienceUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(audienceUpdate !== undefined ? audienceUpdate : {}) : (audienceUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AudiencesApiAxiosParamCreator = AudiencesApiAxiosParamCreator;
/**
 * AudiencesApi - functional programming interface
 * @export
 */
var AudiencesApiFp = function (configuration) {
    return {
        /**
         * Create new audience.
         * @summary Create Audience
         * @param {AudienceCreate} audienceCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudienceApiV1AudiencesPost: function (audienceCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.AudiencesApiAxiosParamCreator)(configuration).createAudienceApiV1AudiencesPost(audienceCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete an audience.
         * @summary Delete Audience
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudienceApiV1AudiencesIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.AudiencesApiAxiosParamCreator)(configuration).deleteAudienceApiV1AudiencesIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get audience by ID.
         * @summary Read Audience
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAudienceApiV1AudiencesIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.AudiencesApiAxiosParamCreator)(configuration).readAudienceApiV1AudiencesIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve audiences.
         * @summary Read Audiences
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAudiencesApiV1AudiencesGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.AudiencesApiAxiosParamCreator)(configuration).readAudiencesApiV1AudiencesGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update an audience.
         * @summary Update Audience
         * @param {number} id
         * @param {AudienceUpdate} audienceUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudienceApiV1AudiencesIdPut: function (id, audienceUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.AudiencesApiAxiosParamCreator)(configuration).updateAudienceApiV1AudiencesIdPut(id, audienceUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.AudiencesApiFp = AudiencesApiFp;
/**
 * AudiencesApi - factory interface
 * @export
 */
var AudiencesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new audience.
         * @summary Create Audience
         * @param {AudienceCreate} audienceCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudienceApiV1AudiencesPost: function (audienceCreate, options) {
            return (0, exports.AudiencesApiFp)(configuration).createAudienceApiV1AudiencesPost(audienceCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an audience.
         * @summary Delete Audience
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudienceApiV1AudiencesIdDelete: function (id, options) {
            return (0, exports.AudiencesApiFp)(configuration).deleteAudienceApiV1AudiencesIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get audience by ID.
         * @summary Read Audience
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAudienceApiV1AudiencesIdGet: function (id, options) {
            return (0, exports.AudiencesApiFp)(configuration).readAudienceApiV1AudiencesIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve audiences.
         * @summary Read Audiences
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAudiencesApiV1AudiencesGet: function (skip, limit, options) {
            return (0, exports.AudiencesApiFp)(configuration).readAudiencesApiV1AudiencesGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update an audience.
         * @summary Update Audience
         * @param {number} id
         * @param {AudienceUpdate} audienceUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudienceApiV1AudiencesIdPut: function (id, audienceUpdate, options) {
            return (0, exports.AudiencesApiFp)(configuration).updateAudienceApiV1AudiencesIdPut(id, audienceUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AudiencesApiFactory = AudiencesApiFactory;
/**
 * AudiencesApi - object-oriented interface
 * @export
 * @class AudiencesApi
 * @extends {BaseAPI}
 */
var AudiencesApi = /** @class */ (function (_super) {
    __extends(AudiencesApi, _super);
    function AudiencesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new audience.
     * @summary Create Audience
     * @param {AudienceCreate} audienceCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesApi
     */
    AudiencesApi.prototype.createAudienceApiV1AudiencesPost = function (audienceCreate, options) {
        var _this = this;
        return (0, exports.AudiencesApiFp)(this.configuration).createAudienceApiV1AudiencesPost(audienceCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an audience.
     * @summary Delete Audience
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesApi
     */
    AudiencesApi.prototype.deleteAudienceApiV1AudiencesIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.AudiencesApiFp)(this.configuration).deleteAudienceApiV1AudiencesIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get audience by ID.
     * @summary Read Audience
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesApi
     */
    AudiencesApi.prototype.readAudienceApiV1AudiencesIdGet = function (id, options) {
        var _this = this;
        return (0, exports.AudiencesApiFp)(this.configuration).readAudienceApiV1AudiencesIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve audiences.
     * @summary Read Audiences
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesApi
     */
    AudiencesApi.prototype.readAudiencesApiV1AudiencesGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.AudiencesApiFp)(this.configuration).readAudiencesApiV1AudiencesGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update an audience.
     * @summary Update Audience
     * @param {number} id
     * @param {AudienceUpdate} audienceUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesApi
     */
    AudiencesApi.prototype.updateAudienceApiV1AudiencesIdPut = function (id, audienceUpdate, options) {
        var _this = this;
        return (0, exports.AudiencesApiFp)(this.configuration).updateAudienceApiV1AudiencesIdPut(id, audienceUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AudiencesApi;
}(base_1.BaseAPI));
exports.AudiencesApi = AudiencesApi;
/**
 * BillingsApi - axios parameter creator
 * @export
 */
var BillingsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Update billing.
         * @summary Create Billing
         * @param {BillingCreate} billingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingApiV1BillingsPost: function (billingCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingCreate_1], args_1, true), void 0, function (billingCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingCreate' is not null or undefined
                            if (billingCreate === null || billingCreate === undefined) {
                                throw new base_1.RequiredError('billingCreate', 'Required parameter billingCreate was null or undefined when calling createBillingApiV1BillingsPost.');
                            }
                            localVarPath = "/api/v1/billings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof billingCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(billingCreate !== undefined ? billingCreate : {}) : (billingCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read billing.
         * @summary Read Billing
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingApiV1BillingsBillingIdGet: function (billingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingId_1], args_1, true), void 0, function (billingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingId' is not null or undefined
                            if (billingId === null || billingId === undefined) {
                                throw new base_1.RequiredError('billingId', 'Required parameter billingId was null or undefined when calling readBillingApiV1BillingsBillingIdGet.');
                            }
                            localVarPath = "/api/v1/billings/{billing_id}"
                                .replace("{".concat("billing_id", "}"), encodeURIComponent(String(billingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Billing Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingMeApiV1BillingsMeGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/billings/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Billing
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBillingApiV1BillingsBillingIdDelete: function (billingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingId_1], args_1, true), void 0, function (billingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingId' is not null or undefined
                            if (billingId === null || billingId === undefined) {
                                throw new base_1.RequiredError('billingId', 'Required parameter billingId was null or undefined when calling removeBillingApiV1BillingsBillingIdDelete.');
                            }
                            localVarPath = "/api/v1/billings/{billing_id}"
                                .replace("{".concat("billing_id", "}"), encodeURIComponent(String(billingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Billing
         * @param {BillingUpdate} billingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingApiV1BillingsMePut: function (billingUpdate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingUpdate_1], args_1, true), void 0, function (billingUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingUpdate' is not null or undefined
                            if (billingUpdate === null || billingUpdate === undefined) {
                                throw new base_1.RequiredError('billingUpdate', 'Required parameter billingUpdate was null or undefined when calling updateBillingApiV1BillingsMePut.');
                            }
                            localVarPath = "/api/v1/billings/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof billingUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(billingUpdate !== undefined ? billingUpdate : {}) : (billingUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.BillingsApiAxiosParamCreator = BillingsApiAxiosParamCreator;
/**
 * BillingsApi - functional programming interface
 * @export
 */
var BillingsApiFp = function (configuration) {
    return {
        /**
         * Update billing.
         * @summary Create Billing
         * @param {BillingCreate} billingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingApiV1BillingsPost: function (billingCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsApiAxiosParamCreator)(configuration).createBillingApiV1BillingsPost(billingCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read billing.
         * @summary Read Billing
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingApiV1BillingsBillingIdGet: function (billingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsApiAxiosParamCreator)(configuration).readBillingApiV1BillingsBillingIdGet(billingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Billing Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingMeApiV1BillingsMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsApiAxiosParamCreator)(configuration).readBillingMeApiV1BillingsMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Billing
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBillingApiV1BillingsBillingIdDelete: function (billingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsApiAxiosParamCreator)(configuration).removeBillingApiV1BillingsBillingIdDelete(billingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Billing
         * @param {BillingUpdate} billingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingApiV1BillingsMePut: function (billingUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsApiAxiosParamCreator)(configuration).updateBillingApiV1BillingsMePut(billingUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.BillingsApiFp = BillingsApiFp;
/**
 * BillingsApi - factory interface
 * @export
 */
var BillingsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Update billing.
         * @summary Create Billing
         * @param {BillingCreate} billingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingApiV1BillingsPost: function (billingCreate, options) {
            return (0, exports.BillingsApiFp)(configuration).createBillingApiV1BillingsPost(billingCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read billing.
         * @summary Read Billing
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingApiV1BillingsBillingIdGet: function (billingId, options) {
            return (0, exports.BillingsApiFp)(configuration).readBillingApiV1BillingsBillingIdGet(billingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Billing Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingMeApiV1BillingsMeGet: function (options) {
            return (0, exports.BillingsApiFp)(configuration).readBillingMeApiV1BillingsMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Billing
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBillingApiV1BillingsBillingIdDelete: function (billingId, options) {
            return (0, exports.BillingsApiFp)(configuration).removeBillingApiV1BillingsBillingIdDelete(billingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Billing
         * @param {BillingUpdate} billingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingApiV1BillingsMePut: function (billingUpdate, options) {
            return (0, exports.BillingsApiFp)(configuration).updateBillingApiV1BillingsMePut(billingUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.BillingsApiFactory = BillingsApiFactory;
/**
 * BillingsApi - object-oriented interface
 * @export
 * @class BillingsApi
 * @extends {BaseAPI}
 */
var BillingsApi = /** @class */ (function (_super) {
    __extends(BillingsApi, _super);
    function BillingsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Update billing.
     * @summary Create Billing
     * @param {BillingCreate} billingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsApi
     */
    BillingsApi.prototype.createBillingApiV1BillingsPost = function (billingCreate, options) {
        var _this = this;
        return (0, exports.BillingsApiFp)(this.configuration).createBillingApiV1BillingsPost(billingCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read billing.
     * @summary Read Billing
     * @param {number} billingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsApi
     */
    BillingsApi.prototype.readBillingApiV1BillingsBillingIdGet = function (billingId, options) {
        var _this = this;
        return (0, exports.BillingsApiFp)(this.configuration).readBillingApiV1BillingsBillingIdGet(billingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Billing Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsApi
     */
    BillingsApi.prototype.readBillingMeApiV1BillingsMeGet = function (options) {
        var _this = this;
        return (0, exports.BillingsApiFp)(this.configuration).readBillingMeApiV1BillingsMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Billing
     * @param {number} billingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsApi
     */
    BillingsApi.prototype.removeBillingApiV1BillingsBillingIdDelete = function (billingId, options) {
        var _this = this;
        return (0, exports.BillingsApiFp)(this.configuration).removeBillingApiV1BillingsBillingIdDelete(billingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Billing
     * @param {BillingUpdate} billingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsApi
     */
    BillingsApi.prototype.updateBillingApiV1BillingsMePut = function (billingUpdate, options) {
        var _this = this;
        return (0, exports.BillingsApiFp)(this.configuration).updateBillingApiV1BillingsMePut(billingUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BillingsApi;
}(base_1.BaseAPI));
exports.BillingsApi = BillingsApi;
/**
 * BillingsAdminApi - axios parameter creator
 * @export
 */
var BillingsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Update billing.
         * @summary Create Billing Admin
         * @param {BillingCreateAdmin} billingCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingAdminApiV1AdminBillingsPost: function (billingCreateAdmin_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingCreateAdmin_1], args_1, true), void 0, function (billingCreateAdmin, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingCreateAdmin' is not null or undefined
                            if (billingCreateAdmin === null || billingCreateAdmin === undefined) {
                                throw new base_1.RequiredError('billingCreateAdmin', 'Required parameter billingCreateAdmin was null or undefined when calling createBillingAdminApiV1AdminBillingsPost.');
                            }
                            localVarPath = "/api/v1/admin/billings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof billingCreateAdmin !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(billingCreateAdmin !== undefined ? billingCreateAdmin : {}) : (billingCreateAdmin || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * * Read billings
         * @summary Read Billings Admin
         * @param {number} [ownerId]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingsAdminApiV1AdminBillingsGet: function (ownerId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ownerId_1, skip_1, limit_1], args_1, true), void 0, function (ownerId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/admin/billings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (ownerId !== undefined) {
                                localVarQueryParameter['owner_id'] = ownerId;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Billing Admin
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBillingAdminApiV1AdminBillingsBillingIdDelete: function (billingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingId_1], args_1, true), void 0, function (billingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingId' is not null or undefined
                            if (billingId === null || billingId === undefined) {
                                throw new base_1.RequiredError('billingId', 'Required parameter billingId was null or undefined when calling removeBillingAdminApiV1AdminBillingsBillingIdDelete.');
                            }
                            localVarPath = "/api/v1/admin/billings/{billing_id}"
                                .replace("{".concat("billing_id", "}"), encodeURIComponent(String(billingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Billing Admin
         * @param {number} billingId
         * @param {BillingUpdate} billingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingAdminApiV1AdminBillingsBillingIdPut: function (billingId_1, billingUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([billingId_1, billingUpdate_1], args_1, true), void 0, function (billingId, billingUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'billingId' is not null or undefined
                            if (billingId === null || billingId === undefined) {
                                throw new base_1.RequiredError('billingId', 'Required parameter billingId was null or undefined when calling updateBillingAdminApiV1AdminBillingsBillingIdPut.');
                            }
                            // verify required parameter 'billingUpdate' is not null or undefined
                            if (billingUpdate === null || billingUpdate === undefined) {
                                throw new base_1.RequiredError('billingUpdate', 'Required parameter billingUpdate was null or undefined when calling updateBillingAdminApiV1AdminBillingsBillingIdPut.');
                            }
                            localVarPath = "/api/v1/admin/billings/{billing_id}"
                                .replace("{".concat("billing_id", "}"), encodeURIComponent(String(billingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof billingUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(billingUpdate !== undefined ? billingUpdate : {}) : (billingUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.BillingsAdminApiAxiosParamCreator = BillingsAdminApiAxiosParamCreator;
/**
 * BillingsAdminApi - functional programming interface
 * @export
 */
var BillingsAdminApiFp = function (configuration) {
    return {
        /**
         * Update billing.
         * @summary Create Billing Admin
         * @param {BillingCreateAdmin} billingCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingAdminApiV1AdminBillingsPost: function (billingCreateAdmin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsAdminApiAxiosParamCreator)(configuration).createBillingAdminApiV1AdminBillingsPost(billingCreateAdmin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * * Read billings
         * @summary Read Billings Admin
         * @param {number} [ownerId]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingsAdminApiV1AdminBillingsGet: function (ownerId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsAdminApiAxiosParamCreator)(configuration).readBillingsAdminApiV1AdminBillingsGet(ownerId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Billing Admin
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBillingAdminApiV1AdminBillingsBillingIdDelete: function (billingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsAdminApiAxiosParamCreator)(configuration).removeBillingAdminApiV1AdminBillingsBillingIdDelete(billingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Billing Admin
         * @param {number} billingId
         * @param {BillingUpdate} billingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingAdminApiV1AdminBillingsBillingIdPut: function (billingId, billingUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BillingsAdminApiAxiosParamCreator)(configuration).updateBillingAdminApiV1AdminBillingsBillingIdPut(billingId, billingUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.BillingsAdminApiFp = BillingsAdminApiFp;
/**
 * BillingsAdminApi - factory interface
 * @export
 */
var BillingsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Update billing.
         * @summary Create Billing Admin
         * @param {BillingCreateAdmin} billingCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBillingAdminApiV1AdminBillingsPost: function (billingCreateAdmin, options) {
            return (0, exports.BillingsAdminApiFp)(configuration).createBillingAdminApiV1AdminBillingsPost(billingCreateAdmin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * * Read billings
         * @summary Read Billings Admin
         * @param {number} [ownerId]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBillingsAdminApiV1AdminBillingsGet: function (ownerId, skip, limit, options) {
            return (0, exports.BillingsAdminApiFp)(configuration).readBillingsAdminApiV1AdminBillingsGet(ownerId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Billing Admin
         * @param {number} billingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBillingAdminApiV1AdminBillingsBillingIdDelete: function (billingId, options) {
            return (0, exports.BillingsAdminApiFp)(configuration).removeBillingAdminApiV1AdminBillingsBillingIdDelete(billingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Billing Admin
         * @param {number} billingId
         * @param {BillingUpdate} billingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingAdminApiV1AdminBillingsBillingIdPut: function (billingId, billingUpdate, options) {
            return (0, exports.BillingsAdminApiFp)(configuration).updateBillingAdminApiV1AdminBillingsBillingIdPut(billingId, billingUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.BillingsAdminApiFactory = BillingsAdminApiFactory;
/**
 * BillingsAdminApi - object-oriented interface
 * @export
 * @class BillingsAdminApi
 * @extends {BaseAPI}
 */
var BillingsAdminApi = /** @class */ (function (_super) {
    __extends(BillingsAdminApi, _super);
    function BillingsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Update billing.
     * @summary Create Billing Admin
     * @param {BillingCreateAdmin} billingCreateAdmin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsAdminApi
     */
    BillingsAdminApi.prototype.createBillingAdminApiV1AdminBillingsPost = function (billingCreateAdmin, options) {
        var _this = this;
        return (0, exports.BillingsAdminApiFp)(this.configuration).createBillingAdminApiV1AdminBillingsPost(billingCreateAdmin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * * Read billings
     * @summary Read Billings Admin
     * @param {number} [ownerId]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsAdminApi
     */
    BillingsAdminApi.prototype.readBillingsAdminApiV1AdminBillingsGet = function (ownerId, skip, limit, options) {
        var _this = this;
        return (0, exports.BillingsAdminApiFp)(this.configuration).readBillingsAdminApiV1AdminBillingsGet(ownerId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Billing Admin
     * @param {number} billingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsAdminApi
     */
    BillingsAdminApi.prototype.removeBillingAdminApiV1AdminBillingsBillingIdDelete = function (billingId, options) {
        var _this = this;
        return (0, exports.BillingsAdminApiFp)(this.configuration).removeBillingAdminApiV1AdminBillingsBillingIdDelete(billingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Billing Admin
     * @param {number} billingId
     * @param {BillingUpdate} billingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingsAdminApi
     */
    BillingsAdminApi.prototype.updateBillingAdminApiV1AdminBillingsBillingIdPut = function (billingId, billingUpdate, options) {
        var _this = this;
        return (0, exports.BillingsAdminApiFp)(this.configuration).updateBillingAdminApiV1AdminBillingsBillingIdPut(billingId, billingUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BillingsAdminApi;
}(base_1.BaseAPI));
exports.BillingsAdminApi = BillingsAdminApi;
/**
 * BookingsApi - axios parameter creator
 * @export
 */
var BookingsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Cancel a booking.
         * @summary Cancel Booking
         * @param {number} [bookingId]
         * @param {number} [listingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingApiV1BookingsCancelPut: function (bookingId_1, listingId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bookingId_1, listingId_1], args_1, true), void 0, function (bookingId, listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/bookings/cancel";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (bookingId !== undefined) {
                                localVarQueryParameter['booking_id'] = bookingId;
                            }
                            if (listingId !== undefined) {
                                localVarQueryParameter['listing_id'] = listingId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new booking.
         * @summary Create Booking
         * @param {BookingCreate} bookingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingApiV1BookingsPost: function (bookingCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bookingCreate_1], args_1, true), void 0, function (bookingCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'bookingCreate' is not null or undefined
                            if (bookingCreate === null || bookingCreate === undefined) {
                                throw new base_1.RequiredError('bookingCreate', 'Required parameter bookingCreate was null or undefined when calling createBookingApiV1BookingsPost.');
                            }
                            localVarPath = "/api/v1/bookings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof bookingCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(bookingCreate !== undefined ? bookingCreate : {}) : (bookingCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an booking.
         * @summary Delete Booking
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingApiV1BookingsIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteBookingApiV1BookingsIdDelete.');
                            }
                            localVarPath = "/api/v1/bookings/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Is Referrable
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReferrableApiV1BookingsIsReferrableListingIdGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling isReferrableApiV1BookingsIsReferrableListingIdGet.');
                            }
                            localVarPath = "/api/v1/bookings/is-referrable/{listing_id}"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get booking by ID.
         * @summary Read Booking
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingApiV1BookingsIdOrSlugGet: function (idOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([idOrSlug_1], args_1, true), void 0, function (idOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'idOrSlug' is not null or undefined
                            if (idOrSlug === null || idOrSlug === undefined) {
                                throw new base_1.RequiredError('idOrSlug', 'Required parameter idOrSlug was null or undefined when calling readBookingApiV1BookingsIdOrSlugGet.');
                            }
                            localVarPath = "/api/v1/bookings/{id_or_slug}"
                                .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Booking From Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingFromListingApiV1BookingsFromListingGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readBookingFromListingApiV1BookingsFromListingGet.');
                            }
                            localVarPath = "/api/v1/bookings/from-listing";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (listingId !== undefined) {
                                localVarQueryParameter['listing_id'] = listingId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve bookings.
         * @summary Read Bookings
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [owner]
         * @param {number} [guru]
         * @param {string} [state]
         * @param {string} [payin]
         * @param {string} [refund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsApiV1BookingsGet: function (skip_1, limit_1, owner_1, guru_1, state_1, payin_1, refund_1) {
            var args_1 = [];
            for (var _i = 7; _i < arguments.length; _i++) {
                args_1[_i - 7] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, owner_1, guru_1, state_1, payin_1, refund_1], args_1, true), void 0, function (skip, limit, owner, guru, state, payin, refund, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/bookings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            if (guru !== undefined) {
                                localVarQueryParameter['guru'] = guru;
                            }
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            if (payin !== undefined) {
                                localVarQueryParameter['payin'] = payin;
                            }
                            if (refund !== undefined) {
                                localVarQueryParameter['refund'] = refund;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve bookings made by current user (i.e. current user is student here)
         * @summary Read Bookings By Me
         * @param {number} [listing]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsByMeApiV1BookingsByMeGet: function (listing_1, skip_1, limit_1, past_1, state_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listing_1, skip_1, limit_1, past_1, state_1], args_1, true), void 0, function (listing, skip, limit, past, state, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/bookings/by-me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (listing !== undefined) {
                                localVarQueryParameter['listing'] = listing;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (past !== undefined) {
                                localVarQueryParameter['past'] = past;
                            }
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve bookings made with current user (i.e. current user is guru here)
         * @summary Read Bookings With Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsWithMeApiV1BookingsWithMeGet: function (skip_1, limit_1, past_1, state_1, owner_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, past_1, state_1, owner_1], args_1, true), void 0, function (skip, limit, past, state, owner, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/bookings/with-me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (past !== undefined) {
                                localVarQueryParameter['past'] = past;
                            }
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update booking.
         * @summary Update Booking
         * @param {number} id
         * @param {BookingUpdate} bookingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingApiV1BookingsIdPut: function (id_1, bookingUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, bookingUpdate_1], args_1, true), void 0, function (id, bookingUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateBookingApiV1BookingsIdPut.');
                            }
                            // verify required parameter 'bookingUpdate' is not null or undefined
                            if (bookingUpdate === null || bookingUpdate === undefined) {
                                throw new base_1.RequiredError('bookingUpdate', 'Required parameter bookingUpdate was null or undefined when calling updateBookingApiV1BookingsIdPut.');
                            }
                            localVarPath = "/api/v1/bookings/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof bookingUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(bookingUpdate !== undefined ? bookingUpdate : {}) : (bookingUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.BookingsApiAxiosParamCreator = BookingsApiAxiosParamCreator;
/**
 * BookingsApi - functional programming interface
 * @export
 */
var BookingsApiFp = function (configuration) {
    return {
        /**
         * Cancel a booking.
         * @summary Cancel Booking
         * @param {number} [bookingId]
         * @param {number} [listingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingApiV1BookingsCancelPut: function (bookingId, listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).cancelBookingApiV1BookingsCancelPut(bookingId, listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new booking.
         * @summary Create Booking
         * @param {BookingCreate} bookingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingApiV1BookingsPost: function (bookingCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).createBookingApiV1BookingsPost(bookingCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete an booking.
         * @summary Delete Booking
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingApiV1BookingsIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).deleteBookingApiV1BookingsIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Is Referrable
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReferrableApiV1BookingsIsReferrableListingIdGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).isReferrableApiV1BookingsIsReferrableListingIdGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get booking by ID.
         * @summary Read Booking
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingApiV1BookingsIdOrSlugGet: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).readBookingApiV1BookingsIdOrSlugGet(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Booking From Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingFromListingApiV1BookingsFromListingGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).readBookingFromListingApiV1BookingsFromListingGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve bookings.
         * @summary Read Bookings
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [owner]
         * @param {number} [guru]
         * @param {string} [state]
         * @param {string} [payin]
         * @param {string} [refund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsApiV1BookingsGet: function (skip, limit, owner, guru, state, payin, refund, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).readBookingsApiV1BookingsGet(skip, limit, owner, guru, state, payin, refund, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve bookings made by current user (i.e. current user is student here)
         * @summary Read Bookings By Me
         * @param {number} [listing]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsByMeApiV1BookingsByMeGet: function (listing, skip, limit, past, state, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).readBookingsByMeApiV1BookingsByMeGet(listing, skip, limit, past, state, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve bookings made with current user (i.e. current user is guru here)
         * @summary Read Bookings With Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsWithMeApiV1BookingsWithMeGet: function (skip, limit, past, state, owner, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).readBookingsWithMeApiV1BookingsWithMeGet(skip, limit, past, state, owner, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update booking.
         * @summary Update Booking
         * @param {number} id
         * @param {BookingUpdate} bookingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingApiV1BookingsIdPut: function (id, bookingUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsApiAxiosParamCreator)(configuration).updateBookingApiV1BookingsIdPut(id, bookingUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.BookingsApiFp = BookingsApiFp;
/**
 * BookingsApi - factory interface
 * @export
 */
var BookingsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Cancel a booking.
         * @summary Cancel Booking
         * @param {number} [bookingId]
         * @param {number} [listingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingApiV1BookingsCancelPut: function (bookingId, listingId, options) {
            return (0, exports.BookingsApiFp)(configuration).cancelBookingApiV1BookingsCancelPut(bookingId, listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new booking.
         * @summary Create Booking
         * @param {BookingCreate} bookingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingApiV1BookingsPost: function (bookingCreate, options) {
            return (0, exports.BookingsApiFp)(configuration).createBookingApiV1BookingsPost(bookingCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an booking.
         * @summary Delete Booking
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingApiV1BookingsIdDelete: function (id, options) {
            return (0, exports.BookingsApiFp)(configuration).deleteBookingApiV1BookingsIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Is Referrable
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReferrableApiV1BookingsIsReferrableListingIdGet: function (listingId, options) {
            return (0, exports.BookingsApiFp)(configuration).isReferrableApiV1BookingsIsReferrableListingIdGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get booking by ID.
         * @summary Read Booking
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingApiV1BookingsIdOrSlugGet: function (idOrSlug, options) {
            return (0, exports.BookingsApiFp)(configuration).readBookingApiV1BookingsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Booking From Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingFromListingApiV1BookingsFromListingGet: function (listingId, options) {
            return (0, exports.BookingsApiFp)(configuration).readBookingFromListingApiV1BookingsFromListingGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve bookings.
         * @summary Read Bookings
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [owner]
         * @param {number} [guru]
         * @param {string} [state]
         * @param {string} [payin]
         * @param {string} [refund]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsApiV1BookingsGet: function (skip, limit, owner, guru, state, payin, refund, options) {
            return (0, exports.BookingsApiFp)(configuration).readBookingsApiV1BookingsGet(skip, limit, owner, guru, state, payin, refund, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve bookings made by current user (i.e. current user is student here)
         * @summary Read Bookings By Me
         * @param {number} [listing]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsByMeApiV1BookingsByMeGet: function (listing, skip, limit, past, state, options) {
            return (0, exports.BookingsApiFp)(configuration).readBookingsByMeApiV1BookingsByMeGet(listing, skip, limit, past, state, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve bookings made with current user (i.e. current user is guru here)
         * @summary Read Bookings With Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsWithMeApiV1BookingsWithMeGet: function (skip, limit, past, state, owner, options) {
            return (0, exports.BookingsApiFp)(configuration).readBookingsWithMeApiV1BookingsWithMeGet(skip, limit, past, state, owner, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update booking.
         * @summary Update Booking
         * @param {number} id
         * @param {BookingUpdate} bookingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingApiV1BookingsIdPut: function (id, bookingUpdate, options) {
            return (0, exports.BookingsApiFp)(configuration).updateBookingApiV1BookingsIdPut(id, bookingUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.BookingsApiFactory = BookingsApiFactory;
/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
var BookingsApi = /** @class */ (function (_super) {
    __extends(BookingsApi, _super);
    function BookingsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Cancel a booking.
     * @summary Cancel Booking
     * @param {number} [bookingId]
     * @param {number} [listingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.cancelBookingApiV1BookingsCancelPut = function (bookingId, listingId, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).cancelBookingApiV1BookingsCancelPut(bookingId, listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new booking.
     * @summary Create Booking
     * @param {BookingCreate} bookingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.createBookingApiV1BookingsPost = function (bookingCreate, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).createBookingApiV1BookingsPost(bookingCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an booking.
     * @summary Delete Booking
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.deleteBookingApiV1BookingsIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).deleteBookingApiV1BookingsIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Is Referrable
     * @param {number} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.isReferrableApiV1BookingsIsReferrableListingIdGet = function (listingId, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).isReferrableApiV1BookingsIsReferrableListingIdGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get booking by ID.
     * @summary Read Booking
     * @param {number | string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.readBookingApiV1BookingsIdOrSlugGet = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).readBookingApiV1BookingsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Booking From Listing
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.readBookingFromListingApiV1BookingsFromListingGet = function (listingId, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).readBookingFromListingApiV1BookingsFromListingGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve bookings.
     * @summary Read Bookings
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [owner]
     * @param {number} [guru]
     * @param {string} [state]
     * @param {string} [payin]
     * @param {string} [refund]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.readBookingsApiV1BookingsGet = function (skip, limit, owner, guru, state, payin, refund, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).readBookingsApiV1BookingsGet(skip, limit, owner, guru, state, payin, refund, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve bookings made by current user (i.e. current user is student here)
     * @summary Read Bookings By Me
     * @param {number} [listing]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {boolean} [past]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.readBookingsByMeApiV1BookingsByMeGet = function (listing, skip, limit, past, state, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).readBookingsByMeApiV1BookingsByMeGet(listing, skip, limit, past, state, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve bookings made with current user (i.e. current user is guru here)
     * @summary Read Bookings With Me
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {boolean} [past]
     * @param {string} [state]
     * @param {number} [owner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.readBookingsWithMeApiV1BookingsWithMeGet = function (skip, limit, past, state, owner, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).readBookingsWithMeApiV1BookingsWithMeGet(skip, limit, past, state, owner, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update booking.
     * @summary Update Booking
     * @param {number} id
     * @param {BookingUpdate} bookingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    BookingsApi.prototype.updateBookingApiV1BookingsIdPut = function (id, bookingUpdate, options) {
        var _this = this;
        return (0, exports.BookingsApiFp)(this.configuration).updateBookingApiV1BookingsIdPut(id, bookingUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BookingsApi;
}(base_1.BaseAPI));
exports.BookingsApi = BookingsApi;
/**
 * BookingsAdminApi - axios parameter creator
 * @export
 */
var BookingsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get Report Admin
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAdminApiV1AdminBookingsReportGet: function (limit_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([limit_1], args_1, true), void 0, function (limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/admin/bookings/report";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Handle Referral
         * @param {number} bookingId
         * @param {string} referrerCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleReferralApiV1AdminBookingsHandleReferralGet: function (bookingId_1, referrerCode_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bookingId_1, referrerCode_1], args_1, true), void 0, function (bookingId, referrerCode, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'bookingId' is not null or undefined
                            if (bookingId === null || bookingId === undefined) {
                                throw new base_1.RequiredError('bookingId', 'Required parameter bookingId was null or undefined when calling handleReferralApiV1AdminBookingsHandleReferralGet.');
                            }
                            // verify required parameter 'referrerCode' is not null or undefined
                            if (referrerCode === null || referrerCode === undefined) {
                                throw new base_1.RequiredError('referrerCode', 'Required parameter referrerCode was null or undefined when calling handleReferralApiV1AdminBookingsHandleReferralGet.');
                            }
                            localVarPath = "/api/v1/admin/bookings/handle-referral";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (bookingId !== undefined) {
                                localVarQueryParameter['booking_id'] = bookingId;
                            }
                            if (referrerCode !== undefined) {
                                localVarQueryParameter['referrer_code'] = referrerCode;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.BookingsAdminApiAxiosParamCreator = BookingsAdminApiAxiosParamCreator;
/**
 * BookingsAdminApi - functional programming interface
 * @export
 */
var BookingsAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get Report Admin
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAdminApiV1AdminBookingsReportGet: function (limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsAdminApiAxiosParamCreator)(configuration).getReportAdminApiV1AdminBookingsReportGet(limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Handle Referral
         * @param {number} bookingId
         * @param {string} referrerCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleReferralApiV1AdminBookingsHandleReferralGet: function (bookingId, referrerCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsAdminApiAxiosParamCreator)(configuration).handleReferralApiV1AdminBookingsHandleReferralGet(bookingId, referrerCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.BookingsAdminApiFp = BookingsAdminApiFp;
/**
 * BookingsAdminApi - factory interface
 * @export
 */
var BookingsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get Report Admin
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAdminApiV1AdminBookingsReportGet: function (limit, options) {
            return (0, exports.BookingsAdminApiFp)(configuration).getReportAdminApiV1AdminBookingsReportGet(limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Handle Referral
         * @param {number} bookingId
         * @param {string} referrerCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleReferralApiV1AdminBookingsHandleReferralGet: function (bookingId, referrerCode, options) {
            return (0, exports.BookingsAdminApiFp)(configuration).handleReferralApiV1AdminBookingsHandleReferralGet(bookingId, referrerCode, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.BookingsAdminApiFactory = BookingsAdminApiFactory;
/**
 * BookingsAdminApi - object-oriented interface
 * @export
 * @class BookingsAdminApi
 * @extends {BaseAPI}
 */
var BookingsAdminApi = /** @class */ (function (_super) {
    __extends(BookingsAdminApi, _super);
    function BookingsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get Report Admin
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsAdminApi
     */
    BookingsAdminApi.prototype.getReportAdminApiV1AdminBookingsReportGet = function (limit, options) {
        var _this = this;
        return (0, exports.BookingsAdminApiFp)(this.configuration).getReportAdminApiV1AdminBookingsReportGet(limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Handle Referral
     * @param {number} bookingId
     * @param {string} referrerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsAdminApi
     */
    BookingsAdminApi.prototype.handleReferralApiV1AdminBookingsHandleReferralGet = function (bookingId, referrerCode, options) {
        var _this = this;
        return (0, exports.BookingsAdminApiFp)(this.configuration).handleReferralApiV1AdminBookingsHandleReferralGet(bookingId, referrerCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BookingsAdminApi;
}(base_1.BaseAPI));
exports.BookingsAdminApi = BookingsAdminApi;
/**
 * BookingsRetreatsApi - axios parameter creator
 * @export
 */
var BookingsRetreatsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Cancel a retreat booking
         * @summary Cancel Booking
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingApiV1BookingsRetreatsCancelIdPut: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling cancelBookingApiV1BookingsRetreatsCancelIdPut.');
                            }
                            localVarPath = "/api/v1/bookings/retreats/cancel/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat booking.
         * @summary Create Booking
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BodyCreateBookingApiV1BookingsRetreatsPost} bodyCreateBookingApiV1BookingsRetreatsPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingApiV1BookingsRetreatsPost: function (cardId_1, saveCard_1, returnUrl_1, bodyCreateBookingApiV1BookingsRetreatsPost_1, accept_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cardId_1, saveCard_1, returnUrl_1, bodyCreateBookingApiV1BookingsRetreatsPost_1, accept_1], args_1, true), void 0, function (cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling createBookingApiV1BookingsRetreatsPost.');
                            }
                            // verify required parameter 'saveCard' is not null or undefined
                            if (saveCard === null || saveCard === undefined) {
                                throw new base_1.RequiredError('saveCard', 'Required parameter saveCard was null or undefined when calling createBookingApiV1BookingsRetreatsPost.');
                            }
                            // verify required parameter 'returnUrl' is not null or undefined
                            if (returnUrl === null || returnUrl === undefined) {
                                throw new base_1.RequiredError('returnUrl', 'Required parameter returnUrl was null or undefined when calling createBookingApiV1BookingsRetreatsPost.');
                            }
                            // verify required parameter 'bodyCreateBookingApiV1BookingsRetreatsPost' is not null or undefined
                            if (bodyCreateBookingApiV1BookingsRetreatsPost === null || bodyCreateBookingApiV1BookingsRetreatsPost === undefined) {
                                throw new base_1.RequiredError('bodyCreateBookingApiV1BookingsRetreatsPost', 'Required parameter bodyCreateBookingApiV1BookingsRetreatsPost was null or undefined when calling createBookingApiV1BookingsRetreatsPost.');
                            }
                            localVarPath = "/api/v1/bookings/retreats";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (cardId !== undefined) {
                                localVarQueryParameter['card_id'] = cardId;
                            }
                            if (saveCard !== undefined) {
                                localVarQueryParameter['save_card'] = saveCard;
                            }
                            if (returnUrl !== undefined) {
                                localVarQueryParameter['return_url'] = returnUrl;
                            }
                            if (accept !== undefined && accept !== null) {
                                localVarHeaderParameter['accept'] = String(accept);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof bodyCreateBookingApiV1BookingsRetreatsPost !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(bodyCreateBookingApiV1BookingsRetreatsPost !== undefined ? bodyCreateBookingApiV1BookingsRetreatsPost : {}) : (bodyCreateBookingApiV1BookingsRetreatsPost || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Is Referrable
         * @param {number} retreatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet: function (retreatId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([retreatId_1], args_1, true), void 0, function (retreatId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'retreatId' is not null or undefined
                            if (retreatId === null || retreatId === undefined) {
                                throw new base_1.RequiredError('retreatId', 'Required parameter retreatId was null or undefined when calling isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet.');
                            }
                            localVarPath = "/api/v1/bookings/retreats/is-referrable/{retreat_id}"
                                .replace("{".concat("retreat_id", "}"), encodeURIComponent(String(retreatId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Retreat Booking
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet: function (idOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([idOrSlug_1], args_1, true), void 0, function (idOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'idOrSlug' is not null or undefined
                    if (idOrSlug === null || idOrSlug === undefined) {
                        throw new base_1.RequiredError('idOrSlug', 'Required parameter idOrSlug was null or undefined when calling readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet.');
                    }
                    localVarPath = "/api/v1/bookings/retreats/{id_or_slug}"
                        .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve retreat bookings made by current user (i.e. current user is student here)
         * @summary Read Retreat Bookings By Me
         * @param {number} [retreat]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet: function (retreat_1, skip_1, limit_1, past_1, state_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([retreat_1, skip_1, limit_1, past_1, state_1], args_1, true), void 0, function (retreat, skip, limit, past, state, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/bookings/retreats/by-me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (retreat !== undefined) {
                                localVarQueryParameter['retreat'] = retreat;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (past !== undefined) {
                                localVarQueryParameter['past'] = past;
                            }
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve retreat bookings from retreat
         * @summary Read Retreat Bookings From Retreat
         * @param {number} retreatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet: function (retreatId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([retreatId_1], args_1, true), void 0, function (retreatId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'retreatId' is not null or undefined
                            if (retreatId === null || retreatId === undefined) {
                                throw new base_1.RequiredError('retreatId', 'Required parameter retreatId was null or undefined when calling readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet.');
                            }
                            localVarPath = "/api/v1/bookings/retreats/from-retreat/{retreat_id}"
                                .replace("{".concat("retreat_id", "}"), encodeURIComponent(String(retreatId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.BookingsRetreatsApiAxiosParamCreator = BookingsRetreatsApiAxiosParamCreator;
/**
 * BookingsRetreatsApi - functional programming interface
 * @export
 */
var BookingsRetreatsApiFp = function (configuration) {
    return {
        /**
         * Cancel a retreat booking
         * @summary Cancel Booking
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingApiV1BookingsRetreatsCancelIdPut: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsRetreatsApiAxiosParamCreator)(configuration).cancelBookingApiV1BookingsRetreatsCancelIdPut(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat booking.
         * @summary Create Booking
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BodyCreateBookingApiV1BookingsRetreatsPost} bodyCreateBookingApiV1BookingsRetreatsPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingApiV1BookingsRetreatsPost: function (cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsRetreatsApiAxiosParamCreator)(configuration).createBookingApiV1BookingsRetreatsPost(cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Is Referrable
         * @param {number} retreatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet: function (retreatId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsRetreatsApiAxiosParamCreator)(configuration).isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet(retreatId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Retreat Booking
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsRetreatsApiAxiosParamCreator)(configuration).readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve retreat bookings made by current user (i.e. current user is student here)
         * @summary Read Retreat Bookings By Me
         * @param {number} [retreat]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet: function (retreat, skip, limit, past, state, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsRetreatsApiAxiosParamCreator)(configuration).readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet(retreat, skip, limit, past, state, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve retreat bookings from retreat
         * @summary Read Retreat Bookings From Retreat
         * @param {number} retreatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet: function (retreatId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.BookingsRetreatsApiAxiosParamCreator)(configuration).readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet(retreatId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.BookingsRetreatsApiFp = BookingsRetreatsApiFp;
/**
 * BookingsRetreatsApi - factory interface
 * @export
 */
var BookingsRetreatsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Cancel a retreat booking
         * @summary Cancel Booking
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookingApiV1BookingsRetreatsCancelIdPut: function (id, options) {
            return (0, exports.BookingsRetreatsApiFp)(configuration).cancelBookingApiV1BookingsRetreatsCancelIdPut(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new retreat booking.
         * @summary Create Booking
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BodyCreateBookingApiV1BookingsRetreatsPost} bodyCreateBookingApiV1BookingsRetreatsPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingApiV1BookingsRetreatsPost: function (cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options) {
            return (0, exports.BookingsRetreatsApiFp)(configuration).createBookingApiV1BookingsRetreatsPost(cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Is Referrable
         * @param {number} retreatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet: function (retreatId, options) {
            return (0, exports.BookingsRetreatsApiFp)(configuration).isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet(retreatId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Retreat Booking
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet: function (idOrSlug, options) {
            return (0, exports.BookingsRetreatsApiFp)(configuration).readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve retreat bookings made by current user (i.e. current user is student here)
         * @summary Read Retreat Bookings By Me
         * @param {number} [retreat]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {boolean} [past]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet: function (retreat, skip, limit, past, state, options) {
            return (0, exports.BookingsRetreatsApiFp)(configuration).readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet(retreat, skip, limit, past, state, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve retreat bookings from retreat
         * @summary Read Retreat Bookings From Retreat
         * @param {number} retreatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet: function (retreatId, options) {
            return (0, exports.BookingsRetreatsApiFp)(configuration).readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet(retreatId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.BookingsRetreatsApiFactory = BookingsRetreatsApiFactory;
/**
 * BookingsRetreatsApi - object-oriented interface
 * @export
 * @class BookingsRetreatsApi
 * @extends {BaseAPI}
 */
var BookingsRetreatsApi = /** @class */ (function (_super) {
    __extends(BookingsRetreatsApi, _super);
    function BookingsRetreatsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Cancel a retreat booking
     * @summary Cancel Booking
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsRetreatsApi
     */
    BookingsRetreatsApi.prototype.cancelBookingApiV1BookingsRetreatsCancelIdPut = function (id, options) {
        var _this = this;
        return (0, exports.BookingsRetreatsApiFp)(this.configuration).cancelBookingApiV1BookingsRetreatsCancelIdPut(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new retreat booking.
     * @summary Create Booking
     * @param {string} cardId
     * @param {boolean} saveCard
     * @param {string} returnUrl
     * @param {BodyCreateBookingApiV1BookingsRetreatsPost} bodyCreateBookingApiV1BookingsRetreatsPost
     * @param {string} [accept]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsRetreatsApi
     */
    BookingsRetreatsApi.prototype.createBookingApiV1BookingsRetreatsPost = function (cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options) {
        var _this = this;
        return (0, exports.BookingsRetreatsApiFp)(this.configuration).createBookingApiV1BookingsRetreatsPost(cardId, saveCard, returnUrl, bodyCreateBookingApiV1BookingsRetreatsPost, accept, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Is Referrable
     * @param {number} retreatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsRetreatsApi
     */
    BookingsRetreatsApi.prototype.isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet = function (retreatId, options) {
        var _this = this;
        return (0, exports.BookingsRetreatsApiFp)(this.configuration).isReferrableApiV1BookingsRetreatsIsReferrableRetreatIdGet(retreatId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Retreat Booking
     * @param {number | string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsRetreatsApi
     */
    BookingsRetreatsApi.prototype.readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.BookingsRetreatsApiFp)(this.configuration).readRetreatBookingApiV1BookingsRetreatsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve retreat bookings made by current user (i.e. current user is student here)
     * @summary Read Retreat Bookings By Me
     * @param {number} [retreat]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {boolean} [past]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsRetreatsApi
     */
    BookingsRetreatsApi.prototype.readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet = function (retreat, skip, limit, past, state, options) {
        var _this = this;
        return (0, exports.BookingsRetreatsApiFp)(this.configuration).readRetreatBookingsByMeApiV1BookingsRetreatsByMeGet(retreat, skip, limit, past, state, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve retreat bookings from retreat
     * @summary Read Retreat Bookings From Retreat
     * @param {number} retreatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsRetreatsApi
     */
    BookingsRetreatsApi.prototype.readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet = function (retreatId, options) {
        var _this = this;
        return (0, exports.BookingsRetreatsApiFp)(this.configuration).readRetreatBookingsFromRetreatApiV1BookingsRetreatsFromRetreatRetreatIdGet(retreatId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BookingsRetreatsApi;
}(base_1.BaseAPI));
exports.BookingsRetreatsApi = BookingsRetreatsApi;
/**
 * ChatsAdminApi - axios parameter creator
 * @export
 */
var ChatsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Admin Delete Chat
         * @param {number} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteChatApiV1AdminChatsChatIdDelete: function (chatId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([chatId_1], args_1, true), void 0, function (chatId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'chatId' is not null or undefined
                            if (chatId === null || chatId === undefined) {
                                throw new base_1.RequiredError('chatId', 'Required parameter chatId was null or undefined when calling adminDeleteChatApiV1AdminChatsChatIdDelete.');
                            }
                            localVarPath = "/api/v1/admin/chats/{chat_id}"
                                .replace("{".concat("chat_id", "}"), encodeURIComponent(String(chatId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Admin Get Chats
         * @param {number} userId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChatsApiV1AdminChatsGet: function (userId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, skip_1, limit_1], args_1, true), void 0, function (userId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling adminGetChatsApiV1AdminChatsGet.');
                            }
                            localVarPath = "/api/v1/admin/chats";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ChatsAdminApiAxiosParamCreator = ChatsAdminApiAxiosParamCreator;
/**
 * ChatsAdminApi - functional programming interface
 * @export
 */
var ChatsAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Admin Delete Chat
         * @param {number} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteChatApiV1AdminChatsChatIdDelete: function (chatId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ChatsAdminApiAxiosParamCreator)(configuration).adminDeleteChatApiV1AdminChatsChatIdDelete(chatId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Admin Get Chats
         * @param {number} userId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChatsApiV1AdminChatsGet: function (userId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ChatsAdminApiAxiosParamCreator)(configuration).adminGetChatsApiV1AdminChatsGet(userId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ChatsAdminApiFp = ChatsAdminApiFp;
/**
 * ChatsAdminApi - factory interface
 * @export
 */
var ChatsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Admin Delete Chat
         * @param {number} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteChatApiV1AdminChatsChatIdDelete: function (chatId, options) {
            return (0, exports.ChatsAdminApiFp)(configuration).adminDeleteChatApiV1AdminChatsChatIdDelete(chatId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Admin Get Chats
         * @param {number} userId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetChatsApiV1AdminChatsGet: function (userId, skip, limit, options) {
            return (0, exports.ChatsAdminApiFp)(configuration).adminGetChatsApiV1AdminChatsGet(userId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ChatsAdminApiFactory = ChatsAdminApiFactory;
/**
 * ChatsAdminApi - object-oriented interface
 * @export
 * @class ChatsAdminApi
 * @extends {BaseAPI}
 */
var ChatsAdminApi = /** @class */ (function (_super) {
    __extends(ChatsAdminApi, _super);
    function ChatsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Admin Delete Chat
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsAdminApi
     */
    ChatsAdminApi.prototype.adminDeleteChatApiV1AdminChatsChatIdDelete = function (chatId, options) {
        var _this = this;
        return (0, exports.ChatsAdminApiFp)(this.configuration).adminDeleteChatApiV1AdminChatsChatIdDelete(chatId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Admin Get Chats
     * @param {number} userId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsAdminApi
     */
    ChatsAdminApi.prototype.adminGetChatsApiV1AdminChatsGet = function (userId, skip, limit, options) {
        var _this = this;
        return (0, exports.ChatsAdminApiFp)(this.configuration).adminGetChatsApiV1AdminChatsGet(userId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ChatsAdminApi;
}(base_1.BaseAPI));
exports.ChatsAdminApi = ChatsAdminApi;
/**
 * CorpoRolesApi - axios parameter creator
 * @export
 */
var CorpoRolesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create Corpo Role
         * @param {CorpoRoleCreate} corpoRoleCreate
         * @param {boolean} [notify]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpoRoleApiV1CorpoRolesPost: function (corpoRoleCreate_1, notify_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoRoleCreate_1, notify_1], args_1, true), void 0, function (corpoRoleCreate, notify, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoRoleCreate' is not null or undefined
                            if (corpoRoleCreate === null || corpoRoleCreate === undefined) {
                                throw new base_1.RequiredError('corpoRoleCreate', 'Required parameter corpoRoleCreate was null or undefined when calling createCorpoRoleApiV1CorpoRolesPost.');
                            }
                            localVarPath = "/api/v1/corpo-roles";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (notify !== undefined) {
                                localVarQueryParameter['notify'] = notify;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof corpoRoleCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(corpoRoleCreate !== undefined ? corpoRoleCreate : {}) : (corpoRoleCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Notify Corpo Roles
         * @param {string} corpoId
         * @param {NotifyCorpoRolesRequest} notifyCorpoRolesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost: function (corpoId_1, notifyCorpoRolesRequest_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, notifyCorpoRolesRequest_1], args_1, true), void 0, function (corpoId, notifyCorpoRolesRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost.');
                            }
                            // verify required parameter 'notifyCorpoRolesRequest' is not null or undefined
                            if (notifyCorpoRolesRequest === null || notifyCorpoRolesRequest === undefined) {
                                throw new base_1.RequiredError('notifyCorpoRolesRequest', 'Required parameter notifyCorpoRolesRequest was null or undefined when calling notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost.');
                            }
                            localVarPath = "/api/v1/corpo-roles/notify/{corpo_id}"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof notifyCorpoRolesRequest !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(notifyCorpoRolesRequest !== undefined ? notifyCorpoRolesRequest : {}) : (notifyCorpoRolesRequest || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Corpo Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoRolesApiV1CorpoRolesMeGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/corpo-roles/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CorpoRolesApiAxiosParamCreator = CorpoRolesApiAxiosParamCreator;
/**
 * CorpoRolesApi - functional programming interface
 * @export
 */
var CorpoRolesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create Corpo Role
         * @param {CorpoRoleCreate} corpoRoleCreate
         * @param {boolean} [notify]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpoRoleApiV1CorpoRolesPost: function (corpoRoleCreate, notify, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorpoRolesApiAxiosParamCreator)(configuration).createCorpoRoleApiV1CorpoRolesPost(corpoRoleCreate, notify, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Notify Corpo Roles
         * @param {string} corpoId
         * @param {NotifyCorpoRolesRequest} notifyCorpoRolesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost: function (corpoId, notifyCorpoRolesRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorpoRolesApiAxiosParamCreator)(configuration).notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost(corpoId, notifyCorpoRolesRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Corpo Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoRolesApiV1CorpoRolesMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorpoRolesApiAxiosParamCreator)(configuration).readCorpoRolesApiV1CorpoRolesMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.CorpoRolesApiFp = CorpoRolesApiFp;
/**
 * CorpoRolesApi - factory interface
 * @export
 */
var CorpoRolesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create Corpo Role
         * @param {CorpoRoleCreate} corpoRoleCreate
         * @param {boolean} [notify]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpoRoleApiV1CorpoRolesPost: function (corpoRoleCreate, notify, options) {
            return (0, exports.CorpoRolesApiFp)(configuration).createCorpoRoleApiV1CorpoRolesPost(corpoRoleCreate, notify, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Notify Corpo Roles
         * @param {string} corpoId
         * @param {NotifyCorpoRolesRequest} notifyCorpoRolesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost: function (corpoId, notifyCorpoRolesRequest, options) {
            return (0, exports.CorpoRolesApiFp)(configuration).notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost(corpoId, notifyCorpoRolesRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Corpo Roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoRolesApiV1CorpoRolesMeGet: function (options) {
            return (0, exports.CorpoRolesApiFp)(configuration).readCorpoRolesApiV1CorpoRolesMeGet(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CorpoRolesApiFactory = CorpoRolesApiFactory;
/**
 * CorpoRolesApi - object-oriented interface
 * @export
 * @class CorpoRolesApi
 * @extends {BaseAPI}
 */
var CorpoRolesApi = /** @class */ (function (_super) {
    __extends(CorpoRolesApi, _super);
    function CorpoRolesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create Corpo Role
     * @param {CorpoRoleCreate} corpoRoleCreate
     * @param {boolean} [notify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpoRolesApi
     */
    CorpoRolesApi.prototype.createCorpoRoleApiV1CorpoRolesPost = function (corpoRoleCreate, notify, options) {
        var _this = this;
        return (0, exports.CorpoRolesApiFp)(this.configuration).createCorpoRoleApiV1CorpoRolesPost(corpoRoleCreate, notify, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Notify Corpo Roles
     * @param {string} corpoId
     * @param {NotifyCorpoRolesRequest} notifyCorpoRolesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpoRolesApi
     */
    CorpoRolesApi.prototype.notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost = function (corpoId, notifyCorpoRolesRequest, options) {
        var _this = this;
        return (0, exports.CorpoRolesApiFp)(this.configuration).notifyCorpoRolesApiV1CorpoRolesNotifyCorpoIdPost(corpoId, notifyCorpoRolesRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Corpo Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorpoRolesApi
     */
    CorpoRolesApi.prototype.readCorpoRolesApiV1CorpoRolesMeGet = function (options) {
        var _this = this;
        return (0, exports.CorpoRolesApiFp)(this.configuration).readCorpoRolesApiV1CorpoRolesMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CorpoRolesApi;
}(base_1.BaseAPI));
exports.CorpoRolesApi = CorpoRolesApi;
/**
 * CorposApi - axios parameter creator
 * @export
 */
var CorposApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create Corpo Listing Request
         * @param {ListingRequestCreateCorpo} listingRequestCreateCorpo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpoListingRequestApiV1CorposRequestPost: function (listingRequestCreateCorpo_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestCreateCorpo_1], args_1, true), void 0, function (listingRequestCreateCorpo, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestCreateCorpo' is not null or undefined
                            if (listingRequestCreateCorpo === null || listingRequestCreateCorpo === undefined) {
                                throw new base_1.RequiredError('listingRequestCreateCorpo', 'Required parameter listingRequestCreateCorpo was null or undefined when calling createCorpoListingRequestApiV1CorposRequestPost.');
                            }
                            localVarPath = "/api/v1/corpos/request";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof listingRequestCreateCorpo !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(listingRequestCreateCorpo !== undefined ? listingRequestCreateCorpo : {}) : (listingRequestCreateCorpo || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo
         * @summary Read Corpo
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoApiV1CorposCorpoIdGet: function (corpoId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1], args_1, true), void 0, function (corpoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readCorpoApiV1CorposCorpoIdGet.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo glance
         * @summary Read Corpo
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoApiV1CorposCorpoIdGlanceGet: function (corpoId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1], args_1, true), void 0, function (corpoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readCorpoApiV1CorposCorpoIdGlanceGet.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}/glance"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Corpo Properties
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet: function (corpoId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, skip_1, limit_1], args_1, true), void 0, function (corpoId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}/properties"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo
         * @summary Read Corpo Public
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoPublicApiV1CorposPublicCorpoIdGet: function (corpoId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1], args_1, true), void 0, function (corpoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'corpoId' is not null or undefined
                    if (corpoId === null || corpoId === undefined) {
                        throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readCorpoPublicApiV1CorposPublicCorpoIdGet.');
                    }
                    localVarPath = "/api/v1/corpos/public/{corpo_id}"
                        .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Read corpo
         * @summary Read Corpo Roles
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoRolesApiV1CorposCorpoIdRolesGet: function (corpoId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, skip_1, limit_1], args_1, true), void 0, function (corpoId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readCorpoRolesApiV1CorposCorpoIdRolesGet.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}/roles"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read corpos me
         * @summary Read Corpos Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorposMeApiV1CorposMeGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/corpos/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Gurus
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [ppptyId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusApiV1CorposCorpoIdGurusGet: function (corpoId_1, skip_1, limit_1, ppptyId_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, skip_1, limit_1, ppptyId_1], args_1, true), void 0, function (corpoId, skip, limit, ppptyId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readGurusApiV1CorposCorpoIdGurusGet.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}/gurus/"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (ppptyId !== undefined) {
                                localVarQueryParameter['pppty_id'] = ppptyId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invite Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInviteListingApiV1CorposListingsListingIdInviteGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readInviteListingApiV1CorposListingsListingIdInviteGet.');
                            }
                            localVarPath = "/api/v1/corpos/listings/{listing_id}/invite"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingApiV1CorposListingsListingIdGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readListingApiV1CorposListingsListingIdGet.');
                            }
                            localVarPath = "/api/v1/corpos/listings/{listing_id}"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Deprecated
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingDeprecatedApiV1CorposListingsListingsListingIdGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readListingDeprecatedApiV1CorposListingsListingsListingIdGet.');
                            }
                            localVarPath = "/api/v1/corpos/listings/listings/{listing_id}"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listings
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsApiV1CorposListingsCorpoIdListingsGet: function (corpoId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, skip_1, limit_1], args_1, true), void 0, function (corpoId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readListingsApiV1CorposListingsCorpoIdListingsGet.');
                            }
                            localVarPath = "/api/v1/corpos/listings/{corpo_id}/listings"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listings Requests
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet: function (corpoId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, skip_1, limit_1], args_1, true), void 0, function (corpoId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet.');
                            }
                            localVarPath = "/api/v1/corpos/listings/{corpo_id}/requests"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send Invite Listing
         * @param {string} listingId
         * @param {InviteListingRequest} inviteListingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteListingApiV1CorposListingsListingIdInvitePost: function (listingId_1, inviteListingRequest_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1, inviteListingRequest_1], args_1, true), void 0, function (listingId, inviteListingRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling sendInviteListingApiV1CorposListingsListingIdInvitePost.');
                            }
                            // verify required parameter 'inviteListingRequest' is not null or undefined
                            if (inviteListingRequest === null || inviteListingRequest === undefined) {
                                throw new base_1.RequiredError('inviteListingRequest', 'Required parameter inviteListingRequest was null or undefined when calling sendInviteListingApiV1CorposListingsListingIdInvitePost.');
                            }
                            localVarPath = "/api/v1/corpos/listings/{listing_id}/invite"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof inviteListingRequest !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(inviteListingRequest !== undefined ? inviteListingRequest : {}) : (inviteListingRequest || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update corpo.
         * @summary Update Corpo
         * @param {number} corpoId
         * @param {CorpoUpdate} corpoUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCorpoApiV1CorposCorpoIdPut: function (corpoId_1, corpoUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, corpoUpdate_1], args_1, true), void 0, function (corpoId, corpoUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling updateCorpoApiV1CorposCorpoIdPut.');
                            }
                            // verify required parameter 'corpoUpdate' is not null or undefined
                            if (corpoUpdate === null || corpoUpdate === undefined) {
                                throw new base_1.RequiredError('corpoUpdate', 'Required parameter corpoUpdate was null or undefined when calling updateCorpoApiV1CorposCorpoIdPut.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof corpoUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(corpoUpdate !== undefined ? corpoUpdate : {}) : (corpoUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update corpo avatar.
         * @summary Update Corpo Avatar
         * @param {number} corpoId
         * @param {any} [avatar]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCorpoAvatarApiV1CorposCorpoIdAvatarPut: function (corpoId_1, avatar_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([corpoId_1, avatar_1], args_1, true), void 0, function (corpoId, avatar, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'corpoId' is not null or undefined
                            if (corpoId === null || corpoId === undefined) {
                                throw new base_1.RequiredError('corpoId', 'Required parameter corpoId was null or undefined when calling updateCorpoAvatarApiV1CorposCorpoIdAvatarPut.');
                            }
                            localVarPath = "/api/v1/corpos/{corpo_id}/avatar"
                                .replace("{".concat("corpo_id", "}"), encodeURIComponent(String(corpoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (avatar !== undefined) {
                                localVarFormParams.append('avatar', avatar);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CorposApiAxiosParamCreator = CorposApiAxiosParamCreator;
/**
 * CorposApi - functional programming interface
 * @export
 */
var CorposApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create Corpo Listing Request
         * @param {ListingRequestCreateCorpo} listingRequestCreateCorpo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpoListingRequestApiV1CorposRequestPost: function (listingRequestCreateCorpo, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).createCorpoListingRequestApiV1CorposRequestPost(listingRequestCreateCorpo, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo
         * @summary Read Corpo
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoApiV1CorposCorpoIdGet: function (corpoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readCorpoApiV1CorposCorpoIdGet(corpoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo glance
         * @summary Read Corpo
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoApiV1CorposCorpoIdGlanceGet: function (corpoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readCorpoApiV1CorposCorpoIdGlanceGet(corpoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Corpo Properties
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet: function (corpoId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet(corpoId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo
         * @summary Read Corpo Public
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoPublicApiV1CorposPublicCorpoIdGet: function (corpoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readCorpoPublicApiV1CorposPublicCorpoIdGet(corpoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read corpo
         * @summary Read Corpo Roles
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoRolesApiV1CorposCorpoIdRolesGet: function (corpoId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readCorpoRolesApiV1CorposCorpoIdRolesGet(corpoId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read corpos me
         * @summary Read Corpos Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorposMeApiV1CorposMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readCorposMeApiV1CorposMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Gurus
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [ppptyId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusApiV1CorposCorpoIdGurusGet: function (corpoId, skip, limit, ppptyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readGurusApiV1CorposCorpoIdGurusGet(corpoId, skip, limit, ppptyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invite Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInviteListingApiV1CorposListingsListingIdInviteGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readInviteListingApiV1CorposListingsListingIdInviteGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingApiV1CorposListingsListingIdGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readListingApiV1CorposListingsListingIdGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Deprecated
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingDeprecatedApiV1CorposListingsListingsListingIdGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readListingDeprecatedApiV1CorposListingsListingsListingIdGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listings
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsApiV1CorposListingsCorpoIdListingsGet: function (corpoId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readListingsApiV1CorposListingsCorpoIdListingsGet(corpoId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listings Requests
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet: function (corpoId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet(corpoId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send Invite Listing
         * @param {string} listingId
         * @param {InviteListingRequest} inviteListingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteListingApiV1CorposListingsListingIdInvitePost: function (listingId, inviteListingRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).sendInviteListingApiV1CorposListingsListingIdInvitePost(listingId, inviteListingRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update corpo.
         * @summary Update Corpo
         * @param {number} corpoId
         * @param {CorpoUpdate} corpoUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCorpoApiV1CorposCorpoIdPut: function (corpoId, corpoUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).updateCorpoApiV1CorposCorpoIdPut(corpoId, corpoUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update corpo avatar.
         * @summary Update Corpo Avatar
         * @param {number} corpoId
         * @param {any} [avatar]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCorpoAvatarApiV1CorposCorpoIdAvatarPut: function (corpoId, avatar, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CorposApiAxiosParamCreator)(configuration).updateCorpoAvatarApiV1CorposCorpoIdAvatarPut(corpoId, avatar, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.CorposApiFp = CorposApiFp;
/**
 * CorposApi - factory interface
 * @export
 */
var CorposApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create Corpo Listing Request
         * @param {ListingRequestCreateCorpo} listingRequestCreateCorpo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorpoListingRequestApiV1CorposRequestPost: function (listingRequestCreateCorpo, options) {
            return (0, exports.CorposApiFp)(configuration).createCorpoListingRequestApiV1CorposRequestPost(listingRequestCreateCorpo, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read corpo
         * @summary Read Corpo
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoApiV1CorposCorpoIdGet: function (corpoId, options) {
            return (0, exports.CorposApiFp)(configuration).readCorpoApiV1CorposCorpoIdGet(corpoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read corpo glance
         * @summary Read Corpo
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoApiV1CorposCorpoIdGlanceGet: function (corpoId, options) {
            return (0, exports.CorposApiFp)(configuration).readCorpoApiV1CorposCorpoIdGlanceGet(corpoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Corpo Properties
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet: function (corpoId, skip, limit, options) {
            return (0, exports.CorposApiFp)(configuration).readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet(corpoId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read corpo
         * @summary Read Corpo Public
         * @param {number | string} corpoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoPublicApiV1CorposPublicCorpoIdGet: function (corpoId, options) {
            return (0, exports.CorposApiFp)(configuration).readCorpoPublicApiV1CorposPublicCorpoIdGet(corpoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read corpo
         * @summary Read Corpo Roles
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorpoRolesApiV1CorposCorpoIdRolesGet: function (corpoId, skip, limit, options) {
            return (0, exports.CorposApiFp)(configuration).readCorpoRolesApiV1CorposCorpoIdRolesGet(corpoId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read corpos me
         * @summary Read Corpos Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCorposMeApiV1CorposMeGet: function (options) {
            return (0, exports.CorposApiFp)(configuration).readCorposMeApiV1CorposMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Gurus
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [ppptyId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusApiV1CorposCorpoIdGurusGet: function (corpoId, skip, limit, ppptyId, options) {
            return (0, exports.CorposApiFp)(configuration).readGurusApiV1CorposCorpoIdGurusGet(corpoId, skip, limit, ppptyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invite Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInviteListingApiV1CorposListingsListingIdInviteGet: function (listingId, options) {
            return (0, exports.CorposApiFp)(configuration).readInviteListingApiV1CorposListingsListingIdInviteGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingApiV1CorposListingsListingIdGet: function (listingId, options) {
            return (0, exports.CorposApiFp)(configuration).readListingApiV1CorposListingsListingIdGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing Deprecated
         * @param {string} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingDeprecatedApiV1CorposListingsListingsListingIdGet: function (listingId, options) {
            return (0, exports.CorposApiFp)(configuration).readListingDeprecatedApiV1CorposListingsListingsListingIdGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listings
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsApiV1CorposListingsCorpoIdListingsGet: function (corpoId, skip, limit, options) {
            return (0, exports.CorposApiFp)(configuration).readListingsApiV1CorposListingsCorpoIdListingsGet(corpoId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listings Requests
         * @param {number} corpoId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet: function (corpoId, skip, limit, options) {
            return (0, exports.CorposApiFp)(configuration).readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet(corpoId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send Invite Listing
         * @param {string} listingId
         * @param {InviteListingRequest} inviteListingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteListingApiV1CorposListingsListingIdInvitePost: function (listingId, inviteListingRequest, options) {
            return (0, exports.CorposApiFp)(configuration).sendInviteListingApiV1CorposListingsListingIdInvitePost(listingId, inviteListingRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update corpo.
         * @summary Update Corpo
         * @param {number} corpoId
         * @param {CorpoUpdate} corpoUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCorpoApiV1CorposCorpoIdPut: function (corpoId, corpoUpdate, options) {
            return (0, exports.CorposApiFp)(configuration).updateCorpoApiV1CorposCorpoIdPut(corpoId, corpoUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update corpo avatar.
         * @summary Update Corpo Avatar
         * @param {number} corpoId
         * @param {any} [avatar]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCorpoAvatarApiV1CorposCorpoIdAvatarPut: function (corpoId, avatar, options) {
            return (0, exports.CorposApiFp)(configuration).updateCorpoAvatarApiV1CorposCorpoIdAvatarPut(corpoId, avatar, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CorposApiFactory = CorposApiFactory;
/**
 * CorposApi - object-oriented interface
 * @export
 * @class CorposApi
 * @extends {BaseAPI}
 */
var CorposApi = /** @class */ (function (_super) {
    __extends(CorposApi, _super);
    function CorposApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create Corpo Listing Request
     * @param {ListingRequestCreateCorpo} listingRequestCreateCorpo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.createCorpoListingRequestApiV1CorposRequestPost = function (listingRequestCreateCorpo, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).createCorpoListingRequestApiV1CorposRequestPost(listingRequestCreateCorpo, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read corpo
     * @summary Read Corpo
     * @param {number | string} corpoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readCorpoApiV1CorposCorpoIdGet = function (corpoId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readCorpoApiV1CorposCorpoIdGet(corpoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read corpo glance
     * @summary Read Corpo
     * @param {number | string} corpoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readCorpoApiV1CorposCorpoIdGlanceGet = function (corpoId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readCorpoApiV1CorposCorpoIdGlanceGet(corpoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Corpo Properties
     * @param {number} corpoId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet = function (corpoId, skip, limit, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readCorpoPropertiesApiV1CorposCorpoIdPropertiesGet(corpoId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read corpo
     * @summary Read Corpo Public
     * @param {number | string} corpoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readCorpoPublicApiV1CorposPublicCorpoIdGet = function (corpoId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readCorpoPublicApiV1CorposPublicCorpoIdGet(corpoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read corpo
     * @summary Read Corpo Roles
     * @param {number} corpoId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readCorpoRolesApiV1CorposCorpoIdRolesGet = function (corpoId, skip, limit, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readCorpoRolesApiV1CorposCorpoIdRolesGet(corpoId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read corpos me
     * @summary Read Corpos Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readCorposMeApiV1CorposMeGet = function (options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readCorposMeApiV1CorposMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Gurus
     * @param {number} corpoId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [ppptyId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readGurusApiV1CorposCorpoIdGurusGet = function (corpoId, skip, limit, ppptyId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readGurusApiV1CorposCorpoIdGurusGet(corpoId, skip, limit, ppptyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invite Listing
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readInviteListingApiV1CorposListingsListingIdInviteGet = function (listingId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readInviteListingApiV1CorposListingsListingIdInviteGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readListingApiV1CorposListingsListingIdGet = function (listingId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readListingApiV1CorposListingsListingIdGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing Deprecated
     * @param {string} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readListingDeprecatedApiV1CorposListingsListingsListingIdGet = function (listingId, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readListingDeprecatedApiV1CorposListingsListingsListingIdGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listings
     * @param {number} corpoId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readListingsApiV1CorposListingsCorpoIdListingsGet = function (corpoId, skip, limit, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readListingsApiV1CorposListingsCorpoIdListingsGet(corpoId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listings Requests
     * @param {number} corpoId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet = function (corpoId, skip, limit, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).readListingsRequestsApiV1CorposListingsCorpoIdRequestsGet(corpoId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send Invite Listing
     * @param {string} listingId
     * @param {InviteListingRequest} inviteListingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.sendInviteListingApiV1CorposListingsListingIdInvitePost = function (listingId, inviteListingRequest, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).sendInviteListingApiV1CorposListingsListingIdInvitePost(listingId, inviteListingRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update corpo.
     * @summary Update Corpo
     * @param {number} corpoId
     * @param {CorpoUpdate} corpoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.updateCorpoApiV1CorposCorpoIdPut = function (corpoId, corpoUpdate, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).updateCorpoApiV1CorposCorpoIdPut(corpoId, corpoUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update corpo avatar.
     * @summary Update Corpo Avatar
     * @param {number} corpoId
     * @param {any} [avatar]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorposApi
     */
    CorposApi.prototype.updateCorpoAvatarApiV1CorposCorpoIdAvatarPut = function (corpoId, avatar, options) {
        var _this = this;
        return (0, exports.CorposApiFp)(this.configuration).updateCorpoAvatarApiV1CorposCorpoIdAvatarPut(corpoId, avatar, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CorposApi;
}(base_1.BaseAPI));
exports.CorposApi = CorposApi;
/**
 * CrmApi - axios parameter creator
 * @export
 */
var CrmApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Read Attendance
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAttendanceApiV1CrmAttendanceGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readAttendanceApiV1CrmAttendanceGet.');
                            }
                            localVarPath = "/api/v1/crm/attendance";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Bookings Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsCrmApiV1CrmBookingsCrmGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/bookings-crm";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Download bookings made with current user
         * @summary Read Bookings Crm Download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/bookings-crm/download";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Glance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGlanceApiV1CrmGlanceGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/glance";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read listings of current user
         * @summary Read Listings Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsCrmApiV1CrmListingsCrmGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/listings-crm";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Download listings of current user
         * @summary Read Listings Crm Download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/listings-crm/download";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read User Metrics
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMetricsApiV1CrmUserMetricsGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readUserMetricsApiV1CrmUserMetricsGet.');
                            }
                            localVarPath = "/api/v1/crm/user-metrics";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users crm
         * @summary Read Users Crm
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmApiV1CrmUsersCrmGet: function (since_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([since_1], args_1, true), void 0, function (since, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/users-crm";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (since !== undefined) {
                                localVarQueryParameter['since'] = (since instanceof Date) ?
                                    since.toISOString().substr(0, 10) :
                                    since;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users crm download
         * @summary Read Users Crm Download
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet: function (since_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([since_1], args_1, true), void 0, function (since, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/crm/users-crm/download";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (since !== undefined) {
                                localVarQueryParameter['since'] = (since instanceof Date) ?
                                    since.toISOString().substr(0, 10) :
                                    since;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update User Note
         * @param {UserNoteCreate} userNoteCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNoteApiV1CrmUserNotePost: function (userNoteCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userNoteCreate_1], args_1, true), void 0, function (userNoteCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userNoteCreate' is not null or undefined
                            if (userNoteCreate === null || userNoteCreate === undefined) {
                                throw new base_1.RequiredError('userNoteCreate', 'Required parameter userNoteCreate was null or undefined when calling updateUserNoteApiV1CrmUserNotePost.');
                            }
                            localVarPath = "/api/v1/crm/user-note";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof userNoteCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(userNoteCreate !== undefined ? userNoteCreate : {}) : (userNoteCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CrmApiAxiosParamCreator = CrmApiAxiosParamCreator;
/**
 * CrmApi - functional programming interface
 * @export
 */
var CrmApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Read Attendance
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAttendanceApiV1CrmAttendanceGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readAttendanceApiV1CrmAttendanceGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Bookings Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsCrmApiV1CrmBookingsCrmGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readBookingsCrmApiV1CrmBookingsCrmGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Download bookings made with current user
         * @summary Read Bookings Crm Download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Glance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGlanceApiV1CrmGlanceGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readGlanceApiV1CrmGlanceGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read listings of current user
         * @summary Read Listings Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsCrmApiV1CrmListingsCrmGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readListingsCrmApiV1CrmListingsCrmGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Download listings of current user
         * @summary Read Listings Crm Download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read User Metrics
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMetricsApiV1CrmUserMetricsGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readUserMetricsApiV1CrmUserMetricsGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users crm
         * @summary Read Users Crm
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmApiV1CrmUsersCrmGet: function (since, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readUsersCrmApiV1CrmUsersCrmGet(since, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users crm download
         * @summary Read Users Crm Download
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet: function (since, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet(since, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update User Note
         * @param {UserNoteCreate} userNoteCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNoteApiV1CrmUserNotePost: function (userNoteCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmApiAxiosParamCreator)(configuration).updateUserNoteApiV1CrmUserNotePost(userNoteCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.CrmApiFp = CrmApiFp;
/**
 * CrmApi - factory interface
 * @export
 */
var CrmApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Read Attendance
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAttendanceApiV1CrmAttendanceGet: function (userId, options) {
            return (0, exports.CrmApiFp)(configuration).readAttendanceApiV1CrmAttendanceGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Bookings Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsCrmApiV1CrmBookingsCrmGet: function (options) {
            return (0, exports.CrmApiFp)(configuration).readBookingsCrmApiV1CrmBookingsCrmGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Download bookings made with current user
         * @summary Read Bookings Crm Download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet: function (options) {
            return (0, exports.CrmApiFp)(configuration).readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Glance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGlanceApiV1CrmGlanceGet: function (options) {
            return (0, exports.CrmApiFp)(configuration).readGlanceApiV1CrmGlanceGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read listings of current user
         * @summary Read Listings Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsCrmApiV1CrmListingsCrmGet: function (options) {
            return (0, exports.CrmApiFp)(configuration).readListingsCrmApiV1CrmListingsCrmGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Download listings of current user
         * @summary Read Listings Crm Download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet: function (options) {
            return (0, exports.CrmApiFp)(configuration).readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read User Metrics
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMetricsApiV1CrmUserMetricsGet: function (userId, options) {
            return (0, exports.CrmApiFp)(configuration).readUserMetricsApiV1CrmUserMetricsGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve users crm
         * @summary Read Users Crm
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmApiV1CrmUsersCrmGet: function (since, options) {
            return (0, exports.CrmApiFp)(configuration).readUsersCrmApiV1CrmUsersCrmGet(since, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve users crm download
         * @summary Read Users Crm Download
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet: function (since, options) {
            return (0, exports.CrmApiFp)(configuration).readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet(since, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update User Note
         * @param {UserNoteCreate} userNoteCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNoteApiV1CrmUserNotePost: function (userNoteCreate, options) {
            return (0, exports.CrmApiFp)(configuration).updateUserNoteApiV1CrmUserNotePost(userNoteCreate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CrmApiFactory = CrmApiFactory;
/**
 * CrmApi - object-oriented interface
 * @export
 * @class CrmApi
 * @extends {BaseAPI}
 */
var CrmApi = /** @class */ (function (_super) {
    __extends(CrmApi, _super);
    function CrmApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Read Attendance
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readAttendanceApiV1CrmAttendanceGet = function (userId, options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readAttendanceApiV1CrmAttendanceGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Bookings Crm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readBookingsCrmApiV1CrmBookingsCrmGet = function (options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readBookingsCrmApiV1CrmBookingsCrmGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Download bookings made with current user
     * @summary Read Bookings Crm Download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet = function (options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readBookingsCrmDownloadApiV1CrmBookingsCrmDownloadGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Glance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readGlanceApiV1CrmGlanceGet = function (options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readGlanceApiV1CrmGlanceGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read listings of current user
     * @summary Read Listings Crm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readListingsCrmApiV1CrmListingsCrmGet = function (options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readListingsCrmApiV1CrmListingsCrmGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Download listings of current user
     * @summary Read Listings Crm Download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet = function (options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readListingsCrmDownloadApiV1CrmListingsCrmDownloadGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read User Metrics
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readUserMetricsApiV1CrmUserMetricsGet = function (userId, options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readUserMetricsApiV1CrmUserMetricsGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve users crm
     * @summary Read Users Crm
     * @param {string} [since]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readUsersCrmApiV1CrmUsersCrmGet = function (since, options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readUsersCrmApiV1CrmUsersCrmGet(since, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve users crm download
     * @summary Read Users Crm Download
     * @param {string} [since]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet = function (since, options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).readUsersCrmDownloadApiV1CrmUsersCrmDownloadGet(since, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update User Note
     * @param {UserNoteCreate} userNoteCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    CrmApi.prototype.updateUserNoteApiV1CrmUserNotePost = function (userNoteCreate, options) {
        var _this = this;
        return (0, exports.CrmApiFp)(this.configuration).updateUserNoteApiV1CrmUserNotePost(userNoteCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CrmApi;
}(base_1.BaseAPI));
exports.CrmApi = CrmApi;
/**
 * CrmAdminApi - axios parameter creator
 * @export
 */
var CrmAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Read Glance Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGlanceAdminApiV1AdminCrmGlanceUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readGlanceAdminApiV1AdminCrmGlanceUserIdGet.');
                            }
                            localVarPath = "/api/v1/admin/crm/glance/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users crm admin
         * @summary Read Users Crm Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet.');
                            }
                            localVarPath = "/api/v1/admin/crm/users-crm/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.CrmAdminApiAxiosParamCreator = CrmAdminApiAxiosParamCreator;
/**
 * CrmAdminApi - functional programming interface
 * @export
 */
var CrmAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Read Glance Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGlanceAdminApiV1AdminCrmGlanceUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmAdminApiAxiosParamCreator)(configuration).readGlanceAdminApiV1AdminCrmGlanceUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users crm admin
         * @summary Read Users Crm Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.CrmAdminApiAxiosParamCreator)(configuration).readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.CrmAdminApiFp = CrmAdminApiFp;
/**
 * CrmAdminApi - factory interface
 * @export
 */
var CrmAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Read Glance Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGlanceAdminApiV1AdminCrmGlanceUserIdGet: function (userId, options) {
            return (0, exports.CrmAdminApiFp)(configuration).readGlanceAdminApiV1AdminCrmGlanceUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve users crm admin
         * @summary Read Users Crm Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet: function (userId, options) {
            return (0, exports.CrmAdminApiFp)(configuration).readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.CrmAdminApiFactory = CrmAdminApiFactory;
/**
 * CrmAdminApi - object-oriented interface
 * @export
 * @class CrmAdminApi
 * @extends {BaseAPI}
 */
var CrmAdminApi = /** @class */ (function (_super) {
    __extends(CrmAdminApi, _super);
    function CrmAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Read Glance Admin
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmAdminApi
     */
    CrmAdminApi.prototype.readGlanceAdminApiV1AdminCrmGlanceUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.CrmAdminApiFp)(this.configuration).readGlanceAdminApiV1AdminCrmGlanceUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve users crm admin
     * @summary Read Users Crm Admin
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmAdminApi
     */
    CrmAdminApi.prototype.readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.CrmAdminApiFp)(this.configuration).readUsersCrmAdminApiV1AdminCrmUsersCrmUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CrmAdminApi;
}(base_1.BaseAPI));
exports.CrmAdminApi = CrmAdminApi;
/**
 * EmailsApi - axios parameter creator
 * @export
 */
var EmailsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Send Contact Email
         * @param {any} firstname
         * @param {any} lastname
         * @param {any} email
         * @param {any} content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactEmailApiV1EmailsContactPost: function (firstname_1, lastname_1, email_1, content_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([firstname_1, lastname_1, email_1, content_1], args_1, true), void 0, function (firstname, lastname, email, content, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'firstname' is not null or undefined
                    if (firstname === null || firstname === undefined) {
                        throw new base_1.RequiredError('firstname', 'Required parameter firstname was null or undefined when calling sendContactEmailApiV1EmailsContactPost.');
                    }
                    // verify required parameter 'lastname' is not null or undefined
                    if (lastname === null || lastname === undefined) {
                        throw new base_1.RequiredError('lastname', 'Required parameter lastname was null or undefined when calling sendContactEmailApiV1EmailsContactPost.');
                    }
                    // verify required parameter 'email' is not null or undefined
                    if (email === null || email === undefined) {
                        throw new base_1.RequiredError('email', 'Required parameter email was null or undefined when calling sendContactEmailApiV1EmailsContactPost.');
                    }
                    // verify required parameter 'content' is not null or undefined
                    if (content === null || content === undefined) {
                        throw new base_1.RequiredError('content', 'Required parameter content was null or undefined when calling sendContactEmailApiV1EmailsContactPost.');
                    }
                    localVarPath = "/api/v1/emails/contact";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (firstname !== undefined) {
                        localVarFormParams.set('firstname', firstname);
                    }
                    if (lastname !== undefined) {
                        localVarFormParams.set('lastname', lastname);
                    }
                    if (email !== undefined) {
                        localVarFormParams.set('email', email);
                    }
                    if (content !== undefined) {
                        localVarFormParams.set('content', content);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.EmailsApiAxiosParamCreator = EmailsApiAxiosParamCreator;
/**
 * EmailsApi - functional programming interface
 * @export
 */
var EmailsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Send Contact Email
         * @param {any} firstname
         * @param {any} lastname
         * @param {any} email
         * @param {any} content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactEmailApiV1EmailsContactPost: function (firstname, lastname, email, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.EmailsApiAxiosParamCreator)(configuration).sendContactEmailApiV1EmailsContactPost(firstname, lastname, email, content, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.EmailsApiFp = EmailsApiFp;
/**
 * EmailsApi - factory interface
 * @export
 */
var EmailsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Send Contact Email
         * @param {any} firstname
         * @param {any} lastname
         * @param {any} email
         * @param {any} content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactEmailApiV1EmailsContactPost: function (firstname, lastname, email, content, options) {
            return (0, exports.EmailsApiFp)(configuration).sendContactEmailApiV1EmailsContactPost(firstname, lastname, email, content, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EmailsApiFactory = EmailsApiFactory;
/**
 * EmailsApi - object-oriented interface
 * @export
 * @class EmailsApi
 * @extends {BaseAPI}
 */
var EmailsApi = /** @class */ (function (_super) {
    __extends(EmailsApi, _super);
    function EmailsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Send Contact Email
     * @param {any} firstname
     * @param {any} lastname
     * @param {any} email
     * @param {any} content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApi
     */
    EmailsApi.prototype.sendContactEmailApiV1EmailsContactPost = function (firstname, lastname, email, content, options) {
        var _this = this;
        return (0, exports.EmailsApiFp)(this.configuration).sendContactEmailApiV1EmailsContactPost(firstname, lastname, email, content, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EmailsApi;
}(base_1.BaseAPI));
exports.EmailsApi = EmailsApi;
/**
 * HooksApi - axios parameter creator
 * @export
 */
var HooksApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Mangopay Hook
         * @param {string} eventType
         * @param {string} ressourceId
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangopayHookApiV1HooksMangopayGet: function (eventType_1, ressourceId_1, date_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([eventType_1, ressourceId_1, date_1], args_1, true), void 0, function (eventType, ressourceId, date, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'eventType' is not null or undefined
                    if (eventType === null || eventType === undefined) {
                        throw new base_1.RequiredError('eventType', 'Required parameter eventType was null or undefined when calling mangopayHookApiV1HooksMangopayGet.');
                    }
                    // verify required parameter 'ressourceId' is not null or undefined
                    if (ressourceId === null || ressourceId === undefined) {
                        throw new base_1.RequiredError('ressourceId', 'Required parameter ressourceId was null or undefined when calling mangopayHookApiV1HooksMangopayGet.');
                    }
                    // verify required parameter 'date' is not null or undefined
                    if (date === null || date === undefined) {
                        throw new base_1.RequiredError('date', 'Required parameter date was null or undefined when calling mangopayHookApiV1HooksMangopayGet.');
                    }
                    localVarPath = "/api/v1/hooks/mangopay";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (eventType !== undefined) {
                        localVarQueryParameter['EventType'] = eventType;
                    }
                    if (ressourceId !== undefined) {
                        localVarQueryParameter['RessourceId'] = ressourceId;
                    }
                    if (date !== undefined) {
                        localVarQueryParameter['Date'] = date;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.HooksApiAxiosParamCreator = HooksApiAxiosParamCreator;
/**
 * HooksApi - functional programming interface
 * @export
 */
var HooksApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Mangopay Hook
         * @param {string} eventType
         * @param {string} ressourceId
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangopayHookApiV1HooksMangopayGet: function (eventType, ressourceId, date, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.HooksApiAxiosParamCreator)(configuration).mangopayHookApiV1HooksMangopayGet(eventType, ressourceId, date, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.HooksApiFp = HooksApiFp;
/**
 * HooksApi - factory interface
 * @export
 */
var HooksApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Mangopay Hook
         * @param {string} eventType
         * @param {string} ressourceId
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangopayHookApiV1HooksMangopayGet: function (eventType, ressourceId, date, options) {
            return (0, exports.HooksApiFp)(configuration).mangopayHookApiV1HooksMangopayGet(eventType, ressourceId, date, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.HooksApiFactory = HooksApiFactory;
/**
 * HooksApi - object-oriented interface
 * @export
 * @class HooksApi
 * @extends {BaseAPI}
 */
var HooksApi = /** @class */ (function (_super) {
    __extends(HooksApi, _super);
    function HooksApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Mangopay Hook
     * @param {string} eventType
     * @param {string} ressourceId
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HooksApi
     */
    HooksApi.prototype.mangopayHookApiV1HooksMangopayGet = function (eventType, ressourceId, date, options) {
        var _this = this;
        return (0, exports.HooksApiFp)(this.configuration).mangopayHookApiV1HooksMangopayGet(eventType, ressourceId, date, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return HooksApi;
}(base_1.BaseAPI));
exports.HooksApi = HooksApi;
/**
 * ImagesApi - axios parameter creator
 * @export
 */
var ImagesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete Image
         * @param {number} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageApiV1ImagesImageIdDelete: function (imageId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([imageId_1], args_1, true), void 0, function (imageId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'imageId' is not null or undefined
                            if (imageId === null || imageId === undefined) {
                                throw new base_1.RequiredError('imageId', 'Required parameter imageId was null or undefined when calling deleteImageApiV1ImagesImageIdDelete.');
                            }
                            localVarPath = "/api/v1/images/{image_id}"
                                .replace("{".concat("image_id", "}"), encodeURIComponent(String(imageId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Image
         * @param {any} file
         * @param {string} imageType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageApiV1ImagesPost: function (file_1, imageType_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([file_1, imageType_1], args_1, true), void 0, function (file, imageType, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadImageApiV1ImagesPost.');
                            }
                            // verify required parameter 'imageType' is not null or undefined
                            if (imageType === null || imageType === undefined) {
                                throw new base_1.RequiredError('imageType', 'Required parameter imageType was null or undefined when calling uploadImageApiV1ImagesPost.');
                            }
                            localVarPath = "/api/v1/images";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (imageType !== undefined) {
                                localVarFormParams.append('image_type', imageType);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ImagesApiAxiosParamCreator = ImagesApiAxiosParamCreator;
/**
 * ImagesApi - functional programming interface
 * @export
 */
var ImagesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Delete Image
         * @param {number} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageApiV1ImagesImageIdDelete: function (imageId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ImagesApiAxiosParamCreator)(configuration).deleteImageApiV1ImagesImageIdDelete(imageId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Image
         * @param {any} file
         * @param {string} imageType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageApiV1ImagesPost: function (file, imageType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ImagesApiAxiosParamCreator)(configuration).uploadImageApiV1ImagesPost(file, imageType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ImagesApiFp = ImagesApiFp;
/**
 * ImagesApi - factory interface
 * @export
 */
var ImagesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Delete Image
         * @param {number} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageApiV1ImagesImageIdDelete: function (imageId, options) {
            return (0, exports.ImagesApiFp)(configuration).deleteImageApiV1ImagesImageIdDelete(imageId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Image
         * @param {any} file
         * @param {string} imageType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageApiV1ImagesPost: function (file, imageType, options) {
            return (0, exports.ImagesApiFp)(configuration).uploadImageApiV1ImagesPost(file, imageType, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ImagesApiFactory = ImagesApiFactory;
/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
var ImagesApi = /** @class */ (function (_super) {
    __extends(ImagesApi, _super);
    function ImagesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete Image
     * @param {number} imageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    ImagesApi.prototype.deleteImageApiV1ImagesImageIdDelete = function (imageId, options) {
        var _this = this;
        return (0, exports.ImagesApiFp)(this.configuration).deleteImageApiV1ImagesImageIdDelete(imageId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Image
     * @param {any} file
     * @param {string} imageType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    ImagesApi.prototype.uploadImageApiV1ImagesPost = function (file, imageType, options) {
        var _this = this;
        return (0, exports.ImagesApiFp)(this.configuration).uploadImageApiV1ImagesPost(file, imageType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ImagesApi;
}(base_1.BaseAPI));
exports.ImagesApi = ImagesApi;
/**
 * InboxApi - axios parameter creator
 * @export
 */
var InboxApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new message
         * @summary Create Message
         * @param {string} [content]
         * @param {number} [ownerId]
         * @param {number} [targetId]
         * @param {number} [chatId]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageApiV1InboxPost: function (content_1, ownerId_1, targetId_1, chatId_1, file_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([content_1, ownerId_1, targetId_1, chatId_1, file_1], args_1, true), void 0, function (content, ownerId, targetId, chatId, file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/inbox";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (content !== undefined) {
                                localVarFormParams.append('content', content);
                            }
                            if (ownerId !== undefined) {
                                localVarFormParams.append('owner_id', ownerId);
                            }
                            if (targetId !== undefined) {
                                localVarFormParams.append('target_id', targetId);
                            }
                            if (chatId !== undefined) {
                                localVarFormParams.append('chat_id', chatId);
                            }
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve chat
         * @summary Get Chat
         * @param {number} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatApiV1InboxChatIdGet: function (chatId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([chatId_1], args_1, true), void 0, function (chatId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'chatId' is not null or undefined
                            if (chatId === null || chatId === undefined) {
                                throw new base_1.RequiredError('chatId', 'Required parameter chatId was null or undefined when calling getChatApiV1InboxChatIdGet.');
                            }
                            localVarPath = "/api/v1/inbox/{chat_id}"
                                .replace("{".concat("chat_id", "}"), encodeURIComponent(String(chatId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve chats
         * @summary Get Chat With User
         * @param {number} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatWithUserApiV1InboxWithUserGet: function (user_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([user_1], args_1, true), void 0, function (user, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'user' is not null or undefined
                            if (user === null || user === undefined) {
                                throw new base_1.RequiredError('user', 'Required parameter user was null or undefined when calling getChatWithUserApiV1InboxWithUserGet.');
                            }
                            localVarPath = "/api/v1/inbox/with-user";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (user !== undefined) {
                                localVarQueryParameter['user'] = user;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve chats
         * @summary Get Chats
         * @param {boolean} [onlyNonEmpty]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsApiV1InboxGet: function (onlyNonEmpty_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([onlyNonEmpty_1, skip_1, limit_1], args_1, true), void 0, function (onlyNonEmpty, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/inbox";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (onlyNonEmpty !== undefined) {
                                localVarQueryParameter['only_non_empty'] = onlyNonEmpty;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve messages from chat
         * @summary Get Messages
         * @param {number} chatId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesApiV1InboxMessagesChatIdGet: function (chatId_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([chatId_1, skip_1, limit_1], args_1, true), void 0, function (chatId, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'chatId' is not null or undefined
                            if (chatId === null || chatId === undefined) {
                                throw new base_1.RequiredError('chatId', 'Required parameter chatId was null or undefined when calling getMessagesApiV1InboxMessagesChatIdGet.');
                            }
                            localVarPath = "/api/v1/inbox/messages/{chat_id}"
                                .replace("{".concat("chat_id", "}"), encodeURIComponent(String(chatId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.InboxApiAxiosParamCreator = InboxApiAxiosParamCreator;
/**
 * InboxApi - functional programming interface
 * @export
 */
var InboxApiFp = function (configuration) {
    return {
        /**
         * Create new message
         * @summary Create Message
         * @param {string} [content]
         * @param {number} [ownerId]
         * @param {number} [targetId]
         * @param {number} [chatId]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageApiV1InboxPost: function (content, ownerId, targetId, chatId, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InboxApiAxiosParamCreator)(configuration).createMessageApiV1InboxPost(content, ownerId, targetId, chatId, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve chat
         * @summary Get Chat
         * @param {number} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatApiV1InboxChatIdGet: function (chatId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InboxApiAxiosParamCreator)(configuration).getChatApiV1InboxChatIdGet(chatId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve chats
         * @summary Get Chat With User
         * @param {number} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatWithUserApiV1InboxWithUserGet: function (user, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InboxApiAxiosParamCreator)(configuration).getChatWithUserApiV1InboxWithUserGet(user, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve chats
         * @summary Get Chats
         * @param {boolean} [onlyNonEmpty]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsApiV1InboxGet: function (onlyNonEmpty, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InboxApiAxiosParamCreator)(configuration).getChatsApiV1InboxGet(onlyNonEmpty, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve messages from chat
         * @summary Get Messages
         * @param {number} chatId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesApiV1InboxMessagesChatIdGet: function (chatId, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InboxApiAxiosParamCreator)(configuration).getMessagesApiV1InboxMessagesChatIdGet(chatId, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.InboxApiFp = InboxApiFp;
/**
 * InboxApi - factory interface
 * @export
 */
var InboxApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new message
         * @summary Create Message
         * @param {string} [content]
         * @param {number} [ownerId]
         * @param {number} [targetId]
         * @param {number} [chatId]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageApiV1InboxPost: function (content, ownerId, targetId, chatId, file, options) {
            return (0, exports.InboxApiFp)(configuration).createMessageApiV1InboxPost(content, ownerId, targetId, chatId, file, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve chat
         * @summary Get Chat
         * @param {number} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatApiV1InboxChatIdGet: function (chatId, options) {
            return (0, exports.InboxApiFp)(configuration).getChatApiV1InboxChatIdGet(chatId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve chats
         * @summary Get Chat With User
         * @param {number} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatWithUserApiV1InboxWithUserGet: function (user, options) {
            return (0, exports.InboxApiFp)(configuration).getChatWithUserApiV1InboxWithUserGet(user, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve chats
         * @summary Get Chats
         * @param {boolean} [onlyNonEmpty]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsApiV1InboxGet: function (onlyNonEmpty, skip, limit, options) {
            return (0, exports.InboxApiFp)(configuration).getChatsApiV1InboxGet(onlyNonEmpty, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve messages from chat
         * @summary Get Messages
         * @param {number} chatId
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesApiV1InboxMessagesChatIdGet: function (chatId, skip, limit, options) {
            return (0, exports.InboxApiFp)(configuration).getMessagesApiV1InboxMessagesChatIdGet(chatId, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.InboxApiFactory = InboxApiFactory;
/**
 * InboxApi - object-oriented interface
 * @export
 * @class InboxApi
 * @extends {BaseAPI}
 */
var InboxApi = /** @class */ (function (_super) {
    __extends(InboxApi, _super);
    function InboxApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new message
     * @summary Create Message
     * @param {string} [content]
     * @param {number} [ownerId]
     * @param {number} [targetId]
     * @param {number} [chatId]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    InboxApi.prototype.createMessageApiV1InboxPost = function (content, ownerId, targetId, chatId, file, options) {
        var _this = this;
        return (0, exports.InboxApiFp)(this.configuration).createMessageApiV1InboxPost(content, ownerId, targetId, chatId, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve chat
     * @summary Get Chat
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    InboxApi.prototype.getChatApiV1InboxChatIdGet = function (chatId, options) {
        var _this = this;
        return (0, exports.InboxApiFp)(this.configuration).getChatApiV1InboxChatIdGet(chatId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve chats
     * @summary Get Chat With User
     * @param {number} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    InboxApi.prototype.getChatWithUserApiV1InboxWithUserGet = function (user, options) {
        var _this = this;
        return (0, exports.InboxApiFp)(this.configuration).getChatWithUserApiV1InboxWithUserGet(user, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve chats
     * @summary Get Chats
     * @param {boolean} [onlyNonEmpty]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    InboxApi.prototype.getChatsApiV1InboxGet = function (onlyNonEmpty, skip, limit, options) {
        var _this = this;
        return (0, exports.InboxApiFp)(this.configuration).getChatsApiV1InboxGet(onlyNonEmpty, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve messages from chat
     * @summary Get Messages
     * @param {number} chatId
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    InboxApi.prototype.getMessagesApiV1InboxMessagesChatIdGet = function (chatId, skip, limit, options) {
        var _this = this;
        return (0, exports.InboxApiFp)(this.configuration).getMessagesApiV1InboxMessagesChatIdGet(chatId, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InboxApi;
}(base_1.BaseAPI));
exports.InboxApi = InboxApi;
/**
 * InvoicesApi - axios parameter creator
 * @export
 */
var InvoicesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Generate Invoice
         * @param {string} invoiceType
         * @param {number} invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoiceApiV1InvoicesGenerateGet: function (invoiceType_1, invoiceId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([invoiceType_1, invoiceId_1], args_1, true), void 0, function (invoiceType, invoiceId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'invoiceType' is not null or undefined
                            if (invoiceType === null || invoiceType === undefined) {
                                throw new base_1.RequiredError('invoiceType', 'Required parameter invoiceType was null or undefined when calling generateInvoiceApiV1InvoicesGenerateGet.');
                            }
                            // verify required parameter 'invoiceId' is not null or undefined
                            if (invoiceId === null || invoiceId === undefined) {
                                throw new base_1.RequiredError('invoiceId', 'Required parameter invoiceId was null or undefined when calling generateInvoiceApiV1InvoicesGenerateGet.');
                            }
                            localVarPath = "/api/v1/invoices/generate";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (invoiceType !== undefined) {
                                localVarQueryParameter['invoice_type'] = invoiceType;
                            }
                            if (invoiceId !== undefined) {
                                localVarQueryParameter['invoice_id'] = invoiceId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice
         * @param {string | number} listingIdOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceApiV1InvoicesListingIdOrSlugGet: function (listingIdOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingIdOrSlug_1], args_1, true), void 0, function (listingIdOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingIdOrSlug' is not null or undefined
                            if (listingIdOrSlug === null || listingIdOrSlug === undefined) {
                                throw new base_1.RequiredError('listingIdOrSlug', 'Required parameter listingIdOrSlug was null or undefined when calling readInvoiceApiV1InvoicesListingIdOrSlugGet.');
                            }
                            localVarPath = "/api/v1/invoices/{listing_id_or_slug}"
                                .replace("{".concat("listing_id_or_slug", "}"), encodeURIComponent(String(listingIdOrSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Months
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceMonthsApiV1InvoicesMonthsGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/invoices/months";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoices For Month
         * @param {number} monthTimestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet: function (monthTimestamp_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([monthTimestamp_1], args_1, true), void 0, function (monthTimestamp, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'monthTimestamp' is not null or undefined
                            if (monthTimestamp === null || monthTimestamp === undefined) {
                                throw new base_1.RequiredError('monthTimestamp', 'Required parameter monthTimestamp was null or undefined when calling readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet.');
                            }
                            localVarPath = "/api/v1/invoices/months/{month_timestamp}"
                                .replace("{".concat("month_timestamp", "}"), encodeURIComponent(String(monthTimestamp)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoices Me
         * @param {any} [offset]
         * @param {any} [limit]
         * @param {any} [invoiceType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesMeApiV1InvoicesMeGet: function (offset_1, limit_1, invoiceType_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([offset_1, limit_1, invoiceType_1], args_1, true), void 0, function (offset, limit, invoiceType, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/invoices/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (offset !== undefined) {
                                localVarQueryParameter['offset'] = offset;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (invoiceType !== undefined) {
                                localVarQueryParameter['invoice_type'] = invoiceType;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.InvoicesApiAxiosParamCreator = InvoicesApiAxiosParamCreator;
/**
 * InvoicesApi - functional programming interface
 * @export
 */
var InvoicesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Generate Invoice
         * @param {string} invoiceType
         * @param {number} invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoiceApiV1InvoicesGenerateGet: function (invoiceType, invoiceId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesApiAxiosParamCreator)(configuration).generateInvoiceApiV1InvoicesGenerateGet(invoiceType, invoiceId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice
         * @param {string | number} listingIdOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceApiV1InvoicesListingIdOrSlugGet: function (listingIdOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesApiAxiosParamCreator)(configuration).readInvoiceApiV1InvoicesListingIdOrSlugGet(listingIdOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Months
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceMonthsApiV1InvoicesMonthsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesApiAxiosParamCreator)(configuration).readInvoiceMonthsApiV1InvoicesMonthsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoices For Month
         * @param {number} monthTimestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet: function (monthTimestamp, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesApiAxiosParamCreator)(configuration).readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet(monthTimestamp, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoices Me
         * @param {any} [offset]
         * @param {any} [limit]
         * @param {any} [invoiceType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesMeApiV1InvoicesMeGet: function (offset, limit, invoiceType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesApiAxiosParamCreator)(configuration).readInvoicesMeApiV1InvoicesMeGet(offset, limit, invoiceType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.InvoicesApiFp = InvoicesApiFp;
/**
 * InvoicesApi - factory interface
 * @export
 */
var InvoicesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Generate Invoice
         * @param {string} invoiceType
         * @param {number} invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInvoiceApiV1InvoicesGenerateGet: function (invoiceType, invoiceId, options) {
            return (0, exports.InvoicesApiFp)(configuration).generateInvoiceApiV1InvoicesGenerateGet(invoiceType, invoiceId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoice
         * @param {string | number} listingIdOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceApiV1InvoicesListingIdOrSlugGet: function (listingIdOrSlug, options) {
            return (0, exports.InvoicesApiFp)(configuration).readInvoiceApiV1InvoicesListingIdOrSlugGet(listingIdOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoice Months
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceMonthsApiV1InvoicesMonthsGet: function (options) {
            return (0, exports.InvoicesApiFp)(configuration).readInvoiceMonthsApiV1InvoicesMonthsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoices For Month
         * @param {number} monthTimestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet: function (monthTimestamp, options) {
            return (0, exports.InvoicesApiFp)(configuration).readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet(monthTimestamp, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoices Me
         * @param {any} [offset]
         * @param {any} [limit]
         * @param {any} [invoiceType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesMeApiV1InvoicesMeGet: function (offset, limit, invoiceType, options) {
            return (0, exports.InvoicesApiFp)(configuration).readInvoicesMeApiV1InvoicesMeGet(offset, limit, invoiceType, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.InvoicesApiFactory = InvoicesApiFactory;
/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
var InvoicesApi = /** @class */ (function (_super) {
    __extends(InvoicesApi, _super);
    function InvoicesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Generate Invoice
     * @param {string} invoiceType
     * @param {number} invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    InvoicesApi.prototype.generateInvoiceApiV1InvoicesGenerateGet = function (invoiceType, invoiceId, options) {
        var _this = this;
        return (0, exports.InvoicesApiFp)(this.configuration).generateInvoiceApiV1InvoicesGenerateGet(invoiceType, invoiceId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoice
     * @param {string | number} listingIdOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    InvoicesApi.prototype.readInvoiceApiV1InvoicesListingIdOrSlugGet = function (listingIdOrSlug, options) {
        var _this = this;
        return (0, exports.InvoicesApiFp)(this.configuration).readInvoiceApiV1InvoicesListingIdOrSlugGet(listingIdOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoice Months
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    InvoicesApi.prototype.readInvoiceMonthsApiV1InvoicesMonthsGet = function (options) {
        var _this = this;
        return (0, exports.InvoicesApiFp)(this.configuration).readInvoiceMonthsApiV1InvoicesMonthsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoices For Month
     * @param {number} monthTimestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    InvoicesApi.prototype.readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet = function (monthTimestamp, options) {
        var _this = this;
        return (0, exports.InvoicesApiFp)(this.configuration).readInvoicesForMonthApiV1InvoicesMonthsMonthTimestampGet(monthTimestamp, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoices Me
     * @param {any} [offset]
     * @param {any} [limit]
     * @param {any} [invoiceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    InvoicesApi.prototype.readInvoicesMeApiV1InvoicesMeGet = function (offset, limit, invoiceType, options) {
        var _this = this;
        return (0, exports.InvoicesApiFp)(this.configuration).readInvoicesMeApiV1InvoicesMeGet(offset, limit, invoiceType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InvoicesApi;
}(base_1.BaseAPI));
exports.InvoicesApi = InvoicesApi;
/**
 * InvoicesAdminApi - axios parameter creator
 * @export
 */
var InvoicesAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Read All Invoices Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet.');
                            }
                            localVarPath = "/api/v1/admin/invoices/all/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Booking Admin
         * @param {number} bookingId
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet: function (bookingId_1, paid_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bookingId_1, paid_1], args_1, true), void 0, function (bookingId, paid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'bookingId' is not null or undefined
                            if (bookingId === null || bookingId === undefined) {
                                throw new base_1.RequiredError('bookingId', 'Required parameter bookingId was null or undefined when calling readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet.');
                            }
                            localVarPath = "/api/v1/admin/invoices/booking/{booking_id}"
                                .replace("{".concat("booking_id", "}"), encodeURIComponent(String(bookingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (paid !== undefined) {
                                localVarQueryParameter['paid'] = paid;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Booking Retreat Admin
         * @param {number} bookingId
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet: function (bookingId_1, paid_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bookingId_1, paid_1], args_1, true), void 0, function (bookingId, paid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'bookingId' is not null or undefined
                            if (bookingId === null || bookingId === undefined) {
                                throw new base_1.RequiredError('bookingId', 'Required parameter bookingId was null or undefined when calling readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet.');
                            }
                            localVarPath = "/api/v1/admin/invoices/booking-retreat/{booking_id}"
                                .replace("{".concat("booking_id", "}"), encodeURIComponent(String(bookingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (paid !== undefined) {
                                localVarQueryParameter['paid'] = paid;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Listing Admin
         * @param {number} listingId
         * @param {boolean} [recreate]
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet: function (listingId_1, recreate_1, paid_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1, recreate_1, paid_1], args_1, true), void 0, function (listingId, recreate, paid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet.');
                            }
                            localVarPath = "/api/v1/admin/invoices/listing/{listing_id}"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (recreate !== undefined) {
                                localVarQueryParameter['recreate'] = recreate;
                            }
                            if (paid !== undefined) {
                                localVarQueryParameter['paid'] = paid;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Pack Admin
         * @param {number} packId
         * @param {boolean} [recreate]
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet: function (packId_1, recreate_1, paid_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packId_1, recreate_1, paid_1], args_1, true), void 0, function (packId, recreate, paid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packId' is not null or undefined
                            if (packId === null || packId === undefined) {
                                throw new base_1.RequiredError('packId', 'Required parameter packId was null or undefined when calling readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet.');
                            }
                            localVarPath = "/api/v1/admin/invoices/pack/{pack_id}"
                                .replace("{".concat("pack_id", "}"), encodeURIComponent(String(packId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (recreate !== undefined) {
                                localVarQueryParameter['recreate'] = recreate;
                            }
                            if (paid !== undefined) {
                                localVarQueryParameter['paid'] = paid;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoiced Items Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet.');
                            }
                            localVarPath = "/api/v1/admin/invoices/invoiced-items/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.InvoicesAdminApiAxiosParamCreator = InvoicesAdminApiAxiosParamCreator;
/**
 * InvoicesAdminApi - functional programming interface
 * @export
 */
var InvoicesAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Read All Invoices Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesAdminApiAxiosParamCreator)(configuration).readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Booking Admin
         * @param {number} bookingId
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet: function (bookingId, paid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesAdminApiAxiosParamCreator)(configuration).readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet(bookingId, paid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Booking Retreat Admin
         * @param {number} bookingId
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet: function (bookingId, paid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesAdminApiAxiosParamCreator)(configuration).readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet(bookingId, paid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Listing Admin
         * @param {number} listingId
         * @param {boolean} [recreate]
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet: function (listingId, recreate, paid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesAdminApiAxiosParamCreator)(configuration).readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet(listingId, recreate, paid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoice Pack Admin
         * @param {number} packId
         * @param {boolean} [recreate]
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet: function (packId, recreate, paid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesAdminApiAxiosParamCreator)(configuration).readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet(packId, recreate, paid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Invoiced Items Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.InvoicesAdminApiAxiosParamCreator)(configuration).readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.InvoicesAdminApiFp = InvoicesAdminApiFp;
/**
 * InvoicesAdminApi - factory interface
 * @export
 */
var InvoicesAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Read All Invoices Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet: function (userId, options) {
            return (0, exports.InvoicesAdminApiFp)(configuration).readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoice Booking Admin
         * @param {number} bookingId
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet: function (bookingId, paid, options) {
            return (0, exports.InvoicesAdminApiFp)(configuration).readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet(bookingId, paid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoice Booking Retreat Admin
         * @param {number} bookingId
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet: function (bookingId, paid, options) {
            return (0, exports.InvoicesAdminApiFp)(configuration).readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet(bookingId, paid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoice Listing Admin
         * @param {number} listingId
         * @param {boolean} [recreate]
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet: function (listingId, recreate, paid, options) {
            return (0, exports.InvoicesAdminApiFp)(configuration).readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet(listingId, recreate, paid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoice Pack Admin
         * @param {number} packId
         * @param {boolean} [recreate]
         * @param {boolean} [paid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet: function (packId, recreate, paid, options) {
            return (0, exports.InvoicesAdminApiFp)(configuration).readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet(packId, recreate, paid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Invoiced Items Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet: function (userId, options) {
            return (0, exports.InvoicesAdminApiFp)(configuration).readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.InvoicesAdminApiFactory = InvoicesAdminApiFactory;
/**
 * InvoicesAdminApi - object-oriented interface
 * @export
 * @class InvoicesAdminApi
 * @extends {BaseAPI}
 */
var InvoicesAdminApi = /** @class */ (function (_super) {
    __extends(InvoicesAdminApi, _super);
    function InvoicesAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Read All Invoices Admin
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesAdminApi
     */
    InvoicesAdminApi.prototype.readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.InvoicesAdminApiFp)(this.configuration).readAllInvoicesAdminApiV1AdminInvoicesAllUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoice Booking Admin
     * @param {number} bookingId
     * @param {boolean} [paid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesAdminApi
     */
    InvoicesAdminApi.prototype.readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet = function (bookingId, paid, options) {
        var _this = this;
        return (0, exports.InvoicesAdminApiFp)(this.configuration).readInvoiceBookingAdminApiV1AdminInvoicesBookingBookingIdGet(bookingId, paid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoice Booking Retreat Admin
     * @param {number} bookingId
     * @param {boolean} [paid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesAdminApi
     */
    InvoicesAdminApi.prototype.readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet = function (bookingId, paid, options) {
        var _this = this;
        return (0, exports.InvoicesAdminApiFp)(this.configuration).readInvoiceBookingRetreatAdminApiV1AdminInvoicesBookingRetreatBookingIdGet(bookingId, paid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoice Listing Admin
     * @param {number} listingId
     * @param {boolean} [recreate]
     * @param {boolean} [paid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesAdminApi
     */
    InvoicesAdminApi.prototype.readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet = function (listingId, recreate, paid, options) {
        var _this = this;
        return (0, exports.InvoicesAdminApiFp)(this.configuration).readInvoiceListingAdminApiV1AdminInvoicesListingListingIdGet(listingId, recreate, paid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoice Pack Admin
     * @param {number} packId
     * @param {boolean} [recreate]
     * @param {boolean} [paid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesAdminApi
     */
    InvoicesAdminApi.prototype.readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet = function (packId, recreate, paid, options) {
        var _this = this;
        return (0, exports.InvoicesAdminApiFp)(this.configuration).readInvoicePackAdminApiV1AdminInvoicesPackPackIdGet(packId, recreate, paid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Invoiced Items Admin
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesAdminApi
     */
    InvoicesAdminApi.prototype.readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.InvoicesAdminApiFp)(this.configuration).readInvoicedItemsAdminApiV1AdminInvoicesInvoicedItemsUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return InvoicesAdminApi;
}(base_1.BaseAPI));
exports.InvoicesAdminApi = InvoicesAdminApi;
/**
 * LegalStatusesApi - axios parameter creator
 * @export
 */
var LegalStatusesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create Legal Status
         * @param {number} userId
         * @param {string} [objectName]
         * @param {string} [civilStatus]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalStatusApiV1LegalStatusesUserIdPost: function (userId_1, objectName_1, civilStatus_1, siren_1, siret_1, immatRcs_1, addressAddress_1, addressCity_1, addressCountry_1, addressPostalCode_1, file_1, filename_1) {
            var args_1 = [];
            for (var _i = 12; _i < arguments.length; _i++) {
                args_1[_i - 12] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, objectName_1, civilStatus_1, siren_1, siret_1, immatRcs_1, addressAddress_1, addressCity_1, addressCountry_1, addressPostalCode_1, file_1, filename_1], args_1, true), void 0, function (userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling createLegalStatusApiV1LegalStatusesUserIdPost.');
                            }
                            localVarPath = "/api/v1/legal_statuses/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (objectName !== undefined) {
                                localVarQueryParameter['object_name'] = objectName;
                            }
                            if (civilStatus !== undefined) {
                                localVarFormParams.append('civil_status', civilStatus);
                            }
                            if (siren !== undefined) {
                                localVarFormParams.append('siren', siren);
                            }
                            if (siret !== undefined) {
                                localVarFormParams.append('siret', siret);
                            }
                            if (immatRcs !== undefined) {
                                localVarFormParams.append('immat_rcs', immatRcs);
                            }
                            if (addressAddress !== undefined) {
                                localVarFormParams.append('address_address', addressAddress);
                            }
                            if (addressCity !== undefined) {
                                localVarFormParams.append('address_city', addressCity);
                            }
                            if (addressCountry !== undefined) {
                                localVarFormParams.append('address_country', addressCountry);
                            }
                            if (addressPostalCode !== undefined) {
                                localVarFormParams.append('address_postal_code', addressPostalCode);
                            }
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (filename !== undefined) {
                                localVarFormParams.append('filename', filename);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Legal Status
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalStatusApiV1LegalStatusesUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readLegalStatusApiV1LegalStatusesUserIdGet.');
                            }
                            localVarPath = "/api/v1/legal_statuses/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Legal Status Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalStatusMeApiV1LegalStatusesMeGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/legal_statuses/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Review Registration Proof
         * @param {number} userId
         * @param {boolean} approved
         * @param {string} [firstname]
         * @param {string} [lastname]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost: function (userId_1, approved_1, firstname_1, lastname_1, siren_1, siret_1, immatRcs_1, addressAddress_1, addressCity_1, addressCountry_1, addressPostalCode_1) {
            var args_1 = [];
            for (var _i = 11; _i < arguments.length; _i++) {
                args_1[_i - 11] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, approved_1, firstname_1, lastname_1, siren_1, siret_1, immatRcs_1, addressAddress_1, addressCity_1, addressCountry_1, addressPostalCode_1], args_1, true), void 0, function (userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost.');
                            }
                            // verify required parameter 'approved' is not null or undefined
                            if (approved === null || approved === undefined) {
                                throw new base_1.RequiredError('approved', 'Required parameter approved was null or undefined when calling reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost.');
                            }
                            localVarPath = "/api/v1/legal_statuses/{user_id}/registration-proof"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (approved !== undefined) {
                                localVarFormParams.set('approved', approved);
                            }
                            if (firstname !== undefined) {
                                localVarFormParams.set('firstname', firstname);
                            }
                            if (lastname !== undefined) {
                                localVarFormParams.set('lastname', lastname);
                            }
                            if (siren !== undefined) {
                                localVarFormParams.set('siren', siren);
                            }
                            if (siret !== undefined) {
                                localVarFormParams.set('siret', siret);
                            }
                            if (immatRcs !== undefined) {
                                localVarFormParams.set('immat_rcs', immatRcs);
                            }
                            if (addressAddress !== undefined) {
                                localVarFormParams.set('address_address', addressAddress);
                            }
                            if (addressCity !== undefined) {
                                localVarFormParams.set('address_city', addressCity);
                            }
                            if (addressCountry !== undefined) {
                                localVarFormParams.set('address_country', addressCountry);
                            }
                            if (addressPostalCode !== undefined) {
                                localVarFormParams.set('address_postal_code', addressPostalCode);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Legal Status
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalStatusApiV1LegalStatusesUserIdPut: function (userId_1, objectName_1, file_1, filename_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, objectName_1, file_1, filename_1], args_1, true), void 0, function (userId, objectName, file, filename, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling updateLegalStatusApiV1LegalStatusesUserIdPut.');
                            }
                            localVarPath = "/api/v1/legal_statuses/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (objectName !== undefined) {
                                localVarQueryParameter['object_name'] = objectName;
                            }
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (filename !== undefined) {
                                localVarFormParams.append('filename', filename);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LegalStatusesApiAxiosParamCreator = LegalStatusesApiAxiosParamCreator;
/**
 * LegalStatusesApi - functional programming interface
 * @export
 */
var LegalStatusesApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create Legal Status
         * @param {number} userId
         * @param {string} [objectName]
         * @param {string} [civilStatus]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalStatusApiV1LegalStatusesUserIdPost: function (userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesApiAxiosParamCreator)(configuration).createLegalStatusApiV1LegalStatusesUserIdPost(userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Legal Status
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalStatusApiV1LegalStatusesUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesApiAxiosParamCreator)(configuration).readLegalStatusApiV1LegalStatusesUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Legal Status Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalStatusMeApiV1LegalStatusesMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesApiAxiosParamCreator)(configuration).readLegalStatusMeApiV1LegalStatusesMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Review Registration Proof
         * @param {number} userId
         * @param {boolean} approved
         * @param {string} [firstname]
         * @param {string} [lastname]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost: function (userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesApiAxiosParamCreator)(configuration).reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost(userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Legal Status
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalStatusApiV1LegalStatusesUserIdPut: function (userId, objectName, file, filename, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesApiAxiosParamCreator)(configuration).updateLegalStatusApiV1LegalStatusesUserIdPut(userId, objectName, file, filename, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.LegalStatusesApiFp = LegalStatusesApiFp;
/**
 * LegalStatusesApi - factory interface
 * @export
 */
var LegalStatusesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create Legal Status
         * @param {number} userId
         * @param {string} [objectName]
         * @param {string} [civilStatus]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalStatusApiV1LegalStatusesUserIdPost: function (userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options) {
            return (0, exports.LegalStatusesApiFp)(configuration).createLegalStatusApiV1LegalStatusesUserIdPost(userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Legal Status
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalStatusApiV1LegalStatusesUserIdGet: function (userId, options) {
            return (0, exports.LegalStatusesApiFp)(configuration).readLegalStatusApiV1LegalStatusesUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Legal Status Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalStatusMeApiV1LegalStatusesMeGet: function (options) {
            return (0, exports.LegalStatusesApiFp)(configuration).readLegalStatusMeApiV1LegalStatusesMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Review Registration Proof
         * @param {number} userId
         * @param {boolean} approved
         * @param {string} [firstname]
         * @param {string} [lastname]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost: function (userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options) {
            return (0, exports.LegalStatusesApiFp)(configuration).reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost(userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Legal Status
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalStatusApiV1LegalStatusesUserIdPut: function (userId, objectName, file, filename, options) {
            return (0, exports.LegalStatusesApiFp)(configuration).updateLegalStatusApiV1LegalStatusesUserIdPut(userId, objectName, file, filename, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LegalStatusesApiFactory = LegalStatusesApiFactory;
/**
 * LegalStatusesApi - object-oriented interface
 * @export
 * @class LegalStatusesApi
 * @extends {BaseAPI}
 */
var LegalStatusesApi = /** @class */ (function (_super) {
    __extends(LegalStatusesApi, _super);
    function LegalStatusesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create Legal Status
     * @param {number} userId
     * @param {string} [objectName]
     * @param {string} [civilStatus]
     * @param {string} [siren]
     * @param {string} [siret]
     * @param {string} [immatRcs]
     * @param {string} [addressAddress]
     * @param {string} [addressCity]
     * @param {string} [addressCountry]
     * @param {string} [addressPostalCode]
     * @param {any} [file]
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesApi
     */
    LegalStatusesApi.prototype.createLegalStatusApiV1LegalStatusesUserIdPost = function (userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options) {
        var _this = this;
        return (0, exports.LegalStatusesApiFp)(this.configuration).createLegalStatusApiV1LegalStatusesUserIdPost(userId, objectName, civilStatus, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, file, filename, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Legal Status
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesApi
     */
    LegalStatusesApi.prototype.readLegalStatusApiV1LegalStatusesUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.LegalStatusesApiFp)(this.configuration).readLegalStatusApiV1LegalStatusesUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Legal Status Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesApi
     */
    LegalStatusesApi.prototype.readLegalStatusMeApiV1LegalStatusesMeGet = function (options) {
        var _this = this;
        return (0, exports.LegalStatusesApiFp)(this.configuration).readLegalStatusMeApiV1LegalStatusesMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Review Registration Proof
     * @param {number} userId
     * @param {boolean} approved
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {string} [siren]
     * @param {string} [siret]
     * @param {string} [immatRcs]
     * @param {string} [addressAddress]
     * @param {string} [addressCity]
     * @param {string} [addressCountry]
     * @param {string} [addressPostalCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesApi
     */
    LegalStatusesApi.prototype.reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost = function (userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options) {
        var _this = this;
        return (0, exports.LegalStatusesApiFp)(this.configuration).reviewRegistrationProofApiV1LegalStatusesUserIdRegistrationProofPost(userId, approved, firstname, lastname, siren, siret, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Legal Status
     * @param {number} userId
     * @param {string} [objectName]
     * @param {any} [file]
     * @param {string} [filename]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesApi
     */
    LegalStatusesApi.prototype.updateLegalStatusApiV1LegalStatusesUserIdPut = function (userId, objectName, file, filename, options) {
        var _this = this;
        return (0, exports.LegalStatusesApiFp)(this.configuration).updateLegalStatusApiV1LegalStatusesUserIdPut(userId, objectName, file, filename, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LegalStatusesApi;
}(base_1.BaseAPI));
exports.LegalStatusesApi = LegalStatusesApi;
/**
 * LegalStatusesAdminApi - axios parameter creator
 * @export
 */
var LegalStatusesAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create Legal Status Admin
         * @param {number} userId
         * @param {string} [legalForm]
         * @param {string} [legalPersonType]
         * @param {string} [civilStatus]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [name]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {number} [vat]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost: function (userId_1, legalForm_1, legalPersonType_1, civilStatus_1, siren_1, siret_1, name_1, immatRcs_1, addressAddress_1, addressCity_1, addressCountry_1, addressPostalCode_1, vat_1) {
            var args_1 = [];
            for (var _i = 13; _i < arguments.length; _i++) {
                args_1[_i - 13] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, legalForm_1, legalPersonType_1, civilStatus_1, siren_1, siret_1, name_1, immatRcs_1, addressAddress_1, addressCity_1, addressCountry_1, addressPostalCode_1, vat_1], args_1, true), void 0, function (userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost.');
                            }
                            localVarPath = "/api/v1/admin/legal_statuses/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (legalForm !== undefined) {
                                localVarFormParams.set('legal_form', legalForm);
                            }
                            if (legalPersonType !== undefined) {
                                localVarFormParams.set('legal_person_type', legalPersonType);
                            }
                            if (civilStatus !== undefined) {
                                localVarFormParams.set('civil_status', civilStatus);
                            }
                            if (siren !== undefined) {
                                localVarFormParams.set('siren', siren);
                            }
                            if (siret !== undefined) {
                                localVarFormParams.set('siret', siret);
                            }
                            if (name !== undefined) {
                                localVarFormParams.set('name', name);
                            }
                            if (immatRcs !== undefined) {
                                localVarFormParams.set('immat_rcs', immatRcs);
                            }
                            if (addressAddress !== undefined) {
                                localVarFormParams.set('address_address', addressAddress);
                            }
                            if (addressCity !== undefined) {
                                localVarFormParams.set('address_city', addressCity);
                            }
                            if (addressCountry !== undefined) {
                                localVarFormParams.set('address_country', addressCountry);
                            }
                            if (addressPostalCode !== undefined) {
                                localVarFormParams.set('address_postal_code', addressPostalCode);
                            }
                            if (vat !== undefined) {
                                localVarFormParams.set('vat', vat);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Articles Of Association
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {boolean} [submitKyc]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut: function (userId_1, objectName_1, file_1, filename_1, submitKyc_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, objectName_1, file_1, filename_1, submitKyc_1], args_1, true), void 0, function (userId, objectName, file, filename, submitKyc, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut.');
                            }
                            localVarPath = "/api/v1/admin/legal_statuses/{user_id}/articles-of-association"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (objectName !== undefined) {
                                localVarQueryParameter['object_name'] = objectName;
                            }
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (filename !== undefined) {
                                localVarFormParams.append('filename', filename);
                            }
                            if (submitKyc !== undefined) {
                                localVarFormParams.append('submit_kyc', submitKyc);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Legal Document
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {boolean} [submitKyc]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut: function (userId_1, objectName_1, file_1, filename_1, submitKyc_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, objectName_1, file_1, filename_1, submitKyc_1], args_1, true), void 0, function (userId, objectName, file, filename, submitKyc, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut.');
                            }
                            localVarPath = "/api/v1/admin/legal_statuses/{user_id}/registration-proof"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (objectName !== undefined) {
                                localVarQueryParameter['object_name'] = objectName;
                            }
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (filename !== undefined) {
                                localVarFormParams.append('filename', filename);
                            }
                            if (submitKyc !== undefined) {
                                localVarFormParams.append('submit_kyc', submitKyc);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LegalStatusesAdminApiAxiosParamCreator = LegalStatusesAdminApiAxiosParamCreator;
/**
 * LegalStatusesAdminApi - functional programming interface
 * @export
 */
var LegalStatusesAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create Legal Status Admin
         * @param {number} userId
         * @param {string} [legalForm]
         * @param {string} [legalPersonType]
         * @param {string} [civilStatus]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [name]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {number} [vat]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost: function (userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesAdminApiAxiosParamCreator)(configuration).createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost(userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Articles Of Association
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {boolean} [submitKyc]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut: function (userId, objectName, file, filename, submitKyc, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesAdminApiAxiosParamCreator)(configuration).uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut(userId, objectName, file, filename, submitKyc, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Legal Document
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {boolean} [submitKyc]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut: function (userId, objectName, file, filename, submitKyc, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LegalStatusesAdminApiAxiosParamCreator)(configuration).uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut(userId, objectName, file, filename, submitKyc, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.LegalStatusesAdminApiFp = LegalStatusesAdminApiFp;
/**
 * LegalStatusesAdminApi - factory interface
 * @export
 */
var LegalStatusesAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create Legal Status Admin
         * @param {number} userId
         * @param {string} [legalForm]
         * @param {string} [legalPersonType]
         * @param {string} [civilStatus]
         * @param {string} [siren]
         * @param {string} [siret]
         * @param {string} [name]
         * @param {string} [immatRcs]
         * @param {string} [addressAddress]
         * @param {string} [addressCity]
         * @param {string} [addressCountry]
         * @param {string} [addressPostalCode]
         * @param {number} [vat]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost: function (userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options) {
            return (0, exports.LegalStatusesAdminApiFp)(configuration).createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost(userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Articles Of Association
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {boolean} [submitKyc]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut: function (userId, objectName, file, filename, submitKyc, options) {
            return (0, exports.LegalStatusesAdminApiFp)(configuration).uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut(userId, objectName, file, filename, submitKyc, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Legal Document
         * @param {number} userId
         * @param {string} [objectName]
         * @param {any} [file]
         * @param {string} [filename]
         * @param {boolean} [submitKyc]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut: function (userId, objectName, file, filename, submitKyc, options) {
            return (0, exports.LegalStatusesAdminApiFp)(configuration).uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut(userId, objectName, file, filename, submitKyc, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LegalStatusesAdminApiFactory = LegalStatusesAdminApiFactory;
/**
 * LegalStatusesAdminApi - object-oriented interface
 * @export
 * @class LegalStatusesAdminApi
 * @extends {BaseAPI}
 */
var LegalStatusesAdminApi = /** @class */ (function (_super) {
    __extends(LegalStatusesAdminApi, _super);
    function LegalStatusesAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create Legal Status Admin
     * @param {number} userId
     * @param {string} [legalForm]
     * @param {string} [legalPersonType]
     * @param {string} [civilStatus]
     * @param {string} [siren]
     * @param {string} [siret]
     * @param {string} [name]
     * @param {string} [immatRcs]
     * @param {string} [addressAddress]
     * @param {string} [addressCity]
     * @param {string} [addressCountry]
     * @param {string} [addressPostalCode]
     * @param {number} [vat]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesAdminApi
     */
    LegalStatusesAdminApi.prototype.createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost = function (userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options) {
        var _this = this;
        return (0, exports.LegalStatusesAdminApiFp)(this.configuration).createLegalStatusAdminApiV1AdminLegalStatusesUserIdPost(userId, legalForm, legalPersonType, civilStatus, siren, siret, name, immatRcs, addressAddress, addressCity, addressCountry, addressPostalCode, vat, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Articles Of Association
     * @param {number} userId
     * @param {string} [objectName]
     * @param {any} [file]
     * @param {string} [filename]
     * @param {boolean} [submitKyc]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesAdminApi
     */
    LegalStatusesAdminApi.prototype.uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut = function (userId, objectName, file, filename, submitKyc, options) {
        var _this = this;
        return (0, exports.LegalStatusesAdminApiFp)(this.configuration).uploadArticlesOfAssociationApiV1AdminLegalStatusesUserIdArticlesOfAssociationPut(userId, objectName, file, filename, submitKyc, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Legal Document
     * @param {number} userId
     * @param {string} [objectName]
     * @param {any} [file]
     * @param {string} [filename]
     * @param {boolean} [submitKyc]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalStatusesAdminApi
     */
    LegalStatusesAdminApi.prototype.uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut = function (userId, objectName, file, filename, submitKyc, options) {
        var _this = this;
        return (0, exports.LegalStatusesAdminApiFp)(this.configuration).uploadLegalDocumentApiV1AdminLegalStatusesUserIdRegistrationProofPut(userId, objectName, file, filename, submitKyc, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LegalStatusesAdminApi;
}(base_1.BaseAPI));
exports.LegalStatusesAdminApi = LegalStatusesAdminApi;
/**
 * ListingRequestsApi - axios parameter creator
 * @export
 */
var ListingRequestsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Accept Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut: function (listingRequestId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestId_1], args_1, true), void 0, function (listingRequestId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestId' is not null or undefined
                            if (listingRequestId === null || listingRequestId === undefined) {
                                throw new base_1.RequiredError('listingRequestId', 'Required parameter listingRequestId was null or undefined when calling acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut.');
                            }
                            localVarPath = "/api/v1/listing-requests/accept/{listing_request_id}"
                                .replace("{".concat("listing_request_id", "}"), encodeURIComponent(String(listingRequestId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Assign Preauth
         * @param {number} listingRequestId
         * @param {string} preauthId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut: function (listingRequestId_1, preauthId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestId_1, preauthId_1], args_1, true), void 0, function (listingRequestId, preauthId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestId' is not null or undefined
                            if (listingRequestId === null || listingRequestId === undefined) {
                                throw new base_1.RequiredError('listingRequestId', 'Required parameter listingRequestId was null or undefined when calling assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut.');
                            }
                            // verify required parameter 'preauthId' is not null or undefined
                            if (preauthId === null || preauthId === undefined) {
                                throw new base_1.RequiredError('preauthId', 'Required parameter preauthId was null or undefined when calling assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut.');
                            }
                            localVarPath = "/api/v1/listing-requests/assign-preauth/{listing_request_id}"
                                .replace("{".concat("listing_request_id", "}"), encodeURIComponent(String(listingRequestId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (preauthId !== undefined) {
                                localVarQueryParameter['preauth_id'] = preauthId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Cancel Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut: function (listingRequestId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestId_1], args_1, true), void 0, function (listingRequestId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestId' is not null or undefined
                            if (listingRequestId === null || listingRequestId === undefined) {
                                throw new base_1.RequiredError('listingRequestId', 'Required parameter listingRequestId was null or undefined when calling cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut.');
                            }
                            localVarPath = "/api/v1/listing-requests/cancel/{listing_request_id}"
                                .replace("{".concat("listing_request_id", "}"), encodeURIComponent(String(listingRequestId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Decline Listing Request
         * @param {number} listingRequestId
         * @param {string} [reason]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut: function (listingRequestId_1, reason_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestId_1, reason_1], args_1, true), void 0, function (listingRequestId, reason, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestId' is not null or undefined
                            if (listingRequestId === null || listingRequestId === undefined) {
                                throw new base_1.RequiredError('listingRequestId', 'Required parameter listingRequestId was null or undefined when calling declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut.');
                            }
                            localVarPath = "/api/v1/listing-requests/decline/{listing_request_id}"
                                .replace("{".concat("listing_request_id", "}"), encodeURIComponent(String(listingRequestId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (reason !== undefined) {
                                localVarFormParams.set('reason', reason);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} guru
         * @param {number} durationInSecs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1ListingRequestsPriceGet: function (guru_1, durationInSecs_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([guru_1, durationInSecs_1], args_1, true), void 0, function (guru, durationInSecs, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'guru' is not null or undefined
                            if (guru === null || guru === undefined) {
                                throw new base_1.RequiredError('guru', 'Required parameter guru was null or undefined when calling getPriceApiV1ListingRequestsPriceGet.');
                            }
                            // verify required parameter 'durationInSecs' is not null or undefined
                            if (durationInSecs === null || durationInSecs === undefined) {
                                throw new base_1.RequiredError('durationInSecs', 'Required parameter durationInSecs was null or undefined when calling getPriceApiV1ListingRequestsPriceGet.');
                            }
                            localVarPath = "/api/v1/listing-requests/price";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (guru !== undefined) {
                                localVarQueryParameter['guru'] = guru;
                            }
                            if (durationInSecs !== undefined) {
                                localVarQueryParameter['duration_in_secs'] = durationInSecs;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestApiV1ListingRequestsListingRequestIdGet: function (listingRequestId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestId_1], args_1, true), void 0, function (listingRequestId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestId' is not null or undefined
                            if (listingRequestId === null || listingRequestId === undefined) {
                                throw new base_1.RequiredError('listingRequestId', 'Required parameter listingRequestId was null or undefined when calling readListingRequestApiV1ListingRequestsListingRequestIdGet.');
                            }
                            localVarPath = "/api/v1/listing-requests/{listing_request_id}"
                                .replace("{".concat("listing_request_id", "}"), encodeURIComponent(String(listingRequestId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Requests
         * @param {string} [status]
         * @param {number} [guru]
         * @param {number} [owner]
         * @param {boolean} [isValid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsApiV1ListingRequestsGet: function (status_1, guru_1, owner_1, isValid_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([status_1, guru_1, owner_1, isValid_1], args_1, true), void 0, function (status, guru, owner, isValid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/listing-requests";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (guru !== undefined) {
                                localVarQueryParameter['guru'] = guru;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            if (isValid !== undefined) {
                                localVarQueryParameter['is_valid'] = isValid;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Requests By Me
         * @param {string} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsByMeApiV1ListingRequestsByMeGet: function (status_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([status_1], args_1, true), void 0, function (status, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/listing-requests/by-me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Requests With Me
         * @param {string} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsWithMeApiV1ListingRequestsWithMeGet: function (status_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([status_1], args_1, true), void 0, function (status, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/listing-requests/with-me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeListingRequestApiV1ListingRequestsListingRequestIdDelete: function (listingRequestId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingRequestId_1], args_1, true), void 0, function (listingRequestId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingRequestId' is not null or undefined
                            if (listingRequestId === null || listingRequestId === undefined) {
                                throw new base_1.RequiredError('listingRequestId', 'Required parameter listingRequestId was null or undefined when calling removeListingRequestApiV1ListingRequestsListingRequestIdDelete.');
                            }
                            localVarPath = "/api/v1/listing-requests/{listing_request_id}"
                                .replace("{".concat("listing_request_id", "}"), encodeURIComponent(String(listingRequestId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ListingRequestsApiAxiosParamCreator = ListingRequestsApiAxiosParamCreator;
/**
 * ListingRequestsApi - functional programming interface
 * @export
 */
var ListingRequestsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Accept Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut: function (listingRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut(listingRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Assign Preauth
         * @param {number} listingRequestId
         * @param {string} preauthId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut: function (listingRequestId, preauthId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut(listingRequestId, preauthId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Cancel Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut: function (listingRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut(listingRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Decline Listing Request
         * @param {number} listingRequestId
         * @param {string} [reason]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut: function (listingRequestId, reason, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut(listingRequestId, reason, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} guru
         * @param {number} durationInSecs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1ListingRequestsPriceGet: function (guru, durationInSecs, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).getPriceApiV1ListingRequestsPriceGet(guru, durationInSecs, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestApiV1ListingRequestsListingRequestIdGet: function (listingRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).readListingRequestApiV1ListingRequestsListingRequestIdGet(listingRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Requests
         * @param {string} [status]
         * @param {number} [guru]
         * @param {number} [owner]
         * @param {boolean} [isValid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsApiV1ListingRequestsGet: function (status, guru, owner, isValid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).readListingRequestsApiV1ListingRequestsGet(status, guru, owner, isValid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Requests By Me
         * @param {string} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsByMeApiV1ListingRequestsByMeGet: function (status, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).readListingRequestsByMeApiV1ListingRequestsByMeGet(status, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Requests With Me
         * @param {string} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsWithMeApiV1ListingRequestsWithMeGet: function (status, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).readListingRequestsWithMeApiV1ListingRequestsWithMeGet(status, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeListingRequestApiV1ListingRequestsListingRequestIdDelete: function (listingRequestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingRequestsApiAxiosParamCreator)(configuration).removeListingRequestApiV1ListingRequestsListingRequestIdDelete(listingRequestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ListingRequestsApiFp = ListingRequestsApiFp;
/**
 * ListingRequestsApi - factory interface
 * @export
 */
var ListingRequestsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Accept Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut: function (listingRequestId, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut(listingRequestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Assign Preauth
         * @param {number} listingRequestId
         * @param {string} preauthId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut: function (listingRequestId, preauthId, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut(listingRequestId, preauthId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Cancel Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut: function (listingRequestId, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut(listingRequestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Decline Listing Request
         * @param {number} listingRequestId
         * @param {string} [reason]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut: function (listingRequestId, reason, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut(listingRequestId, reason, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Price
         * @param {number} guru
         * @param {number} durationInSecs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1ListingRequestsPriceGet: function (guru, durationInSecs, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).getPriceApiV1ListingRequestsPriceGet(guru, durationInSecs, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestApiV1ListingRequestsListingRequestIdGet: function (listingRequestId, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).readListingRequestApiV1ListingRequestsListingRequestIdGet(listingRequestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing Requests
         * @param {string} [status]
         * @param {number} [guru]
         * @param {number} [owner]
         * @param {boolean} [isValid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsApiV1ListingRequestsGet: function (status, guru, owner, isValid, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).readListingRequestsApiV1ListingRequestsGet(status, guru, owner, isValid, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing Requests By Me
         * @param {string} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsByMeApiV1ListingRequestsByMeGet: function (status, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).readListingRequestsByMeApiV1ListingRequestsByMeGet(status, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing Requests With Me
         * @param {string} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingRequestsWithMeApiV1ListingRequestsWithMeGet: function (status, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).readListingRequestsWithMeApiV1ListingRequestsWithMeGet(status, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Listing Request
         * @param {number} listingRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeListingRequestApiV1ListingRequestsListingRequestIdDelete: function (listingRequestId, options) {
            return (0, exports.ListingRequestsApiFp)(configuration).removeListingRequestApiV1ListingRequestsListingRequestIdDelete(listingRequestId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ListingRequestsApiFactory = ListingRequestsApiFactory;
/**
 * ListingRequestsApi - object-oriented interface
 * @export
 * @class ListingRequestsApi
 * @extends {BaseAPI}
 */
var ListingRequestsApi = /** @class */ (function (_super) {
    __extends(ListingRequestsApi, _super);
    function ListingRequestsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Accept Listing Request
     * @param {number} listingRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut = function (listingRequestId, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).acceptListingRequestApiV1ListingRequestsAcceptListingRequestIdPut(listingRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Assign Preauth
     * @param {number} listingRequestId
     * @param {string} preauthId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut = function (listingRequestId, preauthId, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).assignPreauthApiV1ListingRequestsAssignPreauthListingRequestIdPut(listingRequestId, preauthId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Cancel Listing Request
     * @param {number} listingRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut = function (listingRequestId, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).cancelListingRequestApiV1ListingRequestsCancelListingRequestIdPut(listingRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Decline Listing Request
     * @param {number} listingRequestId
     * @param {string} [reason]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut = function (listingRequestId, reason, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).declineListingRequestApiV1ListingRequestsDeclineListingRequestIdPut(listingRequestId, reason, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Price
     * @param {number} guru
     * @param {number} durationInSecs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.getPriceApiV1ListingRequestsPriceGet = function (guru, durationInSecs, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).getPriceApiV1ListingRequestsPriceGet(guru, durationInSecs, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing Request
     * @param {number} listingRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.readListingRequestApiV1ListingRequestsListingRequestIdGet = function (listingRequestId, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).readListingRequestApiV1ListingRequestsListingRequestIdGet(listingRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing Requests
     * @param {string} [status]
     * @param {number} [guru]
     * @param {number} [owner]
     * @param {boolean} [isValid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.readListingRequestsApiV1ListingRequestsGet = function (status, guru, owner, isValid, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).readListingRequestsApiV1ListingRequestsGet(status, guru, owner, isValid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing Requests By Me
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.readListingRequestsByMeApiV1ListingRequestsByMeGet = function (status, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).readListingRequestsByMeApiV1ListingRequestsByMeGet(status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing Requests With Me
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.readListingRequestsWithMeApiV1ListingRequestsWithMeGet = function (status, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).readListingRequestsWithMeApiV1ListingRequestsWithMeGet(status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Listing Request
     * @param {number} listingRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingRequestsApi
     */
    ListingRequestsApi.prototype.removeListingRequestApiV1ListingRequestsListingRequestIdDelete = function (listingRequestId, options) {
        var _this = this;
        return (0, exports.ListingRequestsApiFp)(this.configuration).removeListingRequestApiV1ListingRequestsListingRequestIdDelete(listingRequestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ListingRequestsApi;
}(base_1.BaseAPI));
exports.ListingRequestsApi = ListingRequestsApi;
/**
 * ListingTemplatesApi - axios parameter creator
 * @export
 */
var ListingTemplatesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new listing template
         * @summary Create Listing Template
         * @param {ListingTemplateCreate} listingTemplateCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListingTemplateApiV1TemplatesPost: function (listingTemplateCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingTemplateCreate_1], args_1, true), void 0, function (listingTemplateCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingTemplateCreate' is not null or undefined
                            if (listingTemplateCreate === null || listingTemplateCreate === undefined) {
                                throw new base_1.RequiredError('listingTemplateCreate', 'Required parameter listingTemplateCreate was null or undefined when calling createListingTemplateApiV1TemplatesPost.');
                            }
                            localVarPath = "/api/v1/templates";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof listingTemplateCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(listingTemplateCreate !== undefined ? listingTemplateCreate : {}) : (listingTemplateCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a listing template.
         * @summary Delete Listing Template
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingTemplateApiV1TemplatesIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteListingTemplateApiV1TemplatesIdDelete.');
                            }
                            localVarPath = "/api/v1/templates/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns the default choices for covers
         * @summary Get Cover Defaults
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoverDefaultsApiV1TemplatesCoverDefaultsGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/templates/cover-defaults";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get listing template by ID.
         * @summary Read Listing Template
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingTemplateApiV1TemplatesIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readListingTemplateApiV1TemplatesIdGet.');
                    }
                    localVarPath = "/api/v1/templates/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve listing templates
         * @summary Read Listing Templates
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingTemplatesApiV1TemplatesGet: function (skip_1, limit_1, activity_1, owner_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, activity_1, owner_1], args_1, true), void 0, function (skip, limit, activity, owner, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/templates";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (activity !== undefined) {
                                localVarQueryParameter['activity'] = activity;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a listing template
         * @summary Update Listing
         * @param {number} id
         * @param {ListingTemplateUpdate} listingTemplateUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingApiV1TemplatesIdPut: function (id_1, listingTemplateUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, listingTemplateUpdate_1], args_1, true), void 0, function (id, listingTemplateUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateListingApiV1TemplatesIdPut.');
                            }
                            // verify required parameter 'listingTemplateUpdate' is not null or undefined
                            if (listingTemplateUpdate === null || listingTemplateUpdate === undefined) {
                                throw new base_1.RequiredError('listingTemplateUpdate', 'Required parameter listingTemplateUpdate was null or undefined when calling updateListingApiV1TemplatesIdPut.');
                            }
                            localVarPath = "/api/v1/templates/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof listingTemplateUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(listingTemplateUpdate !== undefined ? listingTemplateUpdate : {}) : (listingTemplateUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Cover Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoApiV1TemplatesCoverPhotoPost: function (file_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([file_1], args_1, true), void 0, function (file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadCoverPhotoApiV1TemplatesCoverPhotoPost.');
                            }
                            localVarPath = "/api/v1/templates/cover-photo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ListingTemplatesApiAxiosParamCreator = ListingTemplatesApiAxiosParamCreator;
/**
 * ListingTemplatesApi - functional programming interface
 * @export
 */
var ListingTemplatesApiFp = function (configuration) {
    return {
        /**
         * Create new listing template
         * @summary Create Listing Template
         * @param {ListingTemplateCreate} listingTemplateCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListingTemplateApiV1TemplatesPost: function (listingTemplateCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).createListingTemplateApiV1TemplatesPost(listingTemplateCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete a listing template.
         * @summary Delete Listing Template
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingTemplateApiV1TemplatesIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).deleteListingTemplateApiV1TemplatesIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Returns the default choices for covers
         * @summary Get Cover Defaults
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoverDefaultsApiV1TemplatesCoverDefaultsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).getCoverDefaultsApiV1TemplatesCoverDefaultsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get listing template by ID.
         * @summary Read Listing Template
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingTemplateApiV1TemplatesIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).readListingTemplateApiV1TemplatesIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve listing templates
         * @summary Read Listing Templates
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingTemplatesApiV1TemplatesGet: function (skip, limit, activity, owner, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).readListingTemplatesApiV1TemplatesGet(skip, limit, activity, owner, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update a listing template
         * @summary Update Listing
         * @param {number} id
         * @param {ListingTemplateUpdate} listingTemplateUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingApiV1TemplatesIdPut: function (id, listingTemplateUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).updateListingApiV1TemplatesIdPut(id, listingTemplateUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Cover Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoApiV1TemplatesCoverPhotoPost: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingTemplatesApiAxiosParamCreator)(configuration).uploadCoverPhotoApiV1TemplatesCoverPhotoPost(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ListingTemplatesApiFp = ListingTemplatesApiFp;
/**
 * ListingTemplatesApi - factory interface
 * @export
 */
var ListingTemplatesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new listing template
         * @summary Create Listing Template
         * @param {ListingTemplateCreate} listingTemplateCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListingTemplateApiV1TemplatesPost: function (listingTemplateCreate, options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).createListingTemplateApiV1TemplatesPost(listingTemplateCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a listing template.
         * @summary Delete Listing Template
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingTemplateApiV1TemplatesIdDelete: function (id, options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).deleteListingTemplateApiV1TemplatesIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns the default choices for covers
         * @summary Get Cover Defaults
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoverDefaultsApiV1TemplatesCoverDefaultsGet: function (options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).getCoverDefaultsApiV1TemplatesCoverDefaultsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get listing template by ID.
         * @summary Read Listing Template
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingTemplateApiV1TemplatesIdGet: function (id, options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).readListingTemplateApiV1TemplatesIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve listing templates
         * @summary Read Listing Templates
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingTemplatesApiV1TemplatesGet: function (skip, limit, activity, owner, options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).readListingTemplatesApiV1TemplatesGet(skip, limit, activity, owner, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a listing template
         * @summary Update Listing
         * @param {number} id
         * @param {ListingTemplateUpdate} listingTemplateUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingApiV1TemplatesIdPut: function (id, listingTemplateUpdate, options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).updateListingApiV1TemplatesIdPut(id, listingTemplateUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Cover Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoApiV1TemplatesCoverPhotoPost: function (file, options) {
            return (0, exports.ListingTemplatesApiFp)(configuration).uploadCoverPhotoApiV1TemplatesCoverPhotoPost(file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ListingTemplatesApiFactory = ListingTemplatesApiFactory;
/**
 * ListingTemplatesApi - object-oriented interface
 * @export
 * @class ListingTemplatesApi
 * @extends {BaseAPI}
 */
var ListingTemplatesApi = /** @class */ (function (_super) {
    __extends(ListingTemplatesApi, _super);
    function ListingTemplatesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new listing template
     * @summary Create Listing Template
     * @param {ListingTemplateCreate} listingTemplateCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.createListingTemplateApiV1TemplatesPost = function (listingTemplateCreate, options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).createListingTemplateApiV1TemplatesPost(listingTemplateCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a listing template.
     * @summary Delete Listing Template
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.deleteListingTemplateApiV1TemplatesIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).deleteListingTemplateApiV1TemplatesIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns the default choices for covers
     * @summary Get Cover Defaults
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.getCoverDefaultsApiV1TemplatesCoverDefaultsGet = function (options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).getCoverDefaultsApiV1TemplatesCoverDefaultsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get listing template by ID.
     * @summary Read Listing Template
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.readListingTemplateApiV1TemplatesIdGet = function (id, options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).readListingTemplateApiV1TemplatesIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve listing templates
     * @summary Read Listing Templates
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [activity]
     * @param {number} [owner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.readListingTemplatesApiV1TemplatesGet = function (skip, limit, activity, owner, options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).readListingTemplatesApiV1TemplatesGet(skip, limit, activity, owner, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a listing template
     * @summary Update Listing
     * @param {number} id
     * @param {ListingTemplateUpdate} listingTemplateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.updateListingApiV1TemplatesIdPut = function (id, listingTemplateUpdate, options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).updateListingApiV1TemplatesIdPut(id, listingTemplateUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Cover Photo
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingTemplatesApi
     */
    ListingTemplatesApi.prototype.uploadCoverPhotoApiV1TemplatesCoverPhotoPost = function (file, options) {
        var _this = this;
        return (0, exports.ListingTemplatesApiFp)(this.configuration).uploadCoverPhotoApiV1TemplatesCoverPhotoPost(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ListingTemplatesApi;
}(base_1.BaseAPI));
exports.ListingTemplatesApi = ListingTemplatesApi;
/**
 * ListingsApi - axios parameter creator
 * @export
 */
var ListingsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new listing.
         * @summary Create Listing
         * @param {ListingCreate} listingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListingApiV1ListingsPost: function (listingCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingCreate_1], args_1, true), void 0, function (listingCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingCreate' is not null or undefined
                            if (listingCreate === null || listingCreate === undefined) {
                                throw new base_1.RequiredError('listingCreate', 'Required parameter listingCreate was null or undefined when calling createListingApiV1ListingsPost.');
                            }
                            localVarPath = "/api/v1/listings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof listingCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(listingCreate !== undefined ? listingCreate : {}) : (listingCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a listing.
         * @summary Delete Listing
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingApiV1ListingsIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteListingApiV1ListingsIdDelete.');
                            }
                            localVarPath = "/api/v1/listings/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a listing and all future ocurrences if recurrent.
         * @summary Delete Listings
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingsApiV1ListingsIdAllFutureDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteListingsApiV1ListingsIdAllFutureDelete.');
                            }
                            localVarPath = "/api/v1/listings/{id}/all-future"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Insta Share
         * @param {string} listingSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaShareApiV1ListingsInstaShareListingSlugGet: function (listingSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingSlug_1], args_1, true), void 0, function (listingSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingSlug' is not null or undefined
                            if (listingSlug === null || listingSlug === undefined) {
                                throw new base_1.RequiredError('listingSlug', 'Required parameter listingSlug was null or undefined when calling getInstaShareApiV1ListingsInstaShareListingSlugGet.');
                            }
                            localVarPath = "/api/v1/listings/insta-share/{listing_slug}"
                                .replace("{".concat("listing_slug", "}"), encodeURIComponent(String(listingSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} listingId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1ListingsPriceGet: function (listingId_1, promoId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1, promoId_1], args_1, true), void 0, function (listingId, promoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling getPriceApiV1ListingsPriceGet.');
                            }
                            localVarPath = "/api/v1/listings/price";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (listingId !== undefined) {
                                localVarQueryParameter['listing_id'] = listingId;
                            }
                            if (promoId !== undefined) {
                                localVarQueryParameter['promo_id'] = promoId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Invite to listing
         * @summary Invite Listing
         * @param {number} id
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteListingApiV1ListingsIdInviteUserIdPost: function (id_1, userId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, userId_1], args_1, true), void 0, function (id, userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling inviteListingApiV1ListingsIdInviteUserIdPost.');
                            }
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling inviteListingApiV1ListingsIdInviteUserIdPost.');
                            }
                            localVarPath = "/api/v1/listings/{id}/invite/{user_id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Am I In Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAmIInWaitingListApiV1ListingsIdWaitingListMeGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readAmIInWaitingListApiV1ListingsIdWaitingListMeGet.');
                            }
                            localVarPath = "/api/v1/listings/{id}/waiting-list/me"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Dates With Listings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDatesWithListingsApiV1ListingsDatesWithListingsGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/listings/dates-with-listings";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get listing by ID.
         * @summary Read Listing
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingApiV1ListingsIdOrSlugGet: function (idOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([idOrSlug_1], args_1, true), void 0, function (idOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'idOrSlug' is not null or undefined
                            if (idOrSlug === null || idOrSlug === undefined) {
                                throw new base_1.RequiredError('idOrSlug', 'Required parameter idOrSlug was null or undefined when calling readListingApiV1ListingsIdOrSlugGet.');
                            }
                            localVarPath = "/api/v1/listings/{id_or_slug}"
                                .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Private
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingPrivateApiV1ListingsPrivateIdOrSlugGet: function (idOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([idOrSlug_1], args_1, true), void 0, function (idOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'idOrSlug' is not null or undefined
                            if (idOrSlug === null || idOrSlug === undefined) {
                                throw new base_1.RequiredError('idOrSlug', 'Required parameter idOrSlug was null or undefined when calling readListingPrivateApiV1ListingsPrivateIdOrSlugGet.');
                            }
                            localVarPath = "/api/v1/listings/private/{id_or_slug}"
                                .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve listings.
         * @summary Read Listings
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {string} [parentActivity]
         * @param {boolean} [isOnline]
         * @param {string} [locationIds]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {string} [sortby]
         * @param {number} [owner]
         * @param {boolean} [onlyNotFull]
         * @param {boolean} [onlyNotEmpty]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {string} [guruFirstnameIlike]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsApiV1ListingsGet: function (skip_1, limit_1, activity_1, parentActivity_1, isOnline_1, locationIds_1, dateStart_1, dateEnd_1, sortby_1, owner_1, onlyNotFull_1, onlyNotEmpty_1, priceMin_1, priceMax_1, guruFirstnameIlike_1) {
            var args_1 = [];
            for (var _i = 15; _i < arguments.length; _i++) {
                args_1[_i - 15] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, activity_1, parentActivity_1, isOnline_1, locationIds_1, dateStart_1, dateEnd_1, sortby_1, owner_1, onlyNotFull_1, onlyNotEmpty_1, priceMin_1, priceMax_1, guruFirstnameIlike_1], args_1, true), void 0, function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/listings";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (activity !== undefined) {
                                localVarQueryParameter['activity'] = activity;
                            }
                            if (parentActivity !== undefined) {
                                localVarQueryParameter['parent_activity'] = parentActivity;
                            }
                            if (isOnline !== undefined) {
                                localVarQueryParameter['is_online'] = isOnline;
                            }
                            if (locationIds !== undefined) {
                                localVarQueryParameter['location_ids'] = locationIds;
                            }
                            if (dateStart !== undefined) {
                                localVarQueryParameter['date_start'] = dateStart;
                            }
                            if (dateEnd !== undefined) {
                                localVarQueryParameter['date_end'] = dateEnd;
                            }
                            if (sortby !== undefined) {
                                localVarQueryParameter['sortby'] = sortby;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            if (onlyNotFull !== undefined) {
                                localVarQueryParameter['only_not_full'] = onlyNotFull;
                            }
                            if (onlyNotEmpty !== undefined) {
                                localVarQueryParameter['only_not_empty'] = onlyNotEmpty;
                            }
                            if (priceMin !== undefined) {
                                localVarQueryParameter['price_min'] = priceMin;
                            }
                            if (priceMax !== undefined) {
                                localVarQueryParameter['price_max'] = priceMax;
                            }
                            if (guruFirstnameIlike !== undefined) {
                                localVarQueryParameter['guru_firstname_ilike'] = guruFirstnameIlike;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve listings.
         * @summary Read Listings Tiny
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {string} [parentActivity]
         * @param {boolean} [isOnline]
         * @param {string} [locationIds]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {string} [sortby]
         * @param {number} [owner]
         * @param {boolean} [onlyNotFull]
         * @param {boolean} [onlyNotEmpty]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {string} [guruFirstnameIlike]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsTinyApiV1ListingsTinyGet: function (skip_1, limit_1, activity_1, parentActivity_1, isOnline_1, locationIds_1, dateStart_1, dateEnd_1, sortby_1, owner_1, onlyNotFull_1, onlyNotEmpty_1, priceMin_1, priceMax_1, guruFirstnameIlike_1) {
            var args_1 = [];
            for (var _i = 15; _i < arguments.length; _i++) {
                args_1[_i - 15] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, activity_1, parentActivity_1, isOnline_1, locationIds_1, dateStart_1, dateEnd_1, sortby_1, owner_1, onlyNotFull_1, onlyNotEmpty_1, priceMin_1, priceMax_1, guruFirstnameIlike_1], args_1, true), void 0, function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/listings/tiny";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (activity !== undefined) {
                                localVarQueryParameter['activity'] = activity;
                            }
                            if (parentActivity !== undefined) {
                                localVarQueryParameter['parent_activity'] = parentActivity;
                            }
                            if (isOnline !== undefined) {
                                localVarQueryParameter['is_online'] = isOnline;
                            }
                            if (locationIds !== undefined) {
                                localVarQueryParameter['location_ids'] = locationIds;
                            }
                            if (dateStart !== undefined) {
                                localVarQueryParameter['date_start'] = dateStart;
                            }
                            if (dateEnd !== undefined) {
                                localVarQueryParameter['date_end'] = dateEnd;
                            }
                            if (sortby !== undefined) {
                                localVarQueryParameter['sortby'] = sortby;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            if (onlyNotFull !== undefined) {
                                localVarQueryParameter['only_not_full'] = onlyNotFull;
                            }
                            if (onlyNotEmpty !== undefined) {
                                localVarQueryParameter['only_not_empty'] = onlyNotEmpty;
                            }
                            if (priceMin !== undefined) {
                                localVarQueryParameter['price_min'] = priceMin;
                            }
                            if (priceMax !== undefined) {
                                localVarQueryParameter['price_max'] = priceMax;
                            }
                            if (guruFirstnameIlike !== undefined) {
                                localVarQueryParameter['guru_firstname_ilike'] = guruFirstnameIlike;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve gurus of current user
         * @summary Read My Teachers
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMyTeachersApiV1ListingsMyTeachersGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/listings/my-teachers";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWaitingListApiV1ListingsIdWaitingListGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readWaitingListApiV1ListingsIdWaitingListGet.');
                            }
                            localVarPath = "/api/v1/listings/{id}/waiting-list"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Subscribe To Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost.');
                            }
                            localVarPath = "/api/v1/listings/{id}/waiting-list/subscribe"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Unsubscribe From Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost.');
                            }
                            localVarPath = "/api/v1/listings/{id}/waiting-list/unsubscribe"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update an listing.
         * @summary Update Listing
         * @param {number} id
         * @param {ListingUpdate} listingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingApiV1ListingsIdPut: function (id_1, listingUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, listingUpdate_1], args_1, true), void 0, function (id, listingUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateListingApiV1ListingsIdPut.');
                            }
                            // verify required parameter 'listingUpdate' is not null or undefined
                            if (listingUpdate === null || listingUpdate === undefined) {
                                throw new base_1.RequiredError('listingUpdate', 'Required parameter listingUpdate was null or undefined when calling updateListingApiV1ListingsIdPut.');
                            }
                            localVarPath = "/api/v1/listings/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof listingUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(listingUpdate !== undefined ? listingUpdate : {}) : (listingUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a listing and all future ocurrences if recurrent.
         * @summary Update Listings
         * @param {number} id
         * @param {ListingUpdate} listingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingsApiV1ListingsIdAllFuturePut: function (id_1, listingUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, listingUpdate_1], args_1, true), void 0, function (id, listingUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateListingsApiV1ListingsIdAllFuturePut.');
                            }
                            // verify required parameter 'listingUpdate' is not null or undefined
                            if (listingUpdate === null || listingUpdate === undefined) {
                                throw new base_1.RequiredError('listingUpdate', 'Required parameter listingUpdate was null or undefined when calling updateListingsApiV1ListingsIdAllFuturePut.');
                            }
                            localVarPath = "/api/v1/listings/{id}/all-future"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof listingUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(listingUpdate !== undefined ? listingUpdate : {}) : (listingUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ListingsApiAxiosParamCreator = ListingsApiAxiosParamCreator;
/**
 * ListingsApi - functional programming interface
 * @export
 */
var ListingsApiFp = function (configuration) {
    return {
        /**
         * Create new listing.
         * @summary Create Listing
         * @param {ListingCreate} listingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListingApiV1ListingsPost: function (listingCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).createListingApiV1ListingsPost(listingCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete a listing.
         * @summary Delete Listing
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingApiV1ListingsIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).deleteListingApiV1ListingsIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete a listing and all future ocurrences if recurrent.
         * @summary Delete Listings
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingsApiV1ListingsIdAllFutureDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).deleteListingsApiV1ListingsIdAllFutureDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Insta Share
         * @param {string} listingSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaShareApiV1ListingsInstaShareListingSlugGet: function (listingSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).getInstaShareApiV1ListingsInstaShareListingSlugGet(listingSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} listingId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1ListingsPriceGet: function (listingId, promoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).getPriceApiV1ListingsPriceGet(listingId, promoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Invite to listing
         * @summary Invite Listing
         * @param {number} id
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteListingApiV1ListingsIdInviteUserIdPost: function (id, userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).inviteListingApiV1ListingsIdInviteUserIdPost(id, userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Am I In Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAmIInWaitingListApiV1ListingsIdWaitingListMeGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readAmIInWaitingListApiV1ListingsIdWaitingListMeGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Dates With Listings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDatesWithListingsApiV1ListingsDatesWithListingsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readDatesWithListingsApiV1ListingsDatesWithListingsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get listing by ID.
         * @summary Read Listing
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingApiV1ListingsIdOrSlugGet: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readListingApiV1ListingsIdOrSlugGet(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Listing Private
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingPrivateApiV1ListingsPrivateIdOrSlugGet: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readListingPrivateApiV1ListingsPrivateIdOrSlugGet(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve listings.
         * @summary Read Listings
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {string} [parentActivity]
         * @param {boolean} [isOnline]
         * @param {string} [locationIds]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {string} [sortby]
         * @param {number} [owner]
         * @param {boolean} [onlyNotFull]
         * @param {boolean} [onlyNotEmpty]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {string} [guruFirstnameIlike]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsApiV1ListingsGet: function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readListingsApiV1ListingsGet(skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve listings.
         * @summary Read Listings Tiny
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {string} [parentActivity]
         * @param {boolean} [isOnline]
         * @param {string} [locationIds]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {string} [sortby]
         * @param {number} [owner]
         * @param {boolean} [onlyNotFull]
         * @param {boolean} [onlyNotEmpty]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {string} [guruFirstnameIlike]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsTinyApiV1ListingsTinyGet: function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readListingsTinyApiV1ListingsTinyGet(skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve gurus of current user
         * @summary Read My Teachers
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMyTeachersApiV1ListingsMyTeachersGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readMyTeachersApiV1ListingsMyTeachersGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWaitingListApiV1ListingsIdWaitingListGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).readWaitingListApiV1ListingsIdWaitingListGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Subscribe To Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Unsubscribe From Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update an listing.
         * @summary Update Listing
         * @param {number} id
         * @param {ListingUpdate} listingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingApiV1ListingsIdPut: function (id, listingUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).updateListingApiV1ListingsIdPut(id, listingUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update a listing and all future ocurrences if recurrent.
         * @summary Update Listings
         * @param {number} id
         * @param {ListingUpdate} listingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingsApiV1ListingsIdAllFuturePut: function (id, listingUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ListingsApiAxiosParamCreator)(configuration).updateListingsApiV1ListingsIdAllFuturePut(id, listingUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ListingsApiFp = ListingsApiFp;
/**
 * ListingsApi - factory interface
 * @export
 */
var ListingsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new listing.
         * @summary Create Listing
         * @param {ListingCreate} listingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListingApiV1ListingsPost: function (listingCreate, options) {
            return (0, exports.ListingsApiFp)(configuration).createListingApiV1ListingsPost(listingCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a listing.
         * @summary Delete Listing
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingApiV1ListingsIdDelete: function (id, options) {
            return (0, exports.ListingsApiFp)(configuration).deleteListingApiV1ListingsIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a listing and all future ocurrences if recurrent.
         * @summary Delete Listings
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingsApiV1ListingsIdAllFutureDelete: function (id, options) {
            return (0, exports.ListingsApiFp)(configuration).deleteListingsApiV1ListingsIdAllFutureDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Insta Share
         * @param {string} listingSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaShareApiV1ListingsInstaShareListingSlugGet: function (listingSlug, options) {
            return (0, exports.ListingsApiFp)(configuration).getInstaShareApiV1ListingsInstaShareListingSlugGet(listingSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Price
         * @param {number} listingId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1ListingsPriceGet: function (listingId, promoId, options) {
            return (0, exports.ListingsApiFp)(configuration).getPriceApiV1ListingsPriceGet(listingId, promoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Invite to listing
         * @summary Invite Listing
         * @param {number} id
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteListingApiV1ListingsIdInviteUserIdPost: function (id, userId, options) {
            return (0, exports.ListingsApiFp)(configuration).inviteListingApiV1ListingsIdInviteUserIdPost(id, userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Am I In Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAmIInWaitingListApiV1ListingsIdWaitingListMeGet: function (id, options) {
            return (0, exports.ListingsApiFp)(configuration).readAmIInWaitingListApiV1ListingsIdWaitingListMeGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Dates With Listings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDatesWithListingsApiV1ListingsDatesWithListingsGet: function (options) {
            return (0, exports.ListingsApiFp)(configuration).readDatesWithListingsApiV1ListingsDatesWithListingsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get listing by ID.
         * @summary Read Listing
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingApiV1ListingsIdOrSlugGet: function (idOrSlug, options) {
            return (0, exports.ListingsApiFp)(configuration).readListingApiV1ListingsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Listing Private
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingPrivateApiV1ListingsPrivateIdOrSlugGet: function (idOrSlug, options) {
            return (0, exports.ListingsApiFp)(configuration).readListingPrivateApiV1ListingsPrivateIdOrSlugGet(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve listings.
         * @summary Read Listings
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {string} [parentActivity]
         * @param {boolean} [isOnline]
         * @param {string} [locationIds]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {string} [sortby]
         * @param {number} [owner]
         * @param {boolean} [onlyNotFull]
         * @param {boolean} [onlyNotEmpty]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {string} [guruFirstnameIlike]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsApiV1ListingsGet: function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
            return (0, exports.ListingsApiFp)(configuration).readListingsApiV1ListingsGet(skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve listings.
         * @summary Read Listings Tiny
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [activity]
         * @param {string} [parentActivity]
         * @param {boolean} [isOnline]
         * @param {string} [locationIds]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {string} [sortby]
         * @param {number} [owner]
         * @param {boolean} [onlyNotFull]
         * @param {boolean} [onlyNotEmpty]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {string} [guruFirstnameIlike]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListingsTinyApiV1ListingsTinyGet: function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
            return (0, exports.ListingsApiFp)(configuration).readListingsTinyApiV1ListingsTinyGet(skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve gurus of current user
         * @summary Read My Teachers
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMyTeachersApiV1ListingsMyTeachersGet: function (skip, limit, options) {
            return (0, exports.ListingsApiFp)(configuration).readMyTeachersApiV1ListingsMyTeachersGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWaitingListApiV1ListingsIdWaitingListGet: function (id, options) {
            return (0, exports.ListingsApiFp)(configuration).readWaitingListApiV1ListingsIdWaitingListGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Subscribe To Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost: function (id, options) {
            return (0, exports.ListingsApiFp)(configuration).subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Unsubscribe From Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost: function (id, options) {
            return (0, exports.ListingsApiFp)(configuration).unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update an listing.
         * @summary Update Listing
         * @param {number} id
         * @param {ListingUpdate} listingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingApiV1ListingsIdPut: function (id, listingUpdate, options) {
            return (0, exports.ListingsApiFp)(configuration).updateListingApiV1ListingsIdPut(id, listingUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a listing and all future ocurrences if recurrent.
         * @summary Update Listings
         * @param {number} id
         * @param {ListingUpdate} listingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListingsApiV1ListingsIdAllFuturePut: function (id, listingUpdate, options) {
            return (0, exports.ListingsApiFp)(configuration).updateListingsApiV1ListingsIdAllFuturePut(id, listingUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ListingsApiFactory = ListingsApiFactory;
/**
 * ListingsApi - object-oriented interface
 * @export
 * @class ListingsApi
 * @extends {BaseAPI}
 */
var ListingsApi = /** @class */ (function (_super) {
    __extends(ListingsApi, _super);
    function ListingsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new listing.
     * @summary Create Listing
     * @param {ListingCreate} listingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.createListingApiV1ListingsPost = function (listingCreate, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).createListingApiV1ListingsPost(listingCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a listing.
     * @summary Delete Listing
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.deleteListingApiV1ListingsIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).deleteListingApiV1ListingsIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a listing and all future ocurrences if recurrent.
     * @summary Delete Listings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.deleteListingsApiV1ListingsIdAllFutureDelete = function (id, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).deleteListingsApiV1ListingsIdAllFutureDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Insta Share
     * @param {string} listingSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.getInstaShareApiV1ListingsInstaShareListingSlugGet = function (listingSlug, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).getInstaShareApiV1ListingsInstaShareListingSlugGet(listingSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Price
     * @param {number} listingId
     * @param {number} [promoId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.getPriceApiV1ListingsPriceGet = function (listingId, promoId, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).getPriceApiV1ListingsPriceGet(listingId, promoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Invite to listing
     * @summary Invite Listing
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.inviteListingApiV1ListingsIdInviteUserIdPost = function (id, userId, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).inviteListingApiV1ListingsIdInviteUserIdPost(id, userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Am I In Waiting List
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readAmIInWaitingListApiV1ListingsIdWaitingListMeGet = function (id, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readAmIInWaitingListApiV1ListingsIdWaitingListMeGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Dates With Listings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readDatesWithListingsApiV1ListingsDatesWithListingsGet = function (options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readDatesWithListingsApiV1ListingsDatesWithListingsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get listing by ID.
     * @summary Read Listing
     * @param {number | string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readListingApiV1ListingsIdOrSlugGet = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readListingApiV1ListingsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Listing Private
     * @param {number | string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readListingPrivateApiV1ListingsPrivateIdOrSlugGet = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readListingPrivateApiV1ListingsPrivateIdOrSlugGet(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve listings.
     * @summary Read Listings
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [activity]
     * @param {string} [parentActivity]
     * @param {boolean} [isOnline]
     * @param {string} [locationIds]
     * @param {number} [dateStart]
     * @param {number} [dateEnd]
     * @param {string} [sortby]
     * @param {number} [owner]
     * @param {boolean} [onlyNotFull]
     * @param {boolean} [onlyNotEmpty]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {string} [guruFirstnameIlike]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readListingsApiV1ListingsGet = function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readListingsApiV1ListingsGet(skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve listings.
     * @summary Read Listings Tiny
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [activity]
     * @param {string} [parentActivity]
     * @param {boolean} [isOnline]
     * @param {string} [locationIds]
     * @param {number} [dateStart]
     * @param {number} [dateEnd]
     * @param {string} [sortby]
     * @param {number} [owner]
     * @param {boolean} [onlyNotFull]
     * @param {boolean} [onlyNotEmpty]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {string} [guruFirstnameIlike]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readListingsTinyApiV1ListingsTinyGet = function (skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readListingsTinyApiV1ListingsTinyGet(skip, limit, activity, parentActivity, isOnline, locationIds, dateStart, dateEnd, sortby, owner, onlyNotFull, onlyNotEmpty, priceMin, priceMax, guruFirstnameIlike, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve gurus of current user
     * @summary Read My Teachers
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readMyTeachersApiV1ListingsMyTeachersGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readMyTeachersApiV1ListingsMyTeachersGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Waiting List
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.readWaitingListApiV1ListingsIdWaitingListGet = function (id, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).readWaitingListApiV1ListingsIdWaitingListGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Subscribe To Waiting List
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost = function (id, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).subscribeToWaitingListApiV1ListingsIdWaitingListSubscribePost(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Unsubscribe From Waiting List
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost = function (id, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).unsubscribeFromWaitingListApiV1ListingsIdWaitingListUnsubscribePost(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update an listing.
     * @summary Update Listing
     * @param {number} id
     * @param {ListingUpdate} listingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.updateListingApiV1ListingsIdPut = function (id, listingUpdate, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).updateListingApiV1ListingsIdPut(id, listingUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a listing and all future ocurrences if recurrent.
     * @summary Update Listings
     * @param {number} id
     * @param {ListingUpdate} listingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListingsApi
     */
    ListingsApi.prototype.updateListingsApiV1ListingsIdAllFuturePut = function (id, listingUpdate, options) {
        var _this = this;
        return (0, exports.ListingsApiFp)(this.configuration).updateListingsApiV1ListingsIdAllFuturePut(id, listingUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ListingsApi;
}(base_1.BaseAPI));
exports.ListingsApi = ListingsApi;
/**
 * LocationsApi - axios parameter creator
 * @export
 */
var LocationsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new location.
         * @summary Create Location
         * @param {LocationCreate} locationCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationApiV1LocationsPost: function (locationCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([locationCreate_1], args_1, true), void 0, function (locationCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'locationCreate' is not null or undefined
                            if (locationCreate === null || locationCreate === undefined) {
                                throw new base_1.RequiredError('locationCreate', 'Required parameter locationCreate was null or undefined when calling createLocationApiV1LocationsPost.');
                            }
                            localVarPath = "/api/v1/locations";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof locationCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(locationCreate !== undefined ? locationCreate : {}) : (locationCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an location.
         * @summary Delete Location
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationApiV1LocationsIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteLocationApiV1LocationsIdDelete.');
                            }
                            localVarPath = "/api/v1/locations/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get location by ID.
         * @summary Read Location
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLocationApiV1LocationsIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readLocationApiV1LocationsIdGet.');
                    }
                    localVarPath = "/api/v1/locations/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve locations.
         * @summary Read Locations
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLocationsApiV1LocationsGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/locations";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve locations.
         * @summary Read Top Locations
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTopLocationsApiV1LocationsTopGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/locations/top";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Update an location.
         * @summary Update Location
         * @param {number} id
         * @param {LocationUpdate} locationUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationApiV1LocationsIdPut: function (id_1, locationUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, locationUpdate_1], args_1, true), void 0, function (id, locationUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateLocationApiV1LocationsIdPut.');
                            }
                            // verify required parameter 'locationUpdate' is not null or undefined
                            if (locationUpdate === null || locationUpdate === undefined) {
                                throw new base_1.RequiredError('locationUpdate', 'Required parameter locationUpdate was null or undefined when calling updateLocationApiV1LocationsIdPut.');
                            }
                            localVarPath = "/api/v1/locations/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof locationUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(locationUpdate !== undefined ? locationUpdate : {}) : (locationUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.LocationsApiAxiosParamCreator = LocationsApiAxiosParamCreator;
/**
 * LocationsApi - functional programming interface
 * @export
 */
var LocationsApiFp = function (configuration) {
    return {
        /**
         * Create new location.
         * @summary Create Location
         * @param {LocationCreate} locationCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationApiV1LocationsPost: function (locationCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LocationsApiAxiosParamCreator)(configuration).createLocationApiV1LocationsPost(locationCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete an location.
         * @summary Delete Location
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationApiV1LocationsIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LocationsApiAxiosParamCreator)(configuration).deleteLocationApiV1LocationsIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get location by ID.
         * @summary Read Location
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLocationApiV1LocationsIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LocationsApiAxiosParamCreator)(configuration).readLocationApiV1LocationsIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve locations.
         * @summary Read Locations
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLocationsApiV1LocationsGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LocationsApiAxiosParamCreator)(configuration).readLocationsApiV1LocationsGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve locations.
         * @summary Read Top Locations
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTopLocationsApiV1LocationsTopGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LocationsApiAxiosParamCreator)(configuration).readTopLocationsApiV1LocationsTopGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update an location.
         * @summary Update Location
         * @param {number} id
         * @param {LocationUpdate} locationUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationApiV1LocationsIdPut: function (id, locationUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LocationsApiAxiosParamCreator)(configuration).updateLocationApiV1LocationsIdPut(id, locationUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.LocationsApiFp = LocationsApiFp;
/**
 * LocationsApi - factory interface
 * @export
 */
var LocationsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new location.
         * @summary Create Location
         * @param {LocationCreate} locationCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationApiV1LocationsPost: function (locationCreate, options) {
            return (0, exports.LocationsApiFp)(configuration).createLocationApiV1LocationsPost(locationCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an location.
         * @summary Delete Location
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationApiV1LocationsIdDelete: function (id, options) {
            return (0, exports.LocationsApiFp)(configuration).deleteLocationApiV1LocationsIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get location by ID.
         * @summary Read Location
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLocationApiV1LocationsIdGet: function (id, options) {
            return (0, exports.LocationsApiFp)(configuration).readLocationApiV1LocationsIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve locations.
         * @summary Read Locations
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLocationsApiV1LocationsGet: function (skip, limit, options) {
            return (0, exports.LocationsApiFp)(configuration).readLocationsApiV1LocationsGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve locations.
         * @summary Read Top Locations
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTopLocationsApiV1LocationsTopGet: function (skip, limit, options) {
            return (0, exports.LocationsApiFp)(configuration).readTopLocationsApiV1LocationsTopGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update an location.
         * @summary Update Location
         * @param {number} id
         * @param {LocationUpdate} locationUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationApiV1LocationsIdPut: function (id, locationUpdate, options) {
            return (0, exports.LocationsApiFp)(configuration).updateLocationApiV1LocationsIdPut(id, locationUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LocationsApiFactory = LocationsApiFactory;
/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
var LocationsApi = /** @class */ (function (_super) {
    __extends(LocationsApi, _super);
    function LocationsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new location.
     * @summary Create Location
     * @param {LocationCreate} locationCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    LocationsApi.prototype.createLocationApiV1LocationsPost = function (locationCreate, options) {
        var _this = this;
        return (0, exports.LocationsApiFp)(this.configuration).createLocationApiV1LocationsPost(locationCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an location.
     * @summary Delete Location
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    LocationsApi.prototype.deleteLocationApiV1LocationsIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.LocationsApiFp)(this.configuration).deleteLocationApiV1LocationsIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get location by ID.
     * @summary Read Location
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    LocationsApi.prototype.readLocationApiV1LocationsIdGet = function (id, options) {
        var _this = this;
        return (0, exports.LocationsApiFp)(this.configuration).readLocationApiV1LocationsIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve locations.
     * @summary Read Locations
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    LocationsApi.prototype.readLocationsApiV1LocationsGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.LocationsApiFp)(this.configuration).readLocationsApiV1LocationsGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve locations.
     * @summary Read Top Locations
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    LocationsApi.prototype.readTopLocationsApiV1LocationsTopGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.LocationsApiFp)(this.configuration).readTopLocationsApiV1LocationsTopGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update an location.
     * @summary Update Location
     * @param {number} id
     * @param {LocationUpdate} locationUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    LocationsApi.prototype.updateLocationApiV1LocationsIdPut = function (id, locationUpdate, options) {
        var _this = this;
        return (0, exports.LocationsApiFp)(this.configuration).updateLocationApiV1LocationsIdPut(id, locationUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LocationsApi;
}(base_1.BaseAPI));
exports.LocationsApi = LocationsApi;
/**
 * LoginApi - axios parameter creator
 * @export
 */
var LoginApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * OAuth2 compatible token login for requested user, get an access token for future requests
         * @summary Admin Login Access Token
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet.');
                            }
                            localVarPath = "/api/v1/login/access-token/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Check Token
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenApiV1LoginCheckTokenPost: function (body_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([body_1], args_1, true), void 0, function (body, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling checkTokenApiV1LoginCheckTokenPost.');
                    }
                    localVarPath = "/api/v1/login/check-token";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Claim account created
         * @summary Claim Account
         * @param {BodyClaimAccountApiV1LoginClaimAccountPost} bodyClaimAccountApiV1LoginClaimAccountPost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAccountApiV1LoginClaimAccountPost: function (bodyClaimAccountApiV1LoginClaimAccountPost_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bodyClaimAccountApiV1LoginClaimAccountPost_1], args_1, true), void 0, function (bodyClaimAccountApiV1LoginClaimAccountPost, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'bodyClaimAccountApiV1LoginClaimAccountPost' is not null or undefined
                    if (bodyClaimAccountApiV1LoginClaimAccountPost === null || bodyClaimAccountApiV1LoginClaimAccountPost === undefined) {
                        throw new base_1.RequiredError('bodyClaimAccountApiV1LoginClaimAccountPost', 'Required parameter bodyClaimAccountApiV1LoginClaimAccountPost was null or undefined when calling claimAccountApiV1LoginClaimAccountPost.');
                    }
                    localVarPath = "/api/v1/login/claim-account";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof bodyClaimAccountApiV1LoginClaimAccountPost !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(bodyClaimAccountApiV1LoginClaimAccountPost !== undefined ? bodyClaimAccountApiV1LoginClaimAccountPost : {}) : (bodyClaimAccountApiV1LoginClaimAccountPost || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Connect SSO
         * @summary Connect Sso
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectSsoApiV1ConnectSsoPost: function (body_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([body_1], args_1, true), void 0, function (body, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling connectSsoApiV1ConnectSsoPost.');
                    }
                    localVarPath = "/api/v1/connect-sso";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {string} username
         * @param {string} password
         * @param {string} [grantType]
         * @param {string} [scope]
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenApiV1LoginAccessTokenPost: function (username_1, password_1, grantType_1, scope_1, clientId_1, clientSecret_1) {
            var args_1 = [];
            for (var _i = 6; _i < arguments.length; _i++) {
                args_1[_i - 6] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([username_1, password_1, grantType_1, scope_1, clientId_1, clientSecret_1], args_1, true), void 0, function (username, password, grantType, scope, clientId, clientSecret, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'username' is not null or undefined
                    if (username === null || username === undefined) {
                        throw new base_1.RequiredError('username', 'Required parameter username was null or undefined when calling loginAccessTokenApiV1LoginAccessTokenPost.');
                    }
                    // verify required parameter 'password' is not null or undefined
                    if (password === null || password === undefined) {
                        throw new base_1.RequiredError('password', 'Required parameter password was null or undefined when calling loginAccessTokenApiV1LoginAccessTokenPost.');
                    }
                    localVarPath = "/api/v1/login/access-token";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (grantType !== undefined) {
                        localVarFormParams.set('grant_type', grantType);
                    }
                    if (username !== undefined) {
                        localVarFormParams.set('username', username);
                    }
                    if (password !== undefined) {
                        localVarFormParams.set('password', password);
                    }
                    if (scope !== undefined) {
                        localVarFormParams.set('scope', scope);
                    }
                    if (clientId !== undefined) {
                        localVarFormParams.set('client_id', clientId);
                    }
                    if (clientSecret !== undefined) {
                        localVarFormParams.set('client_secret', clientSecret);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token Sso
         * @param {string} provider
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenSsoApiV1LoginAccessTokenSsoPost: function (provider_1, token_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([provider_1, token_1], args_1, true), void 0, function (provider, token, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'provider' is not null or undefined
                    if (provider === null || provider === undefined) {
                        throw new base_1.RequiredError('provider', 'Required parameter provider was null or undefined when calling loginAccessTokenSsoApiV1LoginAccessTokenSsoPost.');
                    }
                    // verify required parameter 'token' is not null or undefined
                    if (token === null || token === undefined) {
                        throw new base_1.RequiredError('token', 'Required parameter token was null or undefined when calling loginAccessTokenSsoApiV1LoginAccessTokenSsoPost.');
                    }
                    localVarPath = "/api/v1/login/access-token/sso";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (provider !== undefined) {
                        localVarFormParams.set('provider', provider);
                    }
                    if (token !== undefined) {
                        localVarFormParams.set('token', token);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Password Recovery
         * @summary Recover Password
         * @param {string} email
         * @param {string} [corpoSlug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPasswordApiV1PasswordRecoveryEmailPost: function (email_1, corpoSlug_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([email_1, corpoSlug_1], args_1, true), void 0, function (email, corpoSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    if (email === null || email === undefined) {
                        throw new base_1.RequiredError('email', 'Required parameter email was null or undefined when calling recoverPasswordApiV1PasswordRecoveryEmailPost.');
                    }
                    localVarPath = "/api/v1/password-recovery/{email}"
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (corpoSlug !== undefined) {
                        localVarQueryParameter['corpo_slug'] = corpoSlug;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Reset password
         * @summary Reset Password
         * @param {BodyResetPasswordApiV1ResetPasswordPost} bodyResetPasswordApiV1ResetPasswordPost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordApiV1ResetPasswordPost: function (bodyResetPasswordApiV1ResetPasswordPost_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bodyResetPasswordApiV1ResetPasswordPost_1], args_1, true), void 0, function (bodyResetPasswordApiV1ResetPasswordPost, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'bodyResetPasswordApiV1ResetPasswordPost' is not null or undefined
                    if (bodyResetPasswordApiV1ResetPasswordPost === null || bodyResetPasswordApiV1ResetPasswordPost === undefined) {
                        throw new base_1.RequiredError('bodyResetPasswordApiV1ResetPasswordPost', 'Required parameter bodyResetPasswordApiV1ResetPasswordPost was null or undefined when calling resetPasswordApiV1ResetPasswordPost.');
                    }
                    localVarPath = "/api/v1/reset-password";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof bodyResetPasswordApiV1ResetPasswordPost !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(bodyResetPasswordApiV1ResetPasswordPost !== undefined ? bodyResetPasswordApiV1ResetPasswordPost : {}) : (bodyResetPasswordApiV1ResetPasswordPost || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Test access token
         * @summary Test Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTokenApiV1LoginTestTokenPost: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/login/test-token";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Verify email
         * @summary Verify Email
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailApiV1VerifyEmailPost: function (body_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([body_1], args_1, true), void 0, function (body, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling verifyEmailApiV1VerifyEmailPost.');
                    }
                    localVarPath = "/api/v1/verify-email";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.LoginApiAxiosParamCreator = LoginApiAxiosParamCreator;
/**
 * LoginApi - functional programming interface
 * @export
 */
var LoginApiFp = function (configuration) {
    return {
        /**
         * OAuth2 compatible token login for requested user, get an access token for future requests
         * @summary Admin Login Access Token
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Check Token
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenApiV1LoginCheckTokenPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).checkTokenApiV1LoginCheckTokenPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Claim account created
         * @summary Claim Account
         * @param {BodyClaimAccountApiV1LoginClaimAccountPost} bodyClaimAccountApiV1LoginClaimAccountPost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAccountApiV1LoginClaimAccountPost: function (bodyClaimAccountApiV1LoginClaimAccountPost, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).claimAccountApiV1LoginClaimAccountPost(bodyClaimAccountApiV1LoginClaimAccountPost, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Connect SSO
         * @summary Connect Sso
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectSsoApiV1ConnectSsoPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).connectSsoApiV1ConnectSsoPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {string} username
         * @param {string} password
         * @param {string} [grantType]
         * @param {string} [scope]
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenApiV1LoginAccessTokenPost: function (username, password, grantType, scope, clientId, clientSecret, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).loginAccessTokenApiV1LoginAccessTokenPost(username, password, grantType, scope, clientId, clientSecret, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token Sso
         * @param {string} provider
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenSsoApiV1LoginAccessTokenSsoPost: function (provider, token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).loginAccessTokenSsoApiV1LoginAccessTokenSsoPost(provider, token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Password Recovery
         * @summary Recover Password
         * @param {string} email
         * @param {string} [corpoSlug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPasswordApiV1PasswordRecoveryEmailPost: function (email, corpoSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).recoverPasswordApiV1PasswordRecoveryEmailPost(email, corpoSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Reset password
         * @summary Reset Password
         * @param {BodyResetPasswordApiV1ResetPasswordPost} bodyResetPasswordApiV1ResetPasswordPost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordApiV1ResetPasswordPost: function (bodyResetPasswordApiV1ResetPasswordPost, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).resetPasswordApiV1ResetPasswordPost(bodyResetPasswordApiV1ResetPasswordPost, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Test access token
         * @summary Test Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTokenApiV1LoginTestTokenPost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).testTokenApiV1LoginTestTokenPost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Verify email
         * @summary Verify Email
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailApiV1VerifyEmailPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.LoginApiAxiosParamCreator)(configuration).verifyEmailApiV1VerifyEmailPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.LoginApiFp = LoginApiFp;
/**
 * LoginApi - factory interface
 * @export
 */
var LoginApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * OAuth2 compatible token login for requested user, get an access token for future requests
         * @summary Admin Login Access Token
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet: function (userId, options) {
            return (0, exports.LoginApiFp)(configuration).adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Check Token
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenApiV1LoginCheckTokenPost: function (body, options) {
            return (0, exports.LoginApiFp)(configuration).checkTokenApiV1LoginCheckTokenPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Claim account created
         * @summary Claim Account
         * @param {BodyClaimAccountApiV1LoginClaimAccountPost} bodyClaimAccountApiV1LoginClaimAccountPost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAccountApiV1LoginClaimAccountPost: function (bodyClaimAccountApiV1LoginClaimAccountPost, options) {
            return (0, exports.LoginApiFp)(configuration).claimAccountApiV1LoginClaimAccountPost(bodyClaimAccountApiV1LoginClaimAccountPost, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Connect SSO
         * @summary Connect Sso
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectSsoApiV1ConnectSsoPost: function (body, options) {
            return (0, exports.LoginApiFp)(configuration).connectSsoApiV1ConnectSsoPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {string} username
         * @param {string} password
         * @param {string} [grantType]
         * @param {string} [scope]
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenApiV1LoginAccessTokenPost: function (username, password, grantType, scope, clientId, clientSecret, options) {
            return (0, exports.LoginApiFp)(configuration).loginAccessTokenApiV1LoginAccessTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token Sso
         * @param {string} provider
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenSsoApiV1LoginAccessTokenSsoPost: function (provider, token, options) {
            return (0, exports.LoginApiFp)(configuration).loginAccessTokenSsoApiV1LoginAccessTokenSsoPost(provider, token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Password Recovery
         * @summary Recover Password
         * @param {string} email
         * @param {string} [corpoSlug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPasswordApiV1PasswordRecoveryEmailPost: function (email, corpoSlug, options) {
            return (0, exports.LoginApiFp)(configuration).recoverPasswordApiV1PasswordRecoveryEmailPost(email, corpoSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Reset password
         * @summary Reset Password
         * @param {BodyResetPasswordApiV1ResetPasswordPost} bodyResetPasswordApiV1ResetPasswordPost
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordApiV1ResetPasswordPost: function (bodyResetPasswordApiV1ResetPasswordPost, options) {
            return (0, exports.LoginApiFp)(configuration).resetPasswordApiV1ResetPasswordPost(bodyResetPasswordApiV1ResetPasswordPost, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Test access token
         * @summary Test Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTokenApiV1LoginTestTokenPost: function (options) {
            return (0, exports.LoginApiFp)(configuration).testTokenApiV1LoginTestTokenPost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Verify email
         * @summary Verify Email
         * @param {string} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmailApiV1VerifyEmailPost: function (body, options) {
            return (0, exports.LoginApiFp)(configuration).verifyEmailApiV1VerifyEmailPost(body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.LoginApiFactory = LoginApiFactory;
/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
var LoginApi = /** @class */ (function (_super) {
    __extends(LoginApi, _super);
    function LoginApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * OAuth2 compatible token login for requested user, get an access token for future requests
     * @summary Admin Login Access Token
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).adminLoginAccessTokenApiV1LoginAccessTokenUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Check Token
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.checkTokenApiV1LoginCheckTokenPost = function (body, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).checkTokenApiV1LoginCheckTokenPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Claim account created
     * @summary Claim Account
     * @param {BodyClaimAccountApiV1LoginClaimAccountPost} bodyClaimAccountApiV1LoginClaimAccountPost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.claimAccountApiV1LoginClaimAccountPost = function (bodyClaimAccountApiV1LoginClaimAccountPost, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).claimAccountApiV1LoginClaimAccountPost(bodyClaimAccountApiV1LoginClaimAccountPost, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Connect SSO
     * @summary Connect Sso
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.connectSsoApiV1ConnectSsoPost = function (body, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).connectSsoApiV1ConnectSsoPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * OAuth2 compatible token login, get an access token for future requests
     * @summary Login Access Token
     * @param {string} username
     * @param {string} password
     * @param {string} [grantType]
     * @param {string} [scope]
     * @param {string} [clientId]
     * @param {string} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.loginAccessTokenApiV1LoginAccessTokenPost = function (username, password, grantType, scope, clientId, clientSecret, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).loginAccessTokenApiV1LoginAccessTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * OAuth2 compatible token login, get an access token for future requests
     * @summary Login Access Token Sso
     * @param {string} provider
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.loginAccessTokenSsoApiV1LoginAccessTokenSsoPost = function (provider, token, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).loginAccessTokenSsoApiV1LoginAccessTokenSsoPost(provider, token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Password Recovery
     * @summary Recover Password
     * @param {string} email
     * @param {string} [corpoSlug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.recoverPasswordApiV1PasswordRecoveryEmailPost = function (email, corpoSlug, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).recoverPasswordApiV1PasswordRecoveryEmailPost(email, corpoSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Reset password
     * @summary Reset Password
     * @param {BodyResetPasswordApiV1ResetPasswordPost} bodyResetPasswordApiV1ResetPasswordPost
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.resetPasswordApiV1ResetPasswordPost = function (bodyResetPasswordApiV1ResetPasswordPost, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).resetPasswordApiV1ResetPasswordPost(bodyResetPasswordApiV1ResetPasswordPost, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Test access token
     * @summary Test Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.testTokenApiV1LoginTestTokenPost = function (options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).testTokenApiV1LoginTestTokenPost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Verify email
     * @summary Verify Email
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    LoginApi.prototype.verifyEmailApiV1VerifyEmailPost = function (body, options) {
        var _this = this;
        return (0, exports.LoginApiFp)(this.configuration).verifyEmailApiV1VerifyEmailPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return LoginApi;
}(base_1.BaseAPI));
exports.LoginApi = LoginApi;
/**
 * PackTemplatesApi - axios parameter creator
 * @export
 */
var PackTemplatesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Update pack_template.
         * @summary Create Pack Template
         * @param {PackTemplateCreate} packTemplateCreate
         * @param {number} [ownerId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPackTemplateApiV1PackTemplatesPost: function (packTemplateCreate_1, ownerId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packTemplateCreate_1, ownerId_1], args_1, true), void 0, function (packTemplateCreate, ownerId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packTemplateCreate' is not null or undefined
                            if (packTemplateCreate === null || packTemplateCreate === undefined) {
                                throw new base_1.RequiredError('packTemplateCreate', 'Required parameter packTemplateCreate was null or undefined when calling createPackTemplateApiV1PackTemplatesPost.');
                            }
                            localVarPath = "/api/v1/pack-templates";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (ownerId !== undefined) {
                                localVarQueryParameter['owner_id'] = ownerId;
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof packTemplateCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(packTemplateCreate !== undefined ? packTemplateCreate : {}) : (packTemplateCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Max Saving
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    if (userId === null || userId === undefined) {
                        throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet.');
                    }
                    localVarPath = "/api/v1/pack-templates/max-saving/{user_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} packTemplateId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1PackTemplatesPackTemplateIdPriceGet: function (packTemplateId_1, promoId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packTemplateId_1, promoId_1], args_1, true), void 0, function (packTemplateId, promoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packTemplateId' is not null or undefined
                            if (packTemplateId === null || packTemplateId === undefined) {
                                throw new base_1.RequiredError('packTemplateId', 'Required parameter packTemplateId was null or undefined when calling getPriceApiV1PackTemplatesPackTemplateIdPriceGet.');
                            }
                            localVarPath = "/api/v1/pack-templates/{pack_template_id}/price"
                                .replace("{".concat("pack_template_id", "}"), encodeURIComponent(String(packTemplateId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (promoId !== undefined) {
                                localVarQueryParameter['promo_id'] = promoId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read pack_template.
         * @summary Read Pack Template
         * @param {number} packTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplateApiV1PackTemplatesPackTemplateIdGet: function (packTemplateId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packTemplateId_1], args_1, true), void 0, function (packTemplateId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'packTemplateId' is not null or undefined
                    if (packTemplateId === null || packTemplateId === undefined) {
                        throw new base_1.RequiredError('packTemplateId', 'Required parameter packTemplateId was null or undefined when calling readPackTemplateApiV1PackTemplatesPackTemplateIdGet.');
                    }
                    localVarPath = "/api/v1/pack-templates/{pack_template_id}"
                        .replace("{".concat("pack_template_id", "}"), encodeURIComponent(String(packTemplateId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Read Pack Templates
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplatesApiV1PackTemplatesForListingListingIdGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'listingId' is not null or undefined
                    if (listingId === null || listingId === undefined) {
                        throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readPackTemplatesApiV1PackTemplatesForListingListingIdGet.');
                    }
                    localVarPath = "/api/v1/pack-templates/for-listing/{listing_id}"
                        .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * * Read pack_templates
         * @summary Read Pack Templates
         * @param {number} [ownerId]
         * @param {string} [packType]
         * @param {boolean} [isActive]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplatesApiV1PackTemplatesGet: function (ownerId_1, packType_1, isActive_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ownerId_1, packType_1, isActive_1, skip_1, limit_1], args_1, true), void 0, function (ownerId, packType, isActive, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/pack-templates";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (ownerId !== undefined) {
                                localVarQueryParameter['owner_id'] = ownerId;
                            }
                            if (packType !== undefined) {
                                localVarQueryParameter['pack_type'] = packType;
                            }
                            if (isActive !== undefined) {
                                localVarQueryParameter['is_active'] = isActive;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Pack Template
         * @param {number} packTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePackTemplateApiV1PackTemplatesPackTemplateIdDelete: function (packTemplateId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packTemplateId_1], args_1, true), void 0, function (packTemplateId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packTemplateId' is not null or undefined
                            if (packTemplateId === null || packTemplateId === undefined) {
                                throw new base_1.RequiredError('packTemplateId', 'Required parameter packTemplateId was null or undefined when calling removePackTemplateApiV1PackTemplatesPackTemplateIdDelete.');
                            }
                            localVarPath = "/api/v1/pack-templates/{pack_template_id}"
                                .replace("{".concat("pack_template_id", "}"), encodeURIComponent(String(packTemplateId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Pack Template
         * @param {number} packTemplateId
         * @param {PackTemplateUpdate} packTemplateUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePackTemplateApiV1PackTemplatesPackTemplateIdPut: function (packTemplateId_1, packTemplateUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packTemplateId_1, packTemplateUpdate_1], args_1, true), void 0, function (packTemplateId, packTemplateUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packTemplateId' is not null or undefined
                            if (packTemplateId === null || packTemplateId === undefined) {
                                throw new base_1.RequiredError('packTemplateId', 'Required parameter packTemplateId was null or undefined when calling updatePackTemplateApiV1PackTemplatesPackTemplateIdPut.');
                            }
                            // verify required parameter 'packTemplateUpdate' is not null or undefined
                            if (packTemplateUpdate === null || packTemplateUpdate === undefined) {
                                throw new base_1.RequiredError('packTemplateUpdate', 'Required parameter packTemplateUpdate was null or undefined when calling updatePackTemplateApiV1PackTemplatesPackTemplateIdPut.');
                            }
                            localVarPath = "/api/v1/pack-templates/{pack_template_id}"
                                .replace("{".concat("pack_template_id", "}"), encodeURIComponent(String(packTemplateId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof packTemplateUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(packTemplateUpdate !== undefined ? packTemplateUpdate : {}) : (packTemplateUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PackTemplatesApiAxiosParamCreator = PackTemplatesApiAxiosParamCreator;
/**
 * PackTemplatesApi - functional programming interface
 * @export
 */
var PackTemplatesApiFp = function (configuration) {
    return {
        /**
         * Update pack_template.
         * @summary Create Pack Template
         * @param {PackTemplateCreate} packTemplateCreate
         * @param {number} [ownerId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPackTemplateApiV1PackTemplatesPost: function (packTemplateCreate, ownerId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).createPackTemplateApiV1PackTemplatesPost(packTemplateCreate, ownerId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Max Saving
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} packTemplateId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1PackTemplatesPackTemplateIdPriceGet: function (packTemplateId, promoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).getPriceApiV1PackTemplatesPackTemplateIdPriceGet(packTemplateId, promoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read pack_template.
         * @summary Read Pack Template
         * @param {number} packTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplateApiV1PackTemplatesPackTemplateIdGet: function (packTemplateId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).readPackTemplateApiV1PackTemplatesPackTemplateIdGet(packTemplateId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Pack Templates
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplatesApiV1PackTemplatesForListingListingIdGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).readPackTemplatesApiV1PackTemplatesForListingListingIdGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * * Read pack_templates
         * @summary Read Pack Templates
         * @param {number} [ownerId]
         * @param {string} [packType]
         * @param {boolean} [isActive]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplatesApiV1PackTemplatesGet: function (ownerId, packType, isActive, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).readPackTemplatesApiV1PackTemplatesGet(ownerId, packType, isActive, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Pack Template
         * @param {number} packTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePackTemplateApiV1PackTemplatesPackTemplateIdDelete: function (packTemplateId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).removePackTemplateApiV1PackTemplatesPackTemplateIdDelete(packTemplateId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Pack Template
         * @param {number} packTemplateId
         * @param {PackTemplateUpdate} packTemplateUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePackTemplateApiV1PackTemplatesPackTemplateIdPut: function (packTemplateId, packTemplateUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PackTemplatesApiAxiosParamCreator)(configuration).updatePackTemplateApiV1PackTemplatesPackTemplateIdPut(packTemplateId, packTemplateUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PackTemplatesApiFp = PackTemplatesApiFp;
/**
 * PackTemplatesApi - factory interface
 * @export
 */
var PackTemplatesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Update pack_template.
         * @summary Create Pack Template
         * @param {PackTemplateCreate} packTemplateCreate
         * @param {number} [ownerId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPackTemplateApiV1PackTemplatesPost: function (packTemplateCreate, ownerId, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).createPackTemplateApiV1PackTemplatesPost(packTemplateCreate, ownerId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Max Saving
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet: function (userId, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Price
         * @param {number} packTemplateId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1PackTemplatesPackTemplateIdPriceGet: function (packTemplateId, promoId, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).getPriceApiV1PackTemplatesPackTemplateIdPriceGet(packTemplateId, promoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read pack_template.
         * @summary Read Pack Template
         * @param {number} packTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplateApiV1PackTemplatesPackTemplateIdGet: function (packTemplateId, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).readPackTemplateApiV1PackTemplatesPackTemplateIdGet(packTemplateId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Pack Templates
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplatesApiV1PackTemplatesForListingListingIdGet: function (listingId, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).readPackTemplatesApiV1PackTemplatesForListingListingIdGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * * Read pack_templates
         * @summary Read Pack Templates
         * @param {number} [ownerId]
         * @param {string} [packType]
         * @param {boolean} [isActive]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPackTemplatesApiV1PackTemplatesGet: function (ownerId, packType, isActive, skip, limit, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).readPackTemplatesApiV1PackTemplatesGet(ownerId, packType, isActive, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Pack Template
         * @param {number} packTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePackTemplateApiV1PackTemplatesPackTemplateIdDelete: function (packTemplateId, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).removePackTemplateApiV1PackTemplatesPackTemplateIdDelete(packTemplateId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Pack Template
         * @param {number} packTemplateId
         * @param {PackTemplateUpdate} packTemplateUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePackTemplateApiV1PackTemplatesPackTemplateIdPut: function (packTemplateId, packTemplateUpdate, options) {
            return (0, exports.PackTemplatesApiFp)(configuration).updatePackTemplateApiV1PackTemplatesPackTemplateIdPut(packTemplateId, packTemplateUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PackTemplatesApiFactory = PackTemplatesApiFactory;
/**
 * PackTemplatesApi - object-oriented interface
 * @export
 * @class PackTemplatesApi
 * @extends {BaseAPI}
 */
var PackTemplatesApi = /** @class */ (function (_super) {
    __extends(PackTemplatesApi, _super);
    function PackTemplatesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Update pack_template.
     * @summary Create Pack Template
     * @param {PackTemplateCreate} packTemplateCreate
     * @param {number} [ownerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.createPackTemplateApiV1PackTemplatesPost = function (packTemplateCreate, ownerId, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).createPackTemplateApiV1PackTemplatesPost(packTemplateCreate, ownerId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Max Saving
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).getMaxSavingApiV1PackTemplatesMaxSavingUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Price
     * @param {number} packTemplateId
     * @param {number} [promoId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.getPriceApiV1PackTemplatesPackTemplateIdPriceGet = function (packTemplateId, promoId, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).getPriceApiV1PackTemplatesPackTemplateIdPriceGet(packTemplateId, promoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read pack_template.
     * @summary Read Pack Template
     * @param {number} packTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.readPackTemplateApiV1PackTemplatesPackTemplateIdGet = function (packTemplateId, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).readPackTemplateApiV1PackTemplatesPackTemplateIdGet(packTemplateId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Pack Templates
     * @param {number} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.readPackTemplatesApiV1PackTemplatesForListingListingIdGet = function (listingId, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).readPackTemplatesApiV1PackTemplatesForListingListingIdGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * * Read pack_templates
     * @summary Read Pack Templates
     * @param {number} [ownerId]
     * @param {string} [packType]
     * @param {boolean} [isActive]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.readPackTemplatesApiV1PackTemplatesGet = function (ownerId, packType, isActive, skip, limit, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).readPackTemplatesApiV1PackTemplatesGet(ownerId, packType, isActive, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Pack Template
     * @param {number} packTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.removePackTemplateApiV1PackTemplatesPackTemplateIdDelete = function (packTemplateId, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).removePackTemplateApiV1PackTemplatesPackTemplateIdDelete(packTemplateId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Pack Template
     * @param {number} packTemplateId
     * @param {PackTemplateUpdate} packTemplateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackTemplatesApi
     */
    PackTemplatesApi.prototype.updatePackTemplateApiV1PackTemplatesPackTemplateIdPut = function (packTemplateId, packTemplateUpdate, options) {
        var _this = this;
        return (0, exports.PackTemplatesApiFp)(this.configuration).updatePackTemplateApiV1PackTemplatesPackTemplateIdPut(packTemplateId, packTemplateUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PackTemplatesApi;
}(base_1.BaseAPI));
exports.PackTemplatesApi = PackTemplatesApi;
/**
 * PacksApi - axios parameter creator
 * @export
 */
var PacksApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Assign Payin To Pack
         * @param {number} packId
         * @param {string} payinId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPayinToPackApiV1PacksAssignPackIdPost: function (packId_1, payinId_1, promoId_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packId_1, payinId_1, promoId_1], args_1, true), void 0, function (packId, payinId, promoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packId' is not null or undefined
                            if (packId === null || packId === undefined) {
                                throw new base_1.RequiredError('packId', 'Required parameter packId was null or undefined when calling assignPayinToPackApiV1PacksAssignPackIdPost.');
                            }
                            // verify required parameter 'payinId' is not null or undefined
                            if (payinId === null || payinId === undefined) {
                                throw new base_1.RequiredError('payinId', 'Required parameter payinId was null or undefined when calling assignPayinToPackApiV1PacksAssignPackIdPost.');
                            }
                            localVarPath = "/api/v1/packs/assign/{pack_id}"
                                .replace("{".concat("pack_id", "}"), encodeURIComponent(String(packId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (payinId !== undefined) {
                                localVarQueryParameter['payin_id'] = payinId;
                            }
                            if (promoId !== undefined) {
                                localVarQueryParameter['promo_id'] = promoId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Grant Credit
         * @param {CreditGrantCreate} creditGrantCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantCreditApiV1PacksGrantPost: function (creditGrantCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([creditGrantCreate_1], args_1, true), void 0, function (creditGrantCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'creditGrantCreate' is not null or undefined
                            if (creditGrantCreate === null || creditGrantCreate === undefined) {
                                throw new base_1.RequiredError('creditGrantCreate', 'Required parameter creditGrantCreate was null or undefined when calling grantCreditApiV1PacksGrantPost.');
                            }
                            localVarPath = "/api/v1/packs/grant";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof creditGrantCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(creditGrantCreate !== undefined ? creditGrantCreate : {}) : (creditGrantCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read remaining tickets
         * @summary Read Remaining Tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsApiV1PacksRemainingGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/packs/remaining";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read remaining tickets for listing
         * @summary Read Remaining Tickets For Listing
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet: function (listingId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingId_1], args_1, true), void 0, function (listingId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet.');
                            }
                            localVarPath = "/api/v1/packs/remaining-from-listing/{listing_id}"
                                .replace("{".concat("listing_id", "}"), encodeURIComponent(String(listingId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read remaining tickets with guru
         * @summary Read Remaining Tickets With Guru
         * @param {number} guruId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet: function (guruId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([guruId_1], args_1, true), void 0, function (guruId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'guruId' is not null or undefined
                            if (guruId === null || guruId === undefined) {
                                throw new base_1.RequiredError('guruId', 'Required parameter guruId was null or undefined when calling readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet.');
                            }
                            localVarPath = "/api/v1/packs/remaining/{guru_id}"
                                .replace("{".concat("guru_id", "}"), encodeURIComponent(String(guruId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Credit
         * @param {CreditRemove} creditRemove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreditApiV1PacksRemovePost: function (creditRemove_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([creditRemove_1], args_1, true), void 0, function (creditRemove, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'creditRemove' is not null or undefined
                            if (creditRemove === null || creditRemove === undefined) {
                                throw new base_1.RequiredError('creditRemove', 'Required parameter creditRemove was null or undefined when calling removeCreditApiV1PacksRemovePost.');
                            }
                            localVarPath = "/api/v1/packs/remove";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof creditRemove !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(creditRemove !== undefined ? creditRemove : {}) : (creditRemove || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PacksApiAxiosParamCreator = PacksApiAxiosParamCreator;
/**
 * PacksApi - functional programming interface
 * @export
 */
var PacksApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Assign Payin To Pack
         * @param {number} packId
         * @param {string} payinId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPayinToPackApiV1PacksAssignPackIdPost: function (packId, payinId, promoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksApiAxiosParamCreator)(configuration).assignPayinToPackApiV1PacksAssignPackIdPost(packId, payinId, promoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Grant Credit
         * @param {CreditGrantCreate} creditGrantCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantCreditApiV1PacksGrantPost: function (creditGrantCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksApiAxiosParamCreator)(configuration).grantCreditApiV1PacksGrantPost(creditGrantCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read remaining tickets
         * @summary Read Remaining Tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsApiV1PacksRemainingGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksApiAxiosParamCreator)(configuration).readRemainingTicketsApiV1PacksRemainingGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read remaining tickets for listing
         * @summary Read Remaining Tickets For Listing
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet: function (listingId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksApiAxiosParamCreator)(configuration).readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet(listingId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read remaining tickets with guru
         * @summary Read Remaining Tickets With Guru
         * @param {number} guruId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet: function (guruId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksApiAxiosParamCreator)(configuration).readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet(guruId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Credit
         * @param {CreditRemove} creditRemove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreditApiV1PacksRemovePost: function (creditRemove, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksApiAxiosParamCreator)(configuration).removeCreditApiV1PacksRemovePost(creditRemove, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PacksApiFp = PacksApiFp;
/**
 * PacksApi - factory interface
 * @export
 */
var PacksApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Assign Payin To Pack
         * @param {number} packId
         * @param {string} payinId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignPayinToPackApiV1PacksAssignPackIdPost: function (packId, payinId, promoId, options) {
            return (0, exports.PacksApiFp)(configuration).assignPayinToPackApiV1PacksAssignPackIdPost(packId, payinId, promoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Grant Credit
         * @param {CreditGrantCreate} creditGrantCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantCreditApiV1PacksGrantPost: function (creditGrantCreate, options) {
            return (0, exports.PacksApiFp)(configuration).grantCreditApiV1PacksGrantPost(creditGrantCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read remaining tickets
         * @summary Read Remaining Tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsApiV1PacksRemainingGet: function (options) {
            return (0, exports.PacksApiFp)(configuration).readRemainingTicketsApiV1PacksRemainingGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read remaining tickets for listing
         * @summary Read Remaining Tickets For Listing
         * @param {number} listingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet: function (listingId, options) {
            return (0, exports.PacksApiFp)(configuration).readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet(listingId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read remaining tickets with guru
         * @summary Read Remaining Tickets With Guru
         * @param {number} guruId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet: function (guruId, options) {
            return (0, exports.PacksApiFp)(configuration).readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet(guruId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Credit
         * @param {CreditRemove} creditRemove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreditApiV1PacksRemovePost: function (creditRemove, options) {
            return (0, exports.PacksApiFp)(configuration).removeCreditApiV1PacksRemovePost(creditRemove, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PacksApiFactory = PacksApiFactory;
/**
 * PacksApi - object-oriented interface
 * @export
 * @class PacksApi
 * @extends {BaseAPI}
 */
var PacksApi = /** @class */ (function (_super) {
    __extends(PacksApi, _super);
    function PacksApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Assign Payin To Pack
     * @param {number} packId
     * @param {string} payinId
     * @param {number} [promoId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksApi
     */
    PacksApi.prototype.assignPayinToPackApiV1PacksAssignPackIdPost = function (packId, payinId, promoId, options) {
        var _this = this;
        return (0, exports.PacksApiFp)(this.configuration).assignPayinToPackApiV1PacksAssignPackIdPost(packId, payinId, promoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Grant Credit
     * @param {CreditGrantCreate} creditGrantCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksApi
     */
    PacksApi.prototype.grantCreditApiV1PacksGrantPost = function (creditGrantCreate, options) {
        var _this = this;
        return (0, exports.PacksApiFp)(this.configuration).grantCreditApiV1PacksGrantPost(creditGrantCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read remaining tickets
     * @summary Read Remaining Tickets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksApi
     */
    PacksApi.prototype.readRemainingTicketsApiV1PacksRemainingGet = function (options) {
        var _this = this;
        return (0, exports.PacksApiFp)(this.configuration).readRemainingTicketsApiV1PacksRemainingGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read remaining tickets for listing
     * @summary Read Remaining Tickets For Listing
     * @param {number} listingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksApi
     */
    PacksApi.prototype.readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet = function (listingId, options) {
        var _this = this;
        return (0, exports.PacksApiFp)(this.configuration).readRemainingTicketsForListingApiV1PacksRemainingFromListingListingIdGet(listingId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read remaining tickets with guru
     * @summary Read Remaining Tickets With Guru
     * @param {number} guruId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksApi
     */
    PacksApi.prototype.readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet = function (guruId, options) {
        var _this = this;
        return (0, exports.PacksApiFp)(this.configuration).readRemainingTicketsWithGuruApiV1PacksRemainingGuruIdGet(guruId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Credit
     * @param {CreditRemove} creditRemove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksApi
     */
    PacksApi.prototype.removeCreditApiV1PacksRemovePost = function (creditRemove, options) {
        var _this = this;
        return (0, exports.PacksApiFp)(this.configuration).removeCreditApiV1PacksRemovePost(creditRemove, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PacksApi;
}(base_1.BaseAPI));
exports.PacksApi = PacksApi;
/**
 * PacksAdminApi - axios parameter creator
 * @export
 */
var PacksAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Grant Credit Admin
         * @param {CreditGrantCreate} creditGrantCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantCreditAdminApiV1AdminPacksGrantPost: function (creditGrantCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([creditGrantCreate_1], args_1, true), void 0, function (creditGrantCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'creditGrantCreate' is not null or undefined
                            if (creditGrantCreate === null || creditGrantCreate === undefined) {
                                throw new base_1.RequiredError('creditGrantCreate', 'Required parameter creditGrantCreate was null or undefined when calling grantCreditAdminApiV1AdminPacksGrantPost.');
                            }
                            localVarPath = "/api/v1/admin/packs/grant";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof creditGrantCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(creditGrantCreate !== undefined ? creditGrantCreate : {}) : (creditGrantCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Remaining Tickets Admin
         * @param {number} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsAdminApiV1AdminPacksRemainingPost: function (user_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([user_1], args_1, true), void 0, function (user, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'user' is not null or undefined
                            if (user === null || user === undefined) {
                                throw new base_1.RequiredError('user', 'Required parameter user was null or undefined when calling readRemainingTicketsAdminApiV1AdminPacksRemainingPost.');
                            }
                            localVarPath = "/api/v1/admin/packs/remaining";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (user !== undefined) {
                                localVarQueryParameter['user'] = user;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Credit Admin
         * @param {CreditRemove} creditRemove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreditAdminApiV1AdminPacksRemovePost: function (creditRemove_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([creditRemove_1], args_1, true), void 0, function (creditRemove, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'creditRemove' is not null or undefined
                            if (creditRemove === null || creditRemove === undefined) {
                                throw new base_1.RequiredError('creditRemove', 'Required parameter creditRemove was null or undefined when calling removeCreditAdminApiV1AdminPacksRemovePost.');
                            }
                            localVarPath = "/api/v1/admin/packs/remove";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof creditRemove !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(creditRemove !== undefined ? creditRemove : {}) : (creditRemove || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PacksAdminApiAxiosParamCreator = PacksAdminApiAxiosParamCreator;
/**
 * PacksAdminApi - functional programming interface
 * @export
 */
var PacksAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Grant Credit Admin
         * @param {CreditGrantCreate} creditGrantCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantCreditAdminApiV1AdminPacksGrantPost: function (creditGrantCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksAdminApiAxiosParamCreator)(configuration).grantCreditAdminApiV1AdminPacksGrantPost(creditGrantCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Remaining Tickets Admin
         * @param {number} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsAdminApiV1AdminPacksRemainingPost: function (user, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksAdminApiAxiosParamCreator)(configuration).readRemainingTicketsAdminApiV1AdminPacksRemainingPost(user, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Credit Admin
         * @param {CreditRemove} creditRemove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreditAdminApiV1AdminPacksRemovePost: function (creditRemove, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PacksAdminApiAxiosParamCreator)(configuration).removeCreditAdminApiV1AdminPacksRemovePost(creditRemove, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PacksAdminApiFp = PacksAdminApiFp;
/**
 * PacksAdminApi - factory interface
 * @export
 */
var PacksAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Grant Credit Admin
         * @param {CreditGrantCreate} creditGrantCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantCreditAdminApiV1AdminPacksGrantPost: function (creditGrantCreate, options) {
            return (0, exports.PacksAdminApiFp)(configuration).grantCreditAdminApiV1AdminPacksGrantPost(creditGrantCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Remaining Tickets Admin
         * @param {number} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRemainingTicketsAdminApiV1AdminPacksRemainingPost: function (user, options) {
            return (0, exports.PacksAdminApiFp)(configuration).readRemainingTicketsAdminApiV1AdminPacksRemainingPost(user, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Credit Admin
         * @param {CreditRemove} creditRemove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreditAdminApiV1AdminPacksRemovePost: function (creditRemove, options) {
            return (0, exports.PacksAdminApiFp)(configuration).removeCreditAdminApiV1AdminPacksRemovePost(creditRemove, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PacksAdminApiFactory = PacksAdminApiFactory;
/**
 * PacksAdminApi - object-oriented interface
 * @export
 * @class PacksAdminApi
 * @extends {BaseAPI}
 */
var PacksAdminApi = /** @class */ (function (_super) {
    __extends(PacksAdminApi, _super);
    function PacksAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Grant Credit Admin
     * @param {CreditGrantCreate} creditGrantCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksAdminApi
     */
    PacksAdminApi.prototype.grantCreditAdminApiV1AdminPacksGrantPost = function (creditGrantCreate, options) {
        var _this = this;
        return (0, exports.PacksAdminApiFp)(this.configuration).grantCreditAdminApiV1AdminPacksGrantPost(creditGrantCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Remaining Tickets Admin
     * @param {number} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksAdminApi
     */
    PacksAdminApi.prototype.readRemainingTicketsAdminApiV1AdminPacksRemainingPost = function (user, options) {
        var _this = this;
        return (0, exports.PacksAdminApiFp)(this.configuration).readRemainingTicketsAdminApiV1AdminPacksRemainingPost(user, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Credit Admin
     * @param {CreditRemove} creditRemove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacksAdminApi
     */
    PacksAdminApi.prototype.removeCreditAdminApiV1AdminPacksRemovePost = function (creditRemove, options) {
        var _this = this;
        return (0, exports.PacksAdminApiFp)(this.configuration).removeCreditAdminApiV1AdminPacksRemovePost(creditRemove, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PacksAdminApi;
}(base_1.BaseAPI));
exports.PacksAdminApi = PacksAdminApi;
/**
 * PaymentsApi - axios parameter creator
 * @export
 */
var PaymentsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Buy Pack
         * @param {number} packTemplateId
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BrowserInfo} browserInfo
         * @param {number} [promoId]
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPackApiV1PaymentsBuyPackPost: function (packTemplateId_1, cardId_1, saveCard_1, returnUrl_1, browserInfo_1, promoId_1, accept_1) {
            var args_1 = [];
            for (var _i = 7; _i < arguments.length; _i++) {
                args_1[_i - 7] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([packTemplateId_1, cardId_1, saveCard_1, returnUrl_1, browserInfo_1, promoId_1, accept_1], args_1, true), void 0, function (packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'packTemplateId' is not null or undefined
                            if (packTemplateId === null || packTemplateId === undefined) {
                                throw new base_1.RequiredError('packTemplateId', 'Required parameter packTemplateId was null or undefined when calling buyPackApiV1PaymentsBuyPackPost.');
                            }
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling buyPackApiV1PaymentsBuyPackPost.');
                            }
                            // verify required parameter 'saveCard' is not null or undefined
                            if (saveCard === null || saveCard === undefined) {
                                throw new base_1.RequiredError('saveCard', 'Required parameter saveCard was null or undefined when calling buyPackApiV1PaymentsBuyPackPost.');
                            }
                            // verify required parameter 'returnUrl' is not null or undefined
                            if (returnUrl === null || returnUrl === undefined) {
                                throw new base_1.RequiredError('returnUrl', 'Required parameter returnUrl was null or undefined when calling buyPackApiV1PaymentsBuyPackPost.');
                            }
                            // verify required parameter 'browserInfo' is not null or undefined
                            if (browserInfo === null || browserInfo === undefined) {
                                throw new base_1.RequiredError('browserInfo', 'Required parameter browserInfo was null or undefined when calling buyPackApiV1PaymentsBuyPackPost.');
                            }
                            localVarPath = "/api/v1/payments/buy-pack";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (packTemplateId !== undefined) {
                                localVarQueryParameter['pack_template_id'] = packTemplateId;
                            }
                            if (cardId !== undefined) {
                                localVarQueryParameter['card_id'] = cardId;
                            }
                            if (saveCard !== undefined) {
                                localVarQueryParameter['save_card'] = saveCard;
                            }
                            if (returnUrl !== undefined) {
                                localVarQueryParameter['return_url'] = returnUrl;
                            }
                            if (promoId !== undefined) {
                                localVarQueryParameter['promo_id'] = promoId;
                            }
                            if (accept !== undefined && accept !== null) {
                                localVarHeaderParameter['accept'] = String(accept);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof browserInfo !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(browserInfo !== undefined ? browserInfo : {}) : (browserInfo || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Bank Account
         * @param {BankAccountCreate} bankAccountCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccountApiV1PaymentsBankAccountPost: function (bankAccountCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bankAccountCreate_1], args_1, true), void 0, function (bankAccountCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'bankAccountCreate' is not null or undefined
                            if (bankAccountCreate === null || bankAccountCreate === undefined) {
                                throw new base_1.RequiredError('bankAccountCreate', 'Required parameter bankAccountCreate was null or undefined when calling createBankAccountApiV1PaymentsBankAccountPost.');
                            }
                            localVarPath = "/api/v1/payments/bank-account";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof bankAccountCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(bankAccountCreate !== undefined ? bankAccountCreate : {}) : (bankAccountCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Pre Auth
         * @param {string} cardId
         * @param {number} listingId
         * @param {string} returnUrl
         * @param {boolean} saveCard
         * @param {BrowserInfo} browserInfo
         * @param {number} [promoId]
         * @param {number} [numParticipants]
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreAuthApiV1PaymentsCreatePreauthPost: function (cardId_1, listingId_1, returnUrl_1, saveCard_1, browserInfo_1, promoId_1, numParticipants_1, accept_1) {
            var args_1 = [];
            for (var _i = 8; _i < arguments.length; _i++) {
                args_1[_i - 8] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cardId_1, listingId_1, returnUrl_1, saveCard_1, browserInfo_1, promoId_1, numParticipants_1, accept_1], args_1, true), void 0, function (cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling createPreAuthApiV1PaymentsCreatePreauthPost.');
                            }
                            // verify required parameter 'listingId' is not null or undefined
                            if (listingId === null || listingId === undefined) {
                                throw new base_1.RequiredError('listingId', 'Required parameter listingId was null or undefined when calling createPreAuthApiV1PaymentsCreatePreauthPost.');
                            }
                            // verify required parameter 'returnUrl' is not null or undefined
                            if (returnUrl === null || returnUrl === undefined) {
                                throw new base_1.RequiredError('returnUrl', 'Required parameter returnUrl was null or undefined when calling createPreAuthApiV1PaymentsCreatePreauthPost.');
                            }
                            // verify required parameter 'saveCard' is not null or undefined
                            if (saveCard === null || saveCard === undefined) {
                                throw new base_1.RequiredError('saveCard', 'Required parameter saveCard was null or undefined when calling createPreAuthApiV1PaymentsCreatePreauthPost.');
                            }
                            // verify required parameter 'browserInfo' is not null or undefined
                            if (browserInfo === null || browserInfo === undefined) {
                                throw new base_1.RequiredError('browserInfo', 'Required parameter browserInfo was null or undefined when calling createPreAuthApiV1PaymentsCreatePreauthPost.');
                            }
                            localVarPath = "/api/v1/payments/create-preauth";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (cardId !== undefined) {
                                localVarQueryParameter['card_id'] = cardId;
                            }
                            if (listingId !== undefined) {
                                localVarQueryParameter['listing_id'] = listingId;
                            }
                            if (returnUrl !== undefined) {
                                localVarQueryParameter['return_url'] = returnUrl;
                            }
                            if (saveCard !== undefined) {
                                localVarQueryParameter['save_card'] = saveCard;
                            }
                            if (promoId !== undefined) {
                                localVarQueryParameter['promo_id'] = promoId;
                            }
                            if (numParticipants !== undefined) {
                                localVarQueryParameter['num_participants'] = numParticipants;
                            }
                            if (accept !== undefined && accept !== null) {
                                localVarHeaderParameter['accept'] = String(accept);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof browserInfo !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(browserInfo !== undefined ? browserInfo : {}) : (browserInfo || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Pre Auth Listing Request
         * @param {string} cardId
         * @param {string} returnUrl
         * @param {boolean} saveCard
         * @param {BodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost} bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost: function (cardId_1, returnUrl_1, saveCard_1, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost_1, accept_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cardId_1, returnUrl_1, saveCard_1, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost_1, accept_1], args_1, true), void 0, function (cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost.');
                            }
                            // verify required parameter 'returnUrl' is not null or undefined
                            if (returnUrl === null || returnUrl === undefined) {
                                throw new base_1.RequiredError('returnUrl', 'Required parameter returnUrl was null or undefined when calling createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost.');
                            }
                            // verify required parameter 'saveCard' is not null or undefined
                            if (saveCard === null || saveCard === undefined) {
                                throw new base_1.RequiredError('saveCard', 'Required parameter saveCard was null or undefined when calling createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost.');
                            }
                            // verify required parameter 'bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost' is not null or undefined
                            if (bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost === null || bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost === undefined) {
                                throw new base_1.RequiredError('bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost', 'Required parameter bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost was null or undefined when calling createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost.');
                            }
                            localVarPath = "/api/v1/payments/create-preauth-listing-request";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (cardId !== undefined) {
                                localVarQueryParameter['card_id'] = cardId;
                            }
                            if (returnUrl !== undefined) {
                                localVarQueryParameter['return_url'] = returnUrl;
                            }
                            if (saveCard !== undefined) {
                                localVarQueryParameter['save_card'] = saveCard;
                            }
                            if (accept !== undefined && accept !== null) {
                                localVarHeaderParameter['accept'] = String(accept);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost !== undefined ? bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost : {}) : (bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete credit card
         * @summary Delete Credit Cards
         * @param {number} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete: function (cardId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cardId_1], args_1, true), void 0, function (cardId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete.');
                            }
                            localVarPath = "/api/v1/payments/credit-cards/{card_id}"
                                .replace("{".concat("card_id", "}"), encodeURIComponent(String(cardId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Bank Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountApiV1PaymentsBankAccountGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/payments/bank-account";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Card Registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRegistrationApiV1PaymentsCardRegistrationGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/payments/card-registration";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Credit Cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardsApiV1PaymentsCreditCardsGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/payments/credit-cards";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Payments
         * @param {number} [day]
         * @param {string} [payout]
         * @param {number} [limit]
         * @param {number} [skip]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet: function (day_1, payout_1, limit_1, skip_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([day_1, payout_1, limit_1, skip_1], args_1, true), void 0, function (day, payout, limit, skip, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/paymentsdb: Session = Depends(deps.get_db),";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (day !== undefined) {
                                localVarQueryParameter['day'] = day;
                            }
                            if (payout !== undefined) {
                                localVarQueryParameter['payout'] = payout;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Payment
         * @param {string} listingSlug
         * @param {string} [state]
         * @param {string} [comment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentApiV1PaymentsListingSlugPut: function (listingSlug_1, state_1, comment_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([listingSlug_1, state_1, comment_1], args_1, true), void 0, function (listingSlug, state, comment, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'listingSlug' is not null or undefined
                            if (listingSlug === null || listingSlug === undefined) {
                                throw new base_1.RequiredError('listingSlug', 'Required parameter listingSlug was null or undefined when calling updatePaymentApiV1PaymentsListingSlugPut.');
                            }
                            localVarPath = "/api/v1/payments/{listing_slug}"
                                .replace("{".concat("listing_slug", "}"), encodeURIComponent(String(listingSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (state !== undefined) {
                                localVarFormParams.set('state', state);
                            }
                            if (comment !== undefined) {
                                localVarFormParams.set('comment', comment);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PaymentsApiAxiosParamCreator = PaymentsApiAxiosParamCreator;
/**
 * PaymentsApi - functional programming interface
 * @export
 */
var PaymentsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Buy Pack
         * @param {number} packTemplateId
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BrowserInfo} browserInfo
         * @param {number} [promoId]
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPackApiV1PaymentsBuyPackPost: function (packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).buyPackApiV1PaymentsBuyPackPost(packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Bank Account
         * @param {BankAccountCreate} bankAccountCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccountApiV1PaymentsBankAccountPost: function (bankAccountCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).createBankAccountApiV1PaymentsBankAccountPost(bankAccountCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Pre Auth
         * @param {string} cardId
         * @param {number} listingId
         * @param {string} returnUrl
         * @param {boolean} saveCard
         * @param {BrowserInfo} browserInfo
         * @param {number} [promoId]
         * @param {number} [numParticipants]
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreAuthApiV1PaymentsCreatePreauthPost: function (cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).createPreAuthApiV1PaymentsCreatePreauthPost(cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Pre Auth Listing Request
         * @param {string} cardId
         * @param {string} returnUrl
         * @param {boolean} saveCard
         * @param {BodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost} bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost: function (cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost(cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete credit card
         * @summary Delete Credit Cards
         * @param {number} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete: function (cardId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete(cardId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Bank Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountApiV1PaymentsBankAccountGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).getBankAccountApiV1PaymentsBankAccountGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Card Registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRegistrationApiV1PaymentsCardRegistrationGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).getCardRegistrationApiV1PaymentsCardRegistrationGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Credit Cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardsApiV1PaymentsCreditCardsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).getCreditCardsApiV1PaymentsCreditCardsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Payments
         * @param {number} [day]
         * @param {string} [payout]
         * @param {number} [limit]
         * @param {number} [skip]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet: function (day, payout, limit, skip, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet(day, payout, limit, skip, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Payment
         * @param {string} listingSlug
         * @param {string} [state]
         * @param {string} [comment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentApiV1PaymentsListingSlugPut: function (listingSlug, state, comment, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsApiAxiosParamCreator)(configuration).updatePaymentApiV1PaymentsListingSlugPut(listingSlug, state, comment, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PaymentsApiFp = PaymentsApiFp;
/**
 * PaymentsApi - factory interface
 * @export
 */
var PaymentsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Buy Pack
         * @param {number} packTemplateId
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BrowserInfo} browserInfo
         * @param {number} [promoId]
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPackApiV1PaymentsBuyPackPost: function (packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options) {
            return (0, exports.PaymentsApiFp)(configuration).buyPackApiV1PaymentsBuyPackPost(packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create Bank Account
         * @param {BankAccountCreate} bankAccountCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccountApiV1PaymentsBankAccountPost: function (bankAccountCreate, options) {
            return (0, exports.PaymentsApiFp)(configuration).createBankAccountApiV1PaymentsBankAccountPost(bankAccountCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create Pre Auth
         * @param {string} cardId
         * @param {number} listingId
         * @param {string} returnUrl
         * @param {boolean} saveCard
         * @param {BrowserInfo} browserInfo
         * @param {number} [promoId]
         * @param {number} [numParticipants]
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreAuthApiV1PaymentsCreatePreauthPost: function (cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options) {
            return (0, exports.PaymentsApiFp)(configuration).createPreAuthApiV1PaymentsCreatePreauthPost(cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create Pre Auth Listing Request
         * @param {string} cardId
         * @param {string} returnUrl
         * @param {boolean} saveCard
         * @param {BodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost} bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost: function (cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options) {
            return (0, exports.PaymentsApiFp)(configuration).createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost(cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete credit card
         * @summary Delete Credit Cards
         * @param {number} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete: function (cardId, options) {
            return (0, exports.PaymentsApiFp)(configuration).deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete(cardId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Bank Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountApiV1PaymentsBankAccountGet: function (options) {
            return (0, exports.PaymentsApiFp)(configuration).getBankAccountApiV1PaymentsBankAccountGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Card Registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRegistrationApiV1PaymentsCardRegistrationGet: function (options) {
            return (0, exports.PaymentsApiFp)(configuration).getCardRegistrationApiV1PaymentsCardRegistrationGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Credit Cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardsApiV1PaymentsCreditCardsGet: function (options) {
            return (0, exports.PaymentsApiFp)(configuration).getCreditCardsApiV1PaymentsCreditCardsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Payments
         * @param {number} [day]
         * @param {string} [payout]
         * @param {number} [limit]
         * @param {number} [skip]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet: function (day, payout, limit, skip, options) {
            return (0, exports.PaymentsApiFp)(configuration).readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet(day, payout, limit, skip, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Payment
         * @param {string} listingSlug
         * @param {string} [state]
         * @param {string} [comment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentApiV1PaymentsListingSlugPut: function (listingSlug, state, comment, options) {
            return (0, exports.PaymentsApiFp)(configuration).updatePaymentApiV1PaymentsListingSlugPut(listingSlug, state, comment, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PaymentsApiFactory = PaymentsApiFactory;
/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
var PaymentsApi = /** @class */ (function (_super) {
    __extends(PaymentsApi, _super);
    function PaymentsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Buy Pack
     * @param {number} packTemplateId
     * @param {string} cardId
     * @param {boolean} saveCard
     * @param {string} returnUrl
     * @param {BrowserInfo} browserInfo
     * @param {number} [promoId]
     * @param {string} [accept]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.buyPackApiV1PaymentsBuyPackPost = function (packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).buyPackApiV1PaymentsBuyPackPost(packTemplateId, cardId, saveCard, returnUrl, browserInfo, promoId, accept, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create Bank Account
     * @param {BankAccountCreate} bankAccountCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.createBankAccountApiV1PaymentsBankAccountPost = function (bankAccountCreate, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).createBankAccountApiV1PaymentsBankAccountPost(bankAccountCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create Pre Auth
     * @param {string} cardId
     * @param {number} listingId
     * @param {string} returnUrl
     * @param {boolean} saveCard
     * @param {BrowserInfo} browserInfo
     * @param {number} [promoId]
     * @param {number} [numParticipants]
     * @param {string} [accept]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.createPreAuthApiV1PaymentsCreatePreauthPost = function (cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).createPreAuthApiV1PaymentsCreatePreauthPost(cardId, listingId, returnUrl, saveCard, browserInfo, promoId, numParticipants, accept, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create Pre Auth Listing Request
     * @param {string} cardId
     * @param {string} returnUrl
     * @param {boolean} saveCard
     * @param {BodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost} bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost
     * @param {string} [accept]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost = function (cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).createPreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost(cardId, returnUrl, saveCard, bodyCreatePreAuthListingRequestApiV1PaymentsCreatePreauthListingRequestPost, accept, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete credit card
     * @summary Delete Credit Cards
     * @param {number} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete = function (cardId, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).deleteCreditCardsApiV1PaymentsCreditCardsCardIdDelete(cardId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Bank Account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.getBankAccountApiV1PaymentsBankAccountGet = function (options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).getBankAccountApiV1PaymentsBankAccountGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Card Registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.getCardRegistrationApiV1PaymentsCardRegistrationGet = function (options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).getCardRegistrationApiV1PaymentsCardRegistrationGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Credit Cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.getCreditCardsApiV1PaymentsCreditCardsGet = function (options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).getCreditCardsApiV1PaymentsCreditCardsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Payments
     * @param {number} [day]
     * @param {string} [payout]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet = function (day, payout, limit, skip, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).readPaymentsApiV1PaymentsdbSessionDependsDepsGetDbGet(day, payout, limit, skip, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Payment
     * @param {string} listingSlug
     * @param {string} [state]
     * @param {string} [comment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    PaymentsApi.prototype.updatePaymentApiV1PaymentsListingSlugPut = function (listingSlug, state, comment, options) {
        var _this = this;
        return (0, exports.PaymentsApiFp)(this.configuration).updatePaymentApiV1PaymentsListingSlugPut(listingSlug, state, comment, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PaymentsApi;
}(base_1.BaseAPI));
exports.PaymentsApi = PaymentsApi;
/**
 * PaymentsAdminApi - axios parameter creator
 * @export
 */
var PaymentsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Delete credit card
         * @summary Delete Credit Cards Admin
         * @param {number} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete: function (cardId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cardId_1], args_1, true), void 0, function (cardId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete.');
                            }
                            localVarPath = "/api/v1/admin/payments/credit-cards/{card_id}"
                                .replace("{".concat("card_id", "}"), encodeURIComponent(String(cardId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Credit Cards Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet.');
                            }
                            localVarPath = "/api/v1/admin/payments/credit-cards/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PaymentsAdminApiAxiosParamCreator = PaymentsAdminApiAxiosParamCreator;
/**
 * PaymentsAdminApi - functional programming interface
 * @export
 */
var PaymentsAdminApiFp = function (configuration) {
    return {
        /**
         * Delete credit card
         * @summary Delete Credit Cards Admin
         * @param {number} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete: function (cardId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsAdminApiAxiosParamCreator)(configuration).deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete(cardId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Credit Cards Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PaymentsAdminApiAxiosParamCreator)(configuration).getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PaymentsAdminApiFp = PaymentsAdminApiFp;
/**
 * PaymentsAdminApi - factory interface
 * @export
 */
var PaymentsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Delete credit card
         * @summary Delete Credit Cards Admin
         * @param {number} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete: function (cardId, options) {
            return (0, exports.PaymentsAdminApiFp)(configuration).deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete(cardId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Credit Cards Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet: function (userId, options) {
            return (0, exports.PaymentsAdminApiFp)(configuration).getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PaymentsAdminApiFactory = PaymentsAdminApiFactory;
/**
 * PaymentsAdminApi - object-oriented interface
 * @export
 * @class PaymentsAdminApi
 * @extends {BaseAPI}
 */
var PaymentsAdminApi = /** @class */ (function (_super) {
    __extends(PaymentsAdminApi, _super);
    function PaymentsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Delete credit card
     * @summary Delete Credit Cards Admin
     * @param {number} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsAdminApi
     */
    PaymentsAdminApi.prototype.deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete = function (cardId, options) {
        var _this = this;
        return (0, exports.PaymentsAdminApiFp)(this.configuration).deleteCreditCardsAdminApiV1AdminPaymentsCreditCardsCardIdDelete(cardId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Credit Cards Admin
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsAdminApi
     */
    PaymentsAdminApi.prototype.getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.PaymentsAdminApiFp)(this.configuration).getCreditCardsAdminApiV1AdminPaymentsCreditCardsUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PaymentsAdminApi;
}(base_1.BaseAPI));
exports.PaymentsAdminApi = PaymentsAdminApi;
/**
 * PrefsApi - axios parameter creator
 * @export
 */
var PrefsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Read prefs.
         * @summary Read Prefs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrefsApiV1PrefsGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/prefs";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update prefs.
         * @summary Update Prefs
         * @param {Prefs} prefs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrefsApiV1PrefsPut: function (prefs_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([prefs_1], args_1, true), void 0, function (prefs, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'prefs' is not null or undefined
                            if (prefs === null || prefs === undefined) {
                                throw new base_1.RequiredError('prefs', 'Required parameter prefs was null or undefined when calling updatePrefsApiV1PrefsPut.');
                            }
                            localVarPath = "/api/v1/prefs";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof prefs !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(prefs !== undefined ? prefs : {}) : (prefs || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PrefsApiAxiosParamCreator = PrefsApiAxiosParamCreator;
/**
 * PrefsApi - functional programming interface
 * @export
 */
var PrefsApiFp = function (configuration) {
    return {
        /**
         * Read prefs.
         * @summary Read Prefs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrefsApiV1PrefsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PrefsApiAxiosParamCreator)(configuration).readPrefsApiV1PrefsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update prefs.
         * @summary Update Prefs
         * @param {Prefs} prefs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrefsApiV1PrefsPut: function (prefs, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PrefsApiAxiosParamCreator)(configuration).updatePrefsApiV1PrefsPut(prefs, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PrefsApiFp = PrefsApiFp;
/**
 * PrefsApi - factory interface
 * @export
 */
var PrefsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Read prefs.
         * @summary Read Prefs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrefsApiV1PrefsGet: function (options) {
            return (0, exports.PrefsApiFp)(configuration).readPrefsApiV1PrefsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update prefs.
         * @summary Update Prefs
         * @param {Prefs} prefs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrefsApiV1PrefsPut: function (prefs, options) {
            return (0, exports.PrefsApiFp)(configuration).updatePrefsApiV1PrefsPut(prefs, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PrefsApiFactory = PrefsApiFactory;
/**
 * PrefsApi - object-oriented interface
 * @export
 * @class PrefsApi
 * @extends {BaseAPI}
 */
var PrefsApi = /** @class */ (function (_super) {
    __extends(PrefsApi, _super);
    function PrefsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Read prefs.
     * @summary Read Prefs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrefsApi
     */
    PrefsApi.prototype.readPrefsApiV1PrefsGet = function (options) {
        var _this = this;
        return (0, exports.PrefsApiFp)(this.configuration).readPrefsApiV1PrefsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update prefs.
     * @summary Update Prefs
     * @param {Prefs} prefs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrefsApi
     */
    PrefsApi.prototype.updatePrefsApiV1PrefsPut = function (prefs, options) {
        var _this = this;
        return (0, exports.PrefsApiFp)(this.configuration).updatePrefsApiV1PrefsPut(prefs, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PrefsApi;
}(base_1.BaseAPI));
exports.PrefsApi = PrefsApi;
/**
 * PremiumsApi - axios parameter creator
 * @export
 */
var PremiumsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Can Start Demo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canStartDemoApiV1PremiumsDemoGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/premiums/demo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Premium
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BodyCreatePremiumApiV1PremiumsPost} bodyCreatePremiumApiV1PremiumsPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPremiumApiV1PremiumsPost: function (cardId_1, saveCard_1, returnUrl_1, bodyCreatePremiumApiV1PremiumsPost_1, accept_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cardId_1, saveCard_1, returnUrl_1, bodyCreatePremiumApiV1PremiumsPost_1, accept_1], args_1, true), void 0, function (cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'cardId' is not null or undefined
                            if (cardId === null || cardId === undefined) {
                                throw new base_1.RequiredError('cardId', 'Required parameter cardId was null or undefined when calling createPremiumApiV1PremiumsPost.');
                            }
                            // verify required parameter 'saveCard' is not null or undefined
                            if (saveCard === null || saveCard === undefined) {
                                throw new base_1.RequiredError('saveCard', 'Required parameter saveCard was null or undefined when calling createPremiumApiV1PremiumsPost.');
                            }
                            // verify required parameter 'returnUrl' is not null or undefined
                            if (returnUrl === null || returnUrl === undefined) {
                                throw new base_1.RequiredError('returnUrl', 'Required parameter returnUrl was null or undefined when calling createPremiumApiV1PremiumsPost.');
                            }
                            // verify required parameter 'bodyCreatePremiumApiV1PremiumsPost' is not null or undefined
                            if (bodyCreatePremiumApiV1PremiumsPost === null || bodyCreatePremiumApiV1PremiumsPost === undefined) {
                                throw new base_1.RequiredError('bodyCreatePremiumApiV1PremiumsPost', 'Required parameter bodyCreatePremiumApiV1PremiumsPost was null or undefined when calling createPremiumApiV1PremiumsPost.');
                            }
                            localVarPath = "/api/v1/premiums";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (cardId !== undefined) {
                                localVarQueryParameter['card_id'] = cardId;
                            }
                            if (saveCard !== undefined) {
                                localVarQueryParameter['save_card'] = saveCard;
                            }
                            if (returnUrl !== undefined) {
                                localVarQueryParameter['return_url'] = returnUrl;
                            }
                            if (accept !== undefined && accept !== null) {
                                localVarHeaderParameter['accept'] = String(accept);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof bodyCreatePremiumApiV1PremiumsPost !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(bodyCreatePremiumApiV1PremiumsPost !== undefined ? bodyCreatePremiumApiV1PremiumsPost : {}) : (bodyCreatePremiumApiV1PremiumsPost || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Premium
         * @param {number} premiumId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumApiV1PremiumsPremiumIdGet: function (premiumId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([premiumId_1], args_1, true), void 0, function (premiumId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'premiumId' is not null or undefined
                            if (premiumId === null || premiumId === undefined) {
                                throw new base_1.RequiredError('premiumId', 'Required parameter premiumId was null or undefined when calling readPremiumApiV1PremiumsPremiumIdGet.');
                            }
                            localVarPath = "/api/v1/premiums/{premium_id}"
                                .replace("{".concat("premium_id", "}"), encodeURIComponent(String(premiumId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Premiums Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumsMeApiV1PremiumsMeGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/premiums/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Prices
         * @param {string} [currency]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricesApiV1PremiumsPricesGet: function (currency_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([currency_1], args_1, true), void 0, function (currency, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/premiums/prices";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (currency !== undefined) {
                        localVarQueryParameter['currency'] = currency;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Read Referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralsApiV1PremiumsReferralGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/premiums/referral";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send Referral
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReferralApiV1PremiumsReferralPost: function (email_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([email_1], args_1, true), void 0, function (email, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'email' is not null or undefined
                            if (email === null || email === undefined) {
                                throw new base_1.RequiredError('email', 'Required parameter email was null or undefined when calling sendReferralApiV1PremiumsReferralPost.');
                            }
                            localVarPath = "/api/v1/premiums/referral";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Start Premium Demo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPremiumDemoApiV1PremiumsDemoPost: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/premiums/demo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Premium
         * @param {number} premiumId
         * @param {PremiumUpdate} premiumUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePremiumApiV1PremiumsPremiumIdPut: function (premiumId_1, premiumUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([premiumId_1, premiumUpdate_1], args_1, true), void 0, function (premiumId, premiumUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'premiumId' is not null or undefined
                            if (premiumId === null || premiumId === undefined) {
                                throw new base_1.RequiredError('premiumId', 'Required parameter premiumId was null or undefined when calling updatePremiumApiV1PremiumsPremiumIdPut.');
                            }
                            // verify required parameter 'premiumUpdate' is not null or undefined
                            if (premiumUpdate === null || premiumUpdate === undefined) {
                                throw new base_1.RequiredError('premiumUpdate', 'Required parameter premiumUpdate was null or undefined when calling updatePremiumApiV1PremiumsPremiumIdPut.');
                            }
                            localVarPath = "/api/v1/premiums/{premium_id}"
                                .replace("{".concat("premium_id", "}"), encodeURIComponent(String(premiumId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof premiumUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(premiumUpdate !== undefined ? premiumUpdate : {}) : (premiumUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PremiumsApiAxiosParamCreator = PremiumsApiAxiosParamCreator;
/**
 * PremiumsApi - functional programming interface
 * @export
 */
var PremiumsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Can Start Demo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canStartDemoApiV1PremiumsDemoGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).canStartDemoApiV1PremiumsDemoGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Premium
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BodyCreatePremiumApiV1PremiumsPost} bodyCreatePremiumApiV1PremiumsPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPremiumApiV1PremiumsPost: function (cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).createPremiumApiV1PremiumsPost(cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Premium
         * @param {number} premiumId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumApiV1PremiumsPremiumIdGet: function (premiumId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).readPremiumApiV1PremiumsPremiumIdGet(premiumId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Premiums Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumsMeApiV1PremiumsMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).readPremiumsMeApiV1PremiumsMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Prices
         * @param {string} [currency]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricesApiV1PremiumsPricesGet: function (currency, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).readPricesApiV1PremiumsPricesGet(currency, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralsApiV1PremiumsReferralGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).readReferralsApiV1PremiumsReferralGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Send Referral
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReferralApiV1PremiumsReferralPost: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).sendReferralApiV1PremiumsReferralPost(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Start Premium Demo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPremiumDemoApiV1PremiumsDemoPost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).startPremiumDemoApiV1PremiumsDemoPost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Premium
         * @param {number} premiumId
         * @param {PremiumUpdate} premiumUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePremiumApiV1PremiumsPremiumIdPut: function (premiumId, premiumUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsApiAxiosParamCreator)(configuration).updatePremiumApiV1PremiumsPremiumIdPut(premiumId, premiumUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PremiumsApiFp = PremiumsApiFp;
/**
 * PremiumsApi - factory interface
 * @export
 */
var PremiumsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Can Start Demo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canStartDemoApiV1PremiumsDemoGet: function (options) {
            return (0, exports.PremiumsApiFp)(configuration).canStartDemoApiV1PremiumsDemoGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create Premium
         * @param {string} cardId
         * @param {boolean} saveCard
         * @param {string} returnUrl
         * @param {BodyCreatePremiumApiV1PremiumsPost} bodyCreatePremiumApiV1PremiumsPost
         * @param {string} [accept]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPremiumApiV1PremiumsPost: function (cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options) {
            return (0, exports.PremiumsApiFp)(configuration).createPremiumApiV1PremiumsPost(cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Premium
         * @param {number} premiumId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumApiV1PremiumsPremiumIdGet: function (premiumId, options) {
            return (0, exports.PremiumsApiFp)(configuration).readPremiumApiV1PremiumsPremiumIdGet(premiumId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Premiums Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumsMeApiV1PremiumsMeGet: function (options) {
            return (0, exports.PremiumsApiFp)(configuration).readPremiumsMeApiV1PremiumsMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Prices
         * @param {string} [currency]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricesApiV1PremiumsPricesGet: function (currency, options) {
            return (0, exports.PremiumsApiFp)(configuration).readPricesApiV1PremiumsPricesGet(currency, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralsApiV1PremiumsReferralGet: function (options) {
            return (0, exports.PremiumsApiFp)(configuration).readReferralsApiV1PremiumsReferralGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send Referral
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReferralApiV1PremiumsReferralPost: function (email, options) {
            return (0, exports.PremiumsApiFp)(configuration).sendReferralApiV1PremiumsReferralPost(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Start Premium Demo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPremiumDemoApiV1PremiumsDemoPost: function (options) {
            return (0, exports.PremiumsApiFp)(configuration).startPremiumDemoApiV1PremiumsDemoPost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Premium
         * @param {number} premiumId
         * @param {PremiumUpdate} premiumUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePremiumApiV1PremiumsPremiumIdPut: function (premiumId, premiumUpdate, options) {
            return (0, exports.PremiumsApiFp)(configuration).updatePremiumApiV1PremiumsPremiumIdPut(premiumId, premiumUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PremiumsApiFactory = PremiumsApiFactory;
/**
 * PremiumsApi - object-oriented interface
 * @export
 * @class PremiumsApi
 * @extends {BaseAPI}
 */
var PremiumsApi = /** @class */ (function (_super) {
    __extends(PremiumsApi, _super);
    function PremiumsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Can Start Demo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.canStartDemoApiV1PremiumsDemoGet = function (options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).canStartDemoApiV1PremiumsDemoGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create Premium
     * @param {string} cardId
     * @param {boolean} saveCard
     * @param {string} returnUrl
     * @param {BodyCreatePremiumApiV1PremiumsPost} bodyCreatePremiumApiV1PremiumsPost
     * @param {string} [accept]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.createPremiumApiV1PremiumsPost = function (cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).createPremiumApiV1PremiumsPost(cardId, saveCard, returnUrl, bodyCreatePremiumApiV1PremiumsPost, accept, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Premium
     * @param {number} premiumId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.readPremiumApiV1PremiumsPremiumIdGet = function (premiumId, options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).readPremiumApiV1PremiumsPremiumIdGet(premiumId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Premiums Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.readPremiumsMeApiV1PremiumsMeGet = function (options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).readPremiumsMeApiV1PremiumsMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Prices
     * @param {string} [currency]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.readPricesApiV1PremiumsPricesGet = function (currency, options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).readPricesApiV1PremiumsPricesGet(currency, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Referrals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.readReferralsApiV1PremiumsReferralGet = function (options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).readReferralsApiV1PremiumsReferralGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send Referral
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.sendReferralApiV1PremiumsReferralPost = function (email, options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).sendReferralApiV1PremiumsReferralPost(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Start Premium Demo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.startPremiumDemoApiV1PremiumsDemoPost = function (options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).startPremiumDemoApiV1PremiumsDemoPost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Premium
     * @param {number} premiumId
     * @param {PremiumUpdate} premiumUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsApi
     */
    PremiumsApi.prototype.updatePremiumApiV1PremiumsPremiumIdPut = function (premiumId, premiumUpdate, options) {
        var _this = this;
        return (0, exports.PremiumsApiFp)(this.configuration).updatePremiumApiV1PremiumsPremiumIdPut(premiumId, premiumUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PremiumsApi;
}(base_1.BaseAPI));
exports.PremiumsApi = PremiumsApi;
/**
 * PremiumsAdminApi - axios parameter creator
 * @export
 */
var PremiumsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create Premium Admin
         * @param {PremiumCreateAdmin} premiumCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPremiumAdminApiV1AdminPremiumsPost: function (premiumCreateAdmin_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([premiumCreateAdmin_1], args_1, true), void 0, function (premiumCreateAdmin, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'premiumCreateAdmin' is not null or undefined
                            if (premiumCreateAdmin === null || premiumCreateAdmin === undefined) {
                                throw new base_1.RequiredError('premiumCreateAdmin', 'Required parameter premiumCreateAdmin was null or undefined when calling createPremiumAdminApiV1AdminPremiumsPost.');
                            }
                            localVarPath = "/api/v1/admin/premiums";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof premiumCreateAdmin !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(premiumCreateAdmin !== undefined ? premiumCreateAdmin : {}) : (premiumCreateAdmin || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Premiums Admin
         * @param {number} [ownerId]
         * @param {boolean} [onlyDisplay]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumsAdminApiV1AdminPremiumsGet: function (ownerId_1, onlyDisplay_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ownerId_1, onlyDisplay_1], args_1, true), void 0, function (ownerId, onlyDisplay, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/admin/premiums";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (ownerId !== undefined) {
                                localVarQueryParameter['owner_id'] = ownerId;
                            }
                            if (onlyDisplay !== undefined) {
                                localVarQueryParameter['only_display'] = onlyDisplay;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Referrals Admin
         * @param {number} [guruId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralsAdminApiV1AdminPremiumsReferralGet: function (guruId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([guruId_1], args_1, true), void 0, function (guruId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/admin/premiums/referral";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (guruId !== undefined) {
                                localVarQueryParameter['guru_id'] = guruId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PremiumsAdminApiAxiosParamCreator = PremiumsAdminApiAxiosParamCreator;
/**
 * PremiumsAdminApi - functional programming interface
 * @export
 */
var PremiumsAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create Premium Admin
         * @param {PremiumCreateAdmin} premiumCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPremiumAdminApiV1AdminPremiumsPost: function (premiumCreateAdmin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsAdminApiAxiosParamCreator)(configuration).createPremiumAdminApiV1AdminPremiumsPost(premiumCreateAdmin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Premiums Admin
         * @param {number} [ownerId]
         * @param {boolean} [onlyDisplay]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumsAdminApiV1AdminPremiumsGet: function (ownerId, onlyDisplay, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsAdminApiAxiosParamCreator)(configuration).readPremiumsAdminApiV1AdminPremiumsGet(ownerId, onlyDisplay, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Referrals Admin
         * @param {number} [guruId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralsAdminApiV1AdminPremiumsReferralGet: function (guruId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PremiumsAdminApiAxiosParamCreator)(configuration).readReferralsAdminApiV1AdminPremiumsReferralGet(guruId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PremiumsAdminApiFp = PremiumsAdminApiFp;
/**
 * PremiumsAdminApi - factory interface
 * @export
 */
var PremiumsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create Premium Admin
         * @param {PremiumCreateAdmin} premiumCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPremiumAdminApiV1AdminPremiumsPost: function (premiumCreateAdmin, options) {
            return (0, exports.PremiumsAdminApiFp)(configuration).createPremiumAdminApiV1AdminPremiumsPost(premiumCreateAdmin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Premiums Admin
         * @param {number} [ownerId]
         * @param {boolean} [onlyDisplay]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPremiumsAdminApiV1AdminPremiumsGet: function (ownerId, onlyDisplay, options) {
            return (0, exports.PremiumsAdminApiFp)(configuration).readPremiumsAdminApiV1AdminPremiumsGet(ownerId, onlyDisplay, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Referrals Admin
         * @param {number} [guruId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralsAdminApiV1AdminPremiumsReferralGet: function (guruId, options) {
            return (0, exports.PremiumsAdminApiFp)(configuration).readReferralsAdminApiV1AdminPremiumsReferralGet(guruId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PremiumsAdminApiFactory = PremiumsAdminApiFactory;
/**
 * PremiumsAdminApi - object-oriented interface
 * @export
 * @class PremiumsAdminApi
 * @extends {BaseAPI}
 */
var PremiumsAdminApi = /** @class */ (function (_super) {
    __extends(PremiumsAdminApi, _super);
    function PremiumsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create Premium Admin
     * @param {PremiumCreateAdmin} premiumCreateAdmin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsAdminApi
     */
    PremiumsAdminApi.prototype.createPremiumAdminApiV1AdminPremiumsPost = function (premiumCreateAdmin, options) {
        var _this = this;
        return (0, exports.PremiumsAdminApiFp)(this.configuration).createPremiumAdminApiV1AdminPremiumsPost(premiumCreateAdmin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Premiums Admin
     * @param {number} [ownerId]
     * @param {boolean} [onlyDisplay]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsAdminApi
     */
    PremiumsAdminApi.prototype.readPremiumsAdminApiV1AdminPremiumsGet = function (ownerId, onlyDisplay, options) {
        var _this = this;
        return (0, exports.PremiumsAdminApiFp)(this.configuration).readPremiumsAdminApiV1AdminPremiumsGet(ownerId, onlyDisplay, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Referrals Admin
     * @param {number} [guruId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumsAdminApi
     */
    PremiumsAdminApi.prototype.readReferralsAdminApiV1AdminPremiumsReferralGet = function (guruId, options) {
        var _this = this;
        return (0, exports.PremiumsAdminApiFp)(this.configuration).readReferralsAdminApiV1AdminPremiumsReferralGet(guruId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PremiumsAdminApi;
}(base_1.BaseAPI));
exports.PremiumsAdminApi = PremiumsAdminApi;
/**
 * PromosApi - axios parameter creator
 * @export
 */
var PromosApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Check Promo
         * @param {string} code
         * @param {number} [packTemplateId]
         * @param {number} [listingId]
         * @param {number} [retreatId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPromoApiV1PromosCheckGet: function (code_1, packTemplateId_1, listingId_1, retreatId_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([code_1, packTemplateId_1, listingId_1, retreatId_1], args_1, true), void 0, function (code, packTemplateId, listingId, retreatId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'code' is not null or undefined
                            if (code === null || code === undefined) {
                                throw new base_1.RequiredError('code', 'Required parameter code was null or undefined when calling checkPromoApiV1PromosCheckGet.');
                            }
                            localVarPath = "/api/v1/promos/check";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            if (packTemplateId !== undefined) {
                                localVarQueryParameter['pack_template_id'] = packTemplateId;
                            }
                            if (listingId !== undefined) {
                                localVarQueryParameter['listing_id'] = listingId;
                            }
                            if (retreatId !== undefined) {
                                localVarQueryParameter['retreat_id'] = retreatId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Promo
         * @param {PromoCreate} promoCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromoApiV1PromosPost: function (promoCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([promoCreate_1], args_1, true), void 0, function (promoCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'promoCreate' is not null or undefined
                            if (promoCreate === null || promoCreate === undefined) {
                                throw new base_1.RequiredError('promoCreate', 'Required parameter promoCreate was null or undefined when calling createPromoApiV1PromosPost.');
                            }
                            localVarPath = "/api/v1/promos";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof promoCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(promoCreate !== undefined ? promoCreate : {}) : (promoCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Promo
         * @param {number} promoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromoApiV1PromosPromoIdDelete: function (promoId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([promoId_1], args_1, true), void 0, function (promoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'promoId' is not null or undefined
                            if (promoId === null || promoId === undefined) {
                                throw new base_1.RequiredError('promoId', 'Required parameter promoId was null or undefined when calling deletePromoApiV1PromosPromoIdDelete.');
                            }
                            localVarPath = "/api/v1/promos/{promo_id}"
                                .replace("{".concat("promo_id", "}"), encodeURIComponent(String(promoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Promos Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromosMeApiV1PromosMeGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/promos/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Promo
         * @param {number} promoId
         * @param {PromoUpdate} promoUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromoApiV1PromosPromoIdPut: function (promoId_1, promoUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([promoId_1, promoUpdate_1], args_1, true), void 0, function (promoId, promoUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'promoId' is not null or undefined
                            if (promoId === null || promoId === undefined) {
                                throw new base_1.RequiredError('promoId', 'Required parameter promoId was null or undefined when calling updatePromoApiV1PromosPromoIdPut.');
                            }
                            // verify required parameter 'promoUpdate' is not null or undefined
                            if (promoUpdate === null || promoUpdate === undefined) {
                                throw new base_1.RequiredError('promoUpdate', 'Required parameter promoUpdate was null or undefined when calling updatePromoApiV1PromosPromoIdPut.');
                            }
                            localVarPath = "/api/v1/promos/{promo_id}"
                                .replace("{".concat("promo_id", "}"), encodeURIComponent(String(promoId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof promoUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(promoUpdate !== undefined ? promoUpdate : {}) : (promoUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PromosApiAxiosParamCreator = PromosApiAxiosParamCreator;
/**
 * PromosApi - functional programming interface
 * @export
 */
var PromosApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Check Promo
         * @param {string} code
         * @param {number} [packTemplateId]
         * @param {number} [listingId]
         * @param {number} [retreatId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPromoApiV1PromosCheckGet: function (code, packTemplateId, listingId, retreatId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PromosApiAxiosParamCreator)(configuration).checkPromoApiV1PromosCheckGet(code, packTemplateId, listingId, retreatId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create Promo
         * @param {PromoCreate} promoCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromoApiV1PromosPost: function (promoCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PromosApiAxiosParamCreator)(configuration).createPromoApiV1PromosPost(promoCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Promo
         * @param {number} promoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromoApiV1PromosPromoIdDelete: function (promoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PromosApiAxiosParamCreator)(configuration).deletePromoApiV1PromosPromoIdDelete(promoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Promos Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromosMeApiV1PromosMeGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PromosApiAxiosParamCreator)(configuration).readPromosMeApiV1PromosMeGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update Promo
         * @param {number} promoId
         * @param {PromoUpdate} promoUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromoApiV1PromosPromoIdPut: function (promoId, promoUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PromosApiAxiosParamCreator)(configuration).updatePromoApiV1PromosPromoIdPut(promoId, promoUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PromosApiFp = PromosApiFp;
/**
 * PromosApi - factory interface
 * @export
 */
var PromosApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Check Promo
         * @param {string} code
         * @param {number} [packTemplateId]
         * @param {number} [listingId]
         * @param {number} [retreatId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPromoApiV1PromosCheckGet: function (code, packTemplateId, listingId, retreatId, options) {
            return (0, exports.PromosApiFp)(configuration).checkPromoApiV1PromosCheckGet(code, packTemplateId, listingId, retreatId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create Promo
         * @param {PromoCreate} promoCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromoApiV1PromosPost: function (promoCreate, options) {
            return (0, exports.PromosApiFp)(configuration).createPromoApiV1PromosPost(promoCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete Promo
         * @param {number} promoId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromoApiV1PromosPromoIdDelete: function (promoId, options) {
            return (0, exports.PromosApiFp)(configuration).deletePromoApiV1PromosPromoIdDelete(promoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Promos Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromosMeApiV1PromosMeGet: function (skip, limit, options) {
            return (0, exports.PromosApiFp)(configuration).readPromosMeApiV1PromosMeGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update Promo
         * @param {number} promoId
         * @param {PromoUpdate} promoUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromoApiV1PromosPromoIdPut: function (promoId, promoUpdate, options) {
            return (0, exports.PromosApiFp)(configuration).updatePromoApiV1PromosPromoIdPut(promoId, promoUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PromosApiFactory = PromosApiFactory;
/**
 * PromosApi - object-oriented interface
 * @export
 * @class PromosApi
 * @extends {BaseAPI}
 */
var PromosApi = /** @class */ (function (_super) {
    __extends(PromosApi, _super);
    function PromosApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Check Promo
     * @param {string} code
     * @param {number} [packTemplateId]
     * @param {number} [listingId]
     * @param {number} [retreatId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromosApi
     */
    PromosApi.prototype.checkPromoApiV1PromosCheckGet = function (code, packTemplateId, listingId, retreatId, options) {
        var _this = this;
        return (0, exports.PromosApiFp)(this.configuration).checkPromoApiV1PromosCheckGet(code, packTemplateId, listingId, retreatId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create Promo
     * @param {PromoCreate} promoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromosApi
     */
    PromosApi.prototype.createPromoApiV1PromosPost = function (promoCreate, options) {
        var _this = this;
        return (0, exports.PromosApiFp)(this.configuration).createPromoApiV1PromosPost(promoCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete Promo
     * @param {number} promoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromosApi
     */
    PromosApi.prototype.deletePromoApiV1PromosPromoIdDelete = function (promoId, options) {
        var _this = this;
        return (0, exports.PromosApiFp)(this.configuration).deletePromoApiV1PromosPromoIdDelete(promoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Promos Me
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromosApi
     */
    PromosApi.prototype.readPromosMeApiV1PromosMeGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.PromosApiFp)(this.configuration).readPromosMeApiV1PromosMeGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update Promo
     * @param {number} promoId
     * @param {PromoUpdate} promoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromosApi
     */
    PromosApi.prototype.updatePromoApiV1PromosPromoIdPut = function (promoId, promoUpdate, options) {
        var _this = this;
        return (0, exports.PromosApiFp)(this.configuration).updatePromoApiV1PromosPromoIdPut(promoId, promoUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PromosApi;
}(base_1.BaseAPI));
exports.PromosApi = PromosApi;
/**
 * PromosAdminApi - axios parameter creator
 * @export
 */
var PromosAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Read Promos Admin
         * @param {number} [ownerId]
         * @param {boolean} [isActive]
         * @param {boolean} [isDeleted]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromosAdminApiV1AdminPromosGet: function (ownerId_1, isActive_1, isDeleted_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ownerId_1, isActive_1, isDeleted_1, skip_1, limit_1], args_1, true), void 0, function (ownerId, isActive, isDeleted, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/admin/promos";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (ownerId !== undefined) {
                                localVarQueryParameter['owner_id'] = ownerId;
                            }
                            if (isActive !== undefined) {
                                localVarQueryParameter['is_active'] = isActive;
                            }
                            if (isDeleted !== undefined) {
                                localVarQueryParameter['is_deleted'] = isDeleted;
                            }
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PromosAdminApiAxiosParamCreator = PromosAdminApiAxiosParamCreator;
/**
 * PromosAdminApi - functional programming interface
 * @export
 */
var PromosAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Read Promos Admin
         * @param {number} [ownerId]
         * @param {boolean} [isActive]
         * @param {boolean} [isDeleted]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromosAdminApiV1AdminPromosGet: function (ownerId, isActive, isDeleted, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PromosAdminApiAxiosParamCreator)(configuration).readPromosAdminApiV1AdminPromosGet(ownerId, isActive, isDeleted, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PromosAdminApiFp = PromosAdminApiFp;
/**
 * PromosAdminApi - factory interface
 * @export
 */
var PromosAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Read Promos Admin
         * @param {number} [ownerId]
         * @param {boolean} [isActive]
         * @param {boolean} [isDeleted]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromosAdminApiV1AdminPromosGet: function (ownerId, isActive, isDeleted, skip, limit, options) {
            return (0, exports.PromosAdminApiFp)(configuration).readPromosAdminApiV1AdminPromosGet(ownerId, isActive, isDeleted, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PromosAdminApiFactory = PromosAdminApiFactory;
/**
 * PromosAdminApi - object-oriented interface
 * @export
 * @class PromosAdminApi
 * @extends {BaseAPI}
 */
var PromosAdminApi = /** @class */ (function (_super) {
    __extends(PromosAdminApi, _super);
    function PromosAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Read Promos Admin
     * @param {number} [ownerId]
     * @param {boolean} [isActive]
     * @param {boolean} [isDeleted]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromosAdminApi
     */
    PromosAdminApi.prototype.readPromosAdminApiV1AdminPromosGet = function (ownerId, isActive, isDeleted, skip, limit, options) {
        var _this = this;
        return (0, exports.PromosAdminApiFp)(this.configuration).readPromosAdminApiV1AdminPromosGet(ownerId, isActive, isDeleted, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PromosAdminApi;
}(base_1.BaseAPI));
exports.PromosAdminApi = PromosAdminApi;
/**
 * PropertiesApi - axios parameter creator
 * @export
 */
var PropertiesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new property.
         * @summary Create Property
         * @param {PropertyCreate} propertyCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyApiV1PropertiesPost: function (propertyCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([propertyCreate_1], args_1, true), void 0, function (propertyCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'propertyCreate' is not null or undefined
                            if (propertyCreate === null || propertyCreate === undefined) {
                                throw new base_1.RequiredError('propertyCreate', 'Required parameter propertyCreate was null or undefined when calling createPropertyApiV1PropertiesPost.');
                            }
                            localVarPath = "/api/v1/properties";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof propertyCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(propertyCreate !== undefined ? propertyCreate : {}) : (propertyCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve properties.
         * @summary Read Properties
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertiesApiV1PropertiesGet: function (skip_1, limit_1, owner_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, owner_1], args_1, true), void 0, function (skip, limit, owner, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/properties";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (owner !== undefined) {
                                localVarQueryParameter['owner'] = owner;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get property by ID.
         * @summary Read Property
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyApiV1PropertiesIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readPropertyApiV1PropertiesIdGet.');
                            }
                            localVarPath = "/api/v1/properties/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a property.
         * @summary Update Property
         * @param {number} id
         * @param {PropertyUpdate} propertyUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyApiV1PropertiesIdPut: function (id_1, propertyUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, propertyUpdate_1], args_1, true), void 0, function (id, propertyUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updatePropertyApiV1PropertiesIdPut.');
                            }
                            // verify required parameter 'propertyUpdate' is not null or undefined
                            if (propertyUpdate === null || propertyUpdate === undefined) {
                                throw new base_1.RequiredError('propertyUpdate', 'Required parameter propertyUpdate was null or undefined when calling updatePropertyApiV1PropertiesIdPut.');
                            }
                            localVarPath = "/api/v1/properties/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof propertyUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(propertyUpdate !== undefined ? propertyUpdate : {}) : (propertyUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Cover Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoApiV1PropertiesCoverPhotoPost: function (file_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([file_1], args_1, true), void 0, function (file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadCoverPhotoApiV1PropertiesCoverPhotoPost.');
                            }
                            localVarPath = "/api/v1/properties/cover-photo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertiesApiAxiosParamCreator = PropertiesApiAxiosParamCreator;
/**
 * PropertiesApi - functional programming interface
 * @export
 */
var PropertiesApiFp = function (configuration) {
    return {
        /**
         * Create new property.
         * @summary Create Property
         * @param {PropertyCreate} propertyCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyApiV1PropertiesPost: function (propertyCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesApiAxiosParamCreator)(configuration).createPropertyApiV1PropertiesPost(propertyCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve properties.
         * @summary Read Properties
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertiesApiV1PropertiesGet: function (skip, limit, owner, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesApiAxiosParamCreator)(configuration).readPropertiesApiV1PropertiesGet(skip, limit, owner, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get property by ID.
         * @summary Read Property
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyApiV1PropertiesIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesApiAxiosParamCreator)(configuration).readPropertyApiV1PropertiesIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update a property.
         * @summary Update Property
         * @param {number} id
         * @param {PropertyUpdate} propertyUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyApiV1PropertiesIdPut: function (id, propertyUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesApiAxiosParamCreator)(configuration).updatePropertyApiV1PropertiesIdPut(id, propertyUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Cover Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoApiV1PropertiesCoverPhotoPost: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesApiAxiosParamCreator)(configuration).uploadCoverPhotoApiV1PropertiesCoverPhotoPost(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertiesApiFp = PropertiesApiFp;
/**
 * PropertiesApi - factory interface
 * @export
 */
var PropertiesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new property.
         * @summary Create Property
         * @param {PropertyCreate} propertyCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyApiV1PropertiesPost: function (propertyCreate, options) {
            return (0, exports.PropertiesApiFp)(configuration).createPropertyApiV1PropertiesPost(propertyCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve properties.
         * @summary Read Properties
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [owner]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertiesApiV1PropertiesGet: function (skip, limit, owner, options) {
            return (0, exports.PropertiesApiFp)(configuration).readPropertiesApiV1PropertiesGet(skip, limit, owner, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get property by ID.
         * @summary Read Property
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyApiV1PropertiesIdGet: function (id, options) {
            return (0, exports.PropertiesApiFp)(configuration).readPropertyApiV1PropertiesIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a property.
         * @summary Update Property
         * @param {number} id
         * @param {PropertyUpdate} propertyUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyApiV1PropertiesIdPut: function (id, propertyUpdate, options) {
            return (0, exports.PropertiesApiFp)(configuration).updatePropertyApiV1PropertiesIdPut(id, propertyUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Cover Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoApiV1PropertiesCoverPhotoPost: function (file, options) {
            return (0, exports.PropertiesApiFp)(configuration).uploadCoverPhotoApiV1PropertiesCoverPhotoPost(file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PropertiesApiFactory = PropertiesApiFactory;
/**
 * PropertiesApi - object-oriented interface
 * @export
 * @class PropertiesApi
 * @extends {BaseAPI}
 */
var PropertiesApi = /** @class */ (function (_super) {
    __extends(PropertiesApi, _super);
    function PropertiesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new property.
     * @summary Create Property
     * @param {PropertyCreate} propertyCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    PropertiesApi.prototype.createPropertyApiV1PropertiesPost = function (propertyCreate, options) {
        var _this = this;
        return (0, exports.PropertiesApiFp)(this.configuration).createPropertyApiV1PropertiesPost(propertyCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve properties.
     * @summary Read Properties
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [owner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    PropertiesApi.prototype.readPropertiesApiV1PropertiesGet = function (skip, limit, owner, options) {
        var _this = this;
        return (0, exports.PropertiesApiFp)(this.configuration).readPropertiesApiV1PropertiesGet(skip, limit, owner, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get property by ID.
     * @summary Read Property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    PropertiesApi.prototype.readPropertyApiV1PropertiesIdGet = function (id, options) {
        var _this = this;
        return (0, exports.PropertiesApiFp)(this.configuration).readPropertyApiV1PropertiesIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a property.
     * @summary Update Property
     * @param {number} id
     * @param {PropertyUpdate} propertyUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    PropertiesApi.prototype.updatePropertyApiV1PropertiesIdPut = function (id, propertyUpdate, options) {
        var _this = this;
        return (0, exports.PropertiesApiFp)(this.configuration).updatePropertyApiV1PropertiesIdPut(id, propertyUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Cover Photo
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    PropertiesApi.prototype.uploadCoverPhotoApiV1PropertiesCoverPhotoPost = function (file, options) {
        var _this = this;
        return (0, exports.PropertiesApiFp)(this.configuration).uploadCoverPhotoApiV1PropertiesCoverPhotoPost(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PropertiesApi;
}(base_1.BaseAPI));
exports.PropertiesApi = PropertiesApi;
/**
 * PropertiesAdminApi - axios parameter creator
 * @export
 */
var PropertiesAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new property.
         * @summary Create Property Admin
         * @param {PropertyCreateAdmin} propertyCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyAdminApiV1AdminPropertiesPost: function (propertyCreateAdmin_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([propertyCreateAdmin_1], args_1, true), void 0, function (propertyCreateAdmin, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'propertyCreateAdmin' is not null or undefined
                            if (propertyCreateAdmin === null || propertyCreateAdmin === undefined) {
                                throw new base_1.RequiredError('propertyCreateAdmin', 'Required parameter propertyCreateAdmin was null or undefined when calling createPropertyAdminApiV1AdminPropertiesPost.');
                            }
                            localVarPath = "/api/v1/admin/properties";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof propertyCreateAdmin !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(propertyCreateAdmin !== undefined ? propertyCreateAdmin : {}) : (propertyCreateAdmin || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a property.
         * @summary Delete Property
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyApiV1AdminPropertiesIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deletePropertyApiV1AdminPropertiesIdDelete.');
                            }
                            localVarPath = "/api/v1/admin/properties/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertiesAdminApiAxiosParamCreator = PropertiesAdminApiAxiosParamCreator;
/**
 * PropertiesAdminApi - functional programming interface
 * @export
 */
var PropertiesAdminApiFp = function (configuration) {
    return {
        /**
         * Create new property.
         * @summary Create Property Admin
         * @param {PropertyCreateAdmin} propertyCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyAdminApiV1AdminPropertiesPost: function (propertyCreateAdmin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesAdminApiAxiosParamCreator)(configuration).createPropertyAdminApiV1AdminPropertiesPost(propertyCreateAdmin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete a property.
         * @summary Delete Property
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyApiV1AdminPropertiesIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertiesAdminApiAxiosParamCreator)(configuration).deletePropertyApiV1AdminPropertiesIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertiesAdminApiFp = PropertiesAdminApiFp;
/**
 * PropertiesAdminApi - factory interface
 * @export
 */
var PropertiesAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new property.
         * @summary Create Property Admin
         * @param {PropertyCreateAdmin} propertyCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyAdminApiV1AdminPropertiesPost: function (propertyCreateAdmin, options) {
            return (0, exports.PropertiesAdminApiFp)(configuration).createPropertyAdminApiV1AdminPropertiesPost(propertyCreateAdmin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a property.
         * @summary Delete Property
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyApiV1AdminPropertiesIdDelete: function (id, options) {
            return (0, exports.PropertiesAdminApiFp)(configuration).deletePropertyApiV1AdminPropertiesIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PropertiesAdminApiFactory = PropertiesAdminApiFactory;
/**
 * PropertiesAdminApi - object-oriented interface
 * @export
 * @class PropertiesAdminApi
 * @extends {BaseAPI}
 */
var PropertiesAdminApi = /** @class */ (function (_super) {
    __extends(PropertiesAdminApi, _super);
    function PropertiesAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new property.
     * @summary Create Property Admin
     * @param {PropertyCreateAdmin} propertyCreateAdmin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAdminApi
     */
    PropertiesAdminApi.prototype.createPropertyAdminApiV1AdminPropertiesPost = function (propertyCreateAdmin, options) {
        var _this = this;
        return (0, exports.PropertiesAdminApiFp)(this.configuration).createPropertyAdminApiV1AdminPropertiesPost(propertyCreateAdmin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a property.
     * @summary Delete Property
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAdminApi
     */
    PropertiesAdminApi.prototype.deletePropertyApiV1AdminPropertiesIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.PropertiesAdminApiFp)(this.configuration).deletePropertyApiV1AdminPropertiesIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PropertiesAdminApi;
}(base_1.BaseAPI));
exports.PropertiesAdminApi = PropertiesAdminApi;
/**
 * PropertyTypesApi - axios parameter creator
 * @export
 */
var PropertyTypesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new property_type.
         * @summary Create Property Type
         * @param {PropertyTypeCreate} propertyTypeCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyTypeApiV1PropertyTypesPost: function (propertyTypeCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([propertyTypeCreate_1], args_1, true), void 0, function (propertyTypeCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'propertyTypeCreate' is not null or undefined
                            if (propertyTypeCreate === null || propertyTypeCreate === undefined) {
                                throw new base_1.RequiredError('propertyTypeCreate', 'Required parameter propertyTypeCreate was null or undefined when calling createPropertyTypeApiV1PropertyTypesPost.');
                            }
                            localVarPath = "/api/v1/property_types";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof propertyTypeCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(propertyTypeCreate !== undefined ? propertyTypeCreate : {}) : (propertyTypeCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an property_type.
         * @summary Delete Property Type
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyTypeApiV1PropertyTypesIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deletePropertyTypeApiV1PropertyTypesIdDelete.');
                            }
                            localVarPath = "/api/v1/property_types/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get property_type by ID.
         * @summary Read Property Type
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyTypeApiV1PropertyTypesIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readPropertyTypeApiV1PropertyTypesIdGet.');
                    }
                    localVarPath = "/api/v1/property_types/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve property_types.
         * @summary Read Property Types
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyTypesApiV1PropertyTypesGet: function (skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1], args_1, true), void 0, function (skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/property_types";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Update an property_type.
         * @summary Update Property Type
         * @param {number} id
         * @param {PropertyTypeUpdate} propertyTypeUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyTypeApiV1PropertyTypesIdPut: function (id_1, propertyTypeUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, propertyTypeUpdate_1], args_1, true), void 0, function (id, propertyTypeUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updatePropertyTypeApiV1PropertyTypesIdPut.');
                            }
                            // verify required parameter 'propertyTypeUpdate' is not null or undefined
                            if (propertyTypeUpdate === null || propertyTypeUpdate === undefined) {
                                throw new base_1.RequiredError('propertyTypeUpdate', 'Required parameter propertyTypeUpdate was null or undefined when calling updatePropertyTypeApiV1PropertyTypesIdPut.');
                            }
                            localVarPath = "/api/v1/property_types/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof propertyTypeUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(propertyTypeUpdate !== undefined ? propertyTypeUpdate : {}) : (propertyTypeUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertyTypesApiAxiosParamCreator = PropertyTypesApiAxiosParamCreator;
/**
 * PropertyTypesApi - functional programming interface
 * @export
 */
var PropertyTypesApiFp = function (configuration) {
    return {
        /**
         * Create new property_type.
         * @summary Create Property Type
         * @param {PropertyTypeCreate} propertyTypeCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyTypeApiV1PropertyTypesPost: function (propertyTypeCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertyTypesApiAxiosParamCreator)(configuration).createPropertyTypeApiV1PropertyTypesPost(propertyTypeCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete an property_type.
         * @summary Delete Property Type
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyTypeApiV1PropertyTypesIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertyTypesApiAxiosParamCreator)(configuration).deletePropertyTypeApiV1PropertyTypesIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get property_type by ID.
         * @summary Read Property Type
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyTypeApiV1PropertyTypesIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertyTypesApiAxiosParamCreator)(configuration).readPropertyTypeApiV1PropertyTypesIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve property_types.
         * @summary Read Property Types
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyTypesApiV1PropertyTypesGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertyTypesApiAxiosParamCreator)(configuration).readPropertyTypesApiV1PropertyTypesGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update an property_type.
         * @summary Update Property Type
         * @param {number} id
         * @param {PropertyTypeUpdate} propertyTypeUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyTypeApiV1PropertyTypesIdPut: function (id, propertyTypeUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.PropertyTypesApiAxiosParamCreator)(configuration).updatePropertyTypeApiV1PropertyTypesIdPut(id, propertyTypeUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertyTypesApiFp = PropertyTypesApiFp;
/**
 * PropertyTypesApi - factory interface
 * @export
 */
var PropertyTypesApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create new property_type.
         * @summary Create Property Type
         * @param {PropertyTypeCreate} propertyTypeCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyTypeApiV1PropertyTypesPost: function (propertyTypeCreate, options) {
            return (0, exports.PropertyTypesApiFp)(configuration).createPropertyTypeApiV1PropertyTypesPost(propertyTypeCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an property_type.
         * @summary Delete Property Type
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyTypeApiV1PropertyTypesIdDelete: function (id, options) {
            return (0, exports.PropertyTypesApiFp)(configuration).deletePropertyTypeApiV1PropertyTypesIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get property_type by ID.
         * @summary Read Property Type
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyTypeApiV1PropertyTypesIdGet: function (id, options) {
            return (0, exports.PropertyTypesApiFp)(configuration).readPropertyTypeApiV1PropertyTypesIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve property_types.
         * @summary Read Property Types
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPropertyTypesApiV1PropertyTypesGet: function (skip, limit, options) {
            return (0, exports.PropertyTypesApiFp)(configuration).readPropertyTypesApiV1PropertyTypesGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update an property_type.
         * @summary Update Property Type
         * @param {number} id
         * @param {PropertyTypeUpdate} propertyTypeUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyTypeApiV1PropertyTypesIdPut: function (id, propertyTypeUpdate, options) {
            return (0, exports.PropertyTypesApiFp)(configuration).updatePropertyTypeApiV1PropertyTypesIdPut(id, propertyTypeUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PropertyTypesApiFactory = PropertyTypesApiFactory;
/**
 * PropertyTypesApi - object-oriented interface
 * @export
 * @class PropertyTypesApi
 * @extends {BaseAPI}
 */
var PropertyTypesApi = /** @class */ (function (_super) {
    __extends(PropertyTypesApi, _super);
    function PropertyTypesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new property_type.
     * @summary Create Property Type
     * @param {PropertyTypeCreate} propertyTypeCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTypesApi
     */
    PropertyTypesApi.prototype.createPropertyTypeApiV1PropertyTypesPost = function (propertyTypeCreate, options) {
        var _this = this;
        return (0, exports.PropertyTypesApiFp)(this.configuration).createPropertyTypeApiV1PropertyTypesPost(propertyTypeCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an property_type.
     * @summary Delete Property Type
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTypesApi
     */
    PropertyTypesApi.prototype.deletePropertyTypeApiV1PropertyTypesIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.PropertyTypesApiFp)(this.configuration).deletePropertyTypeApiV1PropertyTypesIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get property_type by ID.
     * @summary Read Property Type
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTypesApi
     */
    PropertyTypesApi.prototype.readPropertyTypeApiV1PropertyTypesIdGet = function (id, options) {
        var _this = this;
        return (0, exports.PropertyTypesApiFp)(this.configuration).readPropertyTypeApiV1PropertyTypesIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve property_types.
     * @summary Read Property Types
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTypesApi
     */
    PropertyTypesApi.prototype.readPropertyTypesApiV1PropertyTypesGet = function (skip, limit, options) {
        var _this = this;
        return (0, exports.PropertyTypesApiFp)(this.configuration).readPropertyTypesApiV1PropertyTypesGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update an property_type.
     * @summary Update Property Type
     * @param {number} id
     * @param {PropertyTypeUpdate} propertyTypeUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyTypesApi
     */
    PropertyTypesApi.prototype.updatePropertyTypeApiV1PropertyTypesIdPut = function (id, propertyTypeUpdate, options) {
        var _this = this;
        return (0, exports.PropertyTypesApiFp)(this.configuration).updatePropertyTypeApiV1PropertyTypesIdPut(id, propertyTypeUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PropertyTypesApi;
}(base_1.BaseAPI));
exports.PropertyTypesApi = PropertyTypesApi;
/**
 * RetreatsApi - axios parameter creator
 * @export
 */
var RetreatsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add retreat arrangement.
         * @summary Add Retreat Arrangement
         * @param {number} id
         * @param {RetreatArrangementCreate} retreatArrangementCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetreatArrangementApiV1RetreatsIdArrangementsPost: function (id_1, retreatArrangementCreate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, retreatArrangementCreate_1], args_1, true), void 0, function (id, retreatArrangementCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling addRetreatArrangementApiV1RetreatsIdArrangementsPost.');
                            }
                            // verify required parameter 'retreatArrangementCreate' is not null or undefined
                            if (retreatArrangementCreate === null || retreatArrangementCreate === undefined) {
                                throw new base_1.RequiredError('retreatArrangementCreate', 'Required parameter retreatArrangementCreate was null or undefined when calling addRetreatArrangementApiV1RetreatsIdArrangementsPost.');
                            }
                            localVarPath = "/api/v1/retreats/{id}/arrangements"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatArrangementCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatArrangementCreate !== undefined ? retreatArrangementCreate : {}) : (retreatArrangementCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat.
         * @summary Create Retreat
         * @param {RetreatCreate} retreatCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRetreatApiV1RetreatsPost: function (retreatCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([retreatCreate_1], args_1, true), void 0, function (retreatCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'retreatCreate' is not null or undefined
                            if (retreatCreate === null || retreatCreate === undefined) {
                                throw new base_1.RequiredError('retreatCreate', 'Required parameter retreatCreate was null or undefined when calling createRetreatApiV1RetreatsPost.');
                            }
                            localVarPath = "/api/v1/retreats";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatCreate !== undefined ? retreatCreate : {}) : (retreatCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} retreatId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1RetreatsPriceGet: function (retreatId_1, promoId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([retreatId_1, promoId_1], args_1, true), void 0, function (retreatId, promoId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'retreatId' is not null or undefined
                            if (retreatId === null || retreatId === undefined) {
                                throw new base_1.RequiredError('retreatId', 'Required parameter retreatId was null or undefined when calling getPriceApiV1RetreatsPriceGet.');
                            }
                            localVarPath = "/api/v1/retreats/price";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (retreatId !== undefined) {
                                localVarQueryParameter['retreat_id'] = retreatId;
                            }
                            if (promoId !== undefined) {
                                localVarQueryParameter['promo_id'] = promoId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Am I In Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet.');
                            }
                            localVarPath = "/api/v1/retreats/{id}/waiting-list/me"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Retreat
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatApiV1RetreatsIdOrSlugGet: function (idOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([idOrSlug_1], args_1, true), void 0, function (idOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'idOrSlug' is not null or undefined
                    if (idOrSlug === null || idOrSlug === undefined) {
                        throw new base_1.RequiredError('idOrSlug', 'Required parameter idOrSlug was null or undefined when calling readRetreatApiV1RetreatsIdOrSlugGet.');
                    }
                    localVarPath = "/api/v1/retreats/{id_or_slug}"
                        .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Read Retreat Private
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet: function (idOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([idOrSlug_1], args_1, true), void 0, function (idOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'idOrSlug' is not null or undefined
                            if (idOrSlug === null || idOrSlug === undefined) {
                                throw new base_1.RequiredError('idOrSlug', 'Required parameter idOrSlug was null or undefined when calling readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet.');
                            }
                            localVarPath = "/api/v1/retreats/private/{id_or_slug}"
                                .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve retreats.
         * @summary Read Retreats
         * @param {number} [owner]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [sortby]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatsApiV1RetreatsGet: function (owner_1, dateStart_1, dateEnd_1, skip_1, limit_1, sortby_1) {
            var args_1 = [];
            for (var _i = 6; _i < arguments.length; _i++) {
                args_1[_i - 6] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([owner_1, dateStart_1, dateEnd_1, skip_1, limit_1, sortby_1], args_1, true), void 0, function (owner, dateStart, dateEnd, skip, limit, sortby, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/retreats";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (owner !== undefined) {
                        localVarQueryParameter['owner'] = owner;
                    }
                    if (dateStart !== undefined) {
                        localVarQueryParameter['date_start'] = dateStart;
                    }
                    if (dateEnd !== undefined) {
                        localVarQueryParameter['date_end'] = dateEnd;
                    }
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (sortby !== undefined) {
                        localVarQueryParameter['sortby'] = sortby;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve retreats me.
         * @summary Read Retreats Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [sortby]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatsMeApiV1RetreatsMeGet: function (skip_1, limit_1, sortby_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, sortby_1], args_1, true), void 0, function (skip, limit, sortby, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/retreats/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (sortby !== undefined) {
                                localVarQueryParameter['sortby'] = sortby;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Remove retreat arrangement.
         * @summary Remove Arrangement
         * @param {number} id
         * @param {number} arrangementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete: function (id_1, arrangementId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, arrangementId_1], args_1, true), void 0, function (id, arrangementId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete.');
                            }
                            // verify required parameter 'arrangementId' is not null or undefined
                            if (arrangementId === null || arrangementId === undefined) {
                                throw new base_1.RequiredError('arrangementId', 'Required parameter arrangementId was null or undefined when calling removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete.');
                            }
                            localVarPath = "/api/v1/retreats/{id}/arrangements/{arrangement_id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("arrangement_id", "}"), encodeURIComponent(String(arrangementId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Retreat
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRetreatApiV1RetreatsIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling removeRetreatApiV1RetreatsIdDelete.');
                            }
                            localVarPath = "/api/v1/retreats/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Subscribe To Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost.');
                            }
                            localVarPath = "/api/v1/retreats/{id}/waiting-list/subscribe"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update retreat arrangement.
         * @summary Update Arrangement
         * @param {number} id
         * @param {number} arrangementId
         * @param {RetreatArrangementUpdate} retreatArrangementUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut: function (id_1, arrangementId_1, retreatArrangementUpdate_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, arrangementId_1, retreatArrangementUpdate_1], args_1, true), void 0, function (id, arrangementId, retreatArrangementUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut.');
                            }
                            // verify required parameter 'arrangementId' is not null or undefined
                            if (arrangementId === null || arrangementId === undefined) {
                                throw new base_1.RequiredError('arrangementId', 'Required parameter arrangementId was null or undefined when calling updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut.');
                            }
                            // verify required parameter 'retreatArrangementUpdate' is not null or undefined
                            if (retreatArrangementUpdate === null || retreatArrangementUpdate === undefined) {
                                throw new base_1.RequiredError('retreatArrangementUpdate', 'Required parameter retreatArrangementUpdate was null or undefined when calling updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut.');
                            }
                            localVarPath = "/api/v1/retreats/{id}/arrangements/{arrangement_id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("arrangement_id", "}"), encodeURIComponent(String(arrangementId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatArrangementUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatArrangementUpdate !== undefined ? retreatArrangementUpdate : {}) : (retreatArrangementUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update retreat.
         * @summary Update Retreat
         * @param {number} id
         * @param {RetreatUpdate} retreatUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRetreatApiV1RetreatsIdPut: function (id_1, retreatUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, retreatUpdate_1], args_1, true), void 0, function (id, retreatUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateRetreatApiV1RetreatsIdPut.');
                            }
                            // verify required parameter 'retreatUpdate' is not null or undefined
                            if (retreatUpdate === null || retreatUpdate === undefined) {
                                throw new base_1.RequiredError('retreatUpdate', 'Required parameter retreatUpdate was null or undefined when calling updateRetreatApiV1RetreatsIdPut.');
                            }
                            localVarPath = "/api/v1/retreats/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatUpdate !== undefined ? retreatUpdate : {}) : (retreatUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoApiV1RetreatsPhotoPost: function (file_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([file_1], args_1, true), void 0, function (file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadPhotoApiV1RetreatsPhotoPost.');
                            }
                            localVarPath = "/api/v1/retreats/photo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.RetreatsApiAxiosParamCreator = RetreatsApiAxiosParamCreator;
/**
 * RetreatsApi - functional programming interface
 * @export
 */
var RetreatsApiFp = function (configuration) {
    return {
        /**
         * Add retreat arrangement.
         * @summary Add Retreat Arrangement
         * @param {number} id
         * @param {RetreatArrangementCreate} retreatArrangementCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetreatArrangementApiV1RetreatsIdArrangementsPost: function (id, retreatArrangementCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).addRetreatArrangementApiV1RetreatsIdArrangementsPost(id, retreatArrangementCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat.
         * @summary Create Retreat
         * @param {RetreatCreate} retreatCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRetreatApiV1RetreatsPost: function (retreatCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).createRetreatApiV1RetreatsPost(retreatCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Price
         * @param {number} retreatId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1RetreatsPriceGet: function (retreatId, promoId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).getPriceApiV1RetreatsPriceGet(retreatId, promoId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Am I In Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Retreat
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatApiV1RetreatsIdOrSlugGet: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).readRetreatApiV1RetreatsIdOrSlugGet(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Retreat Private
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve retreats.
         * @summary Read Retreats
         * @param {number} [owner]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [sortby]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatsApiV1RetreatsGet: function (owner, dateStart, dateEnd, skip, limit, sortby, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).readRetreatsApiV1RetreatsGet(owner, dateStart, dateEnd, skip, limit, sortby, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve retreats me.
         * @summary Read Retreats Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [sortby]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatsMeApiV1RetreatsMeGet: function (skip, limit, sortby, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).readRetreatsMeApiV1RetreatsMeGet(skip, limit, sortby, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Remove retreat arrangement.
         * @summary Remove Arrangement
         * @param {number} id
         * @param {number} arrangementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete: function (id, arrangementId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete(id, arrangementId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Retreat
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRetreatApiV1RetreatsIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).removeRetreatApiV1RetreatsIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Subscribe To Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update retreat arrangement.
         * @summary Update Arrangement
         * @param {number} id
         * @param {number} arrangementId
         * @param {RetreatArrangementUpdate} retreatArrangementUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut: function (id, arrangementId, retreatArrangementUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut(id, arrangementId, retreatArrangementUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update retreat.
         * @summary Update Retreat
         * @param {number} id
         * @param {RetreatUpdate} retreatUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRetreatApiV1RetreatsIdPut: function (id, retreatUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).updateRetreatApiV1RetreatsIdPut(id, retreatUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoApiV1RetreatsPhotoPost: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsApiAxiosParamCreator)(configuration).uploadPhotoApiV1RetreatsPhotoPost(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.RetreatsApiFp = RetreatsApiFp;
/**
 * RetreatsApi - factory interface
 * @export
 */
var RetreatsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add retreat arrangement.
         * @summary Add Retreat Arrangement
         * @param {number} id
         * @param {RetreatArrangementCreate} retreatArrangementCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetreatArrangementApiV1RetreatsIdArrangementsPost: function (id, retreatArrangementCreate, options) {
            return (0, exports.RetreatsApiFp)(configuration).addRetreatArrangementApiV1RetreatsIdArrangementsPost(id, retreatArrangementCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new retreat.
         * @summary Create Retreat
         * @param {RetreatCreate} retreatCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRetreatApiV1RetreatsPost: function (retreatCreate, options) {
            return (0, exports.RetreatsApiFp)(configuration).createRetreatApiV1RetreatsPost(retreatCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Price
         * @param {number} retreatId
         * @param {number} [promoId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceApiV1RetreatsPriceGet: function (retreatId, promoId, options) {
            return (0, exports.RetreatsApiFp)(configuration).getPriceApiV1RetreatsPriceGet(retreatId, promoId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Am I In Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet: function (id, options) {
            return (0, exports.RetreatsApiFp)(configuration).readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Retreat
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatApiV1RetreatsIdOrSlugGet: function (idOrSlug, options) {
            return (0, exports.RetreatsApiFp)(configuration).readRetreatApiV1RetreatsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Retreat Private
         * @param {number | string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet: function (idOrSlug, options) {
            return (0, exports.RetreatsApiFp)(configuration).readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve retreats.
         * @summary Read Retreats
         * @param {number} [owner]
         * @param {number} [dateStart]
         * @param {number} [dateEnd]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [sortby]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatsApiV1RetreatsGet: function (owner, dateStart, dateEnd, skip, limit, sortby, options) {
            return (0, exports.RetreatsApiFp)(configuration).readRetreatsApiV1RetreatsGet(owner, dateStart, dateEnd, skip, limit, sortby, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve retreats me.
         * @summary Read Retreats Me
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [sortby]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRetreatsMeApiV1RetreatsMeGet: function (skip, limit, sortby, options) {
            return (0, exports.RetreatsApiFp)(configuration).readRetreatsMeApiV1RetreatsMeGet(skip, limit, sortby, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Remove retreat arrangement.
         * @summary Remove Arrangement
         * @param {number} id
         * @param {number} arrangementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete: function (id, arrangementId, options) {
            return (0, exports.RetreatsApiFp)(configuration).removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete(id, arrangementId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Retreat
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRetreatApiV1RetreatsIdDelete: function (id, options) {
            return (0, exports.RetreatsApiFp)(configuration).removeRetreatApiV1RetreatsIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Subscribe To Waiting List
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost: function (id, options) {
            return (0, exports.RetreatsApiFp)(configuration).subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update retreat arrangement.
         * @summary Update Arrangement
         * @param {number} id
         * @param {number} arrangementId
         * @param {RetreatArrangementUpdate} retreatArrangementUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut: function (id, arrangementId, retreatArrangementUpdate, options) {
            return (0, exports.RetreatsApiFp)(configuration).updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut(id, arrangementId, retreatArrangementUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update retreat.
         * @summary Update Retreat
         * @param {number} id
         * @param {RetreatUpdate} retreatUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRetreatApiV1RetreatsIdPut: function (id, retreatUpdate, options) {
            return (0, exports.RetreatsApiFp)(configuration).updateRetreatApiV1RetreatsIdPut(id, retreatUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoApiV1RetreatsPhotoPost: function (file, options) {
            return (0, exports.RetreatsApiFp)(configuration).uploadPhotoApiV1RetreatsPhotoPost(file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.RetreatsApiFactory = RetreatsApiFactory;
/**
 * RetreatsApi - object-oriented interface
 * @export
 * @class RetreatsApi
 * @extends {BaseAPI}
 */
var RetreatsApi = /** @class */ (function (_super) {
    __extends(RetreatsApi, _super);
    function RetreatsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add retreat arrangement.
     * @summary Add Retreat Arrangement
     * @param {number} id
     * @param {RetreatArrangementCreate} retreatArrangementCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.addRetreatArrangementApiV1RetreatsIdArrangementsPost = function (id, retreatArrangementCreate, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).addRetreatArrangementApiV1RetreatsIdArrangementsPost(id, retreatArrangementCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new retreat.
     * @summary Create Retreat
     * @param {RetreatCreate} retreatCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.createRetreatApiV1RetreatsPost = function (retreatCreate, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).createRetreatApiV1RetreatsPost(retreatCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Price
     * @param {number} retreatId
     * @param {number} [promoId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.getPriceApiV1RetreatsPriceGet = function (retreatId, promoId, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).getPriceApiV1RetreatsPriceGet(retreatId, promoId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Am I In Waiting List
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet = function (id, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).readAmIInWaitingListApiV1RetreatsIdWaitingListMeGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Retreat
     * @param {number | string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.readRetreatApiV1RetreatsIdOrSlugGet = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).readRetreatApiV1RetreatsIdOrSlugGet(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Retreat Private
     * @param {number | string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).readRetreatPrivateApiV1RetreatsPrivateIdOrSlugGet(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve retreats.
     * @summary Read Retreats
     * @param {number} [owner]
     * @param {number} [dateStart]
     * @param {number} [dateEnd]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {string} [sortby]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.readRetreatsApiV1RetreatsGet = function (owner, dateStart, dateEnd, skip, limit, sortby, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).readRetreatsApiV1RetreatsGet(owner, dateStart, dateEnd, skip, limit, sortby, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve retreats me.
     * @summary Read Retreats Me
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {string} [sortby]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.readRetreatsMeApiV1RetreatsMeGet = function (skip, limit, sortby, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).readRetreatsMeApiV1RetreatsMeGet(skip, limit, sortby, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Remove retreat arrangement.
     * @summary Remove Arrangement
     * @param {number} id
     * @param {number} arrangementId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete = function (id, arrangementId, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).removeArrangementApiV1RetreatsIdArrangementsArrangementIdDelete(id, arrangementId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Retreat
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.removeRetreatApiV1RetreatsIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).removeRetreatApiV1RetreatsIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Subscribe To Waiting List
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost = function (id, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).subscribeToWaitingListApiV1RetreatsIdWaitingListSubscribePost(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update retreat arrangement.
     * @summary Update Arrangement
     * @param {number} id
     * @param {number} arrangementId
     * @param {RetreatArrangementUpdate} retreatArrangementUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut = function (id, arrangementId, retreatArrangementUpdate, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).updateArrangementApiV1RetreatsIdArrangementsArrangementIdPut(id, arrangementId, retreatArrangementUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update retreat.
     * @summary Update Retreat
     * @param {number} id
     * @param {RetreatUpdate} retreatUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.updateRetreatApiV1RetreatsIdPut = function (id, retreatUpdate, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).updateRetreatApiV1RetreatsIdPut(id, retreatUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Photo
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsApi
     */
    RetreatsApi.prototype.uploadPhotoApiV1RetreatsPhotoPost = function (file, options) {
        var _this = this;
        return (0, exports.RetreatsApiFp)(this.configuration).uploadPhotoApiV1RetreatsPhotoPost(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return RetreatsApi;
}(base_1.BaseAPI));
exports.RetreatsApi = RetreatsApi;
/**
 * RetreatsAdminApi - axios parameter creator
 * @export
 */
var RetreatsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add retreat arrangement.
         * @summary Add Retreat Arrangement Admin
         * @param {number} id
         * @param {RetreatArrangementCreateAdmin} retreatArrangementCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost: function (id_1, retreatArrangementCreateAdmin_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, retreatArrangementCreateAdmin_1], args_1, true), void 0, function (id, retreatArrangementCreateAdmin, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost.');
                            }
                            // verify required parameter 'retreatArrangementCreateAdmin' is not null or undefined
                            if (retreatArrangementCreateAdmin === null || retreatArrangementCreateAdmin === undefined) {
                                throw new base_1.RequiredError('retreatArrangementCreateAdmin', 'Required parameter retreatArrangementCreateAdmin was null or undefined when calling addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost.');
                            }
                            localVarPath = "/api/v1/admin/retreats/{id}/arrangements"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatArrangementCreateAdmin !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatArrangementCreateAdmin !== undefined ? retreatArrangementCreateAdmin : {}) : (retreatArrangementCreateAdmin || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat.
         * @summary Create Retreat Admin
         * @param {RetreatCreateAdmin} retreatCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRetreatAdminApiV1AdminRetreatsPost: function (retreatCreateAdmin_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([retreatCreateAdmin_1], args_1, true), void 0, function (retreatCreateAdmin, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'retreatCreateAdmin' is not null or undefined
                            if (retreatCreateAdmin === null || retreatCreateAdmin === undefined) {
                                throw new base_1.RequiredError('retreatCreateAdmin', 'Required parameter retreatCreateAdmin was null or undefined when calling createRetreatAdminApiV1AdminRetreatsPost.');
                            }
                            localVarPath = "/api/v1/admin/retreats";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatCreateAdmin !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatCreateAdmin !== undefined ? retreatCreateAdmin : {}) : (retreatCreateAdmin || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Remove retreat arrangement.
         * @summary Remove Arrangement Admin
         * @param {number} id
         * @param {number} arrangementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete: function (id_1, arrangementId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, arrangementId_1], args_1, true), void 0, function (id, arrangementId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete.');
                            }
                            // verify required parameter 'arrangementId' is not null or undefined
                            if (arrangementId === null || arrangementId === undefined) {
                                throw new base_1.RequiredError('arrangementId', 'Required parameter arrangementId was null or undefined when calling removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete.');
                            }
                            localVarPath = "/api/v1/admin/retreats/{id}/arrangements/{arrangement_id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("arrangement_id", "}"), encodeURIComponent(String(arrangementId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Retreat Admin
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRetreatAdminApiV1AdminRetreatsIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling removeRetreatAdminApiV1AdminRetreatsIdDelete.');
                            }
                            localVarPath = "/api/v1/admin/retreats/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update retreat arrangement.
         * @summary Update Arrangement Admin
         * @param {number} id
         * @param {number} arrangementId
         * @param {RetreatArrangementUpdateAdmin} retreatArrangementUpdateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut: function (id_1, arrangementId_1, retreatArrangementUpdateAdmin_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, arrangementId_1, retreatArrangementUpdateAdmin_1], args_1, true), void 0, function (id, arrangementId, retreatArrangementUpdateAdmin, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut.');
                            }
                            // verify required parameter 'arrangementId' is not null or undefined
                            if (arrangementId === null || arrangementId === undefined) {
                                throw new base_1.RequiredError('arrangementId', 'Required parameter arrangementId was null or undefined when calling updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut.');
                            }
                            // verify required parameter 'retreatArrangementUpdateAdmin' is not null or undefined
                            if (retreatArrangementUpdateAdmin === null || retreatArrangementUpdateAdmin === undefined) {
                                throw new base_1.RequiredError('retreatArrangementUpdateAdmin', 'Required parameter retreatArrangementUpdateAdmin was null or undefined when calling updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut.');
                            }
                            localVarPath = "/api/v1/admin/retreats/{id}/arrangements/{arrangement_id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)))
                                .replace("{".concat("arrangement_id", "}"), encodeURIComponent(String(arrangementId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatArrangementUpdateAdmin !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatArrangementUpdateAdmin !== undefined ? retreatArrangementUpdateAdmin : {}) : (retreatArrangementUpdateAdmin || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat.
         * @summary Update Retreat Admin
         * @param {number} id
         * @param {RetreatUpdate} retreatUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRetreatAdminApiV1AdminRetreatsIdPut: function (id_1, retreatUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, retreatUpdate_1], args_1, true), void 0, function (id, retreatUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateRetreatAdminApiV1AdminRetreatsIdPut.');
                            }
                            // verify required parameter 'retreatUpdate' is not null or undefined
                            if (retreatUpdate === null || retreatUpdate === undefined) {
                                throw new base_1.RequiredError('retreatUpdate', 'Required parameter retreatUpdate was null or undefined when calling updateRetreatAdminApiV1AdminRetreatsIdPut.');
                            }
                            localVarPath = "/api/v1/admin/retreats/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof retreatUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(retreatUpdate !== undefined ? retreatUpdate : {}) : (retreatUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Cover Photo Admin
         * @param {number} ownerId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost: function (ownerId_1, file_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ownerId_1, file_1], args_1, true), void 0, function (ownerId, file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'ownerId' is not null or undefined
                            if (ownerId === null || ownerId === undefined) {
                                throw new base_1.RequiredError('ownerId', 'Required parameter ownerId was null or undefined when calling uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost.');
                            }
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost.');
                            }
                            localVarPath = "/api/v1/admin/retreats/cover-photo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (ownerId !== undefined) {
                                localVarQueryParameter['owner_id'] = ownerId;
                            }
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.RetreatsAdminApiAxiosParamCreator = RetreatsAdminApiAxiosParamCreator;
/**
 * RetreatsAdminApi - functional programming interface
 * @export
 */
var RetreatsAdminApiFp = function (configuration) {
    return {
        /**
         * Add retreat arrangement.
         * @summary Add Retreat Arrangement Admin
         * @param {number} id
         * @param {RetreatArrangementCreateAdmin} retreatArrangementCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost: function (id, retreatArrangementCreateAdmin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost(id, retreatArrangementCreateAdmin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat.
         * @summary Create Retreat Admin
         * @param {RetreatCreateAdmin} retreatCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRetreatAdminApiV1AdminRetreatsPost: function (retreatCreateAdmin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).createRetreatAdminApiV1AdminRetreatsPost(retreatCreateAdmin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Remove retreat arrangement.
         * @summary Remove Arrangement Admin
         * @param {number} id
         * @param {number} arrangementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete: function (id, arrangementId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete(id, arrangementId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Remove Retreat Admin
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRetreatAdminApiV1AdminRetreatsIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).removeRetreatAdminApiV1AdminRetreatsIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update retreat arrangement.
         * @summary Update Arrangement Admin
         * @param {number} id
         * @param {number} arrangementId
         * @param {RetreatArrangementUpdateAdmin} retreatArrangementUpdateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut: function (id, arrangementId, retreatArrangementUpdateAdmin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut(id, arrangementId, retreatArrangementUpdateAdmin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new retreat.
         * @summary Update Retreat Admin
         * @param {number} id
         * @param {RetreatUpdate} retreatUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRetreatAdminApiV1AdminRetreatsIdPut: function (id, retreatUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).updateRetreatAdminApiV1AdminRetreatsIdPut(id, retreatUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Cover Photo Admin
         * @param {number} ownerId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost: function (ownerId, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.RetreatsAdminApiAxiosParamCreator)(configuration).uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost(ownerId, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.RetreatsAdminApiFp = RetreatsAdminApiFp;
/**
 * RetreatsAdminApi - factory interface
 * @export
 */
var RetreatsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add retreat arrangement.
         * @summary Add Retreat Arrangement Admin
         * @param {number} id
         * @param {RetreatArrangementCreateAdmin} retreatArrangementCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost: function (id, retreatArrangementCreateAdmin, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost(id, retreatArrangementCreateAdmin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new retreat.
         * @summary Create Retreat Admin
         * @param {RetreatCreateAdmin} retreatCreateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRetreatAdminApiV1AdminRetreatsPost: function (retreatCreateAdmin, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).createRetreatAdminApiV1AdminRetreatsPost(retreatCreateAdmin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Remove retreat arrangement.
         * @summary Remove Arrangement Admin
         * @param {number} id
         * @param {number} arrangementId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete: function (id, arrangementId, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete(id, arrangementId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Remove Retreat Admin
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRetreatAdminApiV1AdminRetreatsIdDelete: function (id, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).removeRetreatAdminApiV1AdminRetreatsIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update retreat arrangement.
         * @summary Update Arrangement Admin
         * @param {number} id
         * @param {number} arrangementId
         * @param {RetreatArrangementUpdateAdmin} retreatArrangementUpdateAdmin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut: function (id, arrangementId, retreatArrangementUpdateAdmin, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut(id, arrangementId, retreatArrangementUpdateAdmin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new retreat.
         * @summary Update Retreat Admin
         * @param {number} id
         * @param {RetreatUpdate} retreatUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRetreatAdminApiV1AdminRetreatsIdPut: function (id, retreatUpdate, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).updateRetreatAdminApiV1AdminRetreatsIdPut(id, retreatUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Cover Photo Admin
         * @param {number} ownerId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost: function (ownerId, file, options) {
            return (0, exports.RetreatsAdminApiFp)(configuration).uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost(ownerId, file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.RetreatsAdminApiFactory = RetreatsAdminApiFactory;
/**
 * RetreatsAdminApi - object-oriented interface
 * @export
 * @class RetreatsAdminApi
 * @extends {BaseAPI}
 */
var RetreatsAdminApi = /** @class */ (function (_super) {
    __extends(RetreatsAdminApi, _super);
    function RetreatsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add retreat arrangement.
     * @summary Add Retreat Arrangement Admin
     * @param {number} id
     * @param {RetreatArrangementCreateAdmin} retreatArrangementCreateAdmin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost = function (id, retreatArrangementCreateAdmin, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).addRetreatArrangementAdminApiV1AdminRetreatsIdArrangementsPost(id, retreatArrangementCreateAdmin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new retreat.
     * @summary Create Retreat Admin
     * @param {RetreatCreateAdmin} retreatCreateAdmin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.createRetreatAdminApiV1AdminRetreatsPost = function (retreatCreateAdmin, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).createRetreatAdminApiV1AdminRetreatsPost(retreatCreateAdmin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Remove retreat arrangement.
     * @summary Remove Arrangement Admin
     * @param {number} id
     * @param {number} arrangementId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete = function (id, arrangementId, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).removeArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdDelete(id, arrangementId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Remove Retreat Admin
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.removeRetreatAdminApiV1AdminRetreatsIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).removeRetreatAdminApiV1AdminRetreatsIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update retreat arrangement.
     * @summary Update Arrangement Admin
     * @param {number} id
     * @param {number} arrangementId
     * @param {RetreatArrangementUpdateAdmin} retreatArrangementUpdateAdmin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut = function (id, arrangementId, retreatArrangementUpdateAdmin, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).updateArrangementAdminApiV1AdminRetreatsIdArrangementsArrangementIdPut(id, arrangementId, retreatArrangementUpdateAdmin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new retreat.
     * @summary Update Retreat Admin
     * @param {number} id
     * @param {RetreatUpdate} retreatUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.updateRetreatAdminApiV1AdminRetreatsIdPut = function (id, retreatUpdate, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).updateRetreatAdminApiV1AdminRetreatsIdPut(id, retreatUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Cover Photo Admin
     * @param {number} ownerId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RetreatsAdminApi
     */
    RetreatsAdminApi.prototype.uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost = function (ownerId, file, options) {
        var _this = this;
        return (0, exports.RetreatsAdminApiFp)(this.configuration).uploadCoverPhotoAdminApiV1AdminRetreatsCoverPhotoPost(ownerId, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return RetreatsAdminApi;
}(base_1.BaseAPI));
exports.RetreatsAdminApi = RetreatsAdminApi;
/**
 * ReviewsApi - axios parameter creator
 * @export
 */
var ReviewsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Can Review
         * @param {number} guruId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canReviewApiV1ReviewsCanReviewGet: function (guruId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([guruId_1], args_1, true), void 0, function (guruId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'guruId' is not null or undefined
                            if (guruId === null || guruId === undefined) {
                                throw new base_1.RequiredError('guruId', 'Required parameter guruId was null or undefined when calling canReviewApiV1ReviewsCanReviewGet.');
                            }
                            localVarPath = "/api/v1/reviews/can-review";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (guruId !== undefined) {
                                localVarQueryParameter['guru_id'] = guruId;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new review.
         * @summary Create Review
         * @param {ReviewCreate} reviewCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewApiV1ReviewsPost: function (reviewCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([reviewCreate_1], args_1, true), void 0, function (reviewCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'reviewCreate' is not null or undefined
                            if (reviewCreate === null || reviewCreate === undefined) {
                                throw new base_1.RequiredError('reviewCreate', 'Required parameter reviewCreate was null or undefined when calling createReviewApiV1ReviewsPost.');
                            }
                            localVarPath = "/api/v1/reviews";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof reviewCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(reviewCreate !== undefined ? reviewCreate : {}) : (reviewCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete an review.
         * @summary Delete Review
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReviewApiV1ReviewsIdDelete: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling deleteReviewApiV1ReviewsIdDelete.');
                            }
                            localVarPath = "/api/v1/reviews/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get review by ID.
         * @summary Read Review
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReviewApiV1ReviewsIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    if (id === null || id === undefined) {
                        throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling readReviewApiV1ReviewsIdGet.');
                    }
                    localVarPath = "/api/v1/reviews/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve reviews.
         * @summary Read Reviews
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [revieweeId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReviewsApiV1ReviewsGet: function (skip_1, limit_1, revieweeId_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, revieweeId_1], args_1, true), void 0, function (skip, limit, revieweeId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/reviews";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (revieweeId !== undefined) {
                        localVarQueryParameter['reviewee_id'] = revieweeId;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Update review.
         * @summary Update Review
         * @param {number} id
         * @param {ReviewUpdate} reviewUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewApiV1ReviewsIdPut: function (id_1, reviewUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1, reviewUpdate_1], args_1, true), void 0, function (id, reviewUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            if (id === null || id === undefined) {
                                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling updateReviewApiV1ReviewsIdPut.');
                            }
                            // verify required parameter 'reviewUpdate' is not null or undefined
                            if (reviewUpdate === null || reviewUpdate === undefined) {
                                throw new base_1.RequiredError('reviewUpdate', 'Required parameter reviewUpdate was null or undefined when calling updateReviewApiV1ReviewsIdPut.');
                            }
                            localVarPath = "/api/v1/reviews/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof reviewUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(reviewUpdate !== undefined ? reviewUpdate : {}) : (reviewUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ReviewsApiAxiosParamCreator = ReviewsApiAxiosParamCreator;
/**
 * ReviewsApi - functional programming interface
 * @export
 */
var ReviewsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Can Review
         * @param {number} guruId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canReviewApiV1ReviewsCanReviewGet: function (guruId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ReviewsApiAxiosParamCreator)(configuration).canReviewApiV1ReviewsCanReviewGet(guruId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new review.
         * @summary Create Review
         * @param {ReviewCreate} reviewCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewApiV1ReviewsPost: function (reviewCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ReviewsApiAxiosParamCreator)(configuration).createReviewApiV1ReviewsPost(reviewCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete an review.
         * @summary Delete Review
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReviewApiV1ReviewsIdDelete: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ReviewsApiAxiosParamCreator)(configuration).deleteReviewApiV1ReviewsIdDelete(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get review by ID.
         * @summary Read Review
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReviewApiV1ReviewsIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ReviewsApiAxiosParamCreator)(configuration).readReviewApiV1ReviewsIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve reviews.
         * @summary Read Reviews
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [revieweeId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReviewsApiV1ReviewsGet: function (skip, limit, revieweeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ReviewsApiAxiosParamCreator)(configuration).readReviewsApiV1ReviewsGet(skip, limit, revieweeId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update review.
         * @summary Update Review
         * @param {number} id
         * @param {ReviewUpdate} reviewUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewApiV1ReviewsIdPut: function (id, reviewUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.ReviewsApiAxiosParamCreator)(configuration).updateReviewApiV1ReviewsIdPut(id, reviewUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.ReviewsApiFp = ReviewsApiFp;
/**
 * ReviewsApi - factory interface
 * @export
 */
var ReviewsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Can Review
         * @param {number} guruId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canReviewApiV1ReviewsCanReviewGet: function (guruId, options) {
            return (0, exports.ReviewsApiFp)(configuration).canReviewApiV1ReviewsCanReviewGet(guruId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new review.
         * @summary Create Review
         * @param {ReviewCreate} reviewCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReviewApiV1ReviewsPost: function (reviewCreate, options) {
            return (0, exports.ReviewsApiFp)(configuration).createReviewApiV1ReviewsPost(reviewCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete an review.
         * @summary Delete Review
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReviewApiV1ReviewsIdDelete: function (id, options) {
            return (0, exports.ReviewsApiFp)(configuration).deleteReviewApiV1ReviewsIdDelete(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get review by ID.
         * @summary Read Review
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReviewApiV1ReviewsIdGet: function (id, options) {
            return (0, exports.ReviewsApiFp)(configuration).readReviewApiV1ReviewsIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve reviews.
         * @summary Read Reviews
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {number} [revieweeId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReviewsApiV1ReviewsGet: function (skip, limit, revieweeId, options) {
            return (0, exports.ReviewsApiFp)(configuration).readReviewsApiV1ReviewsGet(skip, limit, revieweeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update review.
         * @summary Update Review
         * @param {number} id
         * @param {ReviewUpdate} reviewUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewApiV1ReviewsIdPut: function (id, reviewUpdate, options) {
            return (0, exports.ReviewsApiFp)(configuration).updateReviewApiV1ReviewsIdPut(id, reviewUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ReviewsApiFactory = ReviewsApiFactory;
/**
 * ReviewsApi - object-oriented interface
 * @export
 * @class ReviewsApi
 * @extends {BaseAPI}
 */
var ReviewsApi = /** @class */ (function (_super) {
    __extends(ReviewsApi, _super);
    function ReviewsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Can Review
     * @param {number} guruId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    ReviewsApi.prototype.canReviewApiV1ReviewsCanReviewGet = function (guruId, options) {
        var _this = this;
        return (0, exports.ReviewsApiFp)(this.configuration).canReviewApiV1ReviewsCanReviewGet(guruId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new review.
     * @summary Create Review
     * @param {ReviewCreate} reviewCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    ReviewsApi.prototype.createReviewApiV1ReviewsPost = function (reviewCreate, options) {
        var _this = this;
        return (0, exports.ReviewsApiFp)(this.configuration).createReviewApiV1ReviewsPost(reviewCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete an review.
     * @summary Delete Review
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    ReviewsApi.prototype.deleteReviewApiV1ReviewsIdDelete = function (id, options) {
        var _this = this;
        return (0, exports.ReviewsApiFp)(this.configuration).deleteReviewApiV1ReviewsIdDelete(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get review by ID.
     * @summary Read Review
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    ReviewsApi.prototype.readReviewApiV1ReviewsIdGet = function (id, options) {
        var _this = this;
        return (0, exports.ReviewsApiFp)(this.configuration).readReviewApiV1ReviewsIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve reviews.
     * @summary Read Reviews
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {number} [revieweeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    ReviewsApi.prototype.readReviewsApiV1ReviewsGet = function (skip, limit, revieweeId, options) {
        var _this = this;
        return (0, exports.ReviewsApiFp)(this.configuration).readReviewsApiV1ReviewsGet(skip, limit, revieweeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update review.
     * @summary Update Review
     * @param {number} id
     * @param {ReviewUpdate} reviewUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    ReviewsApi.prototype.updateReviewApiV1ReviewsIdPut = function (id, reviewUpdate, options) {
        var _this = this;
        return (0, exports.ReviewsApiFp)(this.configuration).updateReviewApiV1ReviewsIdPut(id, reviewUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ReviewsApi;
}(base_1.BaseAPI));
exports.ReviewsApi = ReviewsApi;
/**
 * UsersApi - axios parameter creator
 * @export
 */
var UsersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create Availability
         * @param {AvailabilitySlotCreate} availabilitySlotCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAvailabilityApiV1UsersAvailabilitiesPost: function (availabilitySlotCreate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([availabilitySlotCreate_1], args_1, true), void 0, function (availabilitySlotCreate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'availabilitySlotCreate' is not null or undefined
                            if (availabilitySlotCreate === null || availabilitySlotCreate === undefined) {
                                throw new base_1.RequiredError('availabilitySlotCreate', 'Required parameter availabilitySlotCreate was null or undefined when calling createAvailabilityApiV1UsersAvailabilitiesPost.');
                            }
                            localVarPath = "/api/v1/users/availabilities";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof availabilitySlotCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(availabilitySlotCreate !== undefined ? availabilitySlotCreate : {}) : (availabilitySlotCreate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate
         * @param {string} [corpoSlug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost: function (userCreate_1, corpoSlug_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userCreate_1, corpoSlug_1], args_1, true), void 0, function (userCreate, corpoSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userCreate' is not null or undefined
                    if (userCreate === null || userCreate === undefined) {
                        throw new base_1.RequiredError('userCreate', 'Required parameter userCreate was null or undefined when calling createUserApiV1UsersPost.');
                    }
                    localVarPath = "/api/v1/users";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (corpoSlug !== undefined) {
                        localVarQueryParameter['corpo_slug'] = corpoSlug;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof userCreate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(userCreate !== undefined ? userCreate : {}) : (userCreate || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Create new gallery photo for user
         * @summary Create User Photo Gallery
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPhotoGalleryApiV1UsersUserIdGalleryPost: function (userId_1, file_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, file_1], args_1, true), void 0, function (userId, file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling createUserPhotoGalleryApiV1UsersUserIdGalleryPost.');
                            }
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling createUserPhotoGalleryApiV1UsersUserIdGalleryPost.');
                            }
                            localVarPath = "/api/v1/users/{user_id}/gallery"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create User Sso Facebook
         * @param {UserCreateSsoFacebook} userCreateSsoFacebook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSsoFacebookApiV1UsersSsoFacebookPost: function (userCreateSsoFacebook_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userCreateSsoFacebook_1], args_1, true), void 0, function (userCreateSsoFacebook, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userCreateSsoFacebook' is not null or undefined
                    if (userCreateSsoFacebook === null || userCreateSsoFacebook === undefined) {
                        throw new base_1.RequiredError('userCreateSsoFacebook', 'Required parameter userCreateSsoFacebook was null or undefined when calling createUserSsoFacebookApiV1UsersSsoFacebookPost.');
                    }
                    localVarPath = "/api/v1/users/sso/facebook";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof userCreateSsoFacebook !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(userCreateSsoFacebook !== undefined ? userCreateSsoFacebook : {}) : (userCreateSsoFacebook || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Deauthorize Insta
         * @param {any} signedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeInstaApiV1UsersInstaDeauthorizePost: function (signedRequest_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([signedRequest_1], args_1, true), void 0, function (signedRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'signedRequest' is not null or undefined
                    if (signedRequest === null || signedRequest === undefined) {
                        throw new base_1.RequiredError('signedRequest', 'Required parameter signedRequest was null or undefined when calling deauthorizeInstaApiV1UsersInstaDeauthorizePost.');
                    }
                    localVarPath = "/api/v1/users/insta/deauthorize";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (signedRequest !== undefined) {
                        localVarFormParams.set('signed_request', signedRequest);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete Availability
         * @param {number} availabilityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete: function (availabilityId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([availabilityId_1], args_1, true), void 0, function (availabilityId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'availabilityId' is not null or undefined
                            if (availabilityId === null || availabilityId === undefined) {
                                throw new base_1.RequiredError('availabilityId', 'Required parameter availabilityId was null or undefined when calling deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete.');
                            }
                            localVarPath = "/api/v1/users/availabilities/{availability_id}"
                                .replace("{".concat("availability_id", "}"), encodeURIComponent(String(availabilityId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Data Insta
         * @param {any} signedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataInstaApiV1UsersInstaDeletePost: function (signedRequest_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([signedRequest_1], args_1, true), void 0, function (signedRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'signedRequest' is not null or undefined
                    if (signedRequest === null || signedRequest === undefined) {
                        throw new base_1.RequiredError('signedRequest', 'Required parameter signedRequest was null or undefined when calling deleteDataInstaApiV1UsersInstaDeletePost.');
                    }
                    localVarPath = "/api/v1/users/insta/delete";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (signedRequest !== undefined) {
                        localVarFormParams.set('signed_request', signedRequest);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete Profile Photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilePhotoApiV1UsersMeProfilePhotoDelete: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/me/profile-photo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a user.
         * @summary Delete User
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiV1UsersUserIdDelete: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteUserApiV1UsersUserIdDelete.');
                            }
                            localVarPath = "/api/v1/users/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete User Photo From Gallery
         * @param {number} userId
         * @param {number} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete: function (userId_1, imageId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, imageId_1], args_1, true), void 0, function (userId, imageId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete.');
                            }
                            // verify required parameter 'imageId' is not null or undefined
                            if (imageId === null || imageId === undefined) {
                                throw new base_1.RequiredError('imageId', 'Required parameter imageId was null or undefined when calling deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete.');
                            }
                            localVarPath = "/api/v1/users/{user_id}/gallery/{image_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)))
                                .replace("{".concat("image_id", "}"), encodeURIComponent(String(imageId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Disconnect Google Api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectGoogleApiApiV1UsersGapiDisconnectGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/gapi/disconnect";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Disconnect Insta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectInstaApiV1UsersInstaDisconnectGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/insta/disconnect";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Download User Docs
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserDocsApiV1UsersDocsDownloadUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling downloadUserDocsApiV1UsersDocsDownloadUserIdGet.');
                            }
                            localVarPath = "/api/v1/users/docs-download/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Document Presigned Url
         * @param {string} filename
         * @param {string} docType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet: function (filename_1, docType_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([filename_1, docType_1], args_1, true), void 0, function (filename, docType, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'filename' is not null or undefined
                            if (filename === null || filename === undefined) {
                                throw new base_1.RequiredError('filename', 'Required parameter filename was null or undefined when calling getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet.');
                            }
                            // verify required parameter 'docType' is not null or undefined
                            if (docType === null || docType === undefined) {
                                throw new base_1.RequiredError('docType', 'Required parameter docType was null or undefined when calling getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet.');
                            }
                            localVarPath = "/api/v1/users/me/document/presigned";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (filename !== undefined) {
                                localVarQueryParameter['filename'] = filename;
                            }
                            if (docType !== undefined) {
                                localVarQueryParameter['doc_type'] = docType;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Google Api Auth Url
         * @param {string} redirectUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleApiAuthUrlApiV1UsersGapiGet: function (redirectUrl_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([redirectUrl_1], args_1, true), void 0, function (redirectUrl, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'redirectUrl' is not null or undefined
                    if (redirectUrl === null || redirectUrl === undefined) {
                        throw new base_1.RequiredError('redirectUrl', 'Required parameter redirectUrl was null or undefined when calling getGoogleApiAuthUrlApiV1UsersGapiGet.');
                    }
                    localVarPath = "/api/v1/users/gapi";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (redirectUrl !== undefined) {
                        localVarQueryParameter['redirect_url'] = redirectUrl;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Google Api Token
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleApiTokenApiV1UsersGapiTokenUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getGoogleApiTokenApiV1UsersGapiTokenUserIdGet.');
                            }
                            localVarPath = "/api/v1/users/gapi/token/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Gurubay Calendar
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGurubayCalendarApiV1UsersCalendarGet: function (token_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([token_1], args_1, true), void 0, function (token, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    if (token === null || token === undefined) {
                        throw new base_1.RequiredError('token', 'Required parameter token was null or undefined when calling getGurubayCalendarApiV1UsersCalendarGet.');
                    }
                    localVarPath = "/api/v1/users/calendar";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (token !== undefined) {
                        localVarQueryParameter['token'] = token;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Insta Api Auth Url
         * @param {string} redirectUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaApiAuthUrlApiV1UsersInstaGet: function (redirectUrl_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([redirectUrl_1], args_1, true), void 0, function (redirectUrl, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'redirectUrl' is not null or undefined
                    if (redirectUrl === null || redirectUrl === undefined) {
                        throw new base_1.RequiredError('redirectUrl', 'Required parameter redirectUrl was null or undefined when calling getInstaApiAuthUrlApiV1UsersInstaGet.');
                    }
                    localVarPath = "/api/v1/users/insta";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (redirectUrl !== undefined) {
                        localVarQueryParameter['redirect_url'] = redirectUrl;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Is Insta Signed In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInstaSignedInApiV1UsersInstaIsSignedInGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/insta/is-signed-in";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Guru Availabilities
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    if (userId === null || userId === undefined) {
                        throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet.');
                    }
                    localVarPath = "/api/v1/users/{user_id}/availabilities"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Read Guru Availabilities Real
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    if (userId === null || userId === undefined) {
                        throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet.');
                    }
                    localVarPath = "/api/v1/users/{user_id}/availabilities/month"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Gets user associated with guru_code
         * @summary Read Guru With Guru Code
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet: function (guruCode_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([guruCode_1], args_1, true), void 0, function (guruCode, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'guruCode' is not null or undefined
                    if (guruCode === null || guruCode === undefined) {
                        throw new base_1.RequiredError('guruCode', 'Required parameter guruCode was null or undefined when calling readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet.');
                    }
                    localVarPath = "/api/v1/users/guru-codes/{guru_code}"
                        .replace("{".concat("guru_code", "}"), encodeURIComponent(String(guruCode)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve gurus.
         * @summary Read Gurus
         * @param {string} [parentActivity]
         * @param {number} [activity]
         * @param {string} [locationIds]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {boolean} [isAvailableOnline]
         * @param {boolean} [isApproved]
         * @param {string} [firstnameIlike]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusApiV1UsersGurusGet: function (parentActivity_1, activity_1, locationIds_1, priceMin_1, priceMax_1, isAvailableOnline_1, isApproved_1, firstnameIlike_1, skip_1, limit_1) {
            var args_1 = [];
            for (var _i = 10; _i < arguments.length; _i++) {
                args_1[_i - 10] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([parentActivity_1, activity_1, locationIds_1, priceMin_1, priceMax_1, isAvailableOnline_1, isApproved_1, firstnameIlike_1, skip_1, limit_1], args_1, true), void 0, function (parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/users/gurus";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (parentActivity !== undefined) {
                        localVarQueryParameter['parent_activity'] = parentActivity;
                    }
                    if (activity !== undefined) {
                        localVarQueryParameter['activity'] = activity;
                    }
                    if (locationIds !== undefined) {
                        localVarQueryParameter['location_ids'] = locationIds;
                    }
                    if (priceMin !== undefined) {
                        localVarQueryParameter['price_min'] = priceMin;
                    }
                    if (priceMax !== undefined) {
                        localVarQueryParameter['price_max'] = priceMax;
                    }
                    if (isAvailableOnline !== undefined) {
                        localVarQueryParameter['is_available_online'] = isAvailableOnline;
                    }
                    if (isApproved !== undefined) {
                        localVarQueryParameter['is_approved'] = isApproved;
                    }
                    if (firstnameIlike !== undefined) {
                        localVarQueryParameter['firstname_ilike'] = firstnameIlike;
                    }
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Gets the gurus that the user added the guru code of
         * @summary Read Gurus With Guru Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusWithGuruCodeApiV1UsersGuruCodesGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/guru-codes";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Possible Timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPossibleTimezonesApiV1UsersTimezonesGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/users/timezones";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Read Referrable Guru
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferrableGuruApiV1UsersReferrableGuruGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/referrable-guru";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Revenues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRevenuesApiV1UsersRevenuesGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/revenues";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a specific user by id or slug
         * @summary Read User By Id
         * @param {number | string} userIdOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdApiV1UsersUserIdOrSlugGet: function (userIdOrSlug_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userIdOrSlug_1], args_1, true), void 0, function (userIdOrSlug, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userIdOrSlug' is not null or undefined
                            if (userIdOrSlug === null || userIdOrSlug === undefined) {
                                throw new base_1.RequiredError('userIdOrSlug', 'Required parameter userIdOrSlug was null or undefined when calling readUserByIdApiV1UsersUserIdOrSlugGet.');
                            }
                            localVarPath = "/api/v1/users/{user_id_or_slug}"
                                .replace("{".concat("user_id_or_slug", "}"), encodeURIComponent(String(userIdOrSlug)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read User Docs
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserDocsApiV1UsersDocsUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readUserDocsApiV1UsersDocsUserIdGet.');
                            }
                            localVarPath = "/api/v1/users/docs/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user iban
         * @summary Read User Iban
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserIbanApiV1UsersIbanUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readUserIbanApiV1UsersIbanUserIdGet.');
                            }
                            localVarPath = "/api/v1/users/iban/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMeApiV1UsersMeGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read gallery photo of user
         * @summary Read User Photos Gallery
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserPhotosGalleryApiV1UsersUserIdGalleryGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    if (userId === null || userId === undefined) {
                        throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling readUserPhotosGalleryApiV1UsersUserIdGalleryGet.');
                    }
                    localVarPath = "/api/v1/users/{user_id}/gallery"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [lastname]
         * @param {string} [sortBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersApiV1UsersGet: function (skip_1, limit_1, lastname_1, sortBy_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([skip_1, limit_1, lastname_1, sortBy_1], args_1, true), void 0, function (skip, limit, lastname, sortBy, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("MaybeOAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastname !== undefined) {
                                localVarQueryParameter['lastname'] = lastname;
                            }
                            if (sortBy !== undefined) {
                                localVarQueryParameter['sort_by'] = sortBy;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Refresh Google Api Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/gapi/refresh-token";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Request a new email verification email
         * @summary Request Verification Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationEmailApiV1UsersVerificationEmailPost: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/verification-email";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Request Verification Phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationPhoneApiV1UsersVerificationPhonePost: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/verification-phone";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Review Id
         * @param {number} userId
         * @param {boolean} approved
         * @param {string} birthdate
         * @param {string} [firstname]
         * @param {string} [lastname]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewIdApiV1UsersUserIdReviewIdPost: function (userId_1, approved_1, birthdate_1, firstname_1, lastname_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, approved_1, birthdate_1, firstname_1, lastname_1], args_1, true), void 0, function (userId, approved, birthdate, firstname, lastname, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling reviewIdApiV1UsersUserIdReviewIdPost.');
                            }
                            // verify required parameter 'approved' is not null or undefined
                            if (approved === null || approved === undefined) {
                                throw new base_1.RequiredError('approved', 'Required parameter approved was null or undefined when calling reviewIdApiV1UsersUserIdReviewIdPost.');
                            }
                            // verify required parameter 'birthdate' is not null or undefined
                            if (birthdate === null || birthdate === undefined) {
                                throw new base_1.RequiredError('birthdate', 'Required parameter birthdate was null or undefined when calling reviewIdApiV1UsersUserIdReviewIdPost.');
                            }
                            localVarPath = "/api/v1/users/{user_id}/review-id"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (approved !== undefined) {
                                localVarFormParams.set('approved', approved);
                            }
                            if (firstname !== undefined) {
                                localVarFormParams.set('firstname', firstname);
                            }
                            if (lastname !== undefined) {
                                localVarFormParams.set('lastname', lastname);
                            }
                            if (birthdate !== undefined) {
                                localVarFormParams.set('birthdate', birthdate);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Review Profile
         * @param {number} userId
         * @param {boolean} approved
         * @param {number} [commission]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewProfileApiV1UsersUserIdReviewProfilePost: function (userId_1, approved_1, commission_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, approved_1, commission_1], args_1, true), void 0, function (userId, approved, commission, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling reviewProfileApiV1UsersUserIdReviewProfilePost.');
                            }
                            // verify required parameter 'approved' is not null or undefined
                            if (approved === null || approved === undefined) {
                                throw new base_1.RequiredError('approved', 'Required parameter approved was null or undefined when calling reviewProfileApiV1UsersUserIdReviewProfilePost.');
                            }
                            localVarPath = "/api/v1/users/{user_id}/review-profile"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new URLSearchParams();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (approved !== undefined) {
                                localVarFormParams.set('approved', approved);
                            }
                            if (commission !== undefined) {
                                localVarFormParams.set('commission', commission);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams.toString();
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Status Data Insta
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    if (userId === null || userId === undefined) {
                        throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet.');
                    }
                    localVarPath = "/api/v1/users/insta/delete/status/{user_id}"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Submit Google Api Code
         * @param {string} redirectUri
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGoogleApiCodeApiV1UsersGapiCallbackGet: function (redirectUri_1, code_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([redirectUri_1, code_1], args_1, true), void 0, function (redirectUri, code, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'redirectUri' is not null or undefined
                            if (redirectUri === null || redirectUri === undefined) {
                                throw new base_1.RequiredError('redirectUri', 'Required parameter redirectUri was null or undefined when calling submitGoogleApiCodeApiV1UsersGapiCallbackGet.');
                            }
                            // verify required parameter 'code' is not null or undefined
                            if (code === null || code === undefined) {
                                throw new base_1.RequiredError('code', 'Required parameter code was null or undefined when calling submitGoogleApiCodeApiV1UsersGapiCallbackGet.');
                            }
                            localVarPath = "/api/v1/users/gapi/callback";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (redirectUri !== undefined) {
                                localVarQueryParameter['redirect_uri'] = redirectUri;
                            }
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Adds a new guru code
         * @summary Submit Guru Code
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGuruCodeApiV1UsersGuruCodesPost: function (guruCode_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([guruCode_1], args_1, true), void 0, function (guruCode, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'guruCode' is not null or undefined
                            if (guruCode === null || guruCode === undefined) {
                                throw new base_1.RequiredError('guruCode', 'Required parameter guruCode was null or undefined when calling submitGuruCodeApiV1UsersGuruCodesPost.');
                            }
                            localVarPath = "/api/v1/users/guru-codes";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (guruCode !== undefined) {
                                localVarQueryParameter['guru_code'] = guruCode;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit Insta Api Code
         * @param {string} redirectUri
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInstaApiCodeApiV1UsersInstaCallbackGet: function (redirectUri_1, code_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([redirectUri_1, code_1], args_1, true), void 0, function (redirectUri, code, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'redirectUri' is not null or undefined
                            if (redirectUri === null || redirectUri === undefined) {
                                throw new base_1.RequiredError('redirectUri', 'Required parameter redirectUri was null or undefined when calling submitInstaApiCodeApiV1UsersInstaCallbackGet.');
                            }
                            // verify required parameter 'code' is not null or undefined
                            if (code === null || code === undefined) {
                                throw new base_1.RequiredError('code', 'Required parameter code was null or undefined when calling submitInstaApiCodeApiV1UsersInstaCallbackGet.');
                            }
                            localVarPath = "/api/v1/users/insta/callback";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (redirectUri !== undefined) {
                                localVarQueryParameter['redirect_uri'] = redirectUri;
                            }
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Submit phone code for verification
         * @summary Submit Phone Code
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPhoneCodeApiV1UsersSubmitPhoneCodePost: function (code_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([code_1], args_1, true), void 0, function (code, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'code' is not null or undefined
                            if (code === null || code === undefined) {
                                throw new base_1.RequiredError('code', 'Required parameter code was null or undefined when calling submitPhoneCodeApiV1UsersSubmitPhoneCodePost.');
                            }
                            localVarPath = "/api/v1/users/submit-phone-code";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (code !== undefined) {
                                localVarQueryParameter['code'] = code;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Submit retreat interest
         * @summary Submit Retreat Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitRetreatInterestApiV1UsersSubmitRetreatInterestPost: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/users/submit-retreat-interest";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update user.
         * @summary Update User
         * @param {number} userId
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersUserIdPut: function (userId_1, userUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, userUpdate_1], args_1, true), void 0, function (userId, userUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling updateUserApiV1UsersUserIdPut.');
                            }
                            // verify required parameter 'userUpdate' is not null or undefined
                            if (userUpdate === null || userUpdate === undefined) {
                                throw new base_1.RequiredError('userUpdate', 'Required parameter userUpdate was null or undefined when calling updateUserApiV1UsersUserIdPut.');
                            }
                            localVarPath = "/api/v1/users/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof userUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(userUpdate !== undefined ? userUpdate : {}) : (userUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UsersMePut: function (userUpdate_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userUpdate_1], args_1, true), void 0, function (userUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions, needsSerialization;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userUpdate' is not null or undefined
                            if (userUpdate === null || userUpdate === undefined) {
                                throw new base_1.RequiredError('userUpdate', 'Required parameter userUpdate was null or undefined when calling updateUserMeApiV1UsersMePut.');
                            }
                            localVarPath = "/api/v1/users/me";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            needsSerialization = (typeof userUpdate !== "string") && configuration.isJsonMime(localVarRequestOptions.headers['Content-Type']);
                            localVarRequestOptions.data = needsSerialization ? JSON.stringify(userUpdate !== undefined ? userUpdate : {}) : (userUpdate || "");
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Document
         * @param {string} filename
         * @param {string} docType
         * @param {any} [file]
         * @param {string} [objectName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentApiV1UsersMeDocumentPost: function (filename_1, docType_1, file_1, objectName_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([filename_1, docType_1, file_1, objectName_1], args_1, true), void 0, function (filename, docType, file, objectName, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'filename' is not null or undefined
                            if (filename === null || filename === undefined) {
                                throw new base_1.RequiredError('filename', 'Required parameter filename was null or undefined when calling uploadDocumentApiV1UsersMeDocumentPost.');
                            }
                            // verify required parameter 'docType' is not null or undefined
                            if (docType === null || docType === undefined) {
                                throw new base_1.RequiredError('docType', 'Required parameter docType was null or undefined when calling uploadDocumentApiV1UsersMeDocumentPost.');
                            }
                            localVarPath = "/api/v1/users/me/document";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (objectName !== undefined) {
                                localVarFormParams.append('object_name', objectName);
                            }
                            if (filename !== undefined) {
                                localVarFormParams.append('filename', filename);
                            }
                            if (docType !== undefined) {
                                localVarFormParams.append('doc_type', docType);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Id
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIdApiV1UsersMeIdPost: function (file_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([file_1], args_1, true), void 0, function (file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadIdApiV1UsersMeIdPost.');
                            }
                            localVarPath = "/api/v1/users/me/id";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Profile Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePhotoApiV1UsersMeProfilePhotoPost: function (file_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([file_1], args_1, true), void 0, function (file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadProfilePhotoApiV1UsersMeProfilePhotoPost.');
                            }
                            localVarPath = "/api/v1/users/me/profile-photo";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UsersApiAxiosParamCreator = UsersApiAxiosParamCreator;
/**
 * UsersApi - functional programming interface
 * @export
 */
var UsersApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create Availability
         * @param {AvailabilitySlotCreate} availabilitySlotCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAvailabilityApiV1UsersAvailabilitiesPost: function (availabilitySlotCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).createAvailabilityApiV1UsersAvailabilitiesPost(availabilitySlotCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate
         * @param {string} [corpoSlug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost: function (userCreate, corpoSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).createUserApiV1UsersPost(userCreate, corpoSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Create new gallery photo for user
         * @summary Create User Photo Gallery
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPhotoGalleryApiV1UsersUserIdGalleryPost: function (userId, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).createUserPhotoGalleryApiV1UsersUserIdGalleryPost(userId, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create User Sso Facebook
         * @param {UserCreateSsoFacebook} userCreateSsoFacebook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSsoFacebookApiV1UsersSsoFacebookPost: function (userCreateSsoFacebook, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).createUserSsoFacebookApiV1UsersSsoFacebookPost(userCreateSsoFacebook, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Deauthorize Insta
         * @param {any} signedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeInstaApiV1UsersInstaDeauthorizePost: function (signedRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).deauthorizeInstaApiV1UsersInstaDeauthorizePost(signedRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Availability
         * @param {number} availabilityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete: function (availabilityId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete(availabilityId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Data Insta
         * @param {any} signedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataInstaApiV1UsersInstaDeletePost: function (signedRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).deleteDataInstaApiV1UsersInstaDeletePost(signedRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete Profile Photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilePhotoApiV1UsersMeProfilePhotoDelete: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).deleteProfilePhotoApiV1UsersMeProfilePhotoDelete(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Delete a user.
         * @summary Delete User
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiV1UsersUserIdDelete: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).deleteUserApiV1UsersUserIdDelete(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete User Photo From Gallery
         * @param {number} userId
         * @param {number} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete: function (userId, imageId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete(userId, imageId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Disconnect Google Api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectGoogleApiApiV1UsersGapiDisconnectGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).disconnectGoogleApiApiV1UsersGapiDisconnectGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Disconnect Insta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectInstaApiV1UsersInstaDisconnectGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).disconnectInstaApiV1UsersInstaDisconnectGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Download User Docs
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserDocsApiV1UsersDocsDownloadUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).downloadUserDocsApiV1UsersDocsDownloadUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Document Presigned Url
         * @param {string} filename
         * @param {string} docType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet: function (filename, docType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet(filename, docType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Google Api Auth Url
         * @param {string} redirectUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleApiAuthUrlApiV1UsersGapiGet: function (redirectUrl, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).getGoogleApiAuthUrlApiV1UsersGapiGet(redirectUrl, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Google Api Token
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleApiTokenApiV1UsersGapiTokenUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).getGoogleApiTokenApiV1UsersGapiTokenUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Gurubay Calendar
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGurubayCalendarApiV1UsersCalendarGet: function (token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).getGurubayCalendarApiV1UsersCalendarGet(token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Insta Api Auth Url
         * @param {string} redirectUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaApiAuthUrlApiV1UsersInstaGet: function (redirectUrl, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).getInstaApiAuthUrlApiV1UsersInstaGet(redirectUrl, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Is Insta Signed In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInstaSignedInApiV1UsersInstaIsSignedInGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).isInstaSignedInApiV1UsersInstaIsSignedInGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Guru Availabilities
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Guru Availabilities Real
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Gets user associated with guru_code
         * @summary Read Guru With Guru Code
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet: function (guruCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet(guruCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve gurus.
         * @summary Read Gurus
         * @param {string} [parentActivity]
         * @param {number} [activity]
         * @param {string} [locationIds]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {boolean} [isAvailableOnline]
         * @param {boolean} [isApproved]
         * @param {string} [firstnameIlike]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusApiV1UsersGurusGet: function (parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readGurusApiV1UsersGurusGet(parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Gets the gurus that the user added the guru code of
         * @summary Read Gurus With Guru Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusWithGuruCodeApiV1UsersGuruCodesGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readGurusWithGuruCodeApiV1UsersGuruCodesGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Possible Timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPossibleTimezonesApiV1UsersTimezonesGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readPossibleTimezonesApiV1UsersTimezonesGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Referrable Guru
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferrableGuruApiV1UsersReferrableGuruGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readReferrableGuruApiV1UsersReferrableGuruGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Revenues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRevenuesApiV1UsersRevenuesGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readRevenuesApiV1UsersRevenuesGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get a specific user by id or slug
         * @summary Read User By Id
         * @param {number | string} userIdOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdApiV1UsersUserIdOrSlugGet: function (userIdOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readUserByIdApiV1UsersUserIdOrSlugGet(userIdOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read User Docs
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserDocsApiV1UsersDocsUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readUserDocsApiV1UsersDocsUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get user iban
         * @summary Read User Iban
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserIbanApiV1UsersIbanUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readUserIbanApiV1UsersIbanUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMeApiV1UsersMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readUserMeApiV1UsersMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Read gallery photo of user
         * @summary Read User Photos Gallery
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserPhotosGalleryApiV1UsersUserIdGalleryGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readUserPhotosGalleryApiV1UsersUserIdGalleryGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [lastname]
         * @param {string} [sortBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersApiV1UsersGet: function (skip, limit, lastname, sortBy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).readUsersApiV1UsersGet(skip, limit, lastname, sortBy, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Refresh Google Api Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Request a new email verification email
         * @summary Request Verification Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationEmailApiV1UsersVerificationEmailPost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).requestVerificationEmailApiV1UsersVerificationEmailPost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Request Verification Phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationPhoneApiV1UsersVerificationPhonePost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).requestVerificationPhoneApiV1UsersVerificationPhonePost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Review Id
         * @param {number} userId
         * @param {boolean} approved
         * @param {string} birthdate
         * @param {string} [firstname]
         * @param {string} [lastname]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewIdApiV1UsersUserIdReviewIdPost: function (userId, approved, birthdate, firstname, lastname, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).reviewIdApiV1UsersUserIdReviewIdPost(userId, approved, birthdate, firstname, lastname, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Review Profile
         * @param {number} userId
         * @param {boolean} approved
         * @param {number} [commission]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewProfileApiV1UsersUserIdReviewProfilePost: function (userId, approved, commission, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).reviewProfileApiV1UsersUserIdReviewProfilePost(userId, approved, commission, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Status Data Insta
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit Google Api Code
         * @param {string} redirectUri
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGoogleApiCodeApiV1UsersGapiCallbackGet: function (redirectUri, code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).submitGoogleApiCodeApiV1UsersGapiCallbackGet(redirectUri, code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Adds a new guru code
         * @summary Submit Guru Code
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGuruCodeApiV1UsersGuruCodesPost: function (guruCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).submitGuruCodeApiV1UsersGuruCodesPost(guruCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit Insta Api Code
         * @param {string} redirectUri
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInstaApiCodeApiV1UsersInstaCallbackGet: function (redirectUri, code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).submitInstaApiCodeApiV1UsersInstaCallbackGet(redirectUri, code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Submit phone code for verification
         * @summary Submit Phone Code
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPhoneCodeApiV1UsersSubmitPhoneCodePost: function (code, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).submitPhoneCodeApiV1UsersSubmitPhoneCodePost(code, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Submit retreat interest
         * @summary Submit Retreat Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitRetreatInterestApiV1UsersSubmitRetreatInterestPost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).submitRetreatInterestApiV1UsersSubmitRetreatInterestPost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update user.
         * @summary Update User
         * @param {number} userId
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersUserIdPut: function (userId, userUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).updateUserApiV1UsersUserIdPut(userId, userUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UsersMePut: function (userUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).updateUserMeApiV1UsersMePut(userUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Document
         * @param {string} filename
         * @param {string} docType
         * @param {any} [file]
         * @param {string} [objectName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentApiV1UsersMeDocumentPost: function (filename, docType, file, objectName, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).uploadDocumentApiV1UsersMeDocumentPost(filename, docType, file, objectName, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Id
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIdApiV1UsersMeIdPost: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).uploadIdApiV1UsersMeIdPost(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Profile Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePhotoApiV1UsersMeProfilePhotoPost: function (file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersApiAxiosParamCreator)(configuration).uploadProfilePhotoApiV1UsersMeProfilePhotoPost(file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.UsersApiFp = UsersApiFp;
/**
 * UsersApi - factory interface
 * @export
 */
var UsersApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create Availability
         * @param {AvailabilitySlotCreate} availabilitySlotCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAvailabilityApiV1UsersAvailabilitiesPost: function (availabilitySlotCreate, options) {
            return (0, exports.UsersApiFp)(configuration).createAvailabilityApiV1UsersAvailabilitiesPost(availabilitySlotCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate
         * @param {string} [corpoSlug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost: function (userCreate, corpoSlug, options) {
            return (0, exports.UsersApiFp)(configuration).createUserApiV1UsersPost(userCreate, corpoSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new gallery photo for user
         * @summary Create User Photo Gallery
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPhotoGalleryApiV1UsersUserIdGalleryPost: function (userId, file, options) {
            return (0, exports.UsersApiFp)(configuration).createUserPhotoGalleryApiV1UsersUserIdGalleryPost(userId, file, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create User Sso Facebook
         * @param {UserCreateSsoFacebook} userCreateSsoFacebook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSsoFacebookApiV1UsersSsoFacebookPost: function (userCreateSsoFacebook, options) {
            return (0, exports.UsersApiFp)(configuration).createUserSsoFacebookApiV1UsersSsoFacebookPost(userCreateSsoFacebook, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Deauthorize Insta
         * @param {any} signedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeInstaApiV1UsersInstaDeauthorizePost: function (signedRequest, options) {
            return (0, exports.UsersApiFp)(configuration).deauthorizeInstaApiV1UsersInstaDeauthorizePost(signedRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete Availability
         * @param {number} availabilityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete: function (availabilityId, options) {
            return (0, exports.UsersApiFp)(configuration).deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete(availabilityId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete Data Insta
         * @param {any} signedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataInstaApiV1UsersInstaDeletePost: function (signedRequest, options) {
            return (0, exports.UsersApiFp)(configuration).deleteDataInstaApiV1UsersInstaDeletePost(signedRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete Profile Photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilePhotoApiV1UsersMeProfilePhotoDelete: function (options) {
            return (0, exports.UsersApiFp)(configuration).deleteProfilePhotoApiV1UsersMeProfilePhotoDelete(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a user.
         * @summary Delete User
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiV1UsersUserIdDelete: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).deleteUserApiV1UsersUserIdDelete(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete User Photo From Gallery
         * @param {number} userId
         * @param {number} imageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete: function (userId, imageId, options) {
            return (0, exports.UsersApiFp)(configuration).deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete(userId, imageId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Disconnect Google Api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectGoogleApiApiV1UsersGapiDisconnectGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).disconnectGoogleApiApiV1UsersGapiDisconnectGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Disconnect Insta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectInstaApiV1UsersInstaDisconnectGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).disconnectInstaApiV1UsersInstaDisconnectGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Download User Docs
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserDocsApiV1UsersDocsDownloadUserIdGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).downloadUserDocsApiV1UsersDocsDownloadUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Document Presigned Url
         * @param {string} filename
         * @param {string} docType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet: function (filename, docType, options) {
            return (0, exports.UsersApiFp)(configuration).getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet(filename, docType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Google Api Auth Url
         * @param {string} redirectUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleApiAuthUrlApiV1UsersGapiGet: function (redirectUrl, options) {
            return (0, exports.UsersApiFp)(configuration).getGoogleApiAuthUrlApiV1UsersGapiGet(redirectUrl, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Google Api Token
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleApiTokenApiV1UsersGapiTokenUserIdGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).getGoogleApiTokenApiV1UsersGapiTokenUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Gurubay Calendar
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGurubayCalendarApiV1UsersCalendarGet: function (token, options) {
            return (0, exports.UsersApiFp)(configuration).getGurubayCalendarApiV1UsersCalendarGet(token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Insta Api Auth Url
         * @param {string} redirectUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaApiAuthUrlApiV1UsersInstaGet: function (redirectUrl, options) {
            return (0, exports.UsersApiFp)(configuration).getInstaApiAuthUrlApiV1UsersInstaGet(redirectUrl, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Is Insta Signed In
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInstaSignedInApiV1UsersInstaIsSignedInGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).isInstaSignedInApiV1UsersInstaIsSignedInGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Guru Availabilities
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Guru Availabilities Real
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Gets user associated with guru_code
         * @summary Read Guru With Guru Code
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet: function (guruCode, options) {
            return (0, exports.UsersApiFp)(configuration).readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet(guruCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve gurus.
         * @summary Read Gurus
         * @param {string} [parentActivity]
         * @param {number} [activity]
         * @param {string} [locationIds]
         * @param {number} [priceMin]
         * @param {number} [priceMax]
         * @param {boolean} [isAvailableOnline]
         * @param {boolean} [isApproved]
         * @param {string} [firstnameIlike]
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusApiV1UsersGurusGet: function (parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options) {
            return (0, exports.UsersApiFp)(configuration).readGurusApiV1UsersGurusGet(parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Gets the gurus that the user added the guru code of
         * @summary Read Gurus With Guru Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGurusWithGuruCodeApiV1UsersGuruCodesGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).readGurusWithGuruCodeApiV1UsersGuruCodesGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Possible Timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPossibleTimezonesApiV1UsersTimezonesGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).readPossibleTimezonesApiV1UsersTimezonesGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Referrable Guru
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferrableGuruApiV1UsersReferrableGuruGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).readReferrableGuruApiV1UsersReferrableGuruGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Revenues
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRevenuesApiV1UsersRevenuesGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).readRevenuesApiV1UsersRevenuesGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a specific user by id or slug
         * @summary Read User By Id
         * @param {number | string} userIdOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdApiV1UsersUserIdOrSlugGet: function (userIdOrSlug, options) {
            return (0, exports.UsersApiFp)(configuration).readUserByIdApiV1UsersUserIdOrSlugGet(userIdOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read User Docs
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserDocsApiV1UsersDocsUserIdGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).readUserDocsApiV1UsersDocsUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user iban
         * @summary Read User Iban
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserIbanApiV1UsersIbanUserIdGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).readUserIbanApiV1UsersIbanUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMeApiV1UsersMeGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).readUserMeApiV1UsersMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read gallery photo of user
         * @summary Read User Photos Gallery
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserPhotosGalleryApiV1UsersUserIdGalleryGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).readUserPhotosGalleryApiV1UsersUserIdGalleryGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {string} [lastname]
         * @param {string} [sortBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersApiV1UsersGet: function (skip, limit, lastname, sortBy, options) {
            return (0, exports.UsersApiFp)(configuration).readUsersApiV1UsersGet(skip, limit, lastname, sortBy, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Refresh Google Api Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet: function (options) {
            return (0, exports.UsersApiFp)(configuration).refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Request a new email verification email
         * @summary Request Verification Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationEmailApiV1UsersVerificationEmailPost: function (options) {
            return (0, exports.UsersApiFp)(configuration).requestVerificationEmailApiV1UsersVerificationEmailPost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Request Verification Phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationPhoneApiV1UsersVerificationPhonePost: function (options) {
            return (0, exports.UsersApiFp)(configuration).requestVerificationPhoneApiV1UsersVerificationPhonePost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Review Id
         * @param {number} userId
         * @param {boolean} approved
         * @param {string} birthdate
         * @param {string} [firstname]
         * @param {string} [lastname]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewIdApiV1UsersUserIdReviewIdPost: function (userId, approved, birthdate, firstname, lastname, options) {
            return (0, exports.UsersApiFp)(configuration).reviewIdApiV1UsersUserIdReviewIdPost(userId, approved, birthdate, firstname, lastname, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Review Profile
         * @param {number} userId
         * @param {boolean} approved
         * @param {number} [commission]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewProfileApiV1UsersUserIdReviewProfilePost: function (userId, approved, commission, options) {
            return (0, exports.UsersApiFp)(configuration).reviewProfileApiV1UsersUserIdReviewProfilePost(userId, approved, commission, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Status Data Insta
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet: function (userId, options) {
            return (0, exports.UsersApiFp)(configuration).statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Submit Google Api Code
         * @param {string} redirectUri
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGoogleApiCodeApiV1UsersGapiCallbackGet: function (redirectUri, code, options) {
            return (0, exports.UsersApiFp)(configuration).submitGoogleApiCodeApiV1UsersGapiCallbackGet(redirectUri, code, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Adds a new guru code
         * @summary Submit Guru Code
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGuruCodeApiV1UsersGuruCodesPost: function (guruCode, options) {
            return (0, exports.UsersApiFp)(configuration).submitGuruCodeApiV1UsersGuruCodesPost(guruCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Submit Insta Api Code
         * @param {string} redirectUri
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitInstaApiCodeApiV1UsersInstaCallbackGet: function (redirectUri, code, options) {
            return (0, exports.UsersApiFp)(configuration).submitInstaApiCodeApiV1UsersInstaCallbackGet(redirectUri, code, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Submit phone code for verification
         * @summary Submit Phone Code
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPhoneCodeApiV1UsersSubmitPhoneCodePost: function (code, options) {
            return (0, exports.UsersApiFp)(configuration).submitPhoneCodeApiV1UsersSubmitPhoneCodePost(code, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Submit retreat interest
         * @summary Submit Retreat Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitRetreatInterestApiV1UsersSubmitRetreatInterestPost: function (options) {
            return (0, exports.UsersApiFp)(configuration).submitRetreatInterestApiV1UsersSubmitRetreatInterestPost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update user.
         * @summary Update User
         * @param {number} userId
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersUserIdPut: function (userId, userUpdate, options) {
            return (0, exports.UsersApiFp)(configuration).updateUserApiV1UsersUserIdPut(userId, userUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UsersMePut: function (userUpdate, options) {
            return (0, exports.UsersApiFp)(configuration).updateUserMeApiV1UsersMePut(userUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Document
         * @param {string} filename
         * @param {string} docType
         * @param {any} [file]
         * @param {string} [objectName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentApiV1UsersMeDocumentPost: function (filename, docType, file, objectName, options) {
            return (0, exports.UsersApiFp)(configuration).uploadDocumentApiV1UsersMeDocumentPost(filename, docType, file, objectName, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Id
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIdApiV1UsersMeIdPost: function (file, options) {
            return (0, exports.UsersApiFp)(configuration).uploadIdApiV1UsersMeIdPost(file, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Profile Photo
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePhotoApiV1UsersMeProfilePhotoPost: function (file, options) {
            return (0, exports.UsersApiFp)(configuration).uploadProfilePhotoApiV1UsersMeProfilePhotoPost(file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UsersApiFactory = UsersApiFactory;
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
var UsersApi = /** @class */ (function (_super) {
    __extends(UsersApi, _super);
    function UsersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create Availability
     * @param {AvailabilitySlotCreate} availabilitySlotCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.createAvailabilityApiV1UsersAvailabilitiesPost = function (availabilitySlotCreate, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).createAvailabilityApiV1UsersAvailabilitiesPost(availabilitySlotCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new user.
     * @summary Create User
     * @param {UserCreate} userCreate
     * @param {string} [corpoSlug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.createUserApiV1UsersPost = function (userCreate, corpoSlug, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).createUserApiV1UsersPost(userCreate, corpoSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new gallery photo for user
     * @summary Create User Photo Gallery
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.createUserPhotoGalleryApiV1UsersUserIdGalleryPost = function (userId, file, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).createUserPhotoGalleryApiV1UsersUserIdGalleryPost(userId, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create User Sso Facebook
     * @param {UserCreateSsoFacebook} userCreateSsoFacebook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.createUserSsoFacebookApiV1UsersSsoFacebookPost = function (userCreateSsoFacebook, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).createUserSsoFacebookApiV1UsersSsoFacebookPost(userCreateSsoFacebook, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Deauthorize Insta
     * @param {any} signedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deauthorizeInstaApiV1UsersInstaDeauthorizePost = function (signedRequest, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deauthorizeInstaApiV1UsersInstaDeauthorizePost(signedRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete Availability
     * @param {number} availabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete = function (availabilityId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deleteAvailabilityApiV1UsersAvailabilitiesAvailabilityIdDelete(availabilityId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete Data Insta
     * @param {any} signedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteDataInstaApiV1UsersInstaDeletePost = function (signedRequest, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deleteDataInstaApiV1UsersInstaDeletePost(signedRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete Profile Photo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteProfilePhotoApiV1UsersMeProfilePhotoDelete = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deleteProfilePhotoApiV1UsersMeProfilePhotoDelete(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a user.
     * @summary Delete User
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteUserApiV1UsersUserIdDelete = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deleteUserApiV1UsersUserIdDelete(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete User Photo From Gallery
     * @param {number} userId
     * @param {number} imageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete = function (userId, imageId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).deleteUserPhotoFromGalleryApiV1UsersUserIdGalleryImageIdDelete(userId, imageId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Disconnect Google Api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.disconnectGoogleApiApiV1UsersGapiDisconnectGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).disconnectGoogleApiApiV1UsersGapiDisconnectGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Disconnect Insta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.disconnectInstaApiV1UsersInstaDisconnectGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).disconnectInstaApiV1UsersInstaDisconnectGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Download User Docs
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.downloadUserDocsApiV1UsersDocsDownloadUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).downloadUserDocsApiV1UsersDocsDownloadUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Document Presigned Url
     * @param {string} filename
     * @param {string} docType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet = function (filename, docType, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).getDocumentPresignedUrlApiV1UsersMeDocumentPresignedGet(filename, docType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Google Api Auth Url
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getGoogleApiAuthUrlApiV1UsersGapiGet = function (redirectUrl, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).getGoogleApiAuthUrlApiV1UsersGapiGet(redirectUrl, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Google Api Token
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getGoogleApiTokenApiV1UsersGapiTokenUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).getGoogleApiTokenApiV1UsersGapiTokenUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Gurubay Calendar
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getGurubayCalendarApiV1UsersCalendarGet = function (token, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).getGurubayCalendarApiV1UsersCalendarGet(token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Insta Api Auth Url
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getInstaApiAuthUrlApiV1UsersInstaGet = function (redirectUrl, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).getInstaApiAuthUrlApiV1UsersInstaGet(redirectUrl, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Is Insta Signed In
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.isInstaSignedInApiV1UsersInstaIsSignedInGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).isInstaSignedInApiV1UsersInstaIsSignedInGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Guru Availabilities
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readGuruAvailabilitiesApiV1UsersUserIdAvailabilitiesGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Guru Availabilities Real
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readGuruAvailabilitiesRealApiV1UsersUserIdAvailabilitiesMonthGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Gets user associated with guru_code
     * @summary Read Guru With Guru Code
     * @param {string} guruCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet = function (guruCode, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readGuruWithGuruCodeApiV1UsersGuruCodesGuruCodeGet(guruCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve gurus.
     * @summary Read Gurus
     * @param {string} [parentActivity]
     * @param {number} [activity]
     * @param {string} [locationIds]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {boolean} [isAvailableOnline]
     * @param {boolean} [isApproved]
     * @param {string} [firstnameIlike]
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readGurusApiV1UsersGurusGet = function (parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readGurusApiV1UsersGurusGet(parentActivity, activity, locationIds, priceMin, priceMax, isAvailableOnline, isApproved, firstnameIlike, skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Gets the gurus that the user added the guru code of
     * @summary Read Gurus With Guru Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readGurusWithGuruCodeApiV1UsersGuruCodesGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readGurusWithGuruCodeApiV1UsersGuruCodesGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Possible Timezones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readPossibleTimezonesApiV1UsersTimezonesGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readPossibleTimezonesApiV1UsersTimezonesGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Referrable Guru
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readReferrableGuruApiV1UsersReferrableGuruGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readReferrableGuruApiV1UsersReferrableGuruGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Revenues
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readRevenuesApiV1UsersRevenuesGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readRevenuesApiV1UsersRevenuesGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a specific user by id or slug
     * @summary Read User By Id
     * @param {number | string} userIdOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readUserByIdApiV1UsersUserIdOrSlugGet = function (userIdOrSlug, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readUserByIdApiV1UsersUserIdOrSlugGet(userIdOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read User Docs
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readUserDocsApiV1UsersDocsUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readUserDocsApiV1UsersDocsUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user iban
     * @summary Read User Iban
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readUserIbanApiV1UsersIbanUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readUserIbanApiV1UsersIbanUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current user.
     * @summary Read User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readUserMeApiV1UsersMeGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readUserMeApiV1UsersMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read gallery photo of user
     * @summary Read User Photos Gallery
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readUserPhotosGalleryApiV1UsersUserIdGalleryGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readUserPhotosGalleryApiV1UsersUserIdGalleryGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve users.
     * @summary Read Users
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {string} [lastname]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.readUsersApiV1UsersGet = function (skip, limit, lastname, sortBy, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).readUsersApiV1UsersGet(skip, limit, lastname, sortBy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Refresh Google Api Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).refreshGoogleApiTokenApiV1UsersGapiRefreshTokenGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Request a new email verification email
     * @summary Request Verification Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.requestVerificationEmailApiV1UsersVerificationEmailPost = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).requestVerificationEmailApiV1UsersVerificationEmailPost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Request Verification Phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.requestVerificationPhoneApiV1UsersVerificationPhonePost = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).requestVerificationPhoneApiV1UsersVerificationPhonePost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Review Id
     * @param {number} userId
     * @param {boolean} approved
     * @param {string} birthdate
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.reviewIdApiV1UsersUserIdReviewIdPost = function (userId, approved, birthdate, firstname, lastname, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).reviewIdApiV1UsersUserIdReviewIdPost(userId, approved, birthdate, firstname, lastname, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Review Profile
     * @param {number} userId
     * @param {boolean} approved
     * @param {number} [commission]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.reviewProfileApiV1UsersUserIdReviewProfilePost = function (userId, approved, commission, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).reviewProfileApiV1UsersUserIdReviewProfilePost(userId, approved, commission, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Status Data Insta
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).statusDataInstaApiV1UsersInstaDeleteStatusUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Submit Google Api Code
     * @param {string} redirectUri
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.submitGoogleApiCodeApiV1UsersGapiCallbackGet = function (redirectUri, code, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).submitGoogleApiCodeApiV1UsersGapiCallbackGet(redirectUri, code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Adds a new guru code
     * @summary Submit Guru Code
     * @param {string} guruCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.submitGuruCodeApiV1UsersGuruCodesPost = function (guruCode, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).submitGuruCodeApiV1UsersGuruCodesPost(guruCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Submit Insta Api Code
     * @param {string} redirectUri
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.submitInstaApiCodeApiV1UsersInstaCallbackGet = function (redirectUri, code, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).submitInstaApiCodeApiV1UsersInstaCallbackGet(redirectUri, code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Submit phone code for verification
     * @summary Submit Phone Code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.submitPhoneCodeApiV1UsersSubmitPhoneCodePost = function (code, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).submitPhoneCodeApiV1UsersSubmitPhoneCodePost(code, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Submit retreat interest
     * @summary Submit Retreat Interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.submitRetreatInterestApiV1UsersSubmitRetreatInterestPost = function (options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).submitRetreatInterestApiV1UsersSubmitRetreatInterestPost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update user.
     * @summary Update User
     * @param {number} userId
     * @param {UserUpdate} userUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.updateUserApiV1UsersUserIdPut = function (userId, userUpdate, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).updateUserApiV1UsersUserIdPut(userId, userUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update own user.
     * @summary Update User Me
     * @param {UserUpdate} userUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.updateUserMeApiV1UsersMePut = function (userUpdate, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).updateUserMeApiV1UsersMePut(userUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Document
     * @param {string} filename
     * @param {string} docType
     * @param {any} [file]
     * @param {string} [objectName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.uploadDocumentApiV1UsersMeDocumentPost = function (filename, docType, file, objectName, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).uploadDocumentApiV1UsersMeDocumentPost(filename, docType, file, objectName, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.uploadIdApiV1UsersMeIdPost = function (file, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).uploadIdApiV1UsersMeIdPost(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Profile Photo
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.uploadProfilePhotoApiV1UsersMeProfilePhotoPost = function (file, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration).uploadProfilePhotoApiV1UsersMeProfilePhotoPost(file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UsersApi;
}(base_1.BaseAPI));
exports.UsersApi = UsersApi;
/**
 * UsersAdminApi - axios parameter creator
 * @export
 */
var UsersAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Read All Gurus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllGurusApiV1AdminUsersAllGurusGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/admin/users/all-gurus";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read User By Email Admin
         * @param {string} userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByEmailAdminApiV1AdminUsersByEmailGet: function (userEmail_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userEmail_1], args_1, true), void 0, function (userEmail, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userEmail' is not null or undefined
                            if (userEmail === null || userEmail === undefined) {
                                throw new base_1.RequiredError('userEmail', 'Required parameter userEmail was null or undefined when calling readUserByEmailAdminApiV1AdminUsersByEmailGet.');
                            }
                            localVarPath = "/api/v1/admin/users/by-email";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (userEmail !== undefined) {
                                localVarQueryParameter['user_email'] = userEmail;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Request Verification Phone Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost: function (userId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1], args_1, true), void 0, function (userId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost.');
                            }
                            localVarPath = "/api/v1/admin/users/verification-phone/{user_id}"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit Guru Code Admin
         * @param {number} userId
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGuruCodeAdminApiV1AdminUsersGuruCodesPost: function (userId_1, guruCode_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, guruCode_1], args_1, true), void 0, function (userId, guruCode, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling submitGuruCodeAdminApiV1AdminUsersGuruCodesPost.');
                            }
                            // verify required parameter 'guruCode' is not null or undefined
                            if (guruCode === null || guruCode === undefined) {
                                throw new base_1.RequiredError('guruCode', 'Required parameter guruCode was null or undefined when calling submitGuruCodeAdminApiV1AdminUsersGuruCodesPost.');
                            }
                            localVarPath = "/api/v1/admin/users/guru-codes";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (userId !== undefined) {
                                localVarQueryParameter['user_id'] = userId;
                            }
                            if (guruCode !== undefined) {
                                localVarQueryParameter['guru_code'] = guruCode;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Document Admin
         * @param {number} userId
         * @param {string} filename
         * @param {string} docType
         * @param {any} [file]
         * @param {string} [objectName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost: function (userId_1, filename_1, docType_1, file_1, objectName_1) {
            var args_1 = [];
            for (var _i = 5; _i < arguments.length; _i++) {
                args_1[_i - 5] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, filename_1, docType_1, file_1, objectName_1], args_1, true), void 0, function (userId, filename, docType, file, objectName, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost.');
                            }
                            // verify required parameter 'filename' is not null or undefined
                            if (filename === null || filename === undefined) {
                                throw new base_1.RequiredError('filename', 'Required parameter filename was null or undefined when calling uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost.');
                            }
                            // verify required parameter 'docType' is not null or undefined
                            if (docType === null || docType === undefined) {
                                throw new base_1.RequiredError('docType', 'Required parameter docType was null or undefined when calling uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost.');
                            }
                            localVarPath = "/api/v1/admin/users/{user_id}/document"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            if (objectName !== undefined) {
                                localVarFormParams.append('object_name', objectName);
                            }
                            if (filename !== undefined) {
                                localVarFormParams.append('filename', filename);
                            }
                            if (docType !== undefined) {
                                localVarFormParams.append('doc_type', docType);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Id Admin
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIdAdminApiV1AdminUsersUserIdIdPost: function (userId_1, file_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, file_1], args_1, true), void 0, function (userId, file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling uploadIdAdminApiV1AdminUsersUserIdIdPost.');
                            }
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadIdAdminApiV1AdminUsersUserIdIdPost.');
                            }
                            localVarPath = "/api/v1/admin/users/{user_id}/id"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Profile Photo Admin
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost: function (userId_1, file_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([userId_1, file_1], args_1, true), void 0, function (userId, file, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            if (userId === null || userId === undefined) {
                                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost.');
                            }
                            // verify required parameter 'file' is not null or undefined
                            if (file === null || file === undefined) {
                                throw new base_1.RequiredError('file', 'Required parameter file was null or undefined when calling uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost.');
                            }
                            localVarPath = "/api/v1/admin/users/{user_id}/profile-photo"
                                .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (file !== undefined) {
                                localVarFormParams.append('file', file);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UsersAdminApiAxiosParamCreator = UsersAdminApiAxiosParamCreator;
/**
 * UsersAdminApi - functional programming interface
 * @export
 */
var UsersAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Read All Gurus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllGurusApiV1AdminUsersAllGurusGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).readAllGurusApiV1AdminUsersAllGurusGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read User By Email Admin
         * @param {string} userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByEmailAdminApiV1AdminUsersByEmailGet: function (userEmail, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).readUserByEmailAdminApiV1AdminUsersByEmailGet(userEmail, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Request Verification Phone Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit Guru Code Admin
         * @param {number} userId
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGuruCodeAdminApiV1AdminUsersGuruCodesPost: function (userId, guruCode, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).submitGuruCodeAdminApiV1AdminUsersGuruCodesPost(userId, guruCode, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Document Admin
         * @param {number} userId
         * @param {string} filename
         * @param {string} docType
         * @param {any} [file]
         * @param {string} [objectName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost: function (userId, filename, docType, file, objectName, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost(userId, filename, docType, file, objectName, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Id Admin
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIdAdminApiV1AdminUsersUserIdIdPost: function (userId, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).uploadIdAdminApiV1AdminUsersUserIdIdPost(userId, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Upload Profile Photo Admin
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost: function (userId, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UsersAdminApiAxiosParamCreator)(configuration).uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost(userId, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.UsersAdminApiFp = UsersAdminApiFp;
/**
 * UsersAdminApi - factory interface
 * @export
 */
var UsersAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Read All Gurus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllGurusApiV1AdminUsersAllGurusGet: function (options) {
            return (0, exports.UsersAdminApiFp)(configuration).readAllGurusApiV1AdminUsersAllGurusGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read User By Email Admin
         * @param {string} userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByEmailAdminApiV1AdminUsersByEmailGet: function (userEmail, options) {
            return (0, exports.UsersAdminApiFp)(configuration).readUserByEmailAdminApiV1AdminUsersByEmailGet(userEmail, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Request Verification Phone Admin
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost: function (userId, options) {
            return (0, exports.UsersAdminApiFp)(configuration).requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Submit Guru Code Admin
         * @param {number} userId
         * @param {string} guruCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitGuruCodeAdminApiV1AdminUsersGuruCodesPost: function (userId, guruCode, options) {
            return (0, exports.UsersAdminApiFp)(configuration).submitGuruCodeAdminApiV1AdminUsersGuruCodesPost(userId, guruCode, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Document Admin
         * @param {number} userId
         * @param {string} filename
         * @param {string} docType
         * @param {any} [file]
         * @param {string} [objectName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost: function (userId, filename, docType, file, objectName, options) {
            return (0, exports.UsersAdminApiFp)(configuration).uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost(userId, filename, docType, file, objectName, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Id Admin
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadIdAdminApiV1AdminUsersUserIdIdPost: function (userId, file, options) {
            return (0, exports.UsersAdminApiFp)(configuration).uploadIdAdminApiV1AdminUsersUserIdIdPost(userId, file, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Upload Profile Photo Admin
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost: function (userId, file, options) {
            return (0, exports.UsersAdminApiFp)(configuration).uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost(userId, file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UsersAdminApiFactory = UsersAdminApiFactory;
/**
 * UsersAdminApi - object-oriented interface
 * @export
 * @class UsersAdminApi
 * @extends {BaseAPI}
 */
var UsersAdminApi = /** @class */ (function (_super) {
    __extends(UsersAdminApi, _super);
    function UsersAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Read All Gurus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.readAllGurusApiV1AdminUsersAllGurusGet = function (options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).readAllGurusApiV1AdminUsersAllGurusGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read User By Email Admin
     * @param {string} userEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.readUserByEmailAdminApiV1AdminUsersByEmailGet = function (userEmail, options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).readUserByEmailAdminApiV1AdminUsersByEmailGet(userEmail, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Request Verification Phone Admin
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost = function (userId, options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).requestVerificationPhoneAdminApiV1AdminUsersVerificationPhoneUserIdPost(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Submit Guru Code Admin
     * @param {number} userId
     * @param {string} guruCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.submitGuruCodeAdminApiV1AdminUsersGuruCodesPost = function (userId, guruCode, options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).submitGuruCodeAdminApiV1AdminUsersGuruCodesPost(userId, guruCode, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Document Admin
     * @param {number} userId
     * @param {string} filename
     * @param {string} docType
     * @param {any} [file]
     * @param {string} [objectName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost = function (userId, filename, docType, file, objectName, options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).uploadDocumentAdminApiV1AdminUsersUserIdDocumentPost(userId, filename, docType, file, objectName, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Id Admin
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.uploadIdAdminApiV1AdminUsersUserIdIdPost = function (userId, file, options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).uploadIdAdminApiV1AdminUsersUserIdIdPost(userId, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Upload Profile Photo Admin
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAdminApi
     */
    UsersAdminApi.prototype.uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost = function (userId, file, options) {
        var _this = this;
        return (0, exports.UsersAdminApiFp)(this.configuration).uploadProfilePhotoAdminApiV1AdminUsersUserIdProfilePhotoPost(userId, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UsersAdminApi;
}(base_1.BaseAPI));
exports.UsersAdminApi = UsersAdminApi;
/**
 * UtilsApi - axios parameter creator
 * @export
 */
var UtilsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Test Celery worker.
         * @summary Test Celery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCeleryApiV1UtilsTestCeleryPost: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localVarPath = "/api/v1/utils/test-celery/";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Test emails.
         * @summary Test Email
         * @param {string} emailTo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmailApiV1UtilsTestEmailPost: function (emailTo_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([emailTo_1], args_1, true), void 0, function (emailTo, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarAccessTokenValue, _a, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // verify required parameter 'emailTo' is not null or undefined
                            if (emailTo === null || emailTo === undefined) {
                                throw new base_1.RequiredError('emailTo', 'Required parameter emailTo was null or undefined when calling testEmailApiV1UtilsTestEmailPost.');
                            }
                            localVarPath = "/api/v1/utils/test-email/";
                            localVarUrlObj = new URL(localVarPath, 'https://example.com');
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                            if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                            return [4 /*yield*/, configuration.accessToken("OAuth2PasswordBearer", [])];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, configuration.accessToken];
                        case 3:
                            _a = _b.sent();
                            _b.label = 4;
                        case 4:
                            localVarAccessTokenValue = _a;
                            localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
                            _b.label = 5;
                        case 5:
                            if (emailTo !== undefined) {
                                localVarQueryParameter['email_to'] = emailTo;
                            }
                            queryParameters = new URLSearchParams(localVarUrlObj.search);
                            for (key in localVarQueryParameter) {
                                queryParameters.set(key, localVarQueryParameter[key]);
                            }
                            for (key in options.query) {
                                queryParameters.set(key, options.query[key]);
                            }
                            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UtilsApiAxiosParamCreator = UtilsApiAxiosParamCreator;
/**
 * UtilsApi - functional programming interface
 * @export
 */
var UtilsApiFp = function (configuration) {
    return {
        /**
         * Test Celery worker.
         * @summary Test Celery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCeleryApiV1UtilsTestCeleryPost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UtilsApiAxiosParamCreator)(configuration).testCeleryApiV1UtilsTestCeleryPost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         * Test emails.
         * @summary Test Email
         * @param {string} emailTo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmailApiV1UtilsTestEmailPost: function (emailTo, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.UtilsApiAxiosParamCreator)(configuration).testEmailApiV1UtilsTestEmailPost(emailTo, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.UtilsApiFp = UtilsApiFp;
/**
 * UtilsApi - factory interface
 * @export
 */
var UtilsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Test Celery worker.
         * @summary Test Celery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCeleryApiV1UtilsTestCeleryPost: function (options) {
            return (0, exports.UtilsApiFp)(configuration).testCeleryApiV1UtilsTestCeleryPost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Test emails.
         * @summary Test Email
         * @param {string} emailTo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmailApiV1UtilsTestEmailPost: function (emailTo, options) {
            return (0, exports.UtilsApiFp)(configuration).testEmailApiV1UtilsTestEmailPost(emailTo, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UtilsApiFactory = UtilsApiFactory;
/**
 * UtilsApi - object-oriented interface
 * @export
 * @class UtilsApi
 * @extends {BaseAPI}
 */
var UtilsApi = /** @class */ (function (_super) {
    __extends(UtilsApi, _super);
    function UtilsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Test Celery worker.
     * @summary Test Celery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    UtilsApi.prototype.testCeleryApiV1UtilsTestCeleryPost = function (options) {
        var _this = this;
        return (0, exports.UtilsApiFp)(this.configuration).testCeleryApiV1UtilsTestCeleryPost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Test emails.
     * @summary Test Email
     * @param {string} emailTo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    UtilsApi.prototype.testEmailApiV1UtilsTestEmailPost = function (emailTo, options) {
        var _this = this;
        return (0, exports.UtilsApiFp)(this.configuration).testEmailApiV1UtilsTestEmailPost(emailTo, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UtilsApi;
}(base_1.BaseAPI));
exports.UtilsApi = UtilsApi;
/**
 * VitalsAdminApi - axios parameter creator
 * @export
 */
var VitalsAdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Read Mango Calls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMangoCallsApiV1AdminVitalsMangoCallsGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/admin/vitals/mango-calls";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootApiV1AdminVitalsTestIpGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, queryParameters, key, key, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    localVarPath = "/api/v1/admin/vitals/test-ip";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    queryParameters = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        queryParameters.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.query) {
                        queryParameters.set(key, options.query[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.VitalsAdminApiAxiosParamCreator = VitalsAdminApiAxiosParamCreator;
/**
 * VitalsAdminApi - functional programming interface
 * @export
 */
var VitalsAdminApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Read Mango Calls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMangoCallsApiV1AdminVitalsMangoCallsGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.VitalsAdminApiAxiosParamCreator)(configuration).readMangoCallsApiV1AdminVitalsMangoCallsGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootApiV1AdminVitalsTestIpGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, exports.VitalsAdminApiAxiosParamCreator)(configuration).readRootApiV1AdminVitalsTestIpGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
exports.VitalsAdminApiFp = VitalsAdminApiFp;
/**
 * VitalsAdminApi - factory interface
 * @export
 */
var VitalsAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Read Mango Calls
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMangoCallsApiV1AdminVitalsMangoCallsGet: function (options) {
            return (0, exports.VitalsAdminApiFp)(configuration).readMangoCallsApiV1AdminVitalsMangoCallsGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootApiV1AdminVitalsTestIpGet: function (options) {
            return (0, exports.VitalsAdminApiFp)(configuration).readRootApiV1AdminVitalsTestIpGet(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.VitalsAdminApiFactory = VitalsAdminApiFactory;
/**
 * VitalsAdminApi - object-oriented interface
 * @export
 * @class VitalsAdminApi
 * @extends {BaseAPI}
 */
var VitalsAdminApi = /** @class */ (function (_super) {
    __extends(VitalsAdminApi, _super);
    function VitalsAdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Read Mango Calls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VitalsAdminApi
     */
    VitalsAdminApi.prototype.readMangoCallsApiV1AdminVitalsMangoCallsGet = function (options) {
        var _this = this;
        return (0, exports.VitalsAdminApiFp)(this.configuration).readMangoCallsApiV1AdminVitalsMangoCallsGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VitalsAdminApi
     */
    VitalsAdminApi.prototype.readRootApiV1AdminVitalsTestIpGet = function (options) {
        var _this = this;
        return (0, exports.VitalsAdminApiFp)(this.configuration).readRootApiV1AdminVitalsTestIpGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return VitalsAdminApi;
}(base_1.BaseAPI));
exports.VitalsAdminApi = VitalsAdminApi;
